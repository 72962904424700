<template>
  <!-- <div class="law">
    <p class="law-p1">美国公司法律意见书介绍</p>
    <p class="law-p2">一、什么是“美国公司法律意见书”？</p>
    <p class="law-p3">美国公司法律意见书，是由美国执业律师对美国公司的真实性，目前状态，财务状况等各种数据收集整理之后，从法律专业角度给出的综合评价。<br />
    法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
    <p class="law-p2">二、哪些情况需要“美国公司法律意见书”？</p>
    <p class="law-p3">美国公司跟国内公司进行合作、投资或者办理银行贷款主体授信、融资上市、股权质押、提供保函等情况下，通常会要求提供“美国公司法律意见书”作为背景调查的其中一项证明！</p>
    <p class="law-p2">三、美国公司法律意见书”办理时长？</p>
    <p class="law-p3">美国公司法律意见书需要出具的内容不同，办理时间会有所差异，通常来讲，不需要更改资料的情况下，办理美国公司法律意见书”需要20个工作日左右！</p>
    <p class="law-p2">四、美国公司法律意见书”可以发表意见的内容？</p>
    <p class="law-p3">1、公司的基本情况，包括成立时间、公司类型、注册地址、法定代表人、注册资本、主要办公地点、董事情况、经营范围等。<br />
      2、公司是否为依法设立且合法存续，公司的章程是否符合当地法律法规的规定，是否存在破产、清算的情形。<br />
      3、公司成立至注销前的股本和股东情况，已发行的股份是否已被缴足，股东所持公司股权是否存在抵押、质押、留置等任何担保权益，是否存在纠纷或者潜在纠纷。<br />
      4、公司成立至注销前的历次股权变动情况（如发生过股权变动，请将变动情况逐一说明）。<br />
      5、成立以来，公司是否涉及诉讼、仲裁和行政处罚（包括已经完结的和尚未了结的），是否正在面临行政主管部门、司法机关的调查，如有，请逐一说明。<br />
      6、公司注销生效的时间，是否按照公司章程及当地法律的规定和要求完成了相应的注销手续。与其他股东（如有）之间是否存在纠纷及潜在纠纷。
    </p>
    <p class="law-p2">五、美国公司法律意见书”出具流程？</p>
    <p class="law-p3">1、由客户根据自身需要，提出需要出具含有哪些内容的法律意见书；<br />
      2、美国律师初审后，给出初步意见，是否能够出具，需要提供哪些支持文件；<br />
      3、客户按照律师要求提供相关文件，如注册证书，存续证明，章程等；<br />
      4、美国律师审阅相关文件，出具法律意见书草稿；<br />
      5、客户提出修改意见；<br />
      6、客户确定无误，美国律师出具最终意见书。</p> -->
  <!-- </div> -->
  <div class="law1">
    <div class="law-banner">
      <div class="banner-inner">
        <p class="banner-title">美国法律意见书</p>
        <p class="law-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="law-info">
      <p class="b-title">美国公司法律意见书</p>
      <div class="law-info-box">
        <div class="law-info-left">
          <p class="info-p1">美国公司法律意见书，是由美国执业律师对美国公司的真实性，目前状态，财务况等各种数据收集整理之后，从法律专业角度给出的综合评价。</p>
          <p class="info-p1">法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
          <p class="info-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <img class="law-info-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="law-when">
      <p class="b-title">哪些情况需要“美国公司法律意见书”？</p>
      <p class="b-title1">美国公司跟国内公司进行合作、投资或者办理银行贷款主体授信、融资上市、股权质押、提供保函等情况下，通常会要求提供“美国公司法律意见书”作为背景调查的其中一项证明！ </p>
      <div class="law-when-box">
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_2_1.jpg" alt="">
          <p class="when-p">美国公司跟国内公司进行合作、投资</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_2_2.jpg" alt="">
          <p class="when-p">办理银行贷款主体授信</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_2_3.jpg" alt="">
          <p class="when-p">融资上市</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_2_4.jpg" alt="">
          <p class="when-p">股权质押</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/pic_2_5.jpg" alt="">
          <p class="when-p">提供保函</p>
        </div>
      </div>
      <p class="when-tips">（美国公司法律意见书需要出具的内容不同，办理时间会有所差异，通常来讲，不需要更改资料的情况下，办理美国公司法律意见书”需要<span class="red-style">20</span>个工作日左右！）</p>
    </div>
    <div class="law-content">
      <p class="b-title">“美国公司法律意见书”可以发表意见的内容？</p>
      <div class="law-content-box">
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_1.png" alt="">
          <p class="content-p">公司的基本情况，包括成立时间、公司类型、注册地址、法定代表人、注册资本、主要办公地点、董事情况、经营范围等。</p>
        </div>
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_2.png" alt="">
          <p class="content-p">公司是否为依法设立且合法存续，公司的章程是否符合当地法律法规的规定，是否存在破产、清算的情形。</p>
        </div>
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_3.png" alt="">
          <p class="content-p">公司成立至注销前的股本和股东情况，已发行的股份是否已被缴足，股东所持公司股权是否存在抵押、质押、留置等任何担保权益，是否存在纠纷或者潜在纠纷。</p>
        </div>
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_4.png" alt="">
          <p class="content-p">公司成立至注销前的历次股权变动情况（如发生过股权变动，请将变动情况逐一说明）。</p>
        </div>
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_5.png" alt="">
          <p class="content-p">成立以来，公司是否涉及诉讼、仲裁和行政处罚（包括已经完结的和尚未了结的），是否正在面临行政主管部门、司法机关的调查，如有，请逐一说明。</p>
        </div>
        <div class="content-inner">
          <img class="content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_1_6.png" alt="">
          <p class="content-p">公司注销生效的时间，是否按照公司章程及当地法律的规定和要求完成了相应的注销手续。与其他股东（如有）之间是否存在纠纷及潜在纠纷。</p>
        </div>
      </div>
    </div>
    <div class="law-flow">
      <p class="b-title">“美国公司法律意见书”可以发表意见的内容？</p>
      <div class="law-flow-box">
        <div class="law-flow-top">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_1.png" alt="">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_2.png" alt="">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_3.png" alt="">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_4.png" alt="">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_5.png" alt="">
          <img class="law-flow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_6.png" alt="">
        </div>
        <img class="law-flow-center" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/icon_2_7.png" alt="">
        <div class="law-flow-bottom">
          <p class="law-flow-p">由客户根据自身需要，提出需要出具含有哪些内容的法律意见书</p>
          <p class="law-flow-p">美国律师初审后，给出初步意见，是否能够出具，需要提供哪些支持文件</p>
          <p class="law-flow-p">客户按照律师要求提供相关文件，如注册证书，存续证明，章程等</p>
          <p class="law-flow-p">美国律师审阅相关文件，出具法律意见书草稿</p>
          <p class="law-flow-p">客户提出修改意见</p>
          <p class="law-flow-p">客户确定无误，美国律师出具最终意见书</p>
        </div>
      </div>
    </div>
    <div class="odi-add" style="background: #fff;">
      <p class="b-title">增值服务</p>
      <p class="b-title1">我们还为您提供</p>
      <div class="odi-add-box center-width flex-between">
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的<br />申请和申报</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_7.png" alt="">
          <p class="odi-add-p">中国香港及境外公证</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律<br />意见书等服务</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bank-page',
  components: {
    Footer,
    Link
  },
  data(){
    return{
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>
<style>
  .law-banner{
    width: 100%;
    height: 500px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/AmericanLaw/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
