<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1-sing">新加坡公司注册</p>
        <p class="banner-p2-sing">做贸易 区块链 基金会 交易所</p>
        <p class="banner-p3">国际金融中心   外汇自由  贸易地缘优势  开户简单</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="benefits">
      <p class="re-title">选择注册新加坡公司的好处</p>
      <div class="benefits-box">
        <div class="benefits-inner">
          <img class="benefits-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_1_1.jpg" alt="">
          <p class="benefits-p1">国际金融中心</p>
          <p class="benefits-p2">外汇自由，贸易自由</p>
        </div>
        <div class="benefits-inner">
          <img class="benefits-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_1_2.jpg" alt="">
          <p class="benefits-p1">贸易群体东南亚</p>
          <p class="benefits-p2">地缘优势，同属欧盟</p>
        </div>
        <div class="benefits-inner">
          <img class="benefits-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_1_3.jpg" alt="">
          <p class="benefits-p1">开户问题</p>
          <p class="benefits-p2">新加坡开户简单，无烦恼</p>
        </div>
      </div>
    </div>
    <div class="s-type">
      <p class="re-title">选择注册新加坡公司的类型</p>
      <p class="re-title2"><span class="big-style">70%</span>的客户选择公众公司（基金会）</p>
      <div class="stype-box">
        <div class="stype-inner">
          <p class="sin-title">新加坡公众公司（基金会）</p>
          <p class="sin-title2">经营范围：</p>
          <p class="sin-p">需要提供两个经营范围，一般选择计算机软件、网络、信息技术等方面，可以发行货币、做交易所、发行基金</p>
          <p class="sin-title2">注册资料：</p>
          <p class="sin-p">两个及以上的股东董事的身份证/护照/驾驶证、签字样本</p>
          <p class="sin-p">公司英文名称：Limited.有限公司</p>
          <p class="sin-p">公司风控文件（快企务出具客户填写）</p>
          <p class="sin-p">新加坡籍的董事和秘书（快企务可为你提供）</p>
          <p class="sin-title2">注册资本：</p>
          <p class="sin-p">没有注册资金，但每个成员实缴1新币</p>
          <p class="sin-title2">税收政策：</p>
          <p class="sin-p">没有</p>
          <p class="sin-title2">注册时间：</p>
          <p class="sin-p">从资料备齐开始办理20至30个工作日</p>
          <p class="sin-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <div class="stype-inner stype-inner1">
          <p class="sin-title">新加坡私人有限公司</p>
          <p class="sin-title2">经营范围：</p>
          <p class="sin-p sin-p1">需要提供两个经营范围，主要是涉及日常贸易类的服务</p>
          <p class="sin-title2">注册资料：</p>
          <p class="sin-p">一个及以上的股东董事的身份证/护照/驾驶证、签字样本</p>
          <p class="sin-p">公司英文名称：PTE LTD私人有限公司</p>
          <p class="sin-p">公司风控文件（快企务出具客户填写）</p>
          <p class="sin-p">新加坡籍的董事和秘书（快企务可为你提供）</p>
          <p class="sin-title2">注册资本：</p>
          <p class="sin-p">标准3k新币，高于5w新币需要实际验资（1新币也可注册）</p>
          <p class="sin-title2">税收政策：</p>
          <p class="sin-p">公司所得税：17%，前三年可享受免税计划</p>
          <p class="sin-title2">注册时间：</p>
          <p class="sin-p">从资料备齐办理开始起15至20个工作日</p>
          <p class="sin-btn sin-btn1" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="process">
      <p class="re-title">注册新加坡公司优享服务</p>
      <p class="re-title2">为您提供从企业初期到后期全流程服务</p>
      <div class="pro-box">
        <div class="pro-inner">
          <img class="pro-img" style="width: 48px;height: 64px;"  src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_1_1.png" alt="">
          <p class="pro-text1">注册地址</p>
          <p class="pro-text2">提供公司注册地址</p>
        </div>
        <div class="pro-inner">
          <img class="pro-img" style="width: 54px;height: 66px;"  src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_1_2.png" alt="">
          <p class="pro-text1">新加坡董事</p>
          <p class="pro-text2">提供新加坡董事</p>
        </div>
        <div class="pro-inner">
          <img class="pro-img" style="width: 53px;height: 66px;"  src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_1_3.png" alt="">
          <p class="pro-text1">秘书服务</p>
          <p class="pro-text2">年审/报税</p>
        </div>
        <div class="pro-inner">
          <img class="pro-img" style="width: 65px;height: 49px;margin-top: 17px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_1_4.png" alt="">
          <p class="pro-text1">协助开户</p>
          <p class="pro-text2">直接当地开户</p>
        </div>
        <div class="pro-inner">
          <img class="pro-img" style="margin-top: 6px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_1_5.png" alt="">
          <p class="pro-text1">会计师协助</p>
        </div>
      </div>
    </div>
    <div class="sin-process">
      <p class="re-title">注册新加坡公司流程</p>
      <div class="sin-pro-box">
        <div class="sin-pro-inner1">
          <img class="sin-pro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_2_1.png" alt="">
          <p class="sin-pro-p">确认名称<br />查询注册</p>
        </div>
        <img class="right-arrow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon-right.png" alt="">
        <div class="sin-pro-inner1">
          <img class="sin-pro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_2_2.png" alt="">
          <p class="sin-pro-p">定制方案<br />流程明细</p>
        </div>
        <img class="right-arrow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon-right.png" alt="">
        <div class="sin-pro-inner1">
          <img class="sin-pro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_2_3.png" alt="">
          <p class="sin-pro-p">团队申报<br />专家建议</p>
        </div>
        <img class="right-arrow-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon-right.png" alt="">
        <div class="sin-pro-inner1">
          <img class="sin-pro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/icon_2_4.png" alt="">
          <p class="sin-pro-p">全程跟踪<br />进度汇报</p>
        </div>
      </div>
    </div>
    <div class="sin-secretary">
      <p class="re-title">新加坡公司更多服务</p>
      <p class="inner-title">秘书服务</p>
      <div class="sin-secret-box">
        <div class="sin-secret-inner se-inner1">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_1.png" alt="">
          <div class="hover-bg"></div>
          <p class="sin-secret-p">公司年审</p>
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner2">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_2.png" alt="">
          <div class="hover-bg"></div>
          <p class="sin-secret-p">公司审计</p>
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner3">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_3.png" alt="">
          <div class="hover-bg"></div>
          <p class="sin-secret-p">做账报税</p>
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner4">
          <div class="hover-bg"></div>
          <p class="sin-secret-p1">更多服务 ></p>
        </div>
      </div>
      <p class="inner-title">银行开户</p>
      <div class="sin-secret-box">
        <div class="sin-secret-inner se-inner5">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_4.png" alt="">
          <div class="hover-bg"></div>
          <img class="bank-icon bank-icon-1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/logo_1_1.png" alt="">
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner6">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_5.png" alt="">
          <div class="hover-bg"></div>
          <img class="bank-icon bank-ico2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/logo_1_2.png" alt="">
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner7">
          <img class="secre-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/pic_2_6.png" alt="">
          <div class="hover-bg"></div>
          <img class="bank-icon bank-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/logo_1_3.png" alt="">
          <p class="sin-secret-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="sin-secret-inner se-inner4">
          <div class="hover-bg"></div>
          <p class="sin-secret-p1">更多银行开户 ></p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">新加坡公司注册问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="为什么注册新加坡公司?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">1、国际总部优势：新加坡向来是美国、欧洲与日本公司设立亚洲总部的首选地点；<br />
                2、金融中心优势：新加坡不仅在国际金融、贸易融资、海事金融、保险、财务运作方面拥有领先地位；<br />
                3、研发中心优势：新加坡已建立了健全的研发(R&D)生态系统和完善的知识产权(IP)保障制度；<br />
                4、国际品牌和知识产权保护优势：新加坡是各大知识产权国际公约与条约如专利合作与贸易有关的知识产权协议
                </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="新加坡如何征收消费税?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">1、应该纳税的商品和服务营业额在新加坡超过一百新币。<br />
                2、应该纳税的商品和服务的营业额很可能会超过一百万新币。每季度申报一次，季度结束后一个月内完成申报(如果<br />
                没有及时申报，就会逐月产生200新币的罚款，最高可累计达到1万元新币的罚款)。
              </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="新加坡公司注册后投资海外其他国家，是否需要公证??" name="3">
            <div class="inner-pro">
              <div class="pro-p2">1、注册新加坡公司投资海外，需对新加坡的主体资格进行公证。<br />
                2、需要公证的主要文件包括:<br />
                A.	Bizfie(表格式文件，记录了股东、董事、登记地址、注册资本等纽节)<br />
                B.	CI(开立证书，相当于中国企业营业执照)<br />
                C.	会议纪要等(针对海外投资的高级决议)</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册新加坡公司的要求？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">1、公司名称:新加坡公司的名称必须是英文名，以XXX PTE.TLD.结尾；<br />
              2、注册地址:必须提供一个新加坡本地的注册地址；<br />
              3、营业范围:新加坡公司最多可以选择2个营业项目；<br />
              4、注册资本:注册资本最低从1元新币起，最高没有上限，无需验资；<br />
              5、公司董事:至少包含一名18岁以上的本地董事，如需银行开户，建议似定两位董事；<br />
              6、股东资格:股东组成无任何限制，可以是白然人，也可以是公司；<br />
              7、法定秘书:新加坡公司必须委托法定秘书；<br />
              8、提供材料:提供董事、股东相关材料以及股权分配比例说明；<br />
            </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title">我们的优势</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Singapore/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
