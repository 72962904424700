<template>
   <div class="member">
      <p class="member-title">专业人士</p>
      <div class="member-box">
        <el-carousel :interval="4000" arrow="always" height="730px" indicator-position="inside" :autoplay="true">
          <el-carousel-item>
            <div class="member-box1">
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_1.png" alt="">
                <p class="member-box-p1">Jack</p>
                <p class="member-box-p2">高级顾问经理</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_2.png" alt="">
                <p class="member-box-p1">Maggie</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_3.png" alt="">
                <p class="member-box-p1">Jane</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_4.png" alt="">
                <p class="member-box-p1">Cherry</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_5.png" alt="">
                <p class="member-box-p1">Gavin</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_6.png" alt="">
                <p class="member-box-p1">Dereck</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_7.png" alt="">
                <p class="member-box-p1">Tainly</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_8.png" alt="">
                <p class="member-box-p1">Irene</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="member-box1">
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_9.png" alt="">
                <p class="member-box-p1">Jason</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_10.png" alt="">
                <p class="member-box-p1">Mona</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <!-- <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_11.png" alt="">
                <p class="member-box-p1">Carney</p>
                <p class="member-box-p2">高级顾问</p>
              </div> -->
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_12.png" alt="">
                <p class="member-box-p1">Richard</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_13.png" alt="">
                <p class="member-box-p1">Daisy</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <!-- <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_14.png" alt="">
                <p class="member-box-p1">Davis</p>
                <p class="member-box-p2">高级顾问</p>
              </div> -->
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_15.png" alt="">
                <p class="member-box-p1">Tracy</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_16.png" alt="">
                <p class="member-box-p1">Jessica</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_17.png" alt="">
                <p class="member-box-p1">Jun</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_18.png" alt="">
                <p class="member-box-p1">Guoqing Yang</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="member-box1">
              
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_19.png" alt="">
                <p class="member-box-p1">Lisa</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
              <div class="member-box-inner">
                <img class="member-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_1_20.png" alt="">
                <p class="member-box-p1">Shirley</p>
                <p class="member-box-p2">高级顾问</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
</template>

<script>
export default {
  name: 'link-page',
  props: {
    msg: String
  }
}
</script>
<style>
.member{
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_3.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
