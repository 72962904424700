<template>
    <div class="all-advantage">
      <div class="all-advantage-inner">
        <div class="all-advantage-box">
          <img class="all-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_1secrecy_2.png" alt="">
          <p class="all-adv-p1">保密完善</p>
        </div>
        <div class="all-advantage-hover-box">
          <img class="all-adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_1secrecy_1.png" alt="">
          <p class="all-adv-p2">保密完善</p>
          <p class="all-adv-p3">保密系统完善，客户隐私不外泄</p>
        </div>
      </div>
      <div class="all-advantage-inner">
        <div class="all-advantage-box">
          <img class="all-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_2whole_course_2.png" alt="">
          <p class="all-adv-p1">全程服务</p>
        </div>
        <div class="all-advantage-hover-box">
          <img class="all-adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_2whole_course_1.png" alt="">
          <p class="all-adv-p2">全程服务</p>
          <p class="all-adv-p3">一站式专业服务从注册公司到年审、开户，审计报税等</p>
        </div>
      </div>
      <div class="all-advantage-inner">
        <div class="all-advantage-box">
          <img class="all-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_3major_2.png" alt="">
          <p class="all-adv-p1">专业高效</p>
        </div>
        <div class="all-advantage-hover-box">
          <img class="all-adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_3major_1.png" alt="">
          <p class="all-adv-p2">专业高效</p>
          <p class="all-adv-p3">根据需求，为客户制定专业快捷的解决方案</p>
        </div>
      </div>
      <div class="all-advantage-inner">
        <div class="all-advantage-box">
          <img class="all-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_4transparent_2.png" alt="">
          <p class="all-adv-p1">合同保障</p>
        </div>
        <div class="all-advantage-hover-box">
          <img class="all-adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_4transparent_1.png" alt="">
          <p class="all-adv-p2">合同保障</p>
          <p class="all-adv-p3">正规机构，合同保障，费用透明，无隐形消费</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'advantage-page',
  props: {
    msg: String
  }
}
</script>
<style scoped lang="scss">
 
</style>
