<template>
    <div class="hague">
      <div class="notarization-header">
        <div class="center-width">
          <p class="notarization-p2" style="padding-top: 130px;">贸促会认证</p>
          <p class="notarization-p1" style="padding-top: 0;margin-top: 19px;width: 685px;">贸促会认证也叫做报关单认证，是由中国国际贸易促进委员会出具签发的（贸促会商事认证，报关单商会认证，CCPIT认证）</p>
          <p class="notarization-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="exma-business-Am">
        <p class="b-title">贸促会认证办理</p>
        <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
        <div class="exma-business-Am-box center-width">
          <div class="exma-business-Am-inner">
            <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
              <el-form-item label="" prop="name">
                <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的联系方式"></el-input>
              </el-form-item>
              <el-form-item label="" prop="type">
                <el-select class="exma-select-length" v-model="form.type" clearable placeholder="请选择您要办理的文件认证类型">
                  <el-option label="商事证明书" value="商事证明书"></el-option>
                  <el-option label="原产地证书" value="原产地证书"></el-option>
                  <el-option label="不可抗力事实性证明" value="不可抗力事实性证明"></el-option>
                  <el-option label="ATA单证册" value="ATA单证册"></el-option>
                  <el-option label="自由销售证明书" value="自由销售证明书"></el-option>
                  <el-option label="出口商品品牌认证" value="出口商品品牌认证"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
              </el-form-item>
            </el-form>
          </div>
          <div class="exma-business-tips">
            <p class="exma-business-tips-p2">提交表单，免费咨询；或致电 <span class="middle-style">400-860-5600</span></p>
          </div>
        </div>
      </div>
      <div class="trade-file center-width">
        <p class="hague-title">代办贸促会认证，需要客户提供的资料</p>
        <div class="trade-file-box">
          <div class="trade-file-inner">
            <img class="trade-file-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_1.png" alt="">
            <img class="trade-file-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_1.png" alt="">
            <p class="trade-file-p">委托书</p>
          </div>
          <div class="trade-file-inner">
            <img class="trade-file-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_2.png" alt="">
            <img class="trade-file-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_2.png" alt="">
            <p class="trade-file-p">保函</p>
          </div>
          <div class="trade-file-inner">
            <img class="trade-file-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_3.png" alt="">
            <img class="trade-file-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_3.png" alt="">
            <p class="trade-file-p">需要认证的文件</p>
          </div>
          <div class="trade-file-inner">
            <img class="trade-file-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_4.png" alt="">
            <img class="trade-file-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_4.png" alt="">
            <p class="trade-file-p">营业执照副本复印件</p>
          </div>
          <div class="trade-file-inner">
            <img class="trade-file-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_5.png" alt="">
            <img class="trade-file-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_5.png" alt="">
            <p class="trade-file-p">翻译公司营业执照复印件</p>
          </div>
        </div>
      </div>
      <div class="trade-adv center-width">
        <p class="hague-title">我司办理贸促会认证的优势</p>
        <div class="trade-adv-box">
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_1.png" alt="">
              <p class="trade-adv-p1">时间优势</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">时间优势</p>
              <p class="trade-adv-p3">时间优势</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_2.png" alt="">
              <p class="trade-adv-p1">价格优势</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">价格优势</p>
              <p class="trade-adv-p3">我司成本：485+对应文件使馆的官费+对应的翻译费</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_3.png" alt="">
              <p class="trade-adv-p1">翻译价格优势</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">翻译价格优势</p>
              <p class="trade-adv-p3">翻译价格优势</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_4.png" alt="">
              <p class="trade-adv-p1">内容优势</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">内容优势</p>
              <p class="trade-adv-p3">内容优势</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_5.png" alt="">
              <p class="trade-adv-p1">经验丰富</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">经验丰富</p>
              <p class="trade-adv-p3">经验丰富</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_6.png" alt="">
              <p class="trade-adv-p1">渠道多</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">渠道多</p>
              <p class="trade-adv-p3">渠道多</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_7.png" alt="">
              <p class="trade-adv-p1">时间节点清晰</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">时间节点清晰</p>
              <p class="trade-adv-p3">时间节点清晰</p>
            </div>
          </div>
          <div class="trade-adv-inner">
            <div class="trade-adv-box1">
              <img class="trade-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_2_8.png" alt="">
              <p class="trade-adv-p1">办理方便快速</p>
            </div>
            <div class="trade-adv-box2">
              <p class="trade-adv-p2">办理方便快速</p>
              <p class="trade-adv-p3">办理方便快速</p>
            </div>
          </div>
        </div>
      </div>
      <div class="hague-banner center-width" style="margin-top: 60px;">
        <img class="hague-banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_2.png" alt="">
        <p class="hague-banner-p3">办理时间</p>
        <p class="hague-banner-p4">(1)	只做贸促会的商事证明书：3个工作日；可加急次日出文件（费用不一样）<br />(2)	贸促会的商事证明书+外交部+使馆：4个工作日+对应的使馆时间</p>
      </div>
      <div class="trade-deal center-width">
        <p class="hague-title">办理流程</p>
        <div class="trade-deal-box">
          <div class="trade-deal-inner">
            <img class="trade-deal-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_4_1.png" alt="">
            <p class="trade-deal-p">确认要办理的认证的文件</p>
          </div>
          <img class="trade-deal-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_3arrow.png" alt="">
          <div class="trade-deal-inner">
            <img class="trade-deal-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_4_2.png" alt="">
            <p class="trade-deal-p">按要求提供各项资料</p>
          </div>
          <img class="trade-deal-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_3arrow.png" alt="">
          <div class="trade-deal-inner">
            <img class="trade-deal-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_4_3.png" alt="">
            <p class="trade-deal-p">付款</p>
          </div>
          <img class="trade-deal-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_3arrow.png" alt="">
          <div class="trade-deal-inner">
            <img class="trade-deal-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_4_4.png" alt="">
            <p class="trade-deal-p">审核资料并安排递交</p>
          </div>
          <img class="trade-deal-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_3arrow.png" alt="">
          <div class="trade-deal-inner">
            <img class="trade-deal-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_4_5.png" alt="">
            <p class="trade-deal-p">文件办理完成</p>
          </div>
        </div>
      </div>
      <div class="trade-case center-width">
        <p class="hague-title">客户案例</p>
        <div class="trade-case-box">
          <div class="trade-case-inner">
            <img class="trade-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_3_1.jpg" alt="">
            <p class="trade-case-p">商事证明书（商标证明）</p>
          </div>
          <div class="trade-case-inner">
            <img class="trade-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_3_2.jpg" alt="">
            <p class="trade-case-p">商事证明书（签字印章证明）</p>
          </div>
          <div class="trade-case-inner">
            <img class="trade-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_3_3.jpg" alt="">
            <p class="trade-case-p">商事证明书（形式发票认证） </p>
          </div>
          <div class="trade-case-inner">
            <img class="trade-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_3_4.jpg" alt="">
            <p class="trade-case-p">商事证明书</p>
          </div>
        </div>
      </div>
      <div class="hague-consulting center-width">
        <p class="hague-title">咨询服务</p>
        <p class="hague-title1">我们还能为您做</p>
        <div class="hague-consulting-box">
            <div class="hague-consulting-left">
                <div class="consulting-inner">
                    <p class="consulting-p1">中国委托公证人公证</p>
                    <p class="consulting-p2">特聘具有中国委托公证人资格中国香港公证人，为中国香港文件、事实及法律文书等用于内地提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">国际公证认证</p>
                    <p class="consulting-p2">特聘具有国际公证员资格国际公证人，为中国香港文件、事实及法律文书等用于海外提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">使馆认证</p>
                    <p class="consulting-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
            </div>
            <div class="hague-consulting-right">
                <img class="hague-consulting-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_8.png" alt="">
            </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        tab: 1,
        form: {},
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
          type: [{ required: true, message: '请选择您要办理的文件认证类型', trigger: 'change'}],
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods:{
      toSubmit(){
        let that = this
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              type: 6,
              name: that.form.name,
              mobile: that.form.mobile,
              fileCertificType: that.form.type
            }
            that.$axios.post('/cms/message/save',params).then((result)=>{
              this.$notify({
                title: '提交成功',
                message: '我们会尽快联系您！',
                type: 'success',
                offset: 100,
                duration: 2000
              });
              that.form.name = ''
              that.form.mobile = ''
              that.form.type = null
            })
          } else {
            return false;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .notarization-header{
      width: 100%;
      height: 520px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  