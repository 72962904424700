<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式越南公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="vietnam-adv">
      <p class="re-title">注册越南公司的优势</p>
      <div class="vietnam-adv-box">
        <div class="vietnam-adv-inner">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/icon_1_1.png" alt="">
          <p class="vietnam-adv-p">人口结构年轻，劳动成本低，处于人口红利</p>
        </div>
        <div class="vietnam-adv-inner">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/icon_1_2.png" alt="">
          <p class="vietnam-adv-p">经济增长强劲<br />内需市场扩大</p>
        </div>
        <div class="vietnam-adv-inner">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/icon_1_3.png" alt="">
          <p class="vietnam-adv-p">产业结构转型<br />第三产业增长迅速</p>
        </div>
        <div class="vietnam-adv-inner">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/icon_1_4.png" alt="">
          <p class="vietnam-adv-p">电商行业增长快速<br />上升空间极大</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册越南公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/pic_1_3.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">越南有限责任公司</p>
          <p class="anquila-type-p3" v-if="changeTabIndex == 0">（外商独资有限责任公司）</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">分公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">代表处</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="indonesia-file">
      <p class="re-title">注册越南公司的条件/流程/资料</p>
      <div class="indonesia-file-box center-width flex-between">
        <div class="indonesia-file-left">
          <div class="indonesia-file-tab" :class="{'active': tab == 1}" @click="tab = 1">注册越南公司条件</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 2}" @click="tab = 2">注册越南公司流程</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</div>
        </div>
        <div class="indonesia-file-right">
          <div class="indonesia-file-right-box3" v-if="tab == 1">
            <!-- <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              公司名称：须为英文
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东董事：可以100%外国人
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              注册资金：10,000 ~ 300,000美金（还需看注册行业）
            </p> -->
            <div class="indonesia-file-tab3" style="margin-top: 60px;width: 650px">
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/else/icon_1_1.png" alt="">
                <p class="indonesia-file-tab3-p3">公司名称</p>
                <p class="indonesia-file-tab3-p" style="color: rgba(112, 112, 112, 1)">须为英文</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/else/icon_1_2.png" alt="">
                <p class="indonesia-file-tab3-p3">股东董事</p>
                <p class="indonesia-file-tab3-p" style="color: rgba(112, 112, 112, 1)">可以100%外国人</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/else/icon_1_3.png" alt="">
                <p class="indonesia-file-tab3-p3">注册资金</p>
                <p class="indonesia-file-tab3-p" style="color: rgba(112, 112, 112, 1)">10,000 ~ 300,000美金（还需看注册行业）</p>
              </div>
            </div>
          </div>
          <div class="indonesia-file-right-box1" v-if="tab == 2">
            <img style="width: 745px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/pic.png" alt="">
          </div>
          <div class="indonesia-file-right-box3" v-if="tab == 3">
            <div class="indonesia-file-tab3" style="margin-top: 80px">
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_1.png" alt="">
                <p class="indonesia-file-tab3-p">公司注册证书</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_2.png" alt="">
                <p class="indonesia-file-tab3-p">公司章程</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/else/icon_2_3.png" alt="">
                <p class="indonesia-file-tab3-p">公司股东会议记录簿</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_4.png" alt="">
                <p class="indonesia-file-tab3-p">法人公章、钢印</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册越南公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">越南公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="越南公司注册的方式有哪些?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">绝大多数国外公司或个人在越南开公司、建工厂，一般采用如下三种注册方式。<br />
              a.通过—家控股公司间接设立(单层控股)。<br />
              b.通过两家控股公司间接设立(双层控股)。<br />
              c.以个人名义或公司名义直接设立。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册越南公司的资金需要实际到位吗?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">需要，一般需要1万美金到10万美金之间，越南公司注册成功后的3个月内验资到位。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册越南公司需要哪些资料？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">
                a.注册资本（在取得公司注册证书90天内验资到位）；<br />
                b.注册地址（可由我方协助当地寻找）；c.营业范围；<br />
                d.法人名单（1位即可）；<br />
                e.董事名单（无国籍限制，外籍人士需在当地取得工作证及暂住证）；<br />
                f.股东名单、股份金额及股东财务证明（最少1位股东，股份金额无上限）；<br />
                g.所有股东、董事及法人护照扫描件/ 地址证明 （股东如是公司，请提供公司注册证书,）；<br />
                h.股东身份证明及财务证明需国内公证及越南驻中国使领馆认证。
              </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业越南公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
