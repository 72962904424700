<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式安圭拉公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="m-advantage an-advantage">
      <p class="re-title">注册安圭拉公司优势</p>
      <div class="m-adv-box">
        <div class="m-adv-right">
          <p class="an-ad-title">所有注册公司可以享受零税率</p>
          <p class="an-ad-title">免除任何在安圭拉岛当地以外从事经营活动的税收</p>
          <p class="an-ad-title">没有实收资本要求，没有报告要求</p>
          <p class="an-ad-title">董事及股东名称不公开，甲股东名册的副本必须存放在境外的公司的注册办事处</p>
          <p class="an-ad-title">公司名称可为任何语言，如需加中文名，需增加费用</p>
          <p class="an-ad-title">公司会议不一定要在安圭拉岛举行，可通过电话或其他电子方式举行</p>
        </div>
        <img class="m-adv-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/pic_1.png" alt="">
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册安圭拉公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <img class="anquila-type-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/pic_2.jpg" alt="">
        <div class="anquila-type-right">
          <p class="anquila-type-p1">有限责任公司</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="anquila-process">
      <p class="re-title">注册安圭拉公司类型</p>
      <div class="anquila-process-box center-width">
        <div class="anquila-process-tab">
          <p class="anquila-process-tab-p" :class="{'active': tab == 1}" @click="tab = 1">注册安圭拉公司条件</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 2}" @click="tab = 2">注册安圭拉公司流程</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 1">
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_1.png" alt="">
            <p class="anquila-process-p">拟1-3个公司名称（英文为准，中文为辅）</p>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_2.png" alt="">
            <p class="anquila-process-p">注册资金一般为100万美元，可以任何流通货币表达，可以发行不记名股票</p>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_3.png" alt="">
            <p class="anquila-process-p">政府规定有限公司必须有一名法定秘书</p>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_4.png" alt="">
            <p class="anquila-process-p">公司地址必须位于安圭拉</p>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_5.png" alt="">
            <p class="anquila-process-p">股东/董事身份证或者护照复印件</p>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_1_6.png" alt="">
            <p class="anquila-process-p">所有安圭拉岛公司必须在安圭拉岛设立注册办事处和注册代理人</p>
          </div>
        </div>
        <div class="anquila-process-content flex-style" v-if="tab == 2">
          <div class="anquila-process-inner inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/else/icon_1_1.png" alt="">
            <p class="anquila-process-p">注册证书</p>
          </div>
          <div class="anquila-process-inner inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/else/icon_1_2.png" alt="">
            <p class="anquila-process-p">公司董事及股东名册</p>
          </div>
          <div class="anquila-process-inner inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/else/icon_1_3.png" alt="">
            <p class="anquila-process-p">公司章程</p>
          </div>
          <div class="anquila-process-inner inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/else/icon_1_4.png" alt="">
            <p class="anquila-process-p">公司签证章、公司钢印</p>
          </div>
          <div class="anquila-process-inner inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/else/icon_1_5.png" alt="">
            <p class="anquila-process-p">公司股票</p>
          </div>
          <!-- <div class="anquila-process-inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2_1.png" alt="">
            <p class="anquila-process-p">1、客户填写委托书</p>
          </div>
          <img class="anquila-process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2arrow.png" alt="">
          <div class="anquila-process-inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2_2.png" alt="">
            <p class="anquila-process-p">2、客户提供1-3个注册公司名称，由金百利进行免费查名，一般需要1~2个工作日</p>
          </div>
          <img class="anquila-process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2arrow.png" alt="">
          <div class="anquila-process-inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2_3.png" alt="">
            <p class="anquila-process-p">3、双方签订订单</p>
          </div>
          <img class="anquila-process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2arrow.png" alt="">
          <div class="anquila-process-inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2_4.png" alt="">
            <p class="anquila-process-p">4、提供注册安圭拉公司所需相关资料及文件</p>
          </div>
          <img class="anquila-process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2arrow.png" alt="">
          <div class="anquila-process-inner2">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_2_5.png" alt="">
            <p class="anquila-process-p">5、我司到安圭拉政府各部门办理手续</p>
          </div> -->
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 3">
          <div class="anquila-process-inner3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_3_1.png" alt="">
            <p class="anquila-process-p">注册证书</p>
          </div>
          <div class="anquila-process-inner3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_3_2.png" alt="">
            <p class="anquila-process-p">公司董事及股东名册</p>
          </div>
          <div class="anquila-process-inner3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_3_3.png" alt="">
            <p class="anquila-process-p">公司章程</p>
          </div>
          <div class="anquila-process-inner3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_3_4.png" alt="">
            <p class="anquila-process-p">公司签证章、公司钢印</p>
          </div>
          <div class="anquila-process-inner3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_3_5.png" alt="">
            <p class="anquila-process-p">公司股票</p>
          </div>
        </div>
      </div>

    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册安圭拉公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_1bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">目前安圭拉公司可以开中国香港少部分银行、美国银行、内地离岸账户(NRA离岸账户和...</p>
          <p class="sub-p4">目前安圭拉公司可以开中国香港少部分银行、美国银行、内地离岸账户(NRA离岸账户和FTN离岸账户）等银行账户</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_2secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_3tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">安圭拉暂未与任何国度签署双边税务协定，依据1987年版本的国际公司法注册成...</p>
          <p class="sub-p4">安圭拉暂未与任何国度签署双边税务协定，依据1987年版本的国际公司法注册成立公司并不须要就营业收入作出申报或缴纳任何税项，会计报表可无需递交。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_4renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">每年须要续牌费用，期限是公司成立周年。因为是离岸公司，在本地没有经营，不...</p>
          <p class="sub-p4">每年须要续牌费用，期限是公司成立周年。因为是离岸公司，在本地没有经营，不须要做账报税；每年除了续牌费用外，并不须要支付其它费用，也不需递交年报。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_5.png" alt="">
          <p class="sub-p1">商标注册</p>
          <p class="sub-p3">商标权自英国注册日开始生效，若在该地区遇到商标侵权纠纷时，须在当地政府...</p>
          <p class="sub-p4">商标权自英国注册日开始生效，若在该地区遇到商标侵权纠纷时，须在当地政府也核准保护的情况下才能发起维权。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/icon_4_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">安圭拉公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册安圭拉公司的注册名称可以用中文吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">公司名称可用英文或/和加上中文注册。公司的英文名称必须以“LIMITED、CORPORATION、INCORPORATED<br />
                或它们的缩写”结尾，公司名称不能以“信托公司”或“银行”或其它被认为意思相似的词结尾，除非这些公司<br />
                获得相应的执照。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="无当地地址是否能注册公司？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">地址是必须要有才能注册公司的，并且是必须位于安圭拉当地持牌注册代理人的地址。注册地址我司可提供，费<br />
                用已包含在注册费或年费中。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="安圭拉公司的税务是如何核算的呢？都有什么税种？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">安圭拉暂未与任何国家签订双边税务协议，根据1987年版本的国际公司法注册成立公司并不需要就营业收入作<br />
                出申报或缴纳任何税项，会计报表无需递交。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业安圭拉公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      activeName: '1',
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Anquila/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .m-con-tab{
    .active{
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/pic_2.png') no-repeat center center;
      background-size: 100% 100%;
      color: #fff;
      .inner-span{
        border-left: 4px solid #fff;
      }
    }
  }
  
</style>
