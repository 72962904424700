<template>
  <div class="exam-kaiman">
    <div class="se-hong-header">
      <div class="banner-inner">
        <p class="se-hong-p1">BVI公司年审</p>
        <p class="se-hong-p2">一站式全程代办   最快两周完成</p>
        <p class="se-banner-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="exam-business center-width">
      <p class="b-title padding-top">BVI年审业务办理</p>
      <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="emba-use-box">
        <div class="exam-use-inner">
          <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
            <el-form-item label="" prop="name">
              <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
            </el-form-item>
            <el-form-item label="" prop="time">
              <el-select class="exma-input-length" v-model="form.time" placeholder="请选择年审时间">
                <el-option label="一月" value="一月"></el-option>
                <el-option label="二月" value="二月"></el-option>
                <el-option label="三月" value="三月"></el-option>
                <el-option label="四月" value="四月"></el-option>
                <el-option label="五月" value="五月"></el-option>
                <el-option label="六月" value="六月"></el-option>
                <el-option label="七月" value="七月"></el-option>
                <el-option label="八月" value="八月"></el-option>
                <el-option label="九月" value="九月"></el-option>
                <el-option label="十月" value="十月"></el-option>
                <el-option label="十一月" value="十一月"></el-option>
                <el-option label="十二月" value="十二月"></el-option>
              </el-select>
            </el-form-item>
              <el-form-item label="" prop="type">
                <el-select class="exma-input-length" v-model="form.type" placeholder="请选择年审服务类型">
                  <el-option label="BVI公司年审：1000美元" value="BVI公司年审：1000美元"></el-option>
                  <el-option label="BVI有限合伙年审：3500美元" value="BVI有限合伙年审：3500美元"></el-option>
                  <el-option label="BVI公司实质法申报：450美元" value="BVI公司实质法申报：450美元"></el-option>
                  <el-option label="BVI申请良好证明：400美元/份" value="BVI申请良好证明：400美元/份"></el-option>
                </el-select>
              </el-form-item>
          </el-form>
        </div>
        <p class="submit-btn" @click="toSubmit">提交</p>
        <p class="exma-tips">（注：1至6月份的公司要4月30日前审，5月1日之后有罚款；7至12月份的，要10月31日审，11月1日之后有罚款。逾期五个月仍未付款的公司将被撤销！）</p>
      </div>
    </div>
    <div class="exma-why exma-why-bvi">
      <p class="b-title padding-top">为什么要按时年审</p>
      <p class="b-title1">逾期年审影响</p>
      <div class="exma-why-box center-width flex-between">
        <div class="exma-why-inner">
          <img class="exma-why-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/bvi/icon_1_1.png" alt="">
          <p class="exma-why-p">公司各项运营得不到法律保障</p>
        </div>
        <div class="exma-why-inner">
          <img class="exma-why-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/bvi/icon_1_2.png" alt="">
          <p class="exma-why-p">产生逾期罚款</p>
        </div>
        <div class="exma-why-inner">
          <img class="exma-why-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/bvi/icon_1_3.png" alt="">
          <p class="exma-why-p">超过半年的将被注销注册</p>
        </div>
      </div>
    </div>
    <!-- <div class="exma-bvi-punish">
      <p class="b-title padding-top">BVI公司逾期年审的处罚</p>
      <div class="exma-bvi-punish-box">

      </div>
    </div> -->
    <div class="exma-punish exma-punish-bvi">
      <p class="b-title padding-top">BVI公司逾期年审的处罚</p>
      <div class="exma-punish-box center-width flex-between">
        <img class="exma-punish-right" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/bvi/pic_1.png" alt="">
        <div class="exma-punish-left">
          <p class="exma-punish-p">
            <img class="exma-punish-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_wailing_face.png" alt="">
            逾期<span class="blue-style">2个月内</span> ，逾期罚款金额为支付执照费用的
            <span class="exma-punish-span">10%</span>
            追加罚款
          </p>
          <p class="exma-punish-p">
            <img class="exma-punish-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_wailing_face.png" alt="">
            逾期超过  <span class="blue-style">6个月</span> 的，公司将被 
            <span class="exma-punish-span">注销注册</span>
          </p>
          <p class="exma-punish-p">
            <img class="exma-punish-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_wailing_face.png" alt="">
            在7月1日或之后但在9月30日或之前处罚  
            <span class="exma-punish-span">66.67%</span>
            的年费
          </p>
          <p class="exma-punish-btn" @click="Dialog = true">咨询详情</p>
        </div>
        
      </div>
    </div>
    <div class="exma-matters">
      <p class="b-title">BVI公司年审事项</p>
      <div class="exma-matters-box center-width flex-between">
        <div class="exma-matters-inner">
          <div class="exma-matters-box1">
            <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_1.png" alt="">
            <p class="exma-matters-p">年审周期</p>
          </div>
          <div class="exma-matters-hover-box">
            <p class="exma-matters-p1">年审周期</p>
            <p class="exma-matters-p2">上半年成立的要求在次年的5月30日之前提交年审；下半年成立的要求在次年的11月30日之前提交年审</p>
            <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="exma-matters-inner">
          <div class="exma-matters-box1">
            <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_2.png" alt="">
            <p class="exma-matters-p">所需资料</p>
          </div>
          <div class="exma-matters-hover-box">
            <p class="exma-matters-p1">所需资料</p>
            <p class="exma-matters-p2">BVI公司注册扫描件即可</p>
            <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="exma-matters-inner">
          <div class="exma-matters-box1">
            <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_3.png" alt="">
            <p class="exma-matters-p">年审费用</p>
          </div>
          <div class="exma-matters-hover-box">
            <p class="exma-matters-p1">年审费用</p>
            <p class="exma-matters-p2">年审730USD+150USD申报费用（如客户涉及经济实质法案定义的经营范围，需要另外确认）</p>
            <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="exma-matters-inner">
          <div class="exma-matters-box1">
            <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_4.png" alt="">
            <p class="exma-matters-p">办理时间</p>
          </div>
          <div class="exma-matters-hover-box">
            <p class="exma-matters-p1">办理时间</p>
            <p class="exma-matters-p2">15个工作日左右</p>
            <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="exma-matters-inner">
          <div class="exma-matters-box1">
            <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_5.png" alt="">
            <p class="exma-matters-p">年审完成后所得数据或文件</p>
          </div>
          <div class="exma-matters-hover-box">
            <p class="exma-matters-p1">年审完成后所得数据或文件</p>
            <p class="exma-matters-p2">年审回执</p>
            <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="one-server hong-serve">
      <p class="b-title">快企务优质秘书服务</p>
      <p class="b-title1">快企务为您提供更多服务</p>
      <div class="one-serve-box">
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_1.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">提供法定注册地址</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_2.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">提供法定秘书</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_3.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">申报年报</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_4.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">商务秘书服务</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_5.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">公司注册处更改数据</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_6.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">做无雇员申报</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>

    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import { Dialog } from 'element-ui'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {},
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        type: [{ required: true, message: '请选择年审服务类型', trigger: 'change'}],
        time: [{ required: true, message: '请选择年审时间', trigger: 'change'}]
      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 1,
            name: that.form.name,
            mobile: that.form.mobile,
            examinationTime: that.form.time,
            examinationType: that.form.type,
            country: 'BVI'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            }); 
            that.form.name = ''
            that.form.mobile = ''
            that.form.time = null
            that.form.type = null
          })
        } else {
          return false;
        }
      })
    },
    toAsk(){
      // window.href="http://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
      // document.write('<div id="LR_User_Icon0"></div>');
      if(typeof(LiveReceptionCode_isonline)!='undefined')
      console.log(1111)
      LiveReceptionCode_BuildChatWin('传递给客服人员的文本,请修改','客服人员不在线,请点击留言')
      // {
      //   if(LiveReceptionCode_isonline){
      //     console.log(222)
      //     LR_GetObj('LR_User_Icon0').innerHTML='<a '+LiveReceptionCode_BuildChatWin('传递给客服人员的文本,请修改','客服人员在线,欢迎点击咨询')+'>咨询</a>';
      //   }else{
      //     console.log(333)
      //     LR_GetObj('LR_User_Icon0').innerHTML='<a '+LiveReceptionCode_BuildChatWin('传递给客服人员的文本,请修改','客服人员不在线,请点击留言')+'>咨询';
      //   }
          
      // }
    }
  }
}
</script>

<style scoped lang="scss">
  .se-hong-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/bvi/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
