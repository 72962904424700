<template>
  <div class="noc-content">
    <div class="noc-content-header">
      <div class="noc-content-inner center-width">
        <div class="header-tag">
          <p class="tag-inner">无需回英国</p>
          <p class="tag-inner">平台式申请</p>
          <p class="tag-inner">合同保障</p>
        </div>
        <p class="noc-content-p1">英国无犯罪记录申请</p>
        <p class="noc-content-p2">移民 | 绿卡 | 工作 | 签证 | 留学 | 优才</p>
        <p class="noc-content-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="exma-business-Am">
      <p class="b-title">英国无犯罪记录办理</p>
      <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="exma-business-Am-box center-width">
        <div class="exma-business-Am-inner">
          <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
            <el-form-item label="" prop="name">
              <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的联系方式"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select class="exma-select-length" v-model="form.type" disabled placeholder="请选择您要办理的业务类型">
                <el-option label="有指纹无犯罪" value="有指纹无犯罪"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
            </el-form-item>
          </el-form>
        </div>
        <div class="exma-business-tips">
          <p class="exma-business-tips-p2">提交表单，免费咨询；或致电 <span class="middle-style">400-860-5600</span></p>
        </div>
      </div>
    </div>
    <div class="noc-type-amer">
      <p class="b-title">英国无指纹无犯罪办理</p>
      <div class="noc-type-amer-box">
        <div class="noc-type-amer-inner">
          <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/icon_1.png" alt="">
          <p class="noc-type-p1">有指纹无犯罪</p>
          <p class="noc-type-p2">电子版可申请加急</p>
          <p class="noc-type-p3">具体收费请咨询客户经理</p>
          <p class="noc-type-p4">可办理工作、入职、留学、续签证</p>
          <p class="noc-type-p4">需15 ~ 20个工作日</p>
          <p class="noc-type-p4">无需奔赴，就是这么任性</p>
          <p class="noc-type-p4">需申请表、护照扫描件、住址证明、照片</p>
          <p class="noc-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <p class="noc-type-amer-tips">注：全国各城市公证处均可采集公证指纹</p>
      </div>
    </div>
    <div class="noc-dept amer-dept">
      <p class="b-title">哪个部门可出具英国无犯罪记录证明</p>
      <div class="noc-dept-box">
        <div class="noc-dept-inner">
          <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_1.jpg" alt="">
          <div class="noc-dept-right">
            <p class="noc-dept-tag">无指纹无犯罪</p>
            <p class="noc-dept-p1">英国警察协会刑事记录办公室</p>
            <p class="noc-dept-p2">Office of criminal records,British Police Association</p>
            <p class="noc-dept-p3">名字（Name Based）检索的方式</p>
            <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="noc-validity">
      <p class="b-title">英国无犯罪记录证明有效期</p>
      <p class="b-title1">一般为6个月内的无犯罪证明，超出需重新办理</p>
      <div class="noc-validity-box center-width flex-between">
        <img class="noc-validity-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_2.png" alt="">
        <div class="noc-validity-right">
          <p class="noc-validity-p1">6个月以内无犯罪证明</p>
          <p class="noc-validity-p2">英国无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出6个月则需要重新办理</p>
          <p class="noc-validity-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="noc-advantage">
      <p class="re-title">服务优势</p>
      <div class="noc-advantage-box center-width flex-between">
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_1.png" alt="">
          <p class="noc-advantage-p">专业人员<br />全程跟进</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_2.png" alt="">
          <p class="noc-advantage-p">丰富经验<br />积累</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_3.png" alt="">
          <p class="noc-advantage-p">保护客户<br />隐私</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_4.png" alt="">
          <p class="noc-advantage-p">量身定制<br />办理方案</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_5.png" alt="">
          <p class="noc-advantage-p">合同保障<br />客户权益</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_6.png" alt="">
          <p class="noc-advantage-p">实时反馈<br />办理进度</p>
        </div>
      </div>
    </div>
    <!-- <div class="noc-case-britain">
      <p class="b-title">英国无犯罪记录证明案例</p>
      <p class="b-title1">看看办理过的人怎么说</p>
      <div class="noc-case-britain-box center-width flex-between">
        <div class="noc-case-britain-inner">
          <img class="noc-case-britain-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_1.png" alt="">
          <div class="noc-case-britain-right">
            <p class="noc-case-britain-tag">无犯罪证明（无指纹）</p>
            <p class="noc-case-britain-p">由于公证需要，找到了快企务办理无犯罪证明；很快就办理了下来，工作开展顺利，绿卡已经拿到，非常感谢！</p>
            <div class="noc-case-britain-bottom">
              <img class="noc-case-britain-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_3.png" alt="">
              <p class="noc-case-britain-p2">曾先生</p>
            </div>
          </div>
        </div>
        <div class="noc-case-britain-inner">
          <img class="noc-case-britain-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_1.png" alt="">
          <div class="noc-case-britain-right">
            <p class="noc-case-britain-tag">无犯罪证明（无指纹）</p>
            <p class="noc-case-britain-p">由于孩子留学需要，我找到了快企务办理无犯罪证明，接触过程中发现顾问很专业，能够快速明白我的需求，帮我准备资料申报，非常感谢！</p>
            <div class="noc-case-britain-bottom noc-case-britain-bottom1">
              <img class="noc-case-britain-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_4.png" alt="">
              <p class="noc-case-britain-p2">李女士</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="problem">
      <p class="re-title">英国无犯罪热门问答</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="在照片进行背书是什么意思?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">背书是需要申请人找你认识两年以上的朋友，有正当职业，不得是你的配偶或家庭成员，并且年满18岁（任何<br />
                国籍)。需要在照片背后写上申请人的姓氏和出生日期，背书人自己的签名并注明日期。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="警局证明信的有效期有多久?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">通常来说警局注册信没有一个明确的有效期，但是通常情况下如果申请人需要出具警局证明信来辅助签证申请，<br />
                则建议在警局注册信的出具日期在签证申请日期的6个月内出具。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="警局证明信将包含哪些信息?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">警局证明信将会包含申请人在英国警局系统当中所记录的所有的犯罪记录，警告等不良记录。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="如果我现在居住在国外，但我之前在英国居住过，那我如何证明自己的英国地址呢?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">如果申请人居住在国外，那么申请人不需要提供地址证明来证明其在英国的地址。申请人只需要提供地址证明来证明<br />
                现在的居住地址并告知警局办公室过去10年的英国地址。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="谁可以申请警局证明信?" name="5">
            <div class="inner-pro">
              <div class="pro-p2">任何在英国居住过的人都可以申请警局证明信。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="noc-new-case">
      <p class="re-title">英国无犯罪记录证明案例</p>
      <div class="noc-new-case-box">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_1_else.png" alt="">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_2_else.png" alt="">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_else.png" alt="">
      </div>
    </div>
    <div class="noc-handle">
      <div class="noc-handle-box center-width flex-between">
        <div class="noc-handle-left">
          <p class="noc-handle-p1">办理中国驻英国大使馆领事认证</p>
          <p class="noc-handle-p2">英国无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻英国大使馆的领事认证</p>
          <p class="noc-handle-p3">一、将申请下来的英国无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
          <p class="noc-handle-p3">二、提交至英国外交部或者省政府指定部门进行办理认证</p>
          <p class="noc-handle-p3">三、交至中国驻英国大使馆办理认证</p>
          <p class="noc-handle-p4">正常办理：15-18个工作日</p>
          <p class="noc-handle-p5">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
        </div>
        <img class="noc-handle-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_4_1.png" alt="">
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {
        type: '有指纹无犯罪'
      },
      activeName: '1',
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}]
      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 2,
            name: that.form.name,
            mobile: that.form.mobile,
            crimeType: '有指纹无犯罪',
            country: '英国'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .noc-content-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
