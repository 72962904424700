<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="a-banner-p1">美国公司注册</p>
        <p class="a-banner-p2">海外创业丨国际品牌丨国际贸易丨海外上市丨跨境电商开业丨移民签证</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>

    <div class="a-advantage">
      <p class="re-title">注册美国公司优势</p>
      <div class="a-adv-box">
        <div class="a-adv-left">
          <p class="a-ad-title">国际品牌</p>
          <p class="a-ad-p">美国企业在世界市场上实力雄厚，技术先进，注册美国公司面向世界，打造国际化品牌，扩大市场占有率</p>
          <p class="a-ad-title">跨境电商</p>
          <p class="a-ad-p">Ebay、亚马逊、沃尔玛作为美国本土企业，注册美国公司，可直接入驻电商平台，不出国门即可离岸经营获取订单</p>
          <p class="a-ad-title">移民签证</p>
          <p class="a-ad-p">注册美国公司提供赴美商务活动的机会，极大方便了赴美签证、组团商务考察，办理绿卡，享有公立学校教育等</p>
        </div>
        <img class="a-adv-center" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/pic_1.png"  alt="">
        <div class="a-adv-right">
          <p class="a-ad-title">无外汇管制</p>
          <p class="a-ad-p">美国无外汇管制，可接收外币，资金进出自由，贸易自由化程度高，有利于开展国际贸易</p>
          <p class="a-ad-title">税务负担小</p>
          <p class="a-ad-p">美国部分州，税率低、税种少，不在美国本土经营，免收公司所得税，减轻税务负担</p>
          <p class="a-ad-title">注册简单成本低</p>
          <p class="a-ad-p">注册美国公司经营范围受限少，注册程序简单，无需法人股东亲临现场</p>
        </div>
      </div>
    </div>

    <div class="a-welcome">
      <p class="re-title">注册美国公司哪些州比较受欢迎</p>
      <p class="re-title1">四大区域年注册量高达 <span class="big-blue">600+</span></p>
      <div class="a-wel-box">
        <img class="a-wel-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/karte_USA.png" alt="">
        <div class="a-wel-right">
          <div class="a-wel-inner" :class="{'active': wTab == 1}" @click="wTab = 1"> 
            <p class="a-wel-title">加利福尼亚州</p>
            <p class="a-wel-p">世界著名的商业城市，经济前景活跃，知名公司总部林立</p>
            <p class="a-wel-btn" @click="Dialog = true">立即注册</p>
          </div>
          <div class="a-wel-inner" :class="{'active': wTab == 2}" @click="wTab = 2">
            <p class="a-wel-title">纽约州</p>
            <p class="a-wel-p">交通发达，美国重要的商品中心，国际金融中心</p>
            <p class="a-wel-btn" @click="Dialog = true">立即注册</p>
          </div>
          <div class="a-wel-inner" :class="{'active': wTab == 3}" @click="wTab = 3">
            <p class="a-wel-title">特拉华州</p>
            <p class="a-wel-p">司法制度健全，效率高，免税州，无销售税等</p>
            <p class="a-wel-btn" @click="Dialog = true">立即注册</p>
          </div>
          <div class="a-wel-inner" :class="{'active': wTab == 4}" @click="wTab = 4">
            <p class="a-wel-title">科罗拉多州</p>
            <p class="a-wel-p">注册效率高，每年维护费用低，适合中企注册亚马逊账户</p>
            <p class="a-wel-btn" @click="Dialog = true">立即注册</p>
          </div>
        </div>
      </div>
      <p class="a-more-btn" @click="Dialog = true">更多地区注册</p>
    </div>
    
    <div class="a-how">
      <p class="re-title">怎样注册美国公司</p>
      <div class="a-how-box">
        <div class="a-how-inner">
          <p class="a-how-title">注册前准备材料</p>
          <!-- <p class="a-how-p1">公司英文名称及地址</p>
          <p class="a-how-p1">公司股东股份分配比例</p>
          <p class="a-how-p1">股东身份证明护照/身份证</p>
          <p class="a-how-p1">公司经营范围</p> -->
          <div class="how-flex">
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/else/icon_1_1.png" alt="">
              <p class="a-how-p3">公司英文名称</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/else/icon_1_2.png" alt="">
              <p class="a-how-p3">公司地址</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/else/icon_1_3.png" alt="">
              <p class="a-how-p3">公司股东股份<br />分配比例</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/else/icon_1_4.png" alt="">
              <p class="a-how-p3">股东身份证明<br />护照/身份证</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/else/icon_1_5.png" alt="">
              <p class="a-how-p3">公司经营范围</p>
            </div>
          </div>
        </div>
        <div class="a-how-inner">
          <p class="a-how-title">注册流程</p>
          <p class="a-how-p2 margin-top">公司核名</p>
          <img class="a-how-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/arrow.png" alt="">
          <p class="a-how-p2">签署委托代理协议</p>
          <img class="a-how-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/arrow.png" alt="">
          <p class="a-how-p2">提供注册文件资料</p>
          <img class="a-how-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/arrow.png" alt="">
          <p class="a-how-p2">审核通过，领取执照</p>
          <img class="a-how-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/arrow.png" alt="">
          <p class="a-how-p2">收取绿盒，完成注册</p>
        </div>
        <div class="a-how-inner">
          <p class="a-how-title">注册完成所获资料</p>
          <div class="how-flex">
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_1.png" alt="">
              <p class="a-how-p3">注册证书</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_2.png" alt="">
              <p class="a-how-p3">原子印章一枚</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_3.png" alt="">
              <p class="a-how-p3">公司章程</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_4.png" alt="">
              <p class="a-how-p3">金属钢印</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_5.png" alt="">
              <p class="a-how-p3">董事会记录</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_6.png" alt="">
              <p class="a-how-p3">文件盒</p>
            </div>
            <div class="a-how-right">
              <img class="a-how-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_1_7.png" alt="">
              <p class="a-how-p3">公司股票本</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="subsequent subsequent-a">
      <p class="re-title">注册美国公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_1annual_review.png" alt="">
          <p class="sub-p1">公司年审</p>
          <p class="sub-p2">美国公司注册后，每年按时年审，逾期产生罚款，影响公司正常运营</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司离岸账户，随时随地接收国际贸易汇款，绕开外汇管制，资金调度自由...</p>
          <p class="sub-p4">开设公司离岸账户，随时随地接收国际贸易汇款，绕开外汇管制，资金调度自由（开户行：华美银行、花旗银行等）</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_3federal_tax_number.png" alt="">
          <p class="sub-p1">申请联邦税号</p>
          <p class="sub-p2">联邦税号是美国公司申报税务的唯一凭证，同时也是美国银行开户的必备文件</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_5accounting_audit.png" alt="">
          <p class="sub-p1">做账审计</p>
          <p class="sub-p3">注册美国公司后若在美国经营，需交税做账，若不在美国经营，无需做账和交税...</p>
          <p class="sub-p4">注册美国公司后若在美国经营，需交税做账，若不在美国经营，无需做账和交税，但需要报税，可进行零申报</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_6change_data.png" alt="">
          <p class="sub-p1">变更资料</p>
          <p class="sub-p2">美国公司变更包括公司名称、公司注册地址、公司股东股权等美国公司章程里的...</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/icon_2_4cancellation.png" alt="">
          <p class="sub-p1">公司注销</p>
          <p class="sub-p3">美国公司注销需在营业期有效3个月内申请，申请前需注销公司银行账号与对...</p>
          <p class="sub-p4">美国公司注销需在营业期有效3个月内申请，申请前需注销公司银行账号与对公司财务进行清算</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="amer-map">
      <p class="re-title">美国州税图</p>
      <img class="map-box" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/map_1_else.jpg" alt="">
    </div>
    <div class="amer-map">
      <p class="re-title">美国销售税图</p>
      <img class="map-box" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/map_2_else.jpg" alt="">
    </div>
    <div class="problem">
      <p class="re-title">美国公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国在哪些州是“免税州“?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">免税州所指的是美国部分州为了鼓励各州创业而推出的相关税务优惠政策。例如特拉华州、德克萨斯州都是具有代表性的<br />
                免税州。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册美国公司需要交哪些税?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">美国税收分为联邦税和州税，联邦税是公司必须缴纳的，州税视情况而定。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="美国公司如何做零申报？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">美国公司注册后，需要进行年审和报税，如果刚注册公司没有利润，则不用缴税，但需要进行零申报报税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="如何注销美国公司?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">如果美国公司在年审逾期后提交注销美国公司申请，需要在提交注销公司的申请前进行支付年审和报税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业美国公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      activeName: '1',
      Dialog: false,
      wTab: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
