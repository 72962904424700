<template>
  <div class="mark">
    <div class="mark-header">
      <div class="mark-inner center-width">
        <p class="mark-p1">法国商标注册</p>
        <p class="mark-p2"> 法国是商标制度比较完善的国家,审查效率是世界上最快的国家之一。申请时以自然人或者法人身份委托法国当地代理，向法国国家工业产权局提出申请，其一份申请可以指定多个类别，商品分类采用尼斯协定分为45类。商标专用权从申请日起算，有效期十年，每十年可申请续展一次，续期申请应在注册有效期的最后 6个月内提交并缴纳续展费用。</p>
      </div>
    </div>
    <div class="mark-trait">
      <p class="b-title">下列文字和图形一般不能作为注册商标：</p>
      <div class="mark-trait-box center-width">
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3">某种商品通用名称</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 62px;">含有误导或欺骗消费者的名称不能作为商标</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 62px;">名称或图形不能冒犯公共<br />道德及亵渎宗教信仰</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 25px;">在有关商标保护的国际公约中明确规定，任何一个主权国家的国旗、国徽及国际组织的会旗、会徽不能作为商标图形</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 62px;">违反公共秩序及公共道德的标记</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 62px;">违反《巴黎公约》关于禁用标记的规定的标记</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 50px;">对商品或服务项目的内容或品质带有说明性质的标记</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3">有欺骗公众之嫌的标记</p>
        </div>
      </div>
    </div>
    <div class="mark-file">
      <p class="b-title">法国商标注册申请资料</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-left">
          <p class="mark-file-p">以法人申请，附《营业执照》或有效登记证明复印件1份；以自然人申请附个人身份证明文件1 份</p>
          <p class="mark-file-p">申请人名义、地址中英文</p>
          <p class="mark-file-p">商标图样</p>
          <p class="mark-file-p">需保护 商品 / 服务名称和类别</p>
          <p class="mark-file-p">优先权声明（如需要）</p>
        </div>
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="mark-program" style="background: rgba(242, 245, 250, 1);height: 755px;">
      <p class="b-title">法国商标注册程序时间</p>
      <div class="mark-program-box center-width flex-between">
        <div class="mark-program-inner" style="background:#fff;width: 285px;height: 332px">
          <div class="mark-program-title"></div>
          <p class="mark-program-p1">法国商标主管机关对递交的商标注册申请首先进行形式审查，即审查申请文件是否齐备，商标图样是否清晰，商品或服务类别是否准确。对于通过形式审查的商标申请，审查员仅仅审查商标是否具有显著特征，是否包含不得作为商标的名称或标记，而不对在先权利冲突进行检索。如果商标不存在禁止注册的情况，商标将被公告</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;width: 285px;height: 332px">
          <div class="mark-program-title title2"></div>
          <p class="mark-program-p1">如果经审查，其商标缺乏显著性或属于禁止注册条款所列名称或标记，商标申请将被驳回，申请人可以向巴黎上诉法院提出上诉，要求国家工业产权局重新审理</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;width: 285px;height: 332px">
          <div class="mark-program-title title3"></div>
          <p class="mark-program-p1">通过审查的商标将被刊登在商标公告上。公告期为两个月。注册商标所有人或在先申请或享有优先权日的申请人，以及驰名商标所有人均可以对公告商标提出异议</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;width: 285px;height: 332px">
          <div class="mark-program-title title4"></div>
          <p class="mark-program-p1">通过审查的商标将被刊登在商标公告上。公告期为两个月。注册商标所有人或在先申请或享有优先权日的申请人，以及驰名商标所有人均可以对公告商标提出异议</p>
        </div>
      </div>
      <p class="mark-program-tips1">法国商标注册主管机关</p>
      <p class="mark-program-tips2">法国的商标主管部门是 <span class="blue-style">国家工业产权局(INTI: INSTITUT NATIONAL DE LAPROPRIETE INEUSTRIELLE)。
</span>
<br />该局除在巴黎设有总部外，还在波尔多、里昂、马赛、南希、尼斯、雷恩、斯特拉斯堡等大中城市设有七个分局。受理商标注册的部门是国家工业产权局及各地的商业法院。<br />
因此，在法国的商标注册登记有两种途径：一是商标申请人到国家工业产权局（巴黎总部或上述七个分局之一）登记；二是到公司所在的商业法院（TRIBUNAL DE COMMERCE）登记。这两种登记方式具有同等法律效力。</p>
    </div>
    <div class="mark-file" style="height: 672px">
      <p class="b-title">商标异议申请</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-right">
          <img class="mark-file-img" style="width: 484px;height: 298px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/pic_1.png" alt="">
        </div>
        <div class="mark-file-left">
          <p class="mark-file-p">商标异议的内容范围很广，既包括初步审定的商标与申请在先的商标相同或近似，也包括初步审定的商标违反了《商标法》的禁用条款或商标不具显著性，还包括申请人不具备申请资格等；</p>
          <p class="mark-file-p">提出商标异议的可以是任何人，即：既可以是商标注册人，也可以是非商标注册人，既可以是企业、事业单位，也可以是个人，既可以是法人，也可以是非法人；</p>
          <p class="mark-file-p">商标的异议期为初步审定的商标自公告之日起三个月内，商标异议申请日期，以商标局请书件的日期为准。异议期的最后一天是节假日的，可顺延至节假日后的第收到申一个工作日；</p>
          <p class="mark-file-p">商标异议人须交送《商标异议书》，在异议书中要将被异议商标的申请人、商品类别、商标刊登初审公告的日期、公告期号和初审号填写清楚。反之，当别人对自己商标注册提出异议时，被异议人可以在期限内作出答辩</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册法国商标的程序？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">1、向法国国家工业产权局递交申请。<br />
2、形式审查（1-2个月内拿到申请号及申请文件）<br />
提交申请后约两周内受理。申请受理后起1-2个月内进行形式审查，对于违反法律规定的商标或是缺乏显著性<br />
的商标，法国工业产权局拒绝注册。<br />
3、公告：公告期为2个月，任何人可在此期间内提出异议。<br />
4、核准注册<br />
如果无人在公告期提出异议，或是异议不被支持，则商标予以核准注册。<br />
整个顺利的注册过程，大约需要8-10月。<br />
商标的有效期为十年，自申请日起算。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="法国商标权利的丧失？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">1、对于属于商标法中所列的不具备显著性的商标和不得作为商标注册的商标，均被视为无效商标，将丧失其<br />
                商标权利。<br />
2、商标的撤销。如果商标申请是以对第三人权利的欺诈手段或者以违反法律责任或协议不正当方式取得的，<br />
认为对该商标享有权利的当事人，可以向法院起诉，请求撤销该商标。提出撤销商标的请求期限是3年，但对<br />
于恶意申请的商标，不受3年时间的限定。<br />
3、商标所有权人无正当理由，连续5年没有在注册商标指定的产品或服务上使用其商标，将丧失其商标权。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="法国商标生效日期？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">商标生效的日期以国家工业产权局或商业法院收到申请书为准。如果某项商标最终获得注册批准，其商标生效<br />
                日期从国家工业产权局或商业法院收到申请书开始计算。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="法国商标注册的审查？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">重点审查以下几个方面：<br />
1、商标的合法性规定：国家工业产权在收到申请后有4个月期限来审查该商标是否违背了有关规定，该商标文<br />
字及图形是否适合于作为商标。<br />
2、商标的合法性审查：国家工业产权局在收到商标申请后6周内该申请对外公布，所有认为已经注册了相同或<br />
类似商标公司都可以提出异议。其程序是到国家工业产权局填写一张“反对注册表”，详细说明自己的理由及<br />
依据。<br />
3、申请表填写是否正确：国家工业产权局如果认为所申请商标不符合上述两条标准，将拒绝批准该商标。如某<br />
一商标申请被国家工业产权局驳回，申请人向上诉法院（COURD'APPEL）提出申诉，要求国家工业产权局重新<br />
审理。<br />
4、法国商标注册证书：如国家工业产权局批准某一商标注册的申请，将发给申请人一个“商标注册号”及“商<br />
标注册证书”，并对外公布。商标生效的日期以国家工业产权局或商业法院收到的申请书为准。如果某项商标<br />
最终获得注册批准，其商标生效日期从为家工业产权局或商业法院收到申请书开始计算。<br />
5、法国商标保护期： 法国商标保护期为10年。期满后半年内可以续展。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="mark-why">
      <p class="re-title">为什么选择我们</p>
      <div class="mark-why-box center-width flex-between">
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_1.png" alt="">
          <p class="mark-why-p1">快速</p>
          <p class="mark-why-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_2.png" alt="">
          <p class="mark-why-p1">专业</p>
          <p class="mark-why-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_3.png" alt="">
          <p class="mark-why-p1">严谨</p>
          <p class="mark-why-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_4.png" alt="">
          <p class="mark-why-p1">保障</p>
          <p class="mark-why-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .mark-header{
    width: 100%;
    height: 320px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program-title{
    width: 100%;
    height: 40px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/pic_3.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .title2{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/pic_4.png') no-repeat center center;
  }
  .title3{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/pic_5.png') no-repeat center center;
  }
  .title4{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/French/pic_6.png') no-repeat center center;
  }
</style>
