<template>
    <div class="exam-kaiman">
      <div class="se-hong-header">
        <div class="banner-inner">
          <p class="se-hong-p1">新加坡公司年审</p>
          <p class="se-hong-p2">一站式全程代办   最快1天完成</p>
          <p class="se-banner-btn" @click="Dialog = true">立即办理</p>
        </div>
      </div>
      <div class="exam-business center-width">
        <p class="b-title padding-top">新加坡年审业务办理</p>
        <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
        <div class="emba-use-box">
          <div class="exam-use-inner">
            <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
              <el-form-item label="" prop="name">
                <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
              </el-form-item>
              <el-form-item label="" prop="time">
                <el-select class="exma-input-length" v-model="form.time" placeholder="请选择年审时间">
                  <el-option label="第一个月（成立后）" value="第一个月（成立后）"></el-option>
                  <el-option label="第二个月（成立后）" value="第二个月（成立后）"></el-option>
                  <el-option label="第三个月（成立后）" value="第三个月（成立后）"></el-option>
                  <el-option label="第四个月（成立后）" value="第四个月（成立后）"></el-option>
                  <el-option label="第五个月（成立后）" value="第五个月（成立后）"></el-option>
                  <el-option label="第六个月（成立后）" value="第六个月（成立后）"></el-option>
                  <el-option label="第七个月（成立后）" value="第七个月（成立后）"></el-option>
                  <el-option label="第八个月（成立后）" value="第八个月（成立后）"></el-option>
                  <el-option label="第九个月（成立后）" value="第九个月（成立后）"></el-option>
                  <el-option label="第十个月（成立后）" value="第十个月（成立后）"></el-option>
                  <el-option label="第十一个月（成立后）" value="第十一个月（成立后）"></el-option>
                  <el-option label="第十二个月（成立后）" value="第十二个月（成立后）"></el-option>
                  <el-option label="第十三个月（成立后）" value="第十三个月（成立后）"></el-option>
                  <el-option label="第十四个月（成立后）" value="第十四个月（成立后）"></el-option>
                  <el-option label="第十五个月（成立后）" value="第十五个月（成立后）"></el-option>
                  <el-option label="第十六个月（成立后）" value="第十六个月（成立后）"></el-option>
                  <el-option label="第十七个月（成立后）" value="第十七个月（成立后）"></el-option>
                  <el-option label="第十八个月（成立后）" value="第十八个月（成立后）"></el-option>
                </el-select>
              </el-form-item>
                <el-form-item label="" prop="type">
                  <el-select class="exma-input-length" v-model="form.type" placeholder="请选择年审服务类型">
                    <el-option label="新加坡公司年审" value="新加坡公司年审"></el-option>
                    <el-option label="新加坡基金会年审" value="新加坡基金会年审"></el-option>
                  </el-select>
                </el-form-item>
            </el-form>
          </div>
          <p class="submit-btn" @click="toSubmit">计算费用</p>
          <p class="exma-tips">（注：如不能及时年审，可在年审到期前可以向ACRA在线申请延期，可延期两个月（上限）。）</p>
        </div>
      </div>
      <div class="exma-sin-why">
        <p class="b-title padding-top">为什么要按时年审</p>
        <p class="b-title1">逾期年审影响</p>
        <div class="exma-sin-why-box center-width flex-between">
          <div class="exma-sin-why-inner">
            <div class="exma-sin-why-box1">
              <img class="exma-sin-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/icon_1_1.png" alt="">
              <p class="exma-sin-why-p">公司及董事被起诉及罚款风险</p>
            </div>
            <div class="exma-sin-why-hover">
              <p class="exma-sin-why-p1">公司及董事被起诉及罚款风险</p>
              <p class="exma-sin-why-p2">若公司没能在公司法所要求的期限内召开股东大会以及完成年检的申报，公司本身及公司董事将会被法院起诉以及罚款。</p>
            </div>
          </div>
          <div class="exma-sin-why-inner">
            <div class="exma-sin-why-box1">
              <img class="exma-sin-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/icon_1_2.png" alt="">
              <p class="exma-sin-why-p">被法院定罪后影响董事资格</p>
            </div>
            <div class="exma-sin-why-hover">
              <p class="exma-sin-why-p1">被法院定罪后影响董事资格</p>
              <p class="exma-sin-why-p2">若公司没能在公司法所要求的期限内召开股东大会以及完成年检的申报，公司本身及公司董事将会被法院起诉以及罚款。</p>
            </div>
          </div>
          <div class="exma-sin-why-inner">
            <div class="exma-sin-why-box1">
              <img class="exma-sin-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/icon_1_3.png" alt="">
              <p class="exma-sin-why-p">个人商业信誉受损</p>
            </div>
            <div class="exma-sin-why-hover">
              <p class="exma-sin-why-p1">个人商业信誉受损</p>
              <p class="exma-sin-why-p2">若公司没能在公司法所要求的期限内召开股东大会以及完成年检的申报，公司本身及公司董事将会被法院起诉以及罚款。</p>
            </div>
          </div>
        </div>
      </div>
      <div class="exma-sin-time">
        <p class="b-title padding-top">新加坡公司年审办理时间与注意事项</p>
        <p class="b-title1">《新加坡公司法》175、197和201(1)条对公司年审期限有明确规定，公司每年需召开股东大会AGM、提交年报报告和财务报告。2018年，新加坡对年审规定的时限进行了全面更新。按财务年度为2018年8月31日或之后的新加坡公司，年审时限如下：</p>
        <div class="exma-sin-time-box center-width flex-between">
          <div class="exma-sin-time-inner">
            <img class="exma-sin-time-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/pic_1_1.png" alt="">
            <div class="exma-sin-time-inner-box">
              <p class="exma-sin-time-p1">私营有限公司</p>
              <p class="exma-sin-time-p2">财务年度终了后6个月内必须召开联合调查会，7个月内必须召开联合调查组</p>
              <p class="exma-sin-time-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-sin-time-inner">
            <img class="exma-sin-time-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/pic_1_2.png" alt="">
            <div class="exma-sin-time-inner-box">
              <p class="exma-sin-time-p1">公共公司</p>
              <p class="exma-sin-time-p2">财务年度终了后4个月内必须召开工作总结会议，6个月内必须提交董事会审议</p>
              <p class="exma-sin-time-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
        </div>
        <p class="exma-sin-time-tips">如逾期不交年审，则会被注册局罚款，每次逾期罚款300新币，总罚款300至900新币(约4500人民币）</p>
      </div>
      <div class="exma-matters">
        <p class="b-title">新加坡公司年审事项</p>
        <div class="exma-matters-box center-width flex-between">
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_1.png" alt="">
              <p class="exma-matters-p">年审周期</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">年审周期</p>
              <p class="exma-matters-p2">公司设立时间一年后的前一个月的月底最后一天</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_2.png" alt="">
              <p class="exma-matters-p">所需资料</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">所需资料</p>
              <p class="exma-matters-p2">我司注册的不需要提供文件即可办理年审，其他公司注册的开曼公司需先办理转秘书之后办理年审业务</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_3.png" alt="">
              <p class="exma-matters-p">年审费用</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">年审费用</p>
              <p class="exma-matters-p2">普通公司零运营年审费用4280元，如果公司控股并且有业务的情况下，XBRL费用2500元</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_4.png" alt="">
              <p class="exma-matters-p">办理时间</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">办理时间</p>
              <p class="exma-matters-p2">10个工作日</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_5.png" alt="">
              <p class="exma-matters-p">年审完成后所得数据或文件</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">年审完成后所得数据或文件</p>
              <p class="exma-matters-p2">最新电子版注册纸</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
        </div>
      </div>
      <div class="one-server hong-serve">
        <p class="b-title">快企务优质秘书服务</p>
        <p class="b-title1">快企务为您提供更多服务</p>
        <div class="one-serve-box">
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_1.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">提供法定注册地址</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_2.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">提供法定秘书</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_3.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">申报年报</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_4.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">商务秘书服务</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_5.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">公司注册处更改数据</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_6.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">做无雇员申报</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        form: {},
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
          type: [{ required: true, message: '请选择年审服务类型', trigger: 'change'}],
          time: [{ required: true, message: '请选择年审时间', trigger: 'change'}]
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      toSubmit(){
        let that = this
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              type: 1,
              name: that.form.name,
              mobile: that.form.mobile,
              examinationTime: that.form.time,
              examinationType: that.form.type,
              country: '新加坡'
            }
            that.$axios.post('/cms/message/save',params).then((result)=>{
              this.$notify({
                title: '提交成功',
                message: '我们会尽快联系您！',
                type: 'success',
                offset: 100,
                duration: 2000
              }); 
              that.form.name = ''
              that.form.mobile = ''
              that.form.time = null
              that.form.type = null
            })
          } else {
            return false;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .se-hong-header{
      width: 100%;
      height: 600px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/Singapore/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  