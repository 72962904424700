<template>
  <div class="certification mark">
    <div class="certification-header">
      <div class="certification-inner center-width">
        <p class="certification-p2" style="padding-top: 124px">VIE架构搭建</p>
        <p class="certification-p3" >互联网企业都选择在群岛注册公司</p>
        <p class="certification-p4">快企务为您提供公司注册+注册地址+法定秘书一站式服务</p>
        <p class="certification-btn" style="background: rgba(47, 114, 235, 1);" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="vie-intro">
      <p class="b-title">什么是VIE架构？</p>
      <p class="b-title1">VIE架构（Variable Interest Equity，直译为“可变利益实体”）是一种境内企业间接实现境外上市目的的一种模式。在VIE架构下，境外上市主体与境内经营实体相分离，境外上市主体通过协议的方式控制境内经营实体，并将境内经营实体的会计报表并入境外上市主体，从而实现使用境内经营主体的业绩在境外挂牌上市。</p>
      <p class="ask-btn">立即咨询 ></p>
      <img class="vie-intro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_1.jpg" alt="">
    </div>
    <div class="vie-framework">
      <p class="b-title">马云、张勇等大佬海外上市咋这么钟情VIE架构？</p>
      <div class="framework-box">
        <el-carousel :interval="4000" height="400px">
          <el-carousel-item>
            <div class="framework-inner">
              <p class="framework-p1">腾讯</p>
              <p class="framework-p2">腾讯虽然是在中国香港上市，但同样搭建了完善的VIE结构。从下图中及港交所公报可以看出，腾讯的经营实体是深圳市腾讯计算机系统有限公司（以下简称腾讯计算机系统）和深圳市世纪凯旋科技有限公司（以下简称世纪凯旋），这两家公司的股权结构完全一样，由马化腾控股54.29%，其余股东为张志东、许晨晔、陈一丹。</p>
              <p class="framework-p2">WOFE公司为腾讯科技（深圳）有限公司（以下简称腾讯科技），该公司由注册在英属维京群岛的中霸集团有限公司全资控股，同样经营利润通过WFOE腾讯科技最终转移到上市主体开曼公司</p>
              <img class="framework-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_2_4.png" alt="">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="framework-inner">
              <p class="framework-p1">阿里巴巴</p>
              <p class="framework-p2">阿里巴巴目前的体系太非常庞杂，阿里巴巴在境内的经营实体是最底层的浙江淘宝网络有限公司和浙江天猫网络有限公司。</p>
              <p class="framework-p2">浙江淘宝网络和浙江天猫网络的法定代表人都是蒋凡，两家公司的股东是杭州臻希投资管理有限公司，杭州臻希投资管理有限公司的股东两家内资的有限合伙企业，合伙人都是阿里的高管。而这两家公司的利润通过WFOE公司最终输送到阿里巴巴开曼公司。</p>
              <img class="framework-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_2_1.png" alt="">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="framework-inner">
              <p class="framework-p1">京东</p>
              <p class="framework-p2">京东的主体结构相对简单，实际经营主体是北京京东叁佰陆拾度电子商务有限公司，原法定代表人为刘强东（2020年11月25日变更为张奇），股东是刘强东等三位高管；而WOFE公司北京京东世纪贸易有限公司，该公司由京东中国香港国际有限公司全资控股，同样经营利润通过WFOE北京京东世纪贸易有限公司转移到上市主体开曼公司阿里巴巴集团。</p>
              <img class="framework-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_2_2.jpg" alt="">
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="framework-inner">
              <p class="framework-p1">百度</p>
              <p class="framework-p2">百度的经营主体是北京百度网讯科技有限公司（以下简称百度网讯），李彦宏持股99.5%，百度网讯在各地控股27家子公司（四家已经注销）负责当地的运营。而WOFE公司为百度在线网络技术（北京）有限公司（以下简称百度在线），该公司由注册在英属维京群岛的百度控股有限公司全资控股，同样经营利润通过WFOE百度在线最终转移到上市主体开曼公司。</p>
              <p class="framework-p2">百度在线这家WFOE由百度控股全资控股，百度网讯将利润通过百度在线转移到上市开曼公司。</p>
              <img class="framework-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_2_3.png" alt="">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="vie-benefit">
      <p class="b-title">VIE架构有哪些好处？</p>
      <p class="b-title1">VIE架构实现了公司“经营权”和“收益权”的分离，境外实体获得了收入和资产，境内实体负责经营和管理。通过VIE协议，企业就能一举两得，既满足国内监管的要求，又满足境外上市要求，同时满足双重标准。</p>
      <div class="benefit-box">
        <div class="benefit-outer">
          <div class="benefit-inner">
            <img class="benefit-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_1_1.png" alt="">
            <div class="benefit-text">
              <p class="benefit-p1">方便融资</p>
              <p class="benefit-p2">中国大陆有外汇管制政策，如果在海外有架构，就更容易获得海外资本；</p>
            </div>
          </div>
          <div class="benefit-inner">
            <img class="benefit-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_1_2.png" alt="">
            <div class="benefit-text">
              <p class="benefit-p1">躲过证监会的苛刻条件</p>
              <p class="benefit-p2">根据中国证监会的文件，境内企业要到境外融资，要满足“四五六”原则，即企业总资产不得少于4亿，上一年利润不少于6000万，上市的融资额度不少于5000万美元；</p>
            </div>
          </div>
          <div class="benefit-inner">
            <img class="benefit-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_1_3.png" alt="">
            <div class="benefit-text">
              <p class="benefit-p1">财富最大化</p>
              <p class="benefit-p2">上市之后，没有流通上的限制，容易把股票抛售变现；</p>
            </div>
          </div>
        </div>
        <img class="benefit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/pic_3_1.jpg" alt="">
        <div class="benefit-outer">
          <div class="benefit-inner">
            <img class="benefit-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_1_4.png" alt="">
            <div class="benefit-text">
              <p class="benefit-p1">规避了限制外资的领域</p>
              <p class="benefit-p2">中国法律对部分领域是禁止或限制外资进入的，如互联网信息服务、旅游、网络出版、教育、娱乐等</p>
            </div>
          </div>
          <div class="benefit-inner">
            <img class="benefit-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_1_5.png" alt="">
            <div class="benefit-text">
              <p class="benefit-p1">简化上市程序</p>
              <p class="benefit-p2">避免了烦琐冗长的境内企业境外上市的审批程序；即便千辛万苦通过了如商务部、外管局等N个部门的审批成功在境外上市，万一业务扩张要增发股份获发行债券，还得要再报批；</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="odi-serve1 vie-flow">
      <p class="b-title">架构搭建流程（以VIE架构为例）</p>
      <p class="b-title1">由于不同行业特征及境内运营主体股权结构的特殊性，不同项目有不同的搭建流程。以VIE架构为例，最常规的架构流程如下。</p>
      <div class="odi-serve1-box center-width flex-between">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_2_1.png" alt="">
            <p class="odi-serve1-title-p">SPV设立</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">注册第一层SPV</p>
            <p class="vie-bottom-p">注册融资主体</p>
            <p class="vie-bottom-p">注册第二层SPV（如需）</p>
            <p class="vie-bottom-p">注册返程外商主体</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_2_2.png" alt="">
            <p class="odi-serve1-title-p">37号文登记</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">为创始人及个人投资者完成37号文登记</p>
            <p class="vie-bottom-p">为股权激励员工完成37号文登记（或预留期权池）</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_2_3.png" alt="">
            <p class="odi-serve1-title-p">WFOE注册</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">完成外商主体资格认证</p>
            <p class="vie-bottom-p">完成返程投资WFOE注册</p>
            <p class="vie-bottom-p">完成FDI登记并开立资本金账户</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_2_4.png" alt="">
            <p class="odi-serve1-title-p">签订VIE协议</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">独家管理顾问与业务合作协议</p>
            <p class="vie-bottom-p">独家认购权协议</p>
            <p class="vie-bottom-p">股权质押协议</p>
            <p class="vie-bottom-p">配偶承诺书等</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_2_5.png" alt="">
            <p class="odi-serve1-title-p">向机构投资<br />人发股</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">境外机构投资人发股</p>
            <p class="vie-bottom-p">境内机构投资人认股权证确权</p>
            <p class="vie-bottom-p">境外出资境内减资（如需）</p>
          </div>
        </div>
      </div>
    </div>
    <div class="odi-serve1 vie-flow" style="height: 596px">
      <p class="b-title">服务流程</p>
      <p class="b-title1">在双方确定合作后，我们将组建由律师、税务师及行业咨询师等专业人员构成的项目组，分别协调银行、外管局、商务局及发改委等多部门工作，全流程完成架构搭建。</p>
      <div class="odi-serve1-box center-width flex-between">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" style="width: 40px;height: 40px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_3_1.png" alt="">
            <p class="odi-serve1-title-p">确定合作</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">签署项目合同</p>
            <p class="vie-bottom-p">签署保密协议</p>
            <p class="vie-bottom-p">支付项目款项</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" style="width: 40px;height: 40px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_3_2.png" alt="">
            <p class="odi-serve1-title-p">项目启动</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">建立项目组</p>
            <p class="vie-bottom-p">双方确定对接人</p>
            <p class="vie-bottom-p">提交基础资料清单</p>
            <p class="vie-bottom-p">确定合理的投资方案</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" style="width: 40px;height: 40px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_3_3.png" alt="">
            <p class="odi-serve1-title-p">项目执行</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">完成注册、开户及相关部门合规手续</p>
            <p class="vie-bottom-p">签订VIE协议</p>
            <p class="vie-bottom-p">机构投资人发股等</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" style="width: 40px;height: 40px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_3_4.png" alt="">
            <p class="odi-serve1-title-p">项目验收</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">境内外多线并行推进</p>
            <p class="vie-bottom-p">分阶段确认工作成果</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" style="width: 40px;height: 40px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/icon_3_5.png" alt="">
            <p class="odi-serve1-title-p">项目完成</p>
          </div>
          <div class="odi-serve1-bottom1">
            <p class="vie-bottom-p">境外SPV全套文件</p>
            <p class="vie-bottom-p">37号文登记凭证</p>
            <p class="vie-bottom-p">相关审批证书</p>
            <p class="vie-bottom-p">融资主体股票本</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="vie-advantage">
      <p class="b-title">快企务优势</p>
    </div> -->
    <div class="odi-add" style="background: #fff;">
      <p class="b-title">增值服务</p>
      <p class="b-title1">我们还为您提供</p>
      <div class="odi-add-box center-width flex-between">
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的<br />申请和申报</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_7.png" alt="">
          <p class="odi-add-p">中国香港及境外公证</p>
        </div>
        <div class="odi-add-inner" style="background: rgb(242, 245, 250) ;">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律<br />意见书等服务</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {},
      activeName: '1',
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}]
      }
    }
  },
  mounted(){
    // window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 8,
            name: that.form.name,
            mobile: that.form.mobile
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .certification-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/VIE/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
