<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式中国台湾公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="t-advantage">
      <p class="re-title">注册台湾公司优势</p>
      <div class="t-adv-box">
        <div class="t-adv-inner">
          <p class="t-adv-number">01</p>
          <p class="t-adv-p">台湾商品在市场上很有竞争力</p>
          <img class="t-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_1_1.png" alt="">
          <img class="t-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_1_2.png" alt="">
        </div>
        <div class="t-adv-inner">
          <p class="t-adv-number">02</p>
          <p class="t-adv-p">有利于公司品牌宣传，提升公司品牌形象及公司信誉</p>
          <img class="t-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_2_1.png" alt="">
          <img class="t-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_2_2.png" alt="">
        </div>
        <div class="t-adv-inner">
          <p class="t-adv-number">03</p>
          <p class="t-adv-p">大陆政府无法查到台湾公司的税</p>
          <img class="t-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_3_1.png" alt="">
          <img class="t-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_3_2.png" alt="">
        </div>
        <div class="t-adv-inner">
          <p class="t-adv-number">04</p>
          <p class="t-adv-p">台湾政府针对出口有减税的优惠</p>
          <img class="t-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_4_1.png" alt="">
          <img class="t-adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_1_4_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="t-type">
      <p class="re-title">注册中国台湾公司类型</p>
      <div class="t-type-box">
        <el-carousel :interval="5000" arrow="always" height="420px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="t-type-inner">
              <img class="t-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/pic_1_1.jpg" alt="">
              <div class="shadow-box"></div>
              <p class="t-type-p1">注册台湾分公司</p>
              <p class="t-type-p2">分公司具有完整的业务能力，在合法的情形下执行分公司营业执照上所载明的所有业务，可以从事商业活动，签署合同、独立开票</p>
              <p class="t-type-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="t-type-inner">
              <img class="t-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/pic_1_2.jpg" alt="">
              <div class="shadow-box"></div>
              <p class="t-type-p1">注册台湾（新）公司</p>
              <p class="t-type-p2">大陆企业或个人前往台湾注册设立公司，可根据自身需要决定是采取独资或是合资的方式，两者只有股权结构不同，其他涉及公司管理营运的事宜依照台湾的公司法及行业相关法规为准，执行日常业务。</p>
              <p class="t-type-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="t-type-inner">
              <img class="t-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/pic_1_3.jpg" alt="">
              <div class="shadow-box"></div>
              <p class="t-type-p1">台湾办事处</p>
              <p class="t-type-p2">以公司名义在台湾设立的联络点，不能从事商业活动，只能代表总公司做经营联络活动</p>
              <p class="t-type-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="t-conditions">
      <p class="re-title">注册中国台湾公司条件/流程/资料</p>
      <div class="t-con-box">
        <div class="t-cond-inner">
          <p class="t-cond-top"></p>
          <p class="t-con-title">注册中国台湾公司条件</p>
          <div class="t-cond-box1">
            <div class="t-cond-style1">
              <p class="t-cond-p1">提供台湾公司名称（提供给我司核名）</p>
            </div>
            <div class="t-cond-style1">
              <p class="t-cond-p1">提供台湾公司董事和股东</p>
            </div>
            <div class="t-cond-style1 t-cond-style2">
              <p class="t-cond-p1">提供台湾公司的经营范围（特殊行业：不动产、传播、广告、金融都不能经营）</p>
            </div>
            <div class="t-cond-style1 t-cond-style2">
              <p class="t-cond-p1">提供台湾公司注册资本（如注册资金没有20万美金，到时要有一位台湾人作管理人；资金需要实际到资，我司不负责垫资）</p>
            </div>
          </div>
        </div>
        <div class="t-cond-inner">
          <p class="t-cond-top top2"></p>
          <p class="t-con-title">注册中国台湾公司流程</p>
          <div class="t-cond-box2">
            <p class="t-cond-box2-p">台湾公司名称核实</p>
            <p class="t-cond-box2-p p2">大陆人身份、护照公证、委托书公证</p>
            <p class="t-cond-box2-p p3">台湾投审会审批</p>
            <p class="t-cond-box2-p">办理统一编码</p>
            <p class="t-cond-box2-p p2">台湾经济部商业司申请执照</p>
            <p class="t-cond-box2-p p3">台湾公司卡户，资金汇入台湾公司验资户办理验资</p>
            <p class="t-cond-box2-p">办理税务登记（亲赴台湾税务面谈）</p>
          </div>
        </div>
        <div class="t-cond-inner inner3">
          <p class="t-cond-top top3"></p>
          <p class="t-con-title">注册完成后所得资料</p>
          <div class="t-cond-box3">
            <div class="t-cond-box3-inner">
              <img class="t-cond-box3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_2_1.png" alt="">
              <p class="t-cond-box3-p">台湾公司设立登记表</p>
            </div>
            <div class="t-cond-box3-inner">
              <img class="t-cond-box3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_2_2.png" alt="">
              <p class="t-cond-box3-p">台湾公司营业执照</p>
            </div>
            <div class="t-cond-box3-inner">
              <img class="t-cond-box3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_2_3.png" alt="">
              <p class="t-cond-box3-p">公司章程</p>
            </div>
            <div class="t-cond-box3-inner">
              <img class="t-cond-box3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_2_4.png" alt="">
              <p class="t-cond-box3-p">董事股东资料</p>
            </div>
            <div class="t-cond-box3-inner">
              <img class="t-cond-box3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_2_5.png" alt="">
              <p class="t-cond-box3-p">台湾公司印章一套（公司章、人名章）</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent">
      <p class="re-title">注册中国台湾公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_1.png" alt="">
          <p class="sub-p1">公司续牌</p>
          <p class="sub-p2">在台湾，年审年报被称为公司续牌，指台湾公司注册后已发办理续期经营的</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_3_2secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p2">具体包含公司秘书、电话代接代转、信件代接代转、货物代接代转等等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/icon_3_3tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">台湾公司注册后，税务交给会计师管理，每2个月要报一次营业税，按5%收取。年...</p>
          <p class="sub-p4">台湾公司注册后，税务交给会计师管理，每2个月要报一次营业税，按5%收取。年底再报一次营利事业所得税，按20%收取。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_4.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">公司决定不再续牌之后，及时注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">中国台湾公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册台湾公司的优势、好处？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">1、中国台湾具有优越的设计能力,丰沛的创新研发能力。<br />
                2、中国台湾是全球科技产业重镇,坚实的产业聚落,软硬基建环境兼备,亚太运输枢纽。<br />
                3、国际机构对中国台湾的肯定,中国台湾产业竞争力和中国台湾投资环境佳。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="中国台湾公司对股东的要求？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">股份有限公司须至少有法人股东1人或自然人股东2人（于有限公司之情形，1人以上之自然人或法人股东），且<br />
                均得为居住于中国台湾境外之投资人。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="台湾公司经营范围有哪些限制？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">1、台湾公司经营范围较宽松，注册时需要体现经营范围，除特殊行业，如不动产、传播、广告、金融等不得<br />
                经营，其它都可经营。<br />
                2、股东是大陆公司的称为陆资企业，只能从事许可范围内的业务。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="台湾公司注册股东和董事的要求?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">1、年满18周岁有身份证明文件的人士均可以申请台湾公司注册<br />
              2、股东至少一位，股东和董事可为同一人，可以是自然人或法人担任，没有国籍限制；</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业台湾公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      activeName: '1',
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
  .t-cond-box2{
    width: 557px;
    height: 271px;
    margin-top: 30px;
    margin-left: 15px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Taiwan/pic_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
