<template>
  <div class="fiscal">
    <div class="fiscal-header">
      <div class="header-box">
        <p class="fiscal-p1">十余年专业财税筹划</p>
        <p class="fiscal-p2">美国专业会计师团队<span class="blue-style"> 1对1 </span>为您服务</p>
        <p class="fiscal-p3">正规审计报告   解决税务难题</p>
        <p class="bank-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="f-amer-impact">
      <p class="b-title">在美国成立公司不做财税筹划的影响</p>
      <div class="f-amer-impact-box center-width flex-between">
        <div class="f-amer-impact-inner">
          <img class="f-amer-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/icon_1_1.png" alt="">
          <p class="f-amer-impact-p">逾期产生罚款</p>
        </div>
        <div class="f-amer-impact-inner">
          <img class="f-amer-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/icon_1_2.png" alt="">
          <p class="f-amer-impact-p">公司被强制注销</p>
        </div>
        <div class="f-amer-impact-inner">
          <img class="f-amer-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/icon_1_3.png" alt="">
          <p class="f-amer-impact-p">银行账户被关闭</p>
        </div>
      </div>
    </div>
    <div class="f-which">
      <p class="b-title">哪些美国公司必须做账审计</p>
      <div class="f-which-box">
        <div class="f-which-left">
          <p class="f-which-p f-which-p1">银行账户已经留下经营记录</p>
          <p class="f-which-p f-which-p2">政府海关、物流公司已经留下进出口记录</p>
          <p class="f-which-p f-which-p3">与美国客商发生购销头条</p>
          <p class="f-which-p f-which-p4">在美国已经聘请雇员</p>
          <p class="f-which-p f-which-p5">容许或授权在美国使用专利商标设计等资料</p>
          <p class="f-which-p f-which-p6">容许或授权在美国使用动产收取租金，租赁费等款项</p>
          <p class="f-which-p f-which-p7">委托在美国代销</p>
          <p class="f-which-p f-which-p8">其它得自或产生于美国的利润</p>
          <p class="f-which-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <div class="f-which-right" style="width: 550px;height: 550px;">
          <img class="f-which-bg" style="width: 550px;height: 550px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/pic_1.png" alt="">
        </div>
      </div>
    </div>
    <div class="f-cost">
      <p class="b-title" style="color: #fff;">美国公司报税费用</p>
      <p class="b-title1" style="color: rgba(214, 231, 255, 1);">资深顾问为您估算价格</p>
      <div class="f-cost-box">
        <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
          <div class="f-cost-line">
            <el-form-item label="" prop="type">
              <el-select class="g-sub-select" v-model="form.type" placeholder="美国公司报税类型">
                <el-option label="零申报" value="零申报"></el-option>
                <el-option label="实报税" value="实报税"></el-option>
                <el-option label="出审计报告" value="出审计报告"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="received">
              <el-select class="g-sub-select" v-model="form.received" placeholder="美国公司是否收到税表">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="不清楚" value="不清楚"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="money">
              <el-select class="g-sub-select" v-model="form.money" placeholder="银行账户进账多少金额">
                <el-option label="50万以下" value="50万以下"></el-option>
                <el-option label="50-200万" value="50-200万"></el-option>
                <el-option label="200-1000万" value="200-1000万"></el-option>
                <el-option label="1000万以上" value="1000万以上"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="f-cost-line">
            <el-form-item label="" prop="name">
              <el-input class="line-input" style="width: 400px" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="line-input" style="width: 400px" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="f-cost-line">
          <p class="f-cost-btn" @click="toSubmit">计算费用</p>
        </div>
      </div>
    </div>
    <div class="f-crs">
      <p class="b-title">如何规避美国银行调查和税务信息影响</p>
      <div class="f-crs-box" style="margin-top: 80px">
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/pic_2_1.png" alt="">
          <p class="f-crs-p">选择在美国进行税务<br />申报的优势</p>
        </div>
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/pic_2_2.png" alt="">
          <p class="f-crs-p">选择正规的美国会计<br />师行做账审计</p>
        </div>
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/pic_2_3.png" alt="">
          <p class="f-crs-p">出具正规的财务报表<br />让税务局无处挑剔</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">财税筹划专业指导</p>
      <p class="b-title1">财税筹划中常见的专业问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国公司注册下来后要交税吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2"></div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="美国公司交税以什么作为依据?" name="2">
            <div class="inner-pro">
              <div class="pro-p2"></div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="如何判断美国公司需要缴纳的税种?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">每个州的基本情况不同，比如加州有800美金的基本州税，不管公司是否盈利都需缴纳。无基本州税的地区按<br />
                照公司营业利润申报州税及联邦税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="f-process">
      <p class="b-title">记帐/核数/报税流程</p>
      <p class="b-title1">全程跟进</p>
      <div class="f-process-box">
        <img class="f-process-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/pic_3.png" alt="">
      </div>
    </div>
    <div class="f-audit">
      <p class="b-title">一站式专业审计服务</p>
      <p class="b-title1">我们为您提供</p>
      <div class="f-audit-box">
        <el-carousel :interval="5000" arrow="always" height="270px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="f-audit-box1">
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_1.png" alt="">
                <p class="f-audit-p1">做账报税</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_2.png" alt="">
                <p class="f-audit-p1">税务筹划</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="f-audit-box1">
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_3.png" alt="">
                <p class="f-audit-p1">审计报告</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_4.png" alt="">
                <p class="f-audit-p1">税务指南</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'fiscalTax-page',
  components: {
    Footer,
    Link
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      form: {},
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        type: [{ required: true, message: '中国香港公司报税类型', trigger: 'change' }],
        received: [{ required: true, message: '中国香港公司是否收到税表', trigger: 'change' }],
        money: [{ required: true, message: '银行账户进账多少金额', trigger: 'change' }]
      },
      Dialog: false,
      options: [],
      value: '',
      activeName: '3'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    // toSubmit(){
    //   this.$refs['ruleForm'].validate((valid) => {
    //     if (valid) {
    //       this.$message({
    //         message: '提交成功！',
    //         type: 'success'
    //       });
    //     } else {
    //       return false;
    //     }
    //   })
    // }
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 3,
            name: that.form.name,
            mobile: that.form.mobile,
            taxType: that.form.type,
            received: that.form.received,
            bankAccountReceipt: that.form.money,
            country: '美国'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
            that.form.type = null
            that.form.received = null
            that.form.money = null
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.fiscal-header{
  width: 100%;
  height: 600px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/America/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
