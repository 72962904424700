<template>
  <div class="anquila macau">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式塞浦路斯公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="cyprus-advantage center-width">
      <p class="re-title">注册塞浦路斯公司优势</p>
      <div class="cyprus-adv-box flex-between">
        <div class="cyprus-adv-inner">
          <img class="cyprus-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_1_1.png" alt="">
          <p class="cyprus-adv-p">欧盟互免关税分红没有税收</p>
        </div>
        <div class="cyprus-adv-inner">
          <img class="cyprus-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_1_2.png" alt="">
          <p class="cyprus-adv-p">法律氛围良好国内税务宽松</p>
        </div>
        <div class="cyprus-adv-inner">
          <img class="cyprus-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_1_3.png" alt="">
          <p class="cyprus-adv-p">亚欧非交通要塞</p>
        </div>
        <div class="cyprus-adv-inner">
          <img class="cyprus-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_1_4.png" alt="">
          <p class="cyprus-adv-p">自由经济体系无外汇管制</p>
        </div>
        <div class="cyprus-adv-inner">
          <img class="cyprus-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_1_5.png" alt="">
          <p class="cyprus-adv-p">专业化设施世界最低犯罪率</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册塞浦路斯公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_1_3.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_1_4.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_1_5.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">合伙公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">私人有限公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">国际商务公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 3">担保有限责任公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 4">海外分支结构</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="m-advantage an-advantage mac-adv">
      <p class="re-title">注册塞浦路斯公司条件/流程/资料</p>
      <div class="m-adv-box mac-adv-box">
        <div class="m-adv-right">
          <p class="macau-data-title">注册塞浦路斯公司条件</p>
          <p class="m-ad-title">名称</p>
          <p class="m-ad-p">公司名称必须以“limited”结尾</p>
          <p class="m-ad-title">秘书</p>
          <p class="m-ad-p">必须有秘书，可为自然人或法人，无当地秘书要求</p>
          <p class="m-ad-title">注册资本</p>
          <p class="m-ad-p">不需验资，没有最低注册资本限制</p>
          <p class="m-ad-title">注册地址</p>
          <p class="m-ad-p">必须有当地的注册地址</p>
          <p class="m-ad-title">股东/董事</p>
          <p class="m-ad-p">股东/董事至少1人，可以为自然人或法人，无当地居民要求</p>
          <p class="m-ad-title">审计报告</p>
          <p class="m-ad-p">每年需交审计报告</p>
          <p class="m-ad-title">国际信托</p>
          <p class="m-ad-p">塞浦路斯国际信托公司，家庭理财管理或财富保护</p>
        </div>
        <img class="m-adv-left" style="width: 400px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_2_3.png" alt="">
      </div>
    </div>
    <div class="macau-process flex-between">
      <img class="macau-process-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_2_4.png" alt="">
      <div class="macau-process-right">
        <p class="macau-process-title">注册塞浦路斯公司流程</p>
        <p class="macau-process-p"><span class="macau-process-span">1</span>3个备选公司名称</p>
        <p class="macau-process-p"><span class="macau-process-span">2</span>安排文书</p>
        <p class="macau-process-p"><span class="macau-process-span">3</span>交付费用</p>
        <p class="macau-process-p"><span class="macau-process-span">4</span>签署制作法定稳定，提供文档、护照原件</p>
        <p class="macau-process-p"><span class="macau-process-span">5</span>政府部门办理相关手续</p>
        <p class="macau-process-p"><span class="macau-process-span">6</span>完成注册，开具发票，领取资料</p>
      </div>
    </div>
    <div class="macau-data center-width flex-between">
      <div class="macau-data-left">
        <p class="macau-data-title">注册完成所得资料</p>
        <p class="m-ad-title1">以希腊语为官方语言的公司执照和备忘录</p>
        <p class="m-ad-title1">公司注册证、董事证明、股东证明</p>
        <p class="m-ad-title1">记名股东和提名董事签发的无期限股票转让文件和无期限辞职信（是否记名可选）</p>
        <p class="m-ad-title1">记名股东的委托书</p>
        <p class="m-ad-title1">受益人保障提名者行为的赔偿契约</p>
      </div>
      <img class="macau-data-img" style="width: 466px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/pic_2_5.png" alt="">
    </div>

    <div class="subsequent subsequent-b">
      <p class="re-title">注册塞浦路斯公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p3">根据税收征收与评估法（1978-2016）第5、5A条的规定，税务管理部门出于...</p>
          <p class="sub-p4">根据税收征收与评估法（1978-2016）第5、5A条的规定，税务管理部门出于身份识别目的而发放给实体的纳税人识别号</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">塞浦路斯银行，银行设有中国业务部门，由说中文的银行员工为中国客户提供个...</p>
          <p class="sub-p4">塞浦路斯银行，银行设有中国业务部门，由说中文的银行员工为中国客户提供个性化服务</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">塞浦路斯企业税为净利润的10%。塞浦路斯本地公司应对其全球所有收入缴纳企...</p>
          <p class="sub-p4">塞浦路斯企业税为净利润的10%。塞浦路斯本地公司应对其全球所有收入缴纳企业所得税，为属人税制。每3个月需要提交一次VAT报告</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p2">公司年审：必须每年交审计报告，提交周年申报表，进行塞浦路斯公司年审</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Cyprus/icon_3_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">塞浦路斯公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="塞浦路斯公司对公司名有什么要求？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">所有公司名，要以Limited或者Ltd.结尾。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="塞浦路斯需要公司秘书吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">成立公司要委任秘书，秘书可以是公司法人或任何国籍的自然人。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="塞浦路斯是欧盟成员国，那注册塞浦路斯公司是否出口欧盟国家产品，就可以免26个成员国的关税吗？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">是的，但要看货物如何流通，欧盟内是免税的，但仍然要申请税号，申报免税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业塞浦路斯公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      // console.log(e)
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
