<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式日本公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="new-advantage">
      <p class="re-title">注册日本公司的优势</p>
      <div class="new-advantage-box center-width flex-between">
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_1_1.png" alt="">
          <p class="new-advantage-p">日本国际品牌</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_1_2.png" alt="">
          <p class="new-advantage-p">日本出口退税</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_1_3.png" alt="">
          <p class="new-advantage-p">公司取名自由</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_1_4.png" alt="">
          <p class="new-advantage-p">日本永久签证投资经营签证</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_1_5.png" alt="">
          <p class="new-advantage-p">经营范围限制小</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册日本公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/pic_1_2.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">株式会社</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">契约会社</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="anquila-process">
      <p class="re-title">注册日本公司的条件/流程/资料</p>
      <div class="anquila-process-box center-width">
        <div class="anquila-process-tab">
          <p class="anquila-process-tab-p" :class="{'active': tab == 1}" @click="tab = 1">注册日本公司条件</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 2}" @click="tab = 2">注册日本公司流程</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 1">
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_1.png" alt="">
            <div>
              <p class="japan-process1-p1">公司名称</p>
              <p class="japan-process1-p2">名称选择自由，中文、日文、英文皆可</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_2.png" alt="">
            <div>
              <p class="japan-process1-p1">日本代表</p>
              <p class="japan-process1-p2">确定董事名单，法人可为本人</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_3.png" alt="">
            <div>
              <p class="japan-process1-p1">注册资本</p>
              <p class="japan-process1-p2">可以根据法人需求，没有限制，1日元也可以</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_4.png" alt="">
            <div>
              <p class="japan-process1-p1">注册地址</p>
              <p class="japan-process1-p2">可提供价格优惠的注册地址</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_5.png" alt="">
            <div>
              <p class="japan-process1-p1">法人资料</p>
              <p class="japan-process1-p2">身份证复印件、护照复印件、通信地址、联络电话、个人印章、印章公证书</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/else/icon_1_6.png" alt="">
            <div>
              <p class="japan-process1-p1">经营范围</p>
              <p class="japan-process1-p2">在合法范围内，绝大部分行业都可以</p>
            </div>
          </div>
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 2">
          <img class="japan-process2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/pic.png" alt="">
        </div>
        <div class="anquila-process-content flex-between" style="justify-content: flex-start;" v-if="tab == 3">
          <div class="anquila-process-inner3 japan3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_1.png" alt="">
            <p class="anquila-process-p">公司营业藤本一份（营业执照）</p>
          </div>
          <div class="anquila-process-inner3 japan3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_2.png" alt="">
            <p class="anquila-process-p">公司印章卡一枚</p>
          </div>
          <div class="anquila-process-inner3 japan3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_3.png" alt="">
            <p class="anquila-process-p">公司印章一套（公章、银行用章等）</p>
          </div>
          <div class="anquila-process-inner3 japan3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_4.png" alt="">
            <p class="anquila-process-p">公司印章证明书一份</p>
          </div>
          <div class="anquila-process-inner3 japan3">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5.png" alt="">
            <p class="anquila-process-p">株式会社的定款（章程）</p>
          </div>
        </div>
      </div>

    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册日本公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">日本公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="日本公司股东和董事人数有什么限制要求吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">公司董事设置1人即可，表示1个人就可以在日本注册公司，如果董事是中国籍自然人，则需要设置一个日籍董事。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="日本公司注册资本金需要多少？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">注册资本金不受限制，1万日元也可以。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册日本公司有什么优势？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">全球最富裕、经济最发达和生活水平最高的国家之一，注册资本不限，注册地址要求不高，经营范围无太大<br />
                限制，品牌知名度高。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="日本公司可以转年审吗？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">可以转年审，需要把日本证书和章程发过来，先查下公司的情况。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业日本公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      // console.log(e)
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
