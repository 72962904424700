<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式萨摩亚公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    
    <div class="anquila-type">
      <p class="re-title">注册萨摩亚公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/pic_1_3.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/pic_1_4.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">有限责任公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">股份责任公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">基金公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 3">协会</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="indonesia-file">
      <p class="re-title">注册萨摩亚公司的条件/流程/资料</p>
      <div class="indonesia-file-box center-width flex-between">
        <div class="indonesia-file-left">
          <div class="indonesia-file-tab" :class="{'active': tab == 1}" @click="tab = 1">注册萨摩亚公司条件</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 2}" @click="tab = 2">注册萨摩亚公司流程</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</div>
        </div>
        <div class="indonesia-file-right">
          <div class="indonesia-file-right-box1" v-if="tab == 1">
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              最少有一个股东或董事，股东或董事可以是自然人或法人，并且可以是任何国籍的居民或法人，股东身份证或护照复印件（如法人企业：需提供公司执照、营利事业登记证之复印件）
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东住址证明（如：股东、董事、秘书及公司之通信联络地址）
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              公司名称：英文名称或中英文名称
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              各股东间之股权比例
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              标准授权资本一般为100万美元，标准注册资本可以任何货币表达，最少实质资本为一股有或者没有面额的股份，可以发行不记名股票
            </p>
          </div>
          <div class="indonesia-file-right-box1" v-if="tab == 2">
            <img style="width: 745px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/pic.png" alt="">
          </div>
          <div class="indonesia-file-right-box3" v-if="tab == 3">
            <div class="indonesia-file-tab3" style="margin-top: 80px">
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_1.png" alt="">
                <p class="indonesia-file-tab3-p">公司注册正式</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_2.png" alt="">
                <p class="indonesia-file-tab3-p">公司大纲及章程</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_3.png" alt="">
                <p class="indonesia-file-tab3-p">公司股票</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/icon_3_4.png" alt="">
                <p class="indonesia-file-tab3-p">公司签名章及钢印</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册萨摩亚公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">萨摩亚公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册萨摩亚公司需要多少名股东？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">在萨摩亚成立公司， 一个股东就可以。中国内地人士只要提供有效护照即可。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="萨摩亚公司可以用中文名称吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">在萨摩亚成立公司， 一般都使用英文、法文名称，由于近来有较多的中国人来到该岛注册公司，现在该岛政府<br />
                已经允许注册中文名称了。中文名称的注册费用是在原注册费用上增加1000人民币。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册萨摩亚公司的时间需要多久？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">
                萨摩亚公司办理时间:<br />
                a.新成立公司：8-12个工作天，含有中文名称的公司多加2-3个工作天;<br />
                b.购买现成公司：2- 5个工作天。
              </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业萨摩亚公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;" >
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Samoa/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
