<template>
    <div class="hague">
      <div class="notarization-header">
        <div class="center-width">
          <p class="notarization-p2" style="padding-top: 130px;">中国海牙认证办理中心</p>
          <p class="notarization-p1" style="padding-top: 0;margin-top: 19px;">专业顾问团队品质服务</p>
          <p class="notarization-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="hague-type">
        <p class="b-title">海牙认证类型</p>
        <p class="b-title1">选择您需要认证的类型</p>
        <div class="hague-type-box center-width">
            <div class="hague-type-tab">
                <p class="hague-type-tab-p" :class="{'active': tab == 1}" @click="tab = 1">公司文件类型</p>
                <p class="hague-type-tab-p" :class="{'active': tab == 2}" @click="tab = 2">个人文件类型</p>
                <p class="hague-type-tab-p" :class="{'active': tab == 3}" @click="tab = 3">第三方机构类文件</p>
                <!-- <p class="hague-type-tab-p" :class="{'active': tab == 4}" @click="tab = 4">其他文件</p> -->
            </div>
            <div class="hague-type-content" v-if="tab == 1">
                <p class="h-t-p">注册证书</p>
                <p class="h-t-p">更名证书</p>
                <p class="h-t-p">公司章程</p>
                <p class="h-t-p">会议记录</p>
                <p class="h-t-p">董事名册</p>
                <p class="h-t-p">股东名册</p>
                <p class="h-t-p">良好证明</p>
                <p class="h-t-p">协议书（合同）</p>
                <p class="h-t-p">授权书</p>
                <p class="h-t-p">声明书</p>
                <p class="h-t-p">资产证明</p>
                <p class="h-t-p">资信证明</p>
                <p class="h-t-p" @click="Dialog = true">更多</p>
                <!-- <p class="h-t-p">股东名册</p>
                <p class="h-t-p">公司章程</p>
                <p class="h-t-p">会议记录</p>
                <p class="h-t-p">发票</p>
                <p class="h-t-p">原产地证</p>
                <p class="h-t-p">报关单</p>
                <p class="h-t-p">产品证书</p>
                <p class="h-t-p">产品介绍</p>
                <p class="h-t-p">委托书</p> -->
            </div>
            <div class="hague-type-content" v-if="tab == 2">
              <p class="h-t-p">结婚证</p>
              <p class="h-t-p">出生证</p>
              <p class="h-t-p">离婚书</p>
              <!-- <p class="h-t-p">领养</p>
              <p class="h-t-p">寄养</p> -->
              <p class="h-t-p">亲属关系</p>
              <p class="h-t-p">死亡证明</p>
              <p class="h-t-p">单身证明</p>
              <p class="h-t-p">成绩单</p>
              <p class="h-t-p">学历证书</p>
              <p class="h-t-p">授权书</p>
              <p class="h-t-p">声明书</p>
              <p class="h-t-p">护照</p>
              <p class="h-t-p">身份证</p>
              <p class="h-t-p">判决书</p>
              <p class="h-t-p">无犯罪记录</p>
              <p class="h-t-p">房产证</p>
              <p class="h-t-p">职业资格证书</p>
              <p class="h-t-p">医学证明（出生证）</p>
              <p class="h-t-p" @click="Dialog = true">更多</p>
              <!-- <p class="h-t-p">成绩单</p>
              
              <p class="h-t-p">职业资格证书</p>
              <p class="h-t-p">医学证明</p> -->
            </div>
            <div class="hague-type-content" v-if="tab == 3">
              <p class="h-t-p">SO证书</p>
              <p class="h-t-p">CE证书</p>
              <p class="h-t-p">自由销售证书</p>
              <p class="h-t-p">完税证明</p>
              <p class="h-t-p">商标证书</p>
              <p class="h-t-p" @click="Dialog = true">更多</p>
            </div>
        </div>
      </div>
      <div class="hague-banner center-width">
        <img class="hague-banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_3.png" alt="">
        <p class="hague-banner-p1">快企务与国际公证人展开合作，最早进入海牙认证领域</p>
        <p class="hague-banner-p2">办理了 <span class="big-text">10000+ </span>客户文件，承诺文件无法使用，100%全额退还费用</p>
      </div>
      <div class="hague-crowd center-width">
        <p class="hague-title">常见海牙认证的人群</p>
        <p class="hague-title1">包含但不限于以下四种</p>
        <div class="crowd-content">
            <div class="crowd-inner">
                <img class="crowd-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_3_1.png" alt="">
                <div class="hover-box"></div>
                <p class="crowd-p1">海外留学生</p>
                <p class="crowd-p4">海外留学生</p>
                <p class="crowd-p2">户口本，在读证明，学位证明，毕业证明等需办理海牙认证才会被国外承认</p>
                <p class="crowd-p3" @click="Dialog = true">立即咨询 ></p>
            </div>
            <div class="crowd-inner">
                <img class="crowd-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_3_2.png" alt="">
                <div class="hover-box"></div>
                <p class="crowd-p1">子女教育</p>
                <p class="crowd-p4">子女教育</p>
                <p class="crowd-p2">海外发达国家拥有完善的教育制度，并且申请人子女可免费就读公立中小学</p>
                <p class="crowd-p3" @click="Dialog = true">立即咨询 ></p>
            </div>
            <div class="crowd-inner">
                <img class="crowd-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_3_3.png" alt="">
                <div class="hover-box"></div>
                <p class="crowd-p1">进出口贸易企业</p>
                <p class="crowd-p4">进出口贸易企业</p>
                <p class="crowd-p2">产品销往海外需要办理海牙认证让相关执照或文件可以被国外认可</p>
                <p class="crowd-p3" @click="Dialog = true">立即咨询 ></p>
            </div>
            <div class="crowd-inner">
                <img class="crowd-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_3_4.png" alt="">
                <div class="hover-box"></div>
                <p class="crowd-p1">收养孩子/继承海外资产人</p>
                <p class="crowd-p4">收养孩子/继承海外资产人</p>
                <p class="crowd-p2">个人资料，授权书需办理海牙认证被相关海外国家认可</p>
                <p class="crowd-p3" @click="Dialog = true">立即咨询 ></p>
            </div>
        </div>
      </div>
      <div class="hague-process center-width">
        <p class="hague-title">海牙认证流程</p>
        <p class="hague-title1">三步拿下海牙认证</p>
        <div class="hague-process-box">
            <img class="hague-process-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_4.png" alt="">
            <div class="inner-box">
              <p class="inner-box-p">认证用途：告知文件目的</p>
              <p class="inner-box-p">认证使用地：用于哪些国家和地区</p>
              <p class="inner-box-p">认证要求：用证方对文件的要求</p>
            </div>
        </div>
      </div>
      <div class="hague-process2">
        <div class="hague-process2-inner">
          <img class="hague-process2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_14.png" alt="">
          <img class="hague-process2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon15.png" alt="">
          <p class="hague-process2-p">准备与提交资料</p>
        </div>
        <img class="hague-process2-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/arrow.png" alt="">
        <div class="hague-process2-inner">
          <img class="hague-process2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_15.png" alt="">
          <img class="hague-process2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon13.png" alt="">
          <p class="hague-process2-p">审核文件</p>
        </div>
        <img class="hague-process2-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/arrow.png" alt="">
        <div class="hague-process2-inner">
          <img class="hague-process2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_17.png" alt="">
          <img class="hague-process2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon14.png" alt="">
          <p class="hague-process2-p">公证人起草文件客户确认</p>
        </div>
      </div>
      <div class="hague-fast center-width">
        <p class="hague-title">快企务—快速省心</p>
        <p class="hague-title1">专业的机构会让你比别人快，比别人省心</p>
        <div class="hague-fast-box">
            <div class="hague-fast-inner" @click="elseTab = 1">
              <img class="big-box" v-if="elseTab == 1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_5_1.png" alt="">
              <div class="hover-box" v-if="elseTab == 1">
                <p class="hover-title">快企务</p>
                <p class="hover-p">
                  <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_1smiling_face.png" alt="">
                  3~5个工作日完成
                </p>
                <p class="hover-p">
                  <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_1smiling_face.png" alt="">
                  三道加密系统，确保文件信息不泄露
                </p>
                <p class="hover-p">
                  <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_1smiling_face.png" alt="">
                  确保质量，无效承诺退款
                </p>
                <p class="hover-ask" @click="Dialog = true">咨询海牙认证 ></p>
              </div>
              <img class="small-box" style="width: 390px;height: 360px" v-if="elseTab == 2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_2.png" alt="">
            </div>
            <div class="hague-fast-inner" @click="elseTab = 2">
                <img class="small-box" style="width: 780px;height: 360px" v-if="elseTab == 2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_6_2.png" alt="">
                <div class="hover-box" v-if="elseTab == 2">
                  <p class="hover-title else-title">其他公司</p>
                  <p class="hover-p else-p">
                    <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_2wailing_face.png" alt="">
                    时间没把握
                  </p>
                  <p class="hover-p else-p">
                    <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_2wailing_face.png" alt="">
                    无加密系统，文件随意传送，文件信息容易泄露
                  </p>
                  <p class="hover-p else-p">
                    <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/icon_1_2wailing_face.png" alt="">
                    无资质，质量无法保证，出现问题推卸责任
                  </p>
                </div>
                <img class="big-box" v-if="elseTab == 1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_1.png" alt="">
            </div>
        </div>
      </div>
      <div class="hague-case center-width">
        <p class="hague-title">认证案例</p>
        <p class="hague-title1">多达10000+证书，多家知名企业指定服务商</p>
        <div class="hague-case-box flex-between">
            <div class="hague-case-inner">
                <p class="hague-case-title">美国海牙认证</p>
                <img class="hague-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_7_1.png" alt="">
                <div class="hover-box"></div>
                <p class="hague-case-p1">美国海牙认证</p>
                <p class="hague-case-p2" @click="Dialog = true">立即办理 ></p>
            </div>
            <div class="hague-case-inner">
                <p class="hague-case-title">中国香港海牙认证</p>
                <img class="hague-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_7_2.png" alt="">
                <div class="hover-box"></div>
                <p class="hague-case-p1">中国香港海牙认证</p>
                <p class="hague-case-p2" @click="Dialog = true">立即办理 ></p>
            </div>
            <div class="hague-case-inner">
                <p class="hague-case-title">英国海牙认证</p>
                <img class="hague-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_7_3.png" alt="">
                <div class="hover-box"></div>
                <p class="hague-case-p1">英国海牙认证</p>
                <p class="hague-case-p2" @click="Dialog = true">立即办理 ></p>
            </div>
            <div class="hague-case-inner">
                <p class="hague-case-title">韩国海牙认证</p>
                <img class="hague-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_7_4.png" alt="">
                <div class="hover-box"></div>
                <p class="hague-case-p1">韩国海牙认证</p>
                <p class="hague-case-p2" @click="Dialog = true">立即办理 ></p>
            </div>
        </div>
      </div>
      <div class="hague-advantage center-width">
        <p class="hague-title">我司办理的优势</p>
        <p class="hague-title1">十余年行业代办经验</p>
        <div class="hague-adv-box flex-between">
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon1.png" alt="">
                <div>
                  <p class="hague-adv-p1">严格保密</p>
                  <p class="hague-adv-p2" style="width: 166px">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon2.png" alt="">
                <div>
                  <p class="hague-adv-p1">透明公开</p>
                  <p class="hague-adv-p2" style="width: 166px">价格公开透明，公证认证办理费用公开透明可查</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon3.png" alt="">
                <div>
                  <p class="hague-adv-p1">全天跟进</p>
                  <p class="hague-adv-p2" style="width: 166px">24小时跟进，每份公证材料专员全天候跟进反馈</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon4.png" alt="">
                <div>
                  <p class="hague-adv-p1">退费承诺</p>
                  <p class="hague-adv-p2" style="width: 166px">文件无法使用，全额退还费用</p>
                </div>
            </div>
        </div>
      </div>
      <div class="hague-consulting center-width">
        <p class="hague-title">咨询服务</p>
        <p class="hague-title1">我们还能为您做</p>
        <div class="hague-consulting-box">
            <div class="hague-consulting-left">
                <div class="consulting-inner">
                    <p class="consulting-p1">中国委托公证人公证</p>
                    <p class="consulting-p2">特聘具有中国委托公证人资格中国香港公证人，为中国香港文件、事实及法律文书等用于内地提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">国际公证认证</p>
                    <p class="consulting-p2">特聘具有国际公证员资格国际公证人，为中国香港文件、事实及法律文书等用于海外提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">使馆认证</p>
                    <p class="consulting-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
            </div>
            <div class="hague-consulting-right">
                <img class="hague-consulting-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_8.png" alt="">
            </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },

    data(){
      return{
        Dialog: false,
        tab: 1,
        elseTab: 1,
        // flag1: true,
        // flag2: true,
        // flag3: false,
        // flag4: false
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods:{
      // change2(){
      //   this.flag1 = false
      //   this.flag2 = false
      //   this.flag3 = true
      //   this.flag4 = true
      // }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .notarization-header{
      width: 100%;
      height: 520px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }


    // .hague-fast-inner1{
    //   width: 790px;
    //   height: 360px;
    //   background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_5_1.png') no-repeat center center;
    //   background-size: 100% 100%;
    // }
    // .hague-fast-inner2{
    //   width: 390px;
    //   height: 360px;
    //   background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_5_2.png') no-repeat center center;
    //   background-size: 100% 100%;
    // }
    // .hague-fast-inner:hover{
    //   width: 790px;
    // }

    // .hague-fast-inner3{
    //   background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_6_1.png') no-repeat center center;
    //   background-size: 100% 100%;
    // }
    // .hague-fast-inner4{
    //   background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_6_2.png') no-repeat center center;
    //   background-size: 100% 100%;
    // }
    
  </style>
  