<template>
  <div class="anquila dubai">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式迪拜公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="dubai-advantage">
      <p class="re-title">注册迪拜公司优势</p>
      <div class="dubai-advantage-box center-width flex-between">
        <div class="dubai-advantage-left">
          <div class="dubai-advantage-left1">
            <img class="dubai-advantage-left1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/pic_1.png" alt="">
            <p class="dubai-advantage-left1-p1">注册迪拜公司的优势</p>
            <p class="dubai-advantage-left1-p2" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">公司免税经营期长达15到20年</p>
          </div>
        </div>
        <div class="dubai-advantage-right">
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">自由区与在岸相辅的离岸公司在法律方面有豁免权，当局会提供持续的行政协助</p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">地理位置优越</p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">公司所有权完全来自于国外，且享受迪拜当地进口税收的优惠待遇</p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">对绝大多数商品进口和再出口免税，允许所有利润的收入回国</p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">个人所得税豁免；除石油公司和银行，迪拜的境外公司可豁免利得税、资本收益等一切税收</p>
          </div>
          <div class="dubai-advantage-inner">
            <p class="dubai-advantage-p">宽松的移民政策</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dubai-type">
      <p class="re-title">注册迪拜公司类型</p>
      <div class="dubai-type-box center-width">
        <el-carousel :interval="5000" arrow="hover" height="450px" indicator-position="inside" :autoplay="true">
          <el-carousel-item>
            <div class="dubai-type-box1">
              <img class="dubai-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/banner.jpg" alt="">
              <p class="dubai-type-p">公司（LLC）</p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="dubai-type-box1">
              <img class="dubai-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/pic_1.jpg" alt="">
              <p class="dubai-type-p">独资公司（EST） </p>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="dubai-type-box1">
              <img class="dubai-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/pic_2.jpg" alt="">
              <p class="dubai-type-p">办事处（Branch) </p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="dubai-process">
      <p class="re-title">注册迪拜公司条件/流程/资料</p>
      <div class="dubai-process-box center-width flex-between">
        <div class="dubai-process-tab-box">
          <div class="dubai-process-tab" :class="{'dubaiActive': tab == 1}" @click="tab = 1">
            <p class="dubai-process-tab-p">注册迪拜公司的条件</p>
          </div>
          <div class="dubai-process-tab" :class="{'dubaiActive': tab == 2}" @click="tab = 2">
            <p class="dubai-process-tab-p">注册迪拜公司的流程</p>
          </div>
          <div class="dubai-process-tab" :class="{'dubaiActive': tab == 3}" @click="tab = 3">
            <p class="dubai-process-tab-p">注册完成后所得资料</p>
          </div>
        </div>
        <div class="dubai-process-right" v-if="tab == 1">
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_1_1.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p">拟定1-3个公司英文名称，避开当地政府禁止的字眼（详细可咨询我司专业顾问）</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_1_2.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p">公司股东至少一人</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_1_3.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p">迪拜自由区中的杰贝阿里自由区（JAFZA）,董事至少2人；哈伊马角酋长管辖区内的哈伊马角自由区（RAK）,董事至少1人</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_1_4.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p">须有当地注册地址和注册代理人</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_1_5.png" alt="">
            <div class="p-box last-border">
              <p class="dubai-process-p">公司秘书可以由注册代理人担任</p>
            </div>
          </div>
        </div>
        <div class="dubai-process-right" v-if="tab == 2">
          <div class="dubai-process2-box">
            <div class="dubai-process2-inner">
              <p class="dubai-process2-number">01</p>
              <p class="dubai-process2-text">提供公司名称进行核名</p>
            </div>
            <div class="dubai-process2-inner">
              <p class="dubai-process2-number">02</p>
              <p class="dubai-process2-text">提供董事/股东的护照影印件及住所证明（水电费等公用事业单据）</p>
            </div>
            <div class="dubai-process2-inner">
              <p class="dubai-process2-number">03</p>
              <p class="dubai-process2-text">公司注册</p>
            </div>
            <div class="dubai-process2-inner">
              <p class="dubai-process2-number">04</p>
              <p class="dubai-process2-text">董事/股东的银行资信证明</p>
            </div>
            <div class="dubai-process2-inner">
              <p class="dubai-process2-number">05</p>
              <p class="dubai-process2-text">注册完成，邮寄注册所得资料</p>
            </div>
          </div>
        </div>
        <div class="dubai-process-right" v-if="tab == 3">
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/else/icon_1_1.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p" style="margin-top: 30px">营业执照</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/else/icon_1_2.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p" style="margin-top: 30px">股权证书</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/else/icon_1_3.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p" style="margin-top: 30px">公司章程大纲</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/else/icon_1_4.png" alt="">
            <div class="p-box">
              <p class="dubai-process-p" style="margin-top: 30px">公司章程</p>
            </div>
          </div>
          <div class="dubai-process-inner">
            <img class="dubai-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/else/icon_1_5.png" alt="">
            <div class="p-box last-border">
              <p class="dubai-process-p" style="margin-top: 30px">公司卡</p>
            </div>
          </div>
          
          <!-- <div class="dubai-process3-box">
            <div class="dubai-process3-inner">
              <img class="dubai-process3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_3_1.png" alt="">
              <p class="dubai-process3-p">营业执照</p>
            </div>
            <div class="dubai-process3-inner">
              <img class="dubai-process3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_3_2.png" alt="">
              <p class="dubai-process3-p">股权证书</p>
            </div>
            <div class="dubai-process3-inner">
              <img class="dubai-process3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_3_3.png" alt="">
              <p class="dubai-process3-p">公司章程大纲</p>
            </div>
            <div class="dubai-process3-inner">
              <img class="dubai-process3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_3_4.png" alt="">
              <p class="dubai-process3-p">公司章程</p>
            </div>
            <div class="dubai-process3-inner">
              <img class="dubai-process3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_3_5.png" alt="">
              <p class="dubai-process3-p">公司卡</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b dubai-serve center-width">
      <p class="re-title">注册迪拜公司后为您提供的后续服务</p>
      <div class="sub-box center-width">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p2">集团公司的增值税注册、亚马逊卖家的增值税注册</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p2">迪拜国民银行、阿布扎比国民银行、阿布扎比商业银行和马士里格银行</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">对于特殊行业必须要每月报税，其他常规行业的公司做账不是强制但必须的，是...</p>
          <p class="sub-p4">对于特殊行业必须要每月报税，其他常规行业的公司做账不是强制但必须的，是一个公司治理良好的重要指标</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p2">公司年审：每年必须更新公司执照</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/icon_4_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p3">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司，同...</p>
          <p class="sub-p4">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司，同时需要取消各项许可证</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">迪拜公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册迪拜公司所需要注意哪些事项？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">首先需要明确公司运营的实际用途，确定好注册公司的类型。其次，需要了解银行开户对公司以及对股东的<br />
                相关要求和限制。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司的注册名称有哪些要求？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">在公司命名方面并没有太多限制，公司名称可以以“有限公司”结尾。但是一定不能包含下列文字，且不得与<br />
                上市业务活动相同。如“银行”、“保险”、和“再保险”等。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司的注册董事及公司秘书要求？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">公司需要一名股东、董事和秘书。允许法人股东和法人董事。没有要求规定指定一名迪拜本地人为董事，且股<br />
                东和董事可为同一人。董事身份不受国籍限制，也不要求董事常驻注册地。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册迪拜公司需要验资吗？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">标准授权资本及实际资本无最低要求，不用验资。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业迪拜公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/banner1.jpeg') no-repeat center center;
    background-size: 100% 100%;
  }
  .dubai-process-tab{
    width: 300px;
    height: 120px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    margin-bottom: 30px;
    .dubai-process-tab-p{
        font-size: 20px;
        line-height: 120px;
        text-align: center;
    }
  }
  .dubai-process-tab:last-child{
      margin-bottom: 0;
  }
  .dubaiActive{
    width: 300px;
    height: 120px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/dubai/pic_2.png') no-repeat center center;  
    background-size: 100% 100%;    
    .dubai-process-tab-p{
      color: #fff;
    }   
  }               
</style>
