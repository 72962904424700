<template>
  <div class="home">
    <!-- <Header></Header> -->
    <div class="banner">
      <div class="center-width">
        <p class="header-p1">国际公证认证</p>
        <p class="header-p2" style="display: flex;align-items: center;">全球服务超过 
          <span class="span1">
            <NumberGrow :value="30000" :dot="0"></NumberGrow>
          </span>
          <span class="span2">+ </span>客户</p>
        <p class="header-p3">全程代办，一站式服务，业务全面，覆盖全球</p>
        <p class="header-p4" @click="Dialog = true">免费咨询</p>
      </div>
    </div>
    <div class="number1">
      <div class="number-inner">
        <p class="number-num" style="display: flex;align-items: center;justify-content: center;">
          <!-- 10W -->
          <NumberGrow :value="100000" :dot="0"></NumberGrow>
        +</p>
        <p class="number-text">{{$t("home.home-1")}}</p>
      </div>
      <div class="number-inner">
        <p class="number-num" style="display: flex;align-items: center;justify-content: center;">
          <!-- 20W -->
          <NumberGrow :value="200000" :dot="0"></NumberGrow>
        +</p>
        <p class="number-text">{{$t("home.home-2")}}</p>
      </div>
      <div class="number-inner">
        <p class="number-num" style="display: flex;align-items: center;justify-content: center;">
          <!-- 1W -->
          <NumberGrow :value="10000" :dot="0"></NumberGrow>
        +</p>
        <p class="number-text">{{$t("home.home-3")}}</p>
      </div>
    </div>
    <div class="home-notice">
      <marquee scrollamount="3" class="marquee-style">
        {{ notice }}
        
      </marquee>
    </div>
    <!-- 地图 -->
    <div class="map">
      <p class="map-title">{{$t("home.home-4")}}</p>
      <p class="map-tips">{{$t("home.home-5")}}</p>
      <p class="btn-style1" @click="Dialog = true">{{$t("home.home-6")}}</p>
      <div class="map-img-box">
        <img class="map-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/Map.gif" alt="">
      </div>
    </div>
    <!-- 秘书服务 -->
    <div class="mishu">
      <div class="mishu-inner">
        <p class="mishu-title">{{$t("home.home-7")}}</p>
        <p class="mishu-tips">{{$t("home.home-8")}}</p>
        <p class="mishu-ask" @click="Dialog = true">{{$t("home.home-9")}} ></p>
        <div class="mishu-content">
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_1registered_address_service.png" alt="">
            <p class="inner-text">{{$t("home.home-10")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_2secretarial_services.png" alt="">
            <p class="inner-text">{{$t("home.home-12")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_3office_services.png" alt="">
            <p class="inner-text">{{$t("home.home-13")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_4yearly_inspection.png" alt="">
            <p class="inner-text">{{$t("home.home-14")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_5member.png" alt="">
            <p class="inner-text">{{$t("home.home-15")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_6prove.png" alt="">
            <p class="inner-text">{{$t("home.home-16")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <img class="inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_1_8investigation.png" alt="">
            <p class="inner-text">{{$t("home.home-17")}}</p>
            <p class="btn-style" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
          <div class="content-box">
            <p class="more-mi" @click="Dialog = true">{{$t("home.home-18")}} > <img src="" alt=""></p>
          </div>
        </div>
      </div>
    </div>
    <!-- 国际公证认证 -->
    <div class="international" :class="tabBag">
      <p class="inter-title">{{$t("home.home-19")}}</p>
      <p class="inter-tips">{{$t("home.home-20")}}</p>
      <div class="inter-tab">
        <div class="tabs">
          <p class="tab-t" :class="{'active': internationalTab == 1}" @click="internationalTab = 1;tabBag = 'tabBag1'">{{$t("home.home-21")}}</p>
          <p class="tab-t" :class="{'active': internationalTab == 2}" @click="internationalTab = 2;tabBag = 'tabBag2'">{{$t("home.home-22")}}</p>
          <p class="tab-t" :class="{'active': internationalTab == 3}" @click="internationalTab = 3;tabBag = 'tabBag3'">{{$t("home.home-23")}}</p>
          <p class="tab-t" :class="{'active': internationalTab == 4}" @click="internationalTab = 4;tabBag = 'tabBag4'">{{$t("home.home-24")}}</p>
        </div>
        <div class="tab-inner" v-if="internationalTab == 1">
          <div class="tab-left">
            <p class="tab-title">{{$t("home.home-21")}}</p>
            <p class="tab-content">{{$t("home.home-25")}}</p>
            <div class="tab-button">
              <p class="tab-btn-style">{{$t("home.home-26")}} ></p>
              <p class="tab-btn-style style2" @click="Dialog = true">{{$t("home.home-9")}} ></p>
            </div>
          </div>
          <div class="tab-right">
            <div class="right-inner">
              <p class="right-title">{{$t("home.home-27")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-28")}}</p>
                <p class="text-style">{{$t("home.home-29")}}</p>
                <p class="text-style">{{$t("home.home-30")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationHague')">{{$t("home.home-31")}} ></p>
              </div>
              <p class="right-title">{{$t("home.home-32")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-33")}}</p>
                <p class="text-style">{{$t("home.home-34")}}</p>
                <p class="text-style">{{$t("home.home-35")}}</p>
                <p class="text-style">{{$t("home.home-36")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationHague')">{{$t("home.home-31")}} ></p>
              </div>
              <p class="right-title">{{$t("home.home-37")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-38")}}</p>
                <p class="text-style">{{$t("home.home-39")}}</p>
                <p class="text-style">{{$t("home.home-40")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationHague')">{{$t("home.home-31")}} ></p>
              </div>
              <p class="right-title">{{$t("home.home-41")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-42")}}</p>
                <p class="text-style">{{$t("home.home-43")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationHague')">{{$t("home.home-31")}} ></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner" v-if="internationalTab == 2">
          <div class="tab-left">
            <p class="tab-title">{{$t("home.home-44")}}</p>
            <p class="tab-content">{{$t("home.home-45")}}</p>
            <div class="tab-button">
              <p class="tab-btn-style">{{$t("home.home-46")}} ></p>
              <p class="tab-btn-style style2" @click="Dialog = true">{{$t("home.home-47")}} ></p>
            </div>
          </div>
          <div class="tab-right">
            <div class="right-inner">
              <p class="right-title">{{$t("home.home-48")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-49")}}</p>
                <p class="text-style">{{$t("home.home-50")}}</p>
                <p class="text-style">{{$t("home.home-51")}}</p>
                <p class="text-style">{{$t("home.home-52")}}</p>
                <p class="text-style">{{$t("home.home-53")}}</p>
                <p class="text-style">{{$t("home.home-54")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationChina')">{{$t("home.home-31")}} ></p>
              </div>
              <p class="right-title">{{$t("home.home-55")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-56")}}</p>
                <p class="text-style">{{$t("home.home-57")}}</p>
                <p class="text-style">{{$t("home.home-58")}}</p>
                <p class="text-style">{{$t("home.home-59")}}</p>
                <p class="text-style">{{$t("home.home-60")}}</p>
                <p class="text-style">{{$t("home.home-61")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationChina')">{{$t("home.home-31")}} ></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner" v-if="internationalTab == 3">
          <div class="tab-left">
            <p class="tab-title">{{$t("home.home-62")}}</p>
            <p class="tab-content">{{$t("home.home-63")}}</p>
            <div class="tab-button">
              <p class="tab-btn-style">{{$t("home.home-46")}} ></p>
              <p class="tab-btn-style style2" @click="Dialog = true">{{$t("home.home-47")}} ></p>
            </div>
          </div>
          <div class="tab-right">
            <div class="right-inner">
              <p class="right-title">{{$t("home.home-64")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-65")}}</p>
                <p class="text-style">{{$t("home.home-66")}}</p>
                <p class="text-style">{{$t("home.home-67")}}</p>
                <p class="text-style">{{$t("home.home-68")}}</p>
                <p class="text-style">{{$t("home.home-69")}}</p>
                <p class="text-style">{{$t("home.home-70")}}</p>
                <p class="text-style">{{$t("home.home-71")}}</p>
                <p class="text-style">{{$t("home.home-72")}}</p>
                <p class="text-style">{{$t("home.home-73")}}</p>
                <p class="text-style">{{$t("home.home-74")}}</p>
                <p class="text-style">{{$t("home.home-75")}}</p>
                <p class="text-style">{{$t("home.home-76")}}</p>
                <p class="text-style">{{$t("home.home-77")}}</p>
                <p class="text-style">{{$t("home.home-78")}}</p>
                <p class="text-style">{{$t("home.home-79")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationEmbassy')">{{$t("home.home-31")}} ></p>
              </div>
              <p class="right-title">{{$t("home.home-80")}}</p>
              <div class="right-text">
                <p class="text-style">{{$t("home.home-65")}}</p>
                <p class="text-style">{{$t("home.home-71")}}</p>
                <p class="text-style">{{$t("home.home-81")}}</p>
                <p class="text-style">{{$t("home.home-82")}}</p>
                <p class="text-style">{{$t("home.home-83")}}</p>
                <p class="text-style">{{$t("home.home-84")}}</p>
                <p class="text-style">{{$t("home.home-76")}}</p>
                <p class="text-style">{{$t("home.home-72")}}</p>
                <p class="text-style">{{$t("home.home-68")}}</p>
                <p class="text-style">{{$t("home.home-74")}}</p>
                <p class="text-style">{{$t("home.home-85")}}</p>
                <p class="text-style">{{$t("home.home-66")}}</p>
                <p class="text-style">{{$t("home.home-86")}}</p>
                <p class="text-style">{{$t("home.home-87")}}</p>
                <p class="text-style">{{$t("home.home-88")}}</p>
                <p class="text-style">{{$t("home.home-90")}}</p>
                <p class="text-style">{{$t("home.home-91")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationEmbassy')">{{$t("home.home-31")}} ></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner" v-if="internationalTab == 4">
          <div class="tab-left">
            <p class="tab-title">{{$t("home.home-92")}}</p>
            <p class="tab-content">{{$t("home.home-93")}}</p>
            <div class="tab-button">
              <p class="tab-btn-style">{{$t("home.home-46")}} ></p>
              <p class="tab-btn-style style2" @click="Dialog = true">{{$t("home.home-47")}} ></p>
            </div>
          </div>
          <div class="tab-right">
            <div class="right-inner">
              <div class="right-text">
                <p class="text-style">{{$t("home.home-94")}}</p>
                <p class="text-style">{{$t("home.home-95")}}</p>
                <p class="text-style">{{$t("home.home-96")}}</p>
                <p class="text-style">{{$t("home.home-97")}}</p>
                <p class="text-style">{{$t("home.home-98")}}</p>
                <p class="text-style">{{$t("home.home-99")}}</p>
                <p class="text-style">{{$t("home.home-100")}}</p>
                <p class="text-style more-style" @click="toUrl('notarizationTrade')">{{$t("home.home-31")}} ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 银行开户 -->
    <div class="bank">
      <p class="bank-title">{{$t("home.home-101")}}</p>
      <p class="bank-tips">{{$t("home.home-102")}}</p>
      <p class="bank-ask">{{$t("home.home-47")}} ></p>
      <div class="bank-box">
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-103")}}</p>
            <p class="bank2">{{$t("home.home-104")}}</p>
            <p class="bank3" @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_2.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-105")}}</p>
            <p class="bank2">{{$t("home.home-106")}}</p>
            <p class="bank3">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_3.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-107")}}</p>
            <p class="bank2">{{$t("home.home-108")}}</p>
            <p class="bank3"  @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_4.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-109")}}</p>
            <p class="bank2">{{$t("home.home-110")}}</p>
            <p class="bank3"  @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_5.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-111")}}</p>
            <p class="bank2">{{$t("home.home-112")}}</p>
            <p class="bank3"  @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_6.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-113")}}</p>
            <p class="bank2">{{$t("home.home-114")}}</p>
            <p class="bank3"  @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <img class="bank-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_1_7.png" alt="">
          <div class="bank-right">
            <p class="bank1">{{$t("home.home-115")}}</p>
            <p class="bank2">{{$t("home.home-116")}}</p>
            <p class="bank3"  @click="Dialog = true">{{$t("home.home-11")}}</p>
          </div>
        </div>
        <div class="bank-inner">
          <p class="more-bank" @click="toBank">更多银行开户 ></p>
        </div>
      </div>
    </div>
    <!-- 财务筹划 -->
    <div class="financial">
      <p class="title1">{{$t("home.home-117")}}</p>
      <p class="tips1">{{$t("home.home-118")}}</p>
      <p class="mishu-ask" @click="Dialog = true">{{$t("home.home-47")}} ></p>
      <div class="fina-box">
        <el-carousel :interval="5000" arrow="always" height="500px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="fina-content">
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_1.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-119")}}</p>
                  <p class="fina-p2">专业顾问，一对一服务，诚信可靠</p>
                  <p class="fina-p3" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_2.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-121")}}</p>
                  <p class="fina-p2">无隐形收费，办理快，全程一对一服务</p>
                  <p class="fina-p3" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_3.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-123")}}</p>
                  <p class="fina-p2">CRS税务启动，中国香港公司报税，必不可缺，注会出具意见</p>
                  <p class="fina-p3" style="margin-top: 10px;" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="fina-content">
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_4.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-125")}}</p>
                  <p class="fina-p2">CRS全球税务交换，快企务，您身边的税务专家</p>
                  <p class="fina-p3" style="margin-top: 10px;" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_5.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-127")}}</p>
                  <p class="fina-p2">快企务助您积极配合，谨慎填写</p>
                  <p class="fina-p3" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
              <div class="fina-inner">
                <img class="fina-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_2_6.png" alt="">
                <div class="fina-bottom">
                  <p class="fina-p1">{{$t("home.home-129")}}</p>
                  <p class="fina-p2">您身边的财务专家，让您放心经营</p>
                  <p class="fina-p3" @click="toUrl('fiscalTax')">{{$t("home.home-215")}} ></p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 境外投资备案 -->
    <div class="overseas">
      <p class="title1">{{$t("home.home-131")}}</p>
      <p class="k-tips">{{$t("home.home-132")}}</p>
      <div class="overseas-box">
        <el-carousel :interval="5000" arrow="never" indicator-position="outside" height="550px" :autoplay="false">
          <el-carousel-item>
            <div class="overseas-inner">
              <div class="overseas-left">
                <p class="over-p1">{{$t("home.home-133")}}</p>
                <p class="over-p2">{{$t("home.home-134")}}</p>
                <p class="over-p2">{{$t("home.home-135")}}</p>
                <div class="btn-box">
                  <p class="tab-btn-style" @click="Dialog = true">{{$t("home.home-47")}}</p>
                  <p class="tab-btn-style no-background">{{$t("home.home-46")}}</p>
                </div>
              </div>
              <div class="overseas-right">
                <img class="over-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_3_1ODI_filing.png" alt="">
              </div>
            </div>
          </el-carousel-item>
          <!-- <el-carousel-item>
            <div class="overseas-inner">
              <div class="overseas-left">
                <p class="over-p1">{{$t("home.home-136")}}</p>
                <p class="over-p2">{{$t("home.home-137")}}</p>
                <div class="btn-box">
                  <p class="tab-btn-style" @click="Dialog = true">{{$t("home.home-47")}}</p>
                  <p class="tab-btn-style no-background">{{$t("home.home-46")}}</p>
                </div>
              </div>
              <div class="overseas-right">
                <img class="over-img over-img2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_3_2document_No. 37.png" alt="">
              </div>
            </div>
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <!-- 知识产权 -->
    <div class="knowledge">
      <p class="title1">{{$t("home.home-138")}}</p>
      <p class="k-tips"><span class="red-big">1000+</span>{{$t("home.home-139")}}</p>
      <!-- <div class="k-tab"> -->
        <!-- <div class="k-tab-inner" :class="{'active': ktab == 1}" @click="ktab = 1">
          <img class="k-tab-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_2_1trademark_service.png" alt="">
          <p class="k-tab-text">{{$t("home.home-140")}}</p>
        </div> -->
        <!-- <div class="k-tab-inner" :class="{'active': ktab == 2}" @click="ktab = 2">
          <img class="k-tab-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_2_2copyright_registration.png" alt="">
          <p class="k-tab-text">{{$t("home.home-141")}}</p>
        </div>
        <div class="k-tab-inner" :class="{'active': ktab == 3}" @click="ktab = 3">
          <img class="k-tab-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_2_3patent_application.png" alt="">
          <p class="k-tab-text">{{$t("home.home-142")}}</p>
        </div>
        <div class="k-tab-inner" :class="{'active': ktab == 4}" @click="ktab = 4">
          <img class="k-tab-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_2_4project_declaration.png" alt="">
          <p class="k-tab-text">{{$t("home.home-143")}}</p>
        </div> -->
      <!-- </div> -->
      <div class="k-content"  v-if="ktab == 1">
        <div class="k-content-inner">
          <div class="k-content-left">
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-144")}}</p>
              <p class="left-2" @click="Dialog = true">{{$t("home.home-215")}} ></p>
            </div>
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-145")}}</p>
              <p class="left-2" @click="Dialog = true">{{$t("home.home-215")}} ></p>
            </div>
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-146")}}</p>
              <p class="left-2" @click="Dialog = true">{{$t("home.home-215")}} ></p>
            </div>
          </div>
          <div class="k-content-right">
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-147")}}</p>
                <p class="right-2">{{$t("home.home-148")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-149")}}</p>
                <p class="right-2">{{$t("home.home-150")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-151")}}</p>
                <p class="right-2">{{$t("home.home-152")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
            </div>
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-153")}}</p>
                <p class="right-2">{{$t("home.home-154")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-155")}}</p>
                <p class="right-2">{{$t("home.home-156")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-157")}}</p>
                <p class="right-2">{{$t("home.home-158")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
            </div>
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-159")}}</p>
                <p class="right-4">{{$t("home.home-160")}}</p>
                <p class="right-4">{{$t("home.home-162")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-163")}}</p>
                <p class="right-4">{{$t("home.home-164")}}</p>
                <p class="right-4">{{$t("home.home-165")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-166")}}</p>
                <p class="right-3" @click="Dialog = true">{{$t("home.home-215")}} ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="k-content"  v-if="ktab == 2">
        <div class="k-content-inner">
          <div class="k-content-left">
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-167")}}</p>
              <p class="left-2">{{$t("home.home-215")}} ></p>
            </div>
          </div>
          <div class="k-content-right">
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-168")}}</p>
                <p class="right-2">{{$t("home.home-169")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-170")}}</p>
                <p class="right-2">{{$t("home.home-171")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-172")}}</p>
                <p class="right-2">{{$t("home.home-173")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="k-content"  v-if="ktab == 3">
        <div class="k-content-inner">
          <div class="k-content-left">
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-174")}}</p>
              <p class="left-2">{{$t("home.home-215")}} ></p>
            </div>
          </div>
          <div class="k-content-right">
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-175")}}</p>
                <p class="right-2">{{$t("home.home-176")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-177")}}</p>
                <p class="right-2">{{$t("home.home-178")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-179")}}</p>
                <p class="right-2">{{$t("home.home-180")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="k-content"  v-if="ktab == 4">
        <div class="k-content-inner">
          <div class="k-content-left">
            <div class="left-inner">
              <p class="left-1">{{$t("home.home-181")}}</p>
              <p class="left-2">{{$t("home.home-215")}} ></p>
            </div>
          </div>
          <div class="k-content-right">
            <div class="right-box">
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-182")}}</p>
                <p class="right-2">{{$t("home.home-183")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-184")}}</p>
                <p class="right-2">{{$t("home.home-185")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
              <div class="right-inner">
                <p class="right-1">{{$t("home.home-186")}}</p>
                <p class="right-2">{{$t("home.home-187")}}</p>
                <p class="right-3">{{$t("home.home-11")}} ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 客户经理 -->
    <div class="customer">
      <p class="title1">{{$t("home.home-188")}}</p>
      <p class="k-tips">{{$t("home.home-189")}} <span class="red-big">98%</span></p>
      <div class="customer-box">
        <el-carousel :interval="3000" type="card" arrow="aways" indicator-position="none" height="530px" :autoplay="true" @change="changeImg">
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_1_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_2_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_3_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_4_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_5_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_6_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_7_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_8_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_9_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_10_1.png" alt="">
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_11_1.png" alt="">
          </el-carousel-item> -->
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_12_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_13_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_14_1.png" alt="">
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_15_1.png" alt="">
          </el-carousel-item> -->
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_16_1.png" alt="">
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_17_1.png" alt="">
          </el-carousel-item> -->
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_18_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_19_1.png" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="customer-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/photo_20_1.png" alt="">
          </el-carousel-item>
        </el-carousel>
        <div class="customer-tips">
          <div class="customer-intro" v-if="customerNum == 0"> 
            <p class="customer-p1">{{$t("home.home-190")}}</p>
            <p class="customer-p2">{{$t("home.home-191")}}</p>
          </div>
          <div class="customer-intro" v-if="customerNum == 1"> 
            <p class="customer-p1">{{$t("home.home-192")}}</p>
            <p class="customer-p2">{{$t("home.home-193")}}</p>
          </div>
          <div class="customer-intro" v-if="customerNum == 2"> 
            <p class="customer-p1">{{$t("home.home-194")}}</p>
            <p class="customer-p2">{{$t("home.home-195")}}</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 3"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">在注册方面拥有丰富的专业知识和独到的见解，为客户在前期注册提供方案，提供专业知识解答、最佳战略构想等深层次的顾问服务</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 4"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">行业深耕10年有余，精通于股权架构搭建、擅长企业战略规划，能够精准把握客户需求，并给予全面系统化的合理解决方案</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 5"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">为客户提供专业化知识和合规的运营意见，量身打造最合适的解决方案</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 6"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">行业深耕10年有余，能快速全面的解答客户的疑惑，并给予合理化建议，从客户根本利益出发，给予一站式的解决方案</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 7"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">熟悉中国香港、美国、新加坡、英国等地资本市场，精通各国财税实务在上市公司审计、财务风险管控、境外上市融资等方面均有独到见解和丰富经验</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 8"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">擅长迅速精准地把握客户的需求，为客户提供专业的咨询，使之降低成本，树立国际品牌，从而让客户立于不败之地</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 9"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">具备扎实的涉外企业注册专业基础、丰富的商标代理经验，从事市场营销多年，有着丰富的市场管理营销经验</p>   
          </div>
          <!-- <div class="customer-intro" v-if="customerNum == 10"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">从事涉外行业多年，精通国际公证认证（海牙认证、使馆认证、中国委托公证认公证等）、银海开户、国际商标注册等业务</p>   
          </div> -->
          <div class="customer-intro" v-if="customerNum == 10"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">擅长根据客户的需求提供合理的架构方案，并在最快的时间完成公司的设立；并且善于通过涉外公司的设立给予海外品牌拓展的综合方案</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 11"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">从事涉外行业多年，精通涉外律师公证、海牙认证、商标代理等业务</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 12"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">专注国际公证认证（中国委托公证人公证、国内外使领馆认证、海牙认证等），熟悉国内外注册相关业务</p>   
          </div>
          <!-- <div class="customer-intro" v-if="7 == 14"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">精通国内外公司注册及财税筹划，熟悉国内商标版权</p>   
          </div> -->
          <div class="customer-intro" v-if="customerNum == 13"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">精通贸促会认证、各使领馆认证、海牙认证和中国委托公证认证等国内外公证认证类业务，有多年国际公司设立的经验</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 14"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">擅长为客户定制合规合理的运营方案，有多年搭建架构经验及海外扩展运营综合方案的经验</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 15"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">熟悉国内外公司设立，通过公司设立定制公司运营相关方案</p>   
          </div>
          <div class="customer-intro" v-if="customerNum == 16"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">精通国内外公司注册及财税筹划，并熟悉国际公证认证（中国委托公证人公证、国内外使领馆认证、海牙认证等）等相关业务</p>   
          </div>
          <!-- <div class="customer-intro" v-if="customerNum == 17"> 
            <p class="customer-p1">高级顾问</p>
            <p class="customer-p2">具有多年的市场营销经验以及大客户的服务经验，精准把握客户需求并给予相应解决方案</p>   
          </div> -->
          <p class="tab-btn-style no-background" @click="Dialog = true" style="margin: 0 auto">{{$t("home.home-11")}}</p>
        </div>
      </div>
    </div>
    <!-- 服务特色 -->
    <div class="service">
      <p class="title1">{{$t("home.home-196")}}</p>
      <p class="k-tips"><span class="red-big">95% </span>{{$t("home.home-197")}}</p>
      <div class="service-box">
        <div class="service-inner">
          <img class="service-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_1efficiency_1.png" alt="">
          <p class="service-p1">{{$t("home.home-198")}}<br />{{$t("home.home-199")}}</p>
          <p class="service-p2">{{$t("home.home-200")}}</p>
        </div>
        <div class="service-inner">
          <img class="service-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_2global_1.png" alt="">
          <p class="service-p1">{{$t("home.home-201")}}<br />{{$t("home.home-202")}}</p>
          <p class="service-p2">{{$t("home.home-203")}}</p>
        </div>
        <div class="service-inner">
          <img class="service-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_3programme_1.png" alt="">
          <p class="service-p1">{{$t("home.home-204")}}<br />{{$t("home.home-205")}}</p>
          <p class="service-p2">{{$t("home.home-206")}}</p>
        </div>
        <div class="service-inner">
          <img class="service-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_4customer_1.png" alt="">
          <p class="service-p1">{{$t("home.home-207")}}<br />{{$t("home.home-208")}}</p>
          <p class="service-p2">{{$t("home.home-209")}}</p>
        </div>
      </div>
    </div>
    <!-- 最新资讯 -->
    <!-- <div class="information">
      <p class="title1">{{$t("home.home-210")}}</p>
      <p class="k-tips">{{$t("home.home-211")}}</p>
      <div class="info-box">
        <div class="info-left">
          <img class="info-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_4.png" alt="">
          <p class="info-p1">美国无犯罪记录证明申请</p>
          <p class="info-p2">在美国居住超过一年的中国公民，在申请第三国签证，比如加拿大、法国、德国、新西兰、澳大利亚、南非等国家的签证时，该国领事馆会要求申请人...</p>
          <p class="info-p3">2021-03-08</p>
          <p class="same-btn info-style">更多行业资讯</p>
        </div>
        <div class="info-right">
          <div class="info-line">
            <p class="info-p1">2022年快企务春节放假通知</p>
            <p class="info-p2">我司2022年春节放假时间为：1月31日—2月6日（共计7天），2月7日（年初七）正常上班。</p>
            <p class="info-p3">2022-02-11</p>
          </div>
          <div class="info-line">
            <p class="info-p1">2022年快企务元旦放假通知</p>
            <p class="info-p2">根据《国务院办公厅关于2022年部分节假日安排的通知》，我司现将2022年元旦放假安排通知如下：</p>
            <p class="info-p3">2021-12-31</p>
          </div>
          <div class="info-line">
            <p class="info-p1">2021年快企务劳动节放假通知</p>
            <p class="info-p2">您好！五一劳动节将至，根据《国务院办公厅关于2021年劳动节假日安排的通知》我公司劳动节放假时间为5月1日-5月5日，共放假五天...</p>
            <p class="info-p3">2021-04-30</p>
          </div>
          <p class="same-btn info-style">{{$t("home.home-212")}}</p>
        </div>
      </div>
    </div> -->
    <!-- 客户案例 -->
    <div class="anli">
      <p class="title1">{{$t("home.home-213")}}</p>
      <div class="anli-box">
        <!-- <vue-seamless-scroll  :data="listData"  :class-option="optionHover" class="seamless-warp">
                <el-table
                    :data="listData"
                    border
                    :show-header="status"
                    >
                    <el-table-column
                        prop="date"
                        label="日期"
                        >
                    </el-table-column>
                    <el-table-column
                        prop="title"
                        label="姓名"
                        >
                    </el-table-column>
                    <el-table-column	  
                        prop="test"
                        label="地址"
                        >
                    </el-table-column>
                </el-table>
            </vue-seamless-scroll> -->
        <!-- <vue-seamless-scroll :data="listData" :class-option="optionHover" class="seamless-warp">
          <ul class="ul-scoll">
            <li v-for="(item,index) in listData" :key='index'> 
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_11.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_12.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_13.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_14.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_15.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_16.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_7.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_8.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_9.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_10.png" alt="">
            </li>
            <li>
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_11.png" alt="">
              <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_12.png" alt="">
            </li>
          </ul>
        </vue-seamless-scroll> -->
        <!-- <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_11.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_12.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_13.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_14.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_15.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_16.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_7.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_8.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_9.png" alt="">
        <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_10.png" alt=""> -->
        <marquee scrollamount="9" class="marquee-style">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_1.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_2.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_3.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_4.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_5.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_6.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_7.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_8.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_9.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_10.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_11.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_12.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_13.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_14.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_15.png" alt="">
          <img class="anli-logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_2_16.png" alt="">
        </marquee>
      </div>
      <!-- <p class="same-btn">{{$t("home.home-214")}}</p> -->
    </div>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NumberGrow from '@/components/numberGrow.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
    NumberGrow
  },
  
  data(){
    return{
      list: [],
      notice: '',
      internationalTab: 1,
      ktab: 1,
      customerNum: 0,
      Dialog: false,
      tabBag: 'tabBag1',
      status:false,
        listData: [
					{
            title: '无缝滚动第一行无缝滚动第一行',
            date: '2017-12-16',
            test:'测试',
            test2:'测试2',
            test3:'测试3'
          },
          {
              title: '无缝滚动第2行无缝滚动第2行',
              date: '2017-12-16',
              test:'测试',
              test2:'测试2',
              test3:'测试3'
          }
				],
        
    }
  },
  computed: {
    optionHover() {
        return {
            step: 0.5, // 数值越大速度滚动越快
            limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
            hoverStop: true, // 是否开启鼠标悬停stop
            direction: 2, // 0向下 1向上 2向左 3向右
            openWatch: true, // 开启数据实时监控刷新dom
            singleHeight: 50, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
            waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
    },
  },
  mounted(){
    window.scrollTo(0,0);
    this.getNotice()
  },
  methods:{
    getNotice(){
      let that = this
        that.$axios.get(`/notice/front/list`,{}).then((res)=>{
          that.list = res.data.data
          that.notice = that.list[0] ? that.list[0].content : ''
          console.log(that.list)
        })
    },
    changeImg(e){
      // console.log(e)
      this.customerNum = e
    },
    toBank(){
      this.$router.push({name: 'bank'})
    },
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>
<style lang="scss">
.home{
  // font-family: '阿里巴巴惠普体标准';
  // .banner{
  //   width: 100%;
  //   height: 800px;
  //   background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/banner.png') center center;
  //   background-size: 100% 100%;
  // }
}
.banner{
    width: 100%;
    height: 800px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/banner.jpg') center center;
    background-size: 100% 100%;
    .header-p1{
      font-size: 72px;
      font-weight: 700;
      padding-top: 205px;
    }
    .header-p2{
      font-size: 32px;
      padding-top: 30px;
      .span1{
        font-size: 72px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
      .span2{
        font-size: 52px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
    }
    .header-p3{
      font-size: 32px;
      padding-top: 40px;
    }
    .header-p4{
      width: 170px;
      height: 60px;
      border-radius: 8px;
      background: rgba(47, 114, 235, 1);
      line-height: 60px;
      text-align: center;
      font-size: 28px;
      color: #fff;
      margin-top: 50px;
      cursor: pointer;
    }

  }
.international{
  width: 100%;
  height: 838px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_1_1.png') center center;
  background-size: 100% 100%;
}
.tabBag1{
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_1_1.png') center center;
  background-size: 100% 100%;
}
.tabBag2{
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_1_2.png') center center;
  background-size: 100% 100%;
}
.tabBag3{
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_1_3.png') center center;
  background-size: 100% 100%;
}
.tabBag4{
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/pic_1_4.png') center center;
  background-size: 100% 100%;
}
.service-inner:hover:nth-child(1){
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_1efficiency_3.png') center;
}
.service-inner:hover:nth-child(2){
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_2global_3.png') center;
}
.service-inner:hover:nth-child(3){
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_3programme_3.png') center;
}
.service-inner:hover:nth-child(4){
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/icon_3_4customer_3.png') center;
}

</style>
