<template>
  <div class="se-hongkong">
    <div class="se-hong-header">
      <div class="banner-inner">
        <p class="se-hong-p1">中国香港公司年审</p>
        <p class="se-hong-p2">一站式全程代办   最快1天完成</p>
        <p class="se-banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="input-box" style="height: 314px">
      <p class="b-title" style="padding-top: 40px;">中国香港年审业务办理</p>
      <p class="b-title1" style="margin-bottom: 0;">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="input-box-line">
        <el-form :model="form" ref="ruleForm" :rules="rules" label-width="0" class="demo-form-inline" :inline="true">
          <el-form-item label="" prop="name">
            <el-input placeholder="请输入您的姓名" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <el-input placeholder="请输入您的手机号码" v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item label="" prop="time">
            <el-select v-model="form.time" placeholder="请选择年审时间" clearable>
              <el-option label="一月" value="一月"></el-option>
              <el-option label="二月" value="二月"></el-option>
              <el-option label="三月" value="三月"></el-option>
              <el-option label="四月" value="四月"></el-option>
              <el-option label="五月" value="五月"></el-option>
              <el-option label="六月" value="六月"></el-option>
              <el-option label="七月" value="七月"></el-option>
              <el-option label="八月" value="八月"></el-option>
              <el-option label="九月" value="九月"></el-option>
              <el-option label="十月" value="十月"></el-option>
              <el-option label="十一月" value="十一月"></el-option>
              <el-option label="十二月" value="十二月"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="package">
            <el-select v-model="form.package" placeholder="请选择年审套餐" clearable>
              <el-option label="年审" value="年审"></el-option>
              <el-option label="年审+SCR" value="年审+SCR"></el-option>
              <el-option label="年审+SCR+水牌" value="年审+SCR+水牌"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <p class="input-box-btn" @click="toSubmit">计算费用</p>
    </div>
    <div class="m-advantage know-advantage hongkong-advantage">
      <p class="b-title">为什么要按时年审</p>
      <p class="b-title1">逾期年审影响与罚款细则</p>
      <div class="m-adv-box">
        <div class="m-adv-left">
          <img class="m-adv-left-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_1.png" alt="">
        </div>
        <div class="m-adv-right">
          <p class="m-ad-title">政府罚款</p>
          <p class="m-ad-p">政府罚款或起诉，还有法院下发传票</p>
          <p class="m-ad-title">账户冻结</p>
          <p class="m-ad-p">银行将会被冻结，无法收支款，无法取出余额，以后再无法开户</p>
          <p class="m-ad-title">信用受损</p>
          <p class="m-ad-p">可能会导致股东董事被记录不良信用，影响出入境和航班转机等</p>
          <el-popover
            placement="bottom"
            width="446"
            trigger="click">
            <div class="popover-box">
              <p class="popover-title" style="font-size: 20px; font-weight: 500; padding-top: 10px; color: rgba(20, 19, 19, 1); margin-bottom: 20px;">中国香港公司逾期罚款说明</p>
              <p class="popover-p" style="font-size: 16px;border-bottom: 1px solid rgba(237.15, 237.15, 237.15, 1);padding-bottom: 16px;margin-top: 16px; ">公司成立周年日起超过
                <span class="blue-style" style="color: rgba(47, 114, 235, 1)">30日</span>
                <span class="red-style" style="color: rgba(237, 41, 65, 1);font-weight: 500;margin-left: 30px;">罚HKD300</span>
              </p>
              <p class="popover-p" style="font-size: 16px;border-bottom: 1px solid rgba(237.15, 237.15, 237.15, 1);padding-bottom: 16px; margin-top: 16px;">公司成立周年日起超过
                <span class="blue-style" style="color: rgba(47, 114, 235, 1)">42日</span>但不超过3个月	     
                <span class="red-style" style="color: rgba(237, 41, 65, 1);font-weight: 500;margin-left: 30px;">罚HKD870</span>
              </p>
              <p class="popover-p" style="font-size: 16px;border-bottom: 1px solid rgba(237.15, 237.15, 237.15, 1);padding-bottom: 16px; margin-top: 16px;">公司成立周年日起超过
                <span class="blue-style" style="color: rgba(47, 114, 235, 1)">3个月</span>但不超过6个月	    	     
                <span class="red-style" style="color: rgba(237, 41, 65, 1);font-weight: 500;margin-left: 30px;">罚HKD1740</span>
              </p>
              <p class="popover-p" style="font-size: 16px;border-bottom: 1px solid rgba(237.15, 237.15, 237.15, 1);padding-bottom: 16px; margin-top: 16px;">公司成立周年日起超过
                <span class="blue-style" style="color: rgba(47, 114, 235, 1)">6个月</span>但不超过9个月	    	    	     
                <span class="red-style" style="color: rgba(237, 41, 65, 1);font-weight: 500;margin-left: 30px;">罚HKD2610</span>
              </p>
              <p class="popover-p" style="font-size: 16px;border-bottom: 1px solid rgba(237.15, 237.15, 237.15, 1);padding-bottom: 16px;margin-top: 16px; ">公司成立周年日起超过
                <span class="blue-style" style="color: rgba(47, 114, 235, 1)">9个月</span>
                <span class="red-style" style="color: rgba(237, 41, 65, 1);font-weight: 500;margin-left: 30px;">罚HKD3480</span>
              </p>
            </div>
            <p class="m-ad-link"  slot="reference">了解详细罚款额度 ></p>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="m-advantage know-advantage hongkong-advantage1">
      <p class="b-title">中国香港公司的年审什么时候办理</p>
      <p class="b-title1">年审办理时间与注意事项</p>
      <div class="m-adv-box">
        <div class="m-adv-right">
          <p class="m-ad-content">年审办理时间：</p>
          <p class="m-ad-title">安排年审</p>
          <p class="m-ad-p">中国香港公司每年在成立日期到期前都需要安排年审工作</p>
          <p class="m-ad-title">提前一周</p>
          <p class="m-ad-p">一般会提前一周以上准备办理，以免造成年审超期产生罚款</p>
          <p class="m-ad-content1">注意事项：</p>
          <p class="m-ad-title">罚款累加</p>
          <p class="m-ad-p">起期没年审的公司随着时间越长，罚款会越来越多</p>
          <p class="m-ad-title">影响使用</p>
          <p class="m-ad-p">甚至还会影响到银行账户的正常使用</p>
        </div>
        <div class="m-adv-left">
          <img class="m-adv-left-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="h-matters">
      <p class="b-title">中国香港公司年审事项</p>
      <p class="b-title1">年审内容：更换最新年度商业登记证和递交周年申报表</p>
      <div class="h-matters-box">
        <div class="h-matters-inner">
          <img class="h-matters-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/icon_1_1.png" alt="">
          <p class="h-matters-title">年审周期</p>
          <p class="h-matters-p">一年一审；成立日期前一个月办理，我司将有专人电话通知贵司，如联系方式有变动请及时与我司联系</p>
        </div>
        <div class="h-matters-inner">
          <img class="h-matters-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/icon_1_2.png" alt="">
          <p class="h-matters-title">所需资料</p>
          <p class="h-matters-p">快企务成立的公司不需额外提供资料， 快企务投资公司是贵司在中国香港的法定秘书，快企务代为申报所以贵司年审不需要提供额外资料</p>
        </div>
        <div class="h-matters-inner">
          <img class="h-matters-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/icon_1_3.png" alt="">
          <p class="h-matters-title">年审费用</p>
          <p class="h-matters-p">快企务全年秘书服务费用+政府费用实报实销（一次性支付）</p>
        </div>
        <div class="h-matters-inner">
          <img class="h-matters-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/icon_1_4.png" alt="">
          <p class="h-matters-title">办理时间</p>
          <p class="h-matters-p">办理时间为上年度年审到期后的3至5个工作日</p>
        </div>
        <div class="h-matters-inner">
          <img class="h-matters-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/icon_1_5.png" alt="">
          <p class="h-matters-title">年审完成后所得数据</p>
          <p class="h-matters-p">最新年度商业登记证BR，周年申报表NAR1，会计师认证文件，收据等年审资料一起免费快递到您的手中</p>
        </div>
      </div>
      <p class="h-matters-tips"><span class="blue-style">温馨提示：</span>如超过到期日42天未年审，政府会按逾期的时间进行罚款。请在收到快企务年审通知后尽早办理！</p>
    </div>
    <div class="one-server hong-serve">
      <p class="b-title">快企务优质秘书服务</p>
      <p class="b-title1">快企务为您提供更多服务</p>
      <div class="one-serve-box">
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_1.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">提供法定注册地址</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_2.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">提供法定秘书</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_3.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">申报年报</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_4.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">商务秘书服务</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_5.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">公司注册处更改数据</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_6.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">做无雇员申报</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {},
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        package: [{ required: true, message: '请选择年审套餐', trigger: 'change'}],
        time: [{ required: true, message: '请选择年审时间', trigger: 'change'}]
      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 1,
            name: that.form.name,
            mobile: that.form.mobile,
            examinationTime: that.form.time,
            examinationType: that.form.package,
            country: '中国香港'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            }); 
            that.form.name = ''
            that.form.mobile = ''
            that.form.time = null
            that.form.package = null
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .se-hong-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
