<template>
    <div class="exam-kaiman">
      <div class="se-hong-header">
        <div class="banner-inner">
          <p class="se-hong-p1">美国公司年审</p>
          <p class="se-hong-p2">一站式全程代办   最快1天完成</p>
          <p class="se-banner-btn" @click="Dialog = true">立即办理</p>
        </div>
      </div>
      <div class="exma-business-Am">
        <p class="b-title">美国年审业务办理</p>
        <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
        <div class="exma-business-Am-box center-width">
          <div class="exma-business-Am-inner">
            <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
              <el-form-item label="" prop="name">
                <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
              </el-form-item>
              <el-form-item label="" prop="area">
                <el-select class="exma-select-length" v-model="form.area" placeholder="请选择您要办理年审的地区" clearable>
                  <el-option label="佛罗里达州" value="佛罗里达州"></el-option>
                  <el-option label="科罗拉多州" value="科罗拉多州"></el-option>
                  <el-option label="内华达州" value="内华达州"></el-option>
                  <el-option label="纽约州" value="纽约州"></el-option>
                  <el-option label="加利福尼亚州" value="加利福尼亚州"></el-option>
                  <el-option label="华盛顿州" value="华盛顿州"></el-option>
                  <el-option label="马萨诸塞州" value="马萨诸塞州"></el-option>
                  <el-option label="密歇根州" value="密歇根州"></el-option>
                  <el-option label="新罕布什尔州" value="新罕布什尔州"></el-option>
                  <el-option label="新泽西州" value="新泽西州"></el-option>
                  <el-option label="北卡罗来纳州" value="北卡罗来纳州"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
              </el-form-item>
            </el-form>
          </div>
          <div class="exma-business-tips" v-if="form.area == '佛罗里达州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>有限公司为2年一审，股份有限公司为周年年审；</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>2,050美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '科罗拉多州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司成立周年日当月结束前进行年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>990美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '内华达州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司成立后一个半月内进行备案（可理解为年审）</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>6,435美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '纽约州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>有限公司为2年一审，股份有限公司为周年年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>2,625美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '加利福尼亚州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司成立周年日当月结束前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,950美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '华盛顿州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司周年日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,950美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '马萨诸塞州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>每年3月15日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,950美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '密歇根州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>每年3月15日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,800美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '新罕布什尔州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>每年4月1日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,900美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '新泽西州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司周年日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,950美元</p>
          </div>
          <div class="exma-business-tips" v-if="form.area == '北卡罗来纳州'">
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">年审时间：</span>公司周年日前年审</p>
            <p class="exma-business-tips-p"><span class="exma-business-tips-span">费用：</span>1,850美元</p>
          </div>
        </div>
      </div>
      <div class="exma-why-Am">
        <p class="b-title">为什么要按时年审</p>
        <p class="b-title1">逾期年审影响</p>
        <div class="exma-why-Am-box center-width flex-between">
          <div class="exma-why-Am-inner">
            <img class="exma-why-Am-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_1_1.png" alt="">
            <div>
              <p class="exma-why-Am-p1">逾期越长，罚款越多</p>
              <p class="exma-why-Am-p2">各州政府会根据逾期的时长和注册地来决定罚款金额，年审逾期会产生罚款，时间越长罚款越多</p>
            </div>
          </div>
          <div class="exma-why-Am-inner">
            <img class="exma-why-Am-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_1_2.png" alt="">
            <div>
              <p class="exma-why-Am-p1">逾期将被标记，影响正常经营</p>
              <p class="exma-why-Am-p2">美国公司逾期年审三个月会被标记为不正常经营，无法正常开展经营活动，难以维持客户关系</p>
            </div>
          </div>
          <div class="exma-why-Am-inner">
            <img class="exma-why-Am-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_1_3.png" alt="">
            <div>
              <p class="exma-why-Am-p1">被政府列入黑名单</p>
              <p class="exma-why-Am-p2">除此之外，该公司会被列入政府黑名单会有不良信誉记录的，除了美国公司不能正常运作外，该公司董事/股东后续想在美国注册公司就会有一定的阻碍</p>
            </div>
          </div>
          <div class="exma-why-Am-inner">
            <img class="exma-why-Am-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_1_4.png" alt="">
            <div>
              <p class="exma-why-Am-p1">公司被强制除名</p>
              <p class="exma-why-Am-p2">美国公司如果长期不做年审，不仅仅会被罚款，公司也会被除名。一两年后被所在州政府自动注销除名的，这种情况是属于被政府强制除名的</p>
            </div>
          </div>
        </div>
      </div>
      <div class="exma-attention">
        <p class="b-title">美国公司年审办理时间与注意事项</p>
        <div class="exma-attention-box center-width flex-between">
          <div class="exma-attention-inner">
            <img class="exma-attention-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_2_1.png" alt="">
            <p class="exma-attention-p1">美国公司需要年审的最好提前一个月提前办理年审手续流程与要求，保障公司的正常经营</p>
            <p class="exma-attention-btn" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="exma-attention-inner">
            <img class="exma-attention-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_2_2.png" alt="">
            <p class="exma-attention-p1">美国政府对于没有在美国经营，没有和美国发生贸易往来的美国公司，可以不用做账和缴税（依然需要报税），即进行零申报。反之则需要缴纳相应税款</p>
            <p class="exma-attention-btn" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="exma-attention-inner">
            <img class="exma-attention-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/icon_2_3.png" alt="">
            <p class="exma-attention-p1">美国公司如果不按时年审，州政府会根据逾期的时长和注册地来决定罚款金额</p>
            <p class="exma-attention-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
      <div class="exma-matters no-top">
        <p class="b-title">美国公司年审事项</p>
        <div class="exma-matters-box center-width flex-between">
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_1.png" alt="">
              <p class="exma-matters-p">年审周期</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">年审周期</p>
              <p class="exma-matters-p2">特拉华州：每年3月1日前；加州、科罗拉多州、纽约州、内华达州、俄勒冈州：每年的注册月</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_2.png" alt="">
              <p class="exma-matters-p">所需资料</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">所需资料</p>
              <p class="exma-matters-p2">注册下来的主体文件</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_3.png" alt="">
              <p class="exma-matters-p">年审费用</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1" style="padding-top: 20px">年审费用</p>
              <p class="exma-matters-p2">特拉华州：2620；加州州税：1、只做年检，不办理州税 ¥1500；2、年检＋一年基本州税  ¥8800（如有罚款，需额外支付）科罗拉多州：990；内华达州：¥6,435；俄勒冈州：1,815;纽约州：¥2625</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_4.png" alt="">
              <p class="exma-matters-p">办理时间</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">办理时间</p>
              <p class="exma-matters-p2">不同州办理时间不一样</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
          <div class="exma-matters-inner">
            <div class="exma-matters-box1">
              <img class="exma-matters-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/kaiman/icon_2_5.png" alt="">
              <p class="exma-matters-p">年审完成后所得数据或文件</p>
            </div>
            <div class="exma-matters-hover-box">
              <p class="exma-matters-p1">年审完成后所得数据或文件</p>
              <p class="exma-matters-p2">部分州有年审回执</p>
              <p class="exma-matters-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </div>
        </div>
      </div>
      <div class="one-server hong-serve">
        <p class="b-title">快企务优质秘书服务</p>
        <p class="b-title1">快企务为您提供更多服务</p>
        <div class="one-serve-box">
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_1.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">提供法定注册地址</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_2.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">提供法定秘书</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_3.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">申报年报</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_4.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">商务秘书服务</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_5.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">公司注册处更改数据</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
          <div class="one-serve-inner">
            <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/secretary/hongkong/pic_3_6.png" alt="">
            <div class="hover-box"></div>
            <p class="one-serve-p">做无雇员申报</p>
            <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        form: {},
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
          area: [{ required: true, message: '请选择您要办理年审的地区', trigger: 'change' }]
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      toSubmit(){
        let that = this
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              type: 1,
              name: that.form.name,
              mobile: that.form.mobile,
              area: that.form.area,
              country: '美国'
            }
            that.$axios.post('/cms/message/save',params).then((result)=>{
              this.$notify({
                title: '提交成功',
                message: '我们会尽快联系您！',
                type: 'success',
                offset: 100,
                duration: 2000
              }); 
              that.form.name = ''
              that.form.mobile = ''
              that.form.area = null
            })
          } else {
            return false;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .se-hong-header{
      width: 100%;
      height: 600px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/examination/America/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  