<template>
  <div class="header">
    <!-- <div style="border: 1px solid red;width: 200px;height: 200px; position: fixed;bottom: 0;right: 0;" id="LR_User_Icon0"></div> -->
    <div class="header-inner">
      <img class="header-logo" @click="toUrl('home')" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/header-logo.png" alt="">
      <div class="header-tab">
        <div class="tab-inner">
          <p class="tab-inner-title">全球公司注册 <i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content">
              <div class="tab-box-left">
                <p class="tab-box-p1" @click="toUrl('global')">全球公司注册</p>
                <p class="tab-box-p2">在全球最热门的国家/地区注册公司，快企务祝您与国际接轨</p>
                <p class="tab-box-p3" @click="toUrl('global')">点击进入 ></p>
              </div>
              <div class="tab-box-right">
                <p class="tab-box-right-p" @click="toUrl('bvi')">BVI公司注册 <span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('Germany')">德国公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerAnquila')">安圭拉公司注册</p>
                <!-- <p class="tab-box-right-p" @click="toUrl('registerCyprus')">塞浦路斯公司注册</p> -->
                <p class="tab-box-right-p" @click="toUrl('registerItaly')">意大利公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('kaiman')">开曼公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerFrench')">法国公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerMalta')">马耳他公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerNetherlands')">荷兰公司注册</p>
                
                <p class="tab-box-right-p" @click="toUrl('singapore')">新加坡公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerMacau')">中国澳门公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerLuxembourg')">卢森堡公司注册</p>
                <p class="tab-box-right-p"></p>
                <p class="tab-box-right-p" @click="toUrl('HongKong')">中国香港公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerJapan')">日本公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerVietnam')">越南公司注册</p>
                <p class="tab-box-right-p"></p> 
                <p class="tab-box-right-p" @click="toUrl('America')">美国公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerSwiss')">瑞士公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerIndonesia')">印尼公司注册</p>
                <p class="tab-box-right-p"></p>
                <p class="tab-box-right-p" @click="toUrl('Britain')">英国公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerCyprus')">塞浦路斯公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerMauritius')">毛里求斯公司注册</p>
                <p class="tab-box-right-p"></p>
                <p class="tab-box-right-p" @click="toUrl('Taiwan')">中国台湾公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerDubai')">迪拜公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerCanada')">加拿大公司注册</p>
                <p class="tab-box-right-p"></p>
                <p class="tab-box-right-p" @click="toUrl('Marshall')">马绍尔公司注册</p>
                <p class="tab-box-right-p" @click="toUrl('registerKorea')">韩国公司注册<span class="hot-style">HOT</span><img class="hot-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.gif" alt=""></p>
                <p class="tab-box-right-p" @click="toUrl('registerSamoa')">萨摩亚公司注册</p>
                <p class="tab-box-right-p"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title">国际公证认证<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content" style=" width: 1250px">
              <div class="tab-box-left">
                <p class="tab-box-p1" @click="toUrl('notarization')">国际公证认证</p>
                <p class="tab-box-p2">与全球公证机构展开合作，是最早进入国际公证认证领域</p>
                <p class="tab-box-p3" @click="toUrl('notarization')">点击进入 ></p>
              </div>
              <div class="tab-box-right" style=" width: 1100px">
                <p class="tab-box-right-p1" @click="toUrl('notarization')">国际公证认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationCanada')">中国驻加拿大使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationAustralia')">中国驻澳大利亚使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationMalaysia')">中国驻马来西亚使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('notarizationHague')">海牙认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationBvi')">中国驻BVI使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationcayman')">中国驻开曼使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationVietnam')">中国驻越南使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('notarizationEmbassy')">使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationRussia')">中国驻俄罗斯使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationBritain')">中国驻英国使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationKorea')">中国驻韩国使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('notarizationChina')">中国委托公证人认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationGermany')">中国驻德国使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationSeychelles')">中国驻塞舌尔使馆认证</p>
                <!-- <p class="tab-box-right-p1" @click="toUrl('lawHK')">香港法律意见书</p> -->
                
                <p class="tab-box-right-p1" @click="toUrl('notarizationTrade')">贸促会认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationSamoa')">中国驻萨摩亚使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationAmerica')">中国驻美国使馆认证</p>
                
               
                <p class="tab-box-right-p1" @click="toUrl('certificationFrench')">中国驻法国使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationNetherlands')">中国驻荷兰使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationSingapore')">中国驻新加坡使馆认证</p>
                <p class="tab-box-right-p1" @click="toUrl('certificationVideo')">视频委托公证认证</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title1">秘书服务<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content1">
              <p class="tab-box1-p" @click="toUrl('secretaryHongKong')">
                <!-- <img class="tab-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_HK.png" alt=""> -->
                <span class="tab-box1-span">中国香港公司年审</span>
              </p>
              <p class="tab-box1-p" @click="toUrl('examinationAmerica')">
                <!-- <img class="tab-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_US.png" alt=""> -->
                <span class="tab-box1-span">美国公司年审</span>
              </p>
              <p class="tab-box1-p" @click="toUrl('examinationSingapore')">
                <!-- <img class="tab-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_SG.png" alt=""> -->
                <span class="tab-box1-span">新加坡公司年审</span>
              </p>
              <p class="tab-box1-p" @click="toUrl('examinationKaiman')">
                <!-- <img class="tab-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_CI.png" alt=""> -->
                <span class="tab-box1-span">开曼公司年审</span>
              </p>
              <p class="tab-box1-p" @click="toUrl('examinationBvi')">
                <!-- <img class="tab-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_1_BVI.png" alt=""> -->
                <span class="tab-box1-span">BVI公司年审</span>
              </p>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title1">财税筹划<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content2">
              <p class="tab-box2-p" @click="toUrl('fiscalTax')">中国香港公司财税筹划</p>
              <p class="tab-box2-p" @click="toUrl('fiscalTaxAmerica')">美国公司财税筹划</p>
            </div>
          </div>
        </div>
        <!-- <div class="tab-inner">
          <p class="tab-inner-title1" @click="toUrl('visa')">日本签证</p>
        </div> -->
        <div class="tab-inner">
          <p class="tab-inner-title" style="width: 125px">境外投资备案<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content6">
              <p class="tab-box2-p" @click="toUrl('ODI')">境外投资备案</p>
              <p class="tab-box2-p" @click="toUrl('VIE')">VIE架构</p>
              <p class="tab-box2-p" @click="toUrl('lawAmerica')">美国法律意见书</p>
              <p class="tab-box2-p" @click="toUrl('lawHK')">中国香港法律意见书</p>
              <p class="tab-box2-p" @click="toUrl('visa')">日本签证</p>
              <p class="tab-box2-p" @click="toUrl('HKplan')">中国香港优才计划</p>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title" style="width: 135px">无犯罪记录证明<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content5">
              <p class="tab-box2-p" @click="toUrl('noCriminalAmerica')">美国无犯罪记录证明</p>
              <p class="tab-box2-p" @click="toUrl('noCriminalSingapore')">新加坡无犯罪记录证明</p>
              <p class="tab-box2-p" @click="toUrl('noCriminalBritain')">英国无犯罪记录证明</p>
              <p class="tab-box2-p" @click="toUrl('noCriminalCanada')">加拿大无犯罪记录证明</p>
              <p class="tab-box2-p" @click="toUrl('noCriminalAustralia')">澳大利亚无犯罪记录证明</p>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title" @click="toUrl('bank')">海外银行开户</p>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title" style="width: 90px">知识产权<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content4">
              <div class="tab-box4-left">
                <p class="tab-box4-p1" @click="toUrl('knowledge')">知识产权</p>
                <p class="tab-box4-p2" @click="toUrl('knowledge')">点击进入 ></p>
              </div>
              <div class="tab-box4-right">
                <p class="tab-box4-p3" @click="toUrl('trademarkdomestic')">国内商标注册</p>
                <p class="tab-box4-p3" @click="toUrl('trademarkEU')">欧盟商标注册</p>
                <p class="tab-box4-p3" @click="toUrl('trademarkAmerica')">美国商标注册</p>
                <p class="tab-box4-p3" @click="toUrl('trademarkFrench')">法国商标注册</p>
                <p class="tab-box4-p3" @click="toUrl('trademarkJapan')">日本商标注册</p>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-inner">
          <p class="tab-inner-title1" style="width: 90px">关于<i class="el-icon-arrow-down"></i></p>
          <div class="tab-box">
            <div class="tab-box-content3">
              <div class="tab-box3-left">
                <p class="tab-box3-title">关于我们</p>
                <p class="tab-box3-p" @click="toUrl('about')">
                  <img class="tab-box3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_about_us.png" alt="">
                  <span>关于快企务</span>
                </p>
                <p class="tab-box3-p" @click="toUrl('join')">
                  <img class="tab-box3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_join_us.png" alt="">
                  <span>加入我们</span>
                </p>
                <p class="tab-box3-p" @click="toUrl('newsCompany')">
                  <img class="tab-box3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_journalism.png" alt="">
                  <span>快企务资讯</span>
                </p>
              </div>
              <div class="tab-box3-right">
                <p class="tab-box3-p1">服务热线</p>
                <p class="tab-box3-p2">400-860-5600</p>
                <p class="tab-box3-p1 p1-margin">投诉电话</p>
                <p class="tab-box3-p2 no-border">18033436502</p>
                <img class="tab-box3-right-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/QRcode.png" alt="">
                <p class="tab-box3-p5">官方客服微信</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'Home-page',
  props: {
    msg: String
  },
  data(){
    return{
      tab: 2
    }
  },
  methods: {
    toUrl(url){
      this.$router.push({name: url})
    }
  }
}
</script>

<style scoped lang="scss">
  .banner{
    width: 100%;
    height: 800px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/banner1.jpg') center center;
    background-size: 100% 100%;
    .header-p1{
      font-size: 72px;
      font-weight: 700;
      padding-top: 205px;
    }
    .header-p2{
      font-size: 32px;
      padding-top: 30px;
      .span1{
        font-size: 72px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
      .span2{
        font-size: 52px;
        font-weight: 700;
        color: rgba(47, 114, 235, 1);
      }
    }
    .header-p3{
      font-size: 32px;
      padding-top: 40px;
    }
    .header-p4{
      width: 170px;
      height: 60px;
      border-radius: 8px;
      background: rgba(47, 114, 235, 1);
      line-height: 60px;
      text-align: center;
      font-size: 28px;
      color: #fff;
      margin-top: 50px;
      cursor: pointer;
    }

  }
</style>
