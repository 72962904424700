<template>
  <div class="anquila malta">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式马耳他公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="malta-advantage">
      <p class="re-title">注册马耳他公司的优势</p>
      <div class="malta-adv-box center-width flex-between">
        <div class="malta-adv-inner">
          <img class="malta-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_1_1.png" alt="">
          <div>
            <p class="malta-adv-p1">全球唯一的“四位一体”国</p>
            <p class="malta-adv-p2">申根国、欧盟国、欧元国、英联邦成员国。持有马耳他护照可以自由出入欧洲和英联邦多个国家，无需签证</p>
          </div>
        </div>
        <div class="malta-adv-inner">
          <img class="malta-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_1_2.png" alt="">
          <div>
            <p class="malta-adv-p1">政治、经济局势稳定</p>
          </div>
        </div>
        <div class="malta-adv-inner">
          <img class="malta-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_1_3.png" alt="">
          <div>
            <p class="malta-adv-p1">欧洲著名避税天堂</p>
            <p class="malta-adv-p2">无遗产税，无全球征税。马耳他的收税制度对永久居民非常优惠。国外收入则不征收任何税费，移民批准后的半年内带入马耳他的实物资产不征税</p>
          </div>
        </div>
        <div class="malta-adv-inner">
          <img class="malta-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_1_4.png" alt="">
          <div>
            <p class="malta-adv-p1">社会保障体系较完备</p>
            <p class="malta-adv-p2">实行免费教育、免费医疗及退休保险制</p>
          </div>
        </div>
      </div>
    </div>
    <div class="malta-type">
      <p class="re-title">注册马耳他公司的类型</p>
      <div class="malta-type-box center-width flex-between">
        <div class="malta-type-inner">
          <img class="malta-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/pic_1_1.jpg" alt="">
          <p class="malta-type-p1">有限责任公司</p>
          <p class="malta-type-p2">股份有限公司或私人有限公司</p>
          <p class="malta-type-p3" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="malta-type-inner">
          <img class="malta-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/pic_1_2.jpg" alt="">
          <p class="malta-type-p1">合伙公司</p>
          <p class="malta-type-p2">普通合伙公司和有限公司</p>
          <p class="malta-type-p3" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="malta-file">
      <p class="re-title">注册马耳他公司的条件/流程/资料</p>
      <div class="malta-file-box center-width">
        <div class="malta-file-tab">
          <p class="malta-file-tab-p" :class="{'active': tab == 1}" @click="tab = 1">注册马耳他公司条件</p>
          <p class="malta-file-tab-p" :class="{'active': tab == 2}" @click="tab = 2">注册马耳他公司流程</p>
          <p class="malta-file-tab-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="malta-file-content flex-between" v-if="tab == 1">
          <div class="malta-file-inner">
            <div class="malta-file-title">
              <p class="title-number">01</p>
              <p class="malta-file-title-p">有限责任公司<span class="malta-file-title-span">（必须在马耳他注册）</span></p>
            </div>
            <p class="malta-file-p"><span class="circle"></span><span class="else">私人有限责任公司至少有1个创始人，股东不能超过50个</span></p>
            <p class="malta-file-p"><span class="circle"></span><span class="else">最少股本金要1,200欧元，20%需要在注册前缴纳</span></p>
            <p class="malta-file-p"><span class="circle"></span><span class="else">至少1名董事和1名公司秘书。股东和董事可以在当地，也可以在国外</span></p>
          </div>
          <div class="malta-file-inner">
            <div class="malta-file-title">
              <p class="title-number">02</p>
              <p class="malta-file-title-p">股份有限公司<span class="malta-file-title-span">（必须在马耳他注册）</span></p>
            </div>
            <p class="malta-file-p"><span class="circle"></span><span class="else">股份有限公司必须至少2个创始人，可以多于50个股东</span></p>
            <p class="malta-file-p"><span class="circle"></span><span class="else">最少需要股本金46,588（46,000）欧元，25%需要在注册前缴纳</span></p>
          </div>
        </div>
        <div class="malta-file-content flex-between" v-if="tab == 2">
          <img class="malta-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/pic_1.png" alt="">
        </div>
        <div class="malta-file-content flex-between" v-if="tab == 3">
          <div class="malta-file3-box">
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_1.png" alt="">
              <p class="malta-file3-p">注册证书</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_2.png" alt="">
              <p class="malta-file3-p">首任董事<br />委任书</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_3.png" alt="">
              <p class="malta-file3-p">章程两本<br />一正一副</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_4.png" alt="">
              <p class="malta-file3-p">公司会议记录表格（董事名册、股东名册、秘书名册）</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_5.png" alt="">
              <p class="malta-file3-p">印章</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_6.png" alt="">
              <p class="malta-file3-p">代理人架构证书</p>
            </div>
            <div class="malta-file3-inner">
              <img class="malta-file3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_3_7.png" alt="">
              <p class="malta-file3-p">股票</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b malta-serve">
      <p class="re-title">注册马耳他公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p2">可申请税号，一般不需要申请</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">马耳他银行有100多家分行网络，提供所有合法的银行业务服务。各国主要银行在...</p>
          <p class="sub-p4">马耳他银行有100多家分行网络，提供所有合法的银行业务服务。各国主要银行在马耳他都设有直接分行或在地方商业银行和马耳他中央银行设代表处。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">马耳他有限责任制公司须让拥有马耳他公司审计经验的公共注册会计师对其年度...</p>
          <p class="sub-p4">马耳他有限责任制公司须让拥有马耳他公司审计经验的公共注册会计师对其年度金融财务报表做审计。审计后的金融财务报表须按年度定时递交马耳他公司注册局</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/icon_4_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">马耳他公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="马耳他公司的董事必须是本地的吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">私人有限责任公司至少有1个创始人，股东不能超过50个。至少1名董事和1名公司秘书，股东和董事可以在<br />
                当地也可以在国外。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="马耳他可以设立合伙企业吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">可以设立，合伙公司至少需要有2名合伙人，无低股本要求。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业马耳他公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Malta/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
