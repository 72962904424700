<template>
  <div class="noc-content">
    <div class="noc-content-header">
      <div class="noc-content-inner center-width">
        <div class="header-tag">
          <p class="tag-inner">无需回澳大利亚</p>
          <p class="tag-inner">澳大利亚无犯罪记录申请</p>
          <p class="tag-inner">合同保障</p>
        </div>
        <p class="noc-content-p1">澳大利亚无犯罪记录申请</p>
        <p class="noc-content-p2">移民 | 公证签证 | 永久居留权 | 婚姻 | 其他证明</p>
        <p class="noc-content-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="exma-business-Am">
      <p class="b-title">澳大利亚无犯罪记录申请</p>
      <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="exma-business-Am-box center-width">
        <div class="exma-business-Am-inner">
          <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
            <el-form-item label="" prop="name">
              <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的联系方式"></el-input>
            </el-form-item>
            <el-form-item label="" prop="type">
              <el-select class="exma-select-length" v-model="form.type" clearable placeholder="请选择您要办理的业务类型">
                <el-option label="有指纹无犯罪" value="有指纹无犯罪"></el-option>
                <el-option label="无指纹无犯罪" value="无指纹无犯罪"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
            </el-form-item>
          </el-form>
        </div>
        <div class="exma-business-tips">
          <p class="exma-business-tips-p2">提交表单，免费咨询；或致电 <span class="middle-style">400-860-5600</span></p>
        </div>
      </div>
    </div>
    <div class="noc-type" style="height: 800px">
        <p class="b-title">申请澳大利亚无犯罪证明所需材料</p>
        <div class="noc-type-box flex-between">
          <div class="noc-type-inner">
            <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/icon_1_1.png" alt="">
            <p class="noc-type-p1">基于指纹无犯罪</p>
            <p class="noc-type-p2">电子版可申请加急</p>
            <p class="noc-type-p3">具体收费请咨询客户经理</p>
            <p class="noc-type-p4">可办理移民、绿卡</p>
            <p class="noc-type-p4">需15 ~ 25个工作日</p>
            <p class="noc-type-p4">需护照扫描件、澳公民证/驾照扫描件、过去十年的地址、各类联系方式及行用卡</p>
            <p class="noc-type-btn" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="noc-type-inner noc-type-inner1">
            <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/icon_1_2.png" alt="">
            <p class="noc-type-p1">基于名字无犯罪</p>
            <p class="noc-type-p2">电子版可申请加急</p>
            <p class="noc-type-p3">具体收费请咨询客户经理</p>
            <p class="noc-type-p4">可办理工作、入职、留学、续签证</p>
            <p class="noc-type-p4">需15个工作日</p>
            <p class="noc-type-p4">需护照扫描件、澳公民证/驾照扫描件、过去十年的地址、各类联系方式及行用卡</p>
            <p class="noc-type-btn noc-type-btn1" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <p class="noc-type-amer-tips">注：全国各城市公证处均可采集公证指纹</p>
      </div>
    <div class="noc-dept amer-dept">
      <p class="b-title">哪个部门可出具澳大利亚无犯罪记录证明</p>
      <div class="noc-dept-box">
        <div class="noc-dept-inner">
          <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_1.jpg" alt="">
          <div class="noc-dept-right">
            <p class="noc-dept-tag">基于指纹无犯罪</p>
            <p class="noc-dept-p1">澳大利亚联邦警局AFP</p>
            <p class="noc-dept-p2">作用范围：澳大利亚全境</p>
            <p class="noc-dept-p3">指纹采集（Fingerprint acquisition）鉴定方式名字（Name Based）检索的方式</p>
            <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="noc-validity">
      <p class="b-title">哪些人可以申请澳大利亚无犯罪证明</p>
      <div class="noc-validity-box center-width flex-between">
        <img class="noc-validity-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_2.png" alt="">
        <div class="noc-validity-right">
          <p class="noc-validity-p1">在澳连续居住12个月以上的外国人</p>
          <p class="noc-validity-p2">澳大利亚没有户口管理制度，整个国家的警署记录都是全国联网的，澳大利亚公民或者在澳大利亚居住的时间连续超过12个月以上的外国人均可以申请。</p>
          <p class="noc-validity-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="noc-advantage">
      <p class="re-title">服务优势</p>
      <div class="noc-advantage-box center-width flex-between">
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_1.png" alt="">
          <p class="noc-advantage-p">专业人员<br />全程跟进</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_2.png" alt="">
          <p class="noc-advantage-p">丰富经验<br />积累</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_3.png" alt="">
          <p class="noc-advantage-p">保护客户<br />隐私</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_4.png" alt="">
          <p class="noc-advantage-p">量身定制<br />办理方案</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_5.png" alt="">
          <p class="noc-advantage-p">合同保障<br />客户权益</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_6.png" alt="">
          <p class="noc-advantage-p">实时反馈<br />办理进度</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">澳大利亚无犯罪热门问答</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="申请签证不知道怎么做澳洲无犯罪记录证明？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">申请所需材料：<br />
                1）在有效期内的护照信息页扫描件<br />
                2）澳洲驾照扫描件<br />
                3）出生证、结婚证扫描件<br />
                4）银行、水电账单<br />
                澳大利亚各州警局出具的澳洲无犯罪记录证明<br />
                申请周期：由于各州情况不同，以新南威尔士州为例。基于名字的无犯罪证明10个工作日，基于指纹的无犯罪证明15个工作日。<br />
                申请所需材料：由于各州情况不同，以新南威尔士州为例。<br />
                1）在有效期内的护照原件<br />
                2）澳洲驾照原件<br />
                3）出生证、结婚证原件<br />
                4）学生卡原件等</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="澳洲无犯罪记录证明怎么办?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">在澳洲留学的人士，在国内就业入职，或者申请移民，都需要提供在澳洲留学期间的无犯罪记录证明。澳洲无犯<br />
                罪证明提供申请人有效的护照首页扫描件，填写一份申请表，信息准确完整，澳洲驾照扫描件。申请时间需要<br />
                15个工作日</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="移民澳洲,需要开的是无犯罪记录还是无刑事犯罪记录?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">无刑事犯罪记录，如果是刑事犯罪记录是有刑事判决书的。一般的劳教，纠纷都不算刑事犯罪记录。要去户口所<br />
                在地开具证明，到有信誉的公证处公证。<br />
                另外，如果在国外居住超过12个月，还要办国外的无犯罪证明，这个要到该国的领事馆办了</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="澳洲使馆认证的认证材料是什么?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">澳洲使馆认证资料包含个人文件和公司文件 比较常用的文件名单如下：澳洲结婚证，出生证，无犯罪证明，离<br />
                婚证，学历证明，成绩单，委托书，声明书，居留证明，公司注册证书，股东董事名册，章程等文书。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <!-- <div class="noc-case-britain">
      <p class="b-title">澳大利亚无犯罪记录证明案例</p>
      <p class="b-title1">看看办理过的人怎么说</p>
      <div class="noc-case-britain-box center-width flex-between">
        <div class="noc-case-britain-inner">
          <img class="noc-case-britain-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_3_1.png" alt="">
          <div class="noc-case-britain-right">
            <p class="noc-case-britain-tag">无犯罪证明（无指纹）</p>
            <p class="noc-case-britain-p">由于公证需要，找到了快企务办理无犯罪证明；很快就办理了下来，工作开展顺利，绿卡已经拿到，非常感谢！</p>
            <div class="noc-case-britain-bottom">
              <img class="noc-case-britain-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_3.png" alt="">
              <p class="noc-case-britain-p2">曾先生</p>
            </div>
          </div>
        </div>
        <div class="noc-case-britain-inner">
          <img class="noc-case-britain-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_3_1.png" alt="">
          <div class="noc-case-britain-right">
            <p class="noc-case-britain-tag">无犯罪证明（无指纹）</p>
            <p class="noc-case-britain-p">由于孩子留学需要，我找到了快企务办理无犯罪证明，接触过程中发现顾问很专业，能够快速明白我的需求，帮我准备资料申报，非常感谢！</p>
            <div class="noc-case-britain-bottom noc-case-britain-bottom1">
              <img class="noc-case-britain-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Britain/pic_3_4.png" alt="">
              <p class="noc-case-britain-p2">李女士</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="noc-new-case">
      <p class="re-title">澳大利亚无犯罪记录证明案例</p>
      <div class="noc-new-case-box">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_1_else.png" alt="">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_2_else.png" alt="">
        <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/pic_3_else.png" alt="">
      </div>
    </div>
    <div class="noc-handle">
      <div class="noc-handle-box center-width flex-between">
        <div class="noc-handle-left">
          <p class="noc-handle-p1">办理中国驻澳大利亚大使馆领事认证</p>
          <p class="noc-handle-p2">澳大利亚无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻澳大利亚大使馆的领事认证</p>
          <p class="noc-handle-p3">一、将申请下来的澳大利亚无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
          <p class="noc-handle-p3">二、提交至澳大利亚外交部或者省政府指定部门进行办理认证</p>
          <p class="noc-handle-p3">三、交至中国驻澳大利亚大使馆办理认证</p>
          <p class="noc-handle-p4">正常办理：15-18个工作日</p>
          <p class="noc-handle-p5">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
        </div>
        <img class="noc-handle-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_4_1.png" alt="">
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {},
      activeName: '1',
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        type: [{ required: true, message: '请选择您要办理的业务类型', trigger: 'change' }]
      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 2,
            name: that.form.name,
            mobile: that.form.mobile,
            crimeType: that.form.type,
            country: '澳大利亚'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .noc-content-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
