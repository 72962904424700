<template>
    <div class="noc-content">
      <div class="noc-content-header">
        <div class="noc-content-inner center-width">
          <div class="header-tag">
            <p class="tag-inner">无需回加拿大</p>
            <p class="tag-inner">平台式申请</p>
            <p class="tag-inner">合同保障</p>
          </div>
          <p class="noc-content-p1">加拿大无犯罪记录申请</p>
          <p class="noc-content-p2">留学 | 移民 | 工作 | 旅游 | 签证</p>
          <p class="noc-content-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="exma-business-Am">
        <p class="b-title">加拿大无犯罪记录办理</p>
        <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
        <div class="exma-business-Am-box center-width">
          <div class="exma-business-Am-inner">
            <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
              <el-form-item label="" prop="name">
                <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
              </el-form-item>
              <el-form-item label="" prop="mobile">
                <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的联系方式"></el-input>
              </el-form-item>
              <el-form-item label="" prop="type">
                <el-select class="exma-select-length" v-model="form.type" placeholder="请选择您要办理的业务类型">
                  <el-option label="有指纹无犯罪" value="有指纹无犯罪"></el-option>
                  <el-option label="无指纹无犯罪" value="无指纹无犯罪"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
              </el-form-item>
            </el-form>
          </div>
          <div class="exma-business-tips">
            <p class="exma-business-tips-p2">提交表单，免费咨询；或致电 <span class="middle-style">400-860-5600</span></p>
          </div>
        </div>
      </div>
      <div class="noc-type">
        <p class="b-title">加拿大无犯罪办理类型</p>
        <p class="b-title1">有指纹无犯罪/无指纹无犯罪</p>
        <div class="noc-type-box flex-between">
          <div class="noc-type-inner">
            <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/icon_1_1.png" alt="">
            <p class="noc-type-p1">有指纹无犯罪</p>
            <p class="noc-type-p2">电子版可申请加急</p>
            <p class="noc-type-p3">具体收费请咨询客户经理</p>
            <p class="noc-type-p4">可办理移民、绿卡</p>
            <p class="noc-type-p4">需20 ~ 25个工作日</p>
            <p class="noc-type-p4">需奔赴采集指纹，无需回加拿大</p>
            <p class="noc-type-p4">需两个ID扫描件、护照扫描件、指纹信息</p>
            <p class="noc-type-btn" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="noc-type-inner noc-type-inner1">
            <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/icon_1_2.png" alt="">
            <p class="noc-type-p1">无指纹无犯罪</p>
            <p class="noc-type-p2">电子版可申请加急</p>
            <p class="noc-type-p3">具体收费请咨询客户经理</p>
            <p class="noc-type-p4">可办理工作、入职、留学、续签证</p>
            <p class="noc-type-p4">需3 ~ 5个工作日</p>
            <p class="noc-type-p4">无需奔赴，就是这么任性</p>
            <p class="noc-type-p4">需两个ID扫描件、护照扫描件</p>
            <p class="noc-type-btn noc-type-btn1" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
      <div class="noc-dept">
        <p class="b-title">哪个部门可出具加拿大无犯罪记录证明</p>
        <p class="b-title1">加拿大驻中国大使馆或领事馆并不能出具加拿大无犯罪记录证明，申请人需通过加拿大皇家警察和地方警察署来申请加拿大无犯罪记录证明</p>
        <div class="noc-dept-box">
          <el-carousel :interval="5000" arrow="hover" height="430px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="noc-dept-inner">
              <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_2_1.jpg" alt="">
              <div class="noc-dept-right">
                <p class="noc-dept-tag">有指纹无犯罪</p>
                <p class="noc-dept-p1">加拿大皇家警察RCMP</p>
                <p class="noc-dept-p2">Royal Canadian Mounted Police</p>
                <p class="noc-dept-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="noc-dept-inner">
              <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_2_2.jpg" alt="">
              <div class="noc-dept-right">
                <p class="noc-dept-tag">有指纹无犯罪</p>
                <p class="noc-dept-p1">加拿大皇家警察RCMP</p>
                <p class="noc-dept-p2">Royal Canadian Mounted Police</p>
                <p class="noc-dept-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        </div>
      </div>
      <div class="noc-validity">
        <p class="b-title">加拿大无犯罪记录证明有效期</p>
        <p class="b-title1">一般为6个月内的无犯罪证明，超出需重新办理</p>
        <div class="noc-validity-box center-width flex-between">
          <img class="noc-validity-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_3_1.png" alt="">
          <div class="noc-validity-right">
            <p class="noc-validity-p1">6个月以内无犯罪证明</p>
            <p class="noc-validity-p2">加拿大无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出六个月则需要重新办理</p>
            <p class="noc-validity-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
      <div class="noc-advantage">
        <p class="re-title">服务优势</p>
        <div class="noc-advantage-box center-width flex-between">
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_1.png" alt="">
            <p class="noc-advantage-p">专业人员<br />全程跟进</p>
          </div>
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_2.png" alt="">
            <p class="noc-advantage-p">丰富经验<br />积累</p>
          </div>
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_3.png" alt="">
            <p class="noc-advantage-p">保护客户<br />隐私</p>
          </div>
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_4.png" alt="">
            <p class="noc-advantage-p">量身定制<br />办理方案</p>
          </div>
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_5.png" alt="">
            <p class="noc-advantage-p">合同保障<br />客户权益</p>
          </div>
          <div class="noc-advantage-inner">
            <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_6.png" alt="">
            <p class="noc-advantage-p">实时反馈<br />办理进度</p>
          </div>
        </div>
      </div>
      <div class="problem">
        <p class="re-title">加拿大无犯罪热门问答</p>
        <div class="pro-box">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="加拿大无犯罪记录证明有哪些？" name="1">
              <div class="inner-pro">
                <div class="pro-p2">加拿大无犯罪记录证明的类型主要包括两大类：一类是Criminal record check，另一类是Police information<br />
                   check</div>
                <p class="pro-p3" @click="Dialog = true">立即咨询</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="加拿大公民申请在北京申请中国永久居留（也叫中国绿卡）必须要提供皇家骑警出具的无犯罪记录证明吗？" name="2">
              <div class="inner-pro">
                <div class="pro-p2">加拿大公民申请在北京申请中国永久居留（也叫中国绿卡）必须要提供皇家骑警出具的无犯罪记录证明吗？</div>
                <p class="pro-p3" @click="Dialog = true">立即咨询</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="办理哪种类型的无犯罪记录证明？" name="3">
              <div class="inner-pro">
                <div class="pro-p2">在中国境内用于工作或办理永居时，一般国内政府机构没有特别限定需要哪种类型的无犯罪，只要求加拿大<br />
                  无犯罪记录证明需要经过中国驻加拿大大使馆或领事馆的认证即可。<br />
                  而加拿大无犯罪记录证明用于移民用途时，移民国家一般都会要求出具加拿大皇家骑警出具的无犯罪记录<br />
                  证明，这种情况下需要申请人采集指纹才可以。</div>
                <p class="pro-p3" @click="Dialog = true">立即咨询</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="基于名字检索的加拿大无犯罪记录证明 （加拿大当地警局出具）" name="4">
              <div class="inner-pro">
                <div class="pro-p2">一种是以名字（Name Based）检索的方式，用申请人姓名和出生日期在加拿大皇家骑警信息服务中心CPIC（<br />
                  Canadian Police Information Centre）的数据库中检索是否存在犯罪记录。<br />
                  基于名字检索的无犯罪记录证明是由加拿大当地的警局出具的。CPIC是一个全国性的警方数据库。
                  </div>
                <p class="pro-p3" @click="Dialog = true">立即咨询</p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="基于指纹见搜的加拿大无犯罪记录证明（加拿大皇家骑警出具）" name="5">
              <div class="inner-pro">
                <div class="pro-p2">另外一种是指纹采集（Fingerprinted Based）鉴定方式，用采集到的指纹信息以及申请人的姓名及出生日期<br />
                  在CPIC数据库中匹配是否存在犯罪记录。<br />
                  基于指纹检索的无犯罪记录证明由加拿大皇家骑警出具的。
                  </div>
                <p class="pro-p3" @click="Dialog = true">立即咨询</p>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="noc-new-case">
        <p class="re-title">加拿大无犯罪记录证明案例</p>
        <div class="noc-new-case-box">
          <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_1_else.png" alt="">
          <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_2_else.png" alt="">
          <img class="noc-new-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_3_else.png" alt="">
        </div>
      </div>
      <div class="noc-handle">
        <div class="noc-handle-box center-width flex-between">
          <div class="noc-handle-left">
            <p class="noc-handle-p1">办理中国驻加拿大大使馆领事认证</p>
            <p class="noc-handle-p2">加拿大无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻加拿大大使馆的领事认证</p>
            <p class="noc-handle-p3">一、将申请下来的加拿大无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
            <p class="noc-handle-p3">二、提交至加拿大外交部或者省政府指定部门进行办理认证</p>
            <p class="noc-handle-p3">三、交至中国驻加拿大大使馆办理认证</p>
            <p class="noc-handle-p4">正常办理：15-18个工作日</p>
            <p class="noc-handle-p5">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
          </div>
          <img class="noc-handle-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_4_1.png" alt="">
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        form: {
          
        },
        activeName: '1',
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
          type: [{ required: true, message: '请选择您要办理的业务类型', trigger: 'change' }]
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 2,
            name: that.form.name,
            mobile: that.form.mobile,
            crimeType: that.form.type,
            country: '加拿大'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .noc-content-header{
      width: 100%;
      height: 600px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  