<template>
  <div class="anquila macau">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式中国澳门公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="macau-advantage center-width">
      <div class="macau-advantage-left">
        <p class="macau-advantage-title">注册中国澳门公司优势</p>
        <p class="blue-bg-btn" @click="Dialog = true">立即咨询</p>
      </div>
      <div class="macau-advantage-right">
        <div class="macau-advantage-inner">
          <div class="macau-advantage-box1">
            <img class="macau-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_1_1.png" alt="">
            <p class="macau-advantage-p">低税率</p>
            <p class="macau-advantage-ask" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="macau-advantage-box2">
            <p class="macau-advantage-p1">低税率</p>
            <p class="macau-advantage-p2">地税制度，经营成本低</p>
            <p class="macau-advantage-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="macau-advantage-inner">
          <div class="macau-advantage-box1">
            <img class="macau-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_1_2.png" alt="">
            <p class="macau-advantage-p">无需会计师</p>
            <p class="macau-advantage-ask" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="macau-advantage-box2">
            <p class="macau-advantage-p1">无需会计师</p>
            <p class="macau-advantage-p2">无需会计师申报税表，中国澳门是拓展葡语地区</p>
            <p class="macau-advantage-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="macau-advantage-inner">
          <div class="macau-advantage-box1">
            <img class="macau-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_1_3.png" alt="">
            <p class="macau-advantage-p">位置优越</p>
            <p class="macau-advantage-ask" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="macau-advantage-box2">
            <p class="macau-advantage-p1">位置优越</p>
            <p class="macau-advantage-p2">中国澳门公司方便在中国澳门银行开户的纽带，商机无限</p>
            <p class="macau-advantage-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="macau-advantage-inner">
          <div class="macau-advantage-box1">
            <img class="macau-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_1_4.png" alt="">
            <p class="macau-advantage-p">银行开户</p>
            <p class="macau-advantage-ask" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="macau-advantage-box2">
            <p class="macau-advantage-p1">银行开户</p>
            <p class="macau-advantage-p2">中国澳门公司方便在中国澳门银行开户</p>
            <p class="macau-advantage-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="macau-advantage-inner">
          <div class="macau-advantage-box1">
            <img class="macau-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_1_5.png" alt="">
            <p class="macau-advantage-p">投资待遇</p>
            <p class="macau-advantage-ask" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="macau-advantage-box2">
            <p class="macau-advantage-p1">投资待遇</p>
            <p class="macau-advantage-p2">回程投资享受“超国民待遇”</p>
            <p class="macau-advantage-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册中国澳门公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_1_3.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_1_4.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">中国澳门无限公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">中国澳门有限公司 </p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">股份有限公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 3">一般二合公司</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="m-advantage an-advantage mac-adv">
      <p class="re-title">注册中国澳门公司条件/流程/资料</p>
      <div class="m-adv-box mac-adv-box">
        <div class="m-adv-right">
          <p class="m-ad-title">公司名称</p>
          <p class="m-ad-p">中国澳门公司可用中文或葡文书写，若加英文，需中英葡三语，需对应翻译</p>
          <p class="m-ad-title">本厘印税及登记费</p>
          <p class="m-ad-p">政府按实际的注册资本额收取千分之一至千分之四的厘印税</p>
          <p class="m-ad-title">法人信息</p>
          <p class="m-ad-p">法人信息护照扫描件</p>
          <p class="m-ad-title">注册资本</p>
          <p class="m-ad-p">最少25000澳门币，没有上限（高于25000将会产生厘印税）</p>
          <p class="m-ad-title">注册地址</p>
          <p class="m-ad-p">由我司提供注册地址（若自身已有办公地址，可用自有地址）</p>
          <p class="m-ad-title">经营范围</p>
          <p class="m-ad-p">经营范围没有限制，除了少数几个行业需要特批，比如博彩，金融</p>
        </div>
        <img class="m-adv-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_2_1.png" alt="">
      </div>
    </div>
    <div class="macau-process flex-between">
      <img class="macau-process-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_2_2.png" alt="">
      <div class="macau-process-right">
        <p class="macau-process-title">注册中国澳门公司流程</p>
        <p class="macau-process-p"><span class="macau-process-span">1</span>确认公司名称及经营范围</p>
        <p class="macau-process-p"><span class="macau-process-span">2</span>填写委托书及递交有效证件签署协议书</p>
        <p class="macau-process-p"><span class="macau-process-span">3</span>交付费用</p>
        <p class="macau-process-p"><span class="macau-process-span">4</span>签署制作法定文档，提供文档、护照原件</p>
        <p class="macau-process-p"><span class="macau-process-span">5</span>政府部门办理相关手续</p>
        <p class="macau-process-p"><span class="macau-process-span">6</span>完成注册，领取资料</p>
      </div>
    </div>
    <div class="macau-data center-width flex-between">
      <div class="macau-data-left">
        <p class="macau-data-title">注册完成后所得资料</p>
        <p class="macau-data-p"><span class="macau-process-span">1</span>商业登记证</p>
        <p class="macau-data-p"><span class="macau-process-span">2</span>公司章程</p>
        <p class="macau-data-p"><span class="macau-process-span">3</span>税务登记</p>
        <p class="macau-data-p"><span class="macau-process-span">4</span>原子印章</p>
        <p class="macau-data-p"><span class="macau-process-span">5</span>行政管理机关成员（董事）清单</p>
      </div>
      <img class="macau-data-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/pic_2_3.png" alt="">
    </div>

    <div class="subsequent subsequent-b">
      <p class="re-title">注册中国澳门公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_1bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p2">中国银行中国澳门分行、中国澳门立桥银行、中国澳门发展银行、中国澳门广发银行等</p>
          <p class="sub-p4"></p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_2secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p2">办公地址，公司周年报表年检，名称变更，公司董事股东成员名册更新</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_3tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">申报职业税、申报收益、所得税等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_4.png" alt="">
          <p class="sub-p1">商标注册</p>
          <p class="sub-p3">中国澳门商标申请：从注册日起算7年，注册有效期满需继续使用的，应在注册有效期满...</p>
          <p class="sub-p4">中国澳门商标申请：从注册日起算7年，注册有效期满需继续使用的，应在注册有效期满前 12个月申请续展注册，每次续展注册有效期为7年。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_5cancellation.png" alt="">
          <p class="sub-p1">公证业务</p>
          <p class="sub-p2">中国澳门公司所需文件的公证手续办理或中国澳门公司投资内地的主体相关公证文件</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/icon_2_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p3">因破产、股东决议解散、存续期届满等原因需要注销的，向登记局和财政局办理相关...</p>
          <p class="sub-p4">因破产、股东决议解散、存续期届满等原因需要注销的，向登记局和财政局办理相关注销手续</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">中国澳门注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="全部国内人可不可以注册中国澳门公司？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">可以，持中国身份证也可办理注册。在中国澳门注册公司，并没有对股东/董事之国籍有所限制。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="中国中国澳门公司需要本地居民做股东或董事吗?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">没有这方面的要求，任何地方的居民皆可成为澳门公司的股东或董事。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="中国澳门公司对于商业名称有没有限制?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">基本上没有限制，只要满足以下几个条件：<br />
                1、没有冲突在先登记；<br />
                2、不涉及金融等行业；<br />
                3、公司名称可以仅为中文或仅为葡文，根据商法典的规定，若要加英文，则需要中英<br />
                葡三语，客户只需提供中英文即可，葡文由我们翻译。
                </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册中国澳门公司需时多长？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">新注册一间中国澳门公司，在签署完所有法定文件起7个工作日左右。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业中国澳门公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      // console.log(e)
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Macau/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
