import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import bviView from '../views/register/bvi.vue'
import kaimanView from '../views/register/kaiman.vue'
import singaporeView from '../views/register/singapore.vue'
import HongKongView from '../views/register/HongKong.vue'
import AmericaView from '../views/register/America.vue'
import BritainView from '../views/register/Britain.vue'
import TaiwanView from '../views/register/Taiwan.vue'
import MarshallView from '../views/register/Marshall.vue'
import globalView from '../views/register/global.vue'
import GermanyView from '../views/register/Germany.vue'
import bank from '../views/bank.vue'
import knowledge from '../views/knowledge.vue'
import fiscalTax from '../views/fiscalTax.vue'
import secretaryHongKong from '../views/secretary/HongKong.vue'
import notarization from '../views/notarization/index.vue'
import notarizationHague from '../views/notarization/hague.vue'
import notarizationChina from '../views/notarization/china.vue'
import notarizationEmbassy from '../views/notarization/embassy.vue'
import notarizationTrade from '../views/notarization/trade.vue'
import registerAnquila from '../views/register/Anquila.vue'
import registerMacau from '../views/register/Macau.vue'
import registerDubai from '../views/register/dubai.vue'
import registerFrench from '../views/register/French.vue'
import registerKorea from '../views/register/Korea.vue'
import registerSwiss from '../views/register/Swiss.vue'
import registerMalta from '../views/register/Malta.vue'
import registerNetherlands from '../views/register/Netherlands.vue'
import registerCyprus from '../views/register/Cyprus.vue'
import registerJapan from '../views/register/Japan.vue'
import registerLuxembourg from '../views/register/Luxembourg.vue'
import registerSamoa from '../views/register/Samoa.vue'
import registerVietnam from '../views/register/Vietnam.vue'
import registerCanada from '../views/register/Canada.vue'
import registerItaly from '../views/register/Italy.vue'
import registerIndonesia from '../views/register/Indonesia.vue' 
import registerMauritius from '../views/register/Mauritius.vue'
import examinationKaiman from '../views/examination/kaiman.vue' 
import examinationBvi from '../views/examination/bvi.vue'
import examinationSingapore from '../views/examination/Singapore.vue'
import examinationAmerica from '../views/examination/America.vue'
import noCriminalAmerica from '../views/noCriminal/America.vue'
import noCriminalAustralia from '../views/noCriminal/Australia.vue'
import noCriminalBritain from '../views/noCriminal/Britain.vue'
import noCriminalCanada from '../views/noCriminal/Canada.vue'
import noCriminalSingapore from '../views/noCriminal/Singapore.vue'
import fiscalTaxAmerica from '../views/fiscalTaxAmerica.vue'
import join from '../views/join.vue'
import about from '../views/about.vue'
import certificationSingapore from '../views/certification/Singapore.vue'
import certificationAmerica from '../views/certification/America.vue'
import certificationAustralia from '../views/certification/Australia.vue'
import certificationBritain from '../views/certification/Britain.vue'
import certificationBvi from '../views/certification/bvi.vue'
import certificationCanada from '../views/certification/Canada.vue'
import certificationcayman from '../views/certification/cayman.vue'
import certificationFrench from '../views/certification/French.vue'
import certificationGermany from '../views/certification/Germany.vue'
import certificationKorea from '../views/certification/Korea.vue'
import certificationMalaysia from '../views/certification/Malaysia.vue'
import certificationNetherlands from '../views/certification/Netherlands.vue'
import certificationRussia from '../views/certification/Russia.vue'
import certificationSamoa from '../views/certification/Samoa.vue'
import certificationVietnam from '../views/certification/Vietnam.vue'
import certificationSeychelles from '../views/certification/Seychelles.vue'
import certificationVideo from '../views/certification/video.vue'


import lawAmerica from '../views/law-America.vue'
import lawHK from '../views/law-HK.vue'
import ODI from '../views/ODI.vue'
import VIE from '../views/vie.vue'
import visa from '../views/visa.vue'
import trademarkAmerica from '../views/trademark/America.vue'
import trademarkEU from '../views/trademark/EU.vue'
import trademarkJapan from '../views/trademark/Japan.vue'
import trademarkFrench from '../views/trademark/French.vue'
import trademarkdomestic from '../views/trademark/domestic.vue'
import newsCompany from '../views/news/company.vue'
import newsDetail from '../views/news/newsDetail.vue'
import HKplan from '../views/HKplan.vue'
import point from '../views/points.vue'

import { bvi } from '@/assets/lang/zh'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bvi',
    name: 'bvi',
    component: bviView
  },
  {
    path: '/kaiman',
    name: 'kaiman',
    component: kaimanView
  },
  {
    path: '/singapore',
    name: 'singapore',
    component: singaporeView
  },
  {
    path: '/HongKong',
    name: 'HongKong',
    component: HongKongView
  },
  {
    path: '/America',
    name: 'America',
    component: AmericaView
  },
  {
    path: '/Britain',
    name: 'Britain',
    component: BritainView
  },
  {
    path: '/Taiwan',
    name: 'Taiwan',
    component: TaiwanView
  },
  {
    path: '/Marshall',
    name: 'Marshall',
    component: MarshallView
  },
  {
    path: '/global',
    name: 'global',
    component: globalView
  },
  {
    path: '/Germany',
    name: 'Germany',
    component: GermanyView
  },
  {
    path: '/bank',
    name: 'bank',
    component: bank
  },
  {
    path: '/knowledge',
    name: 'knowledge',
    component: knowledge
  },
  {
    path: '/ODI',
    name: 'ODI',
    component: ODI
  },
  {
    path: '/VIE',
    name: 'VIE',
    component: VIE
  },
  {
    path: '/visa',
    name: 'visa',
    component: visa
  },
  {
    path: '/point',
    name: 'point',
    component: point
  },
  
  {
    path: '/fiscalTax',
    name: 'fiscalTax',
    component: fiscalTax
  },
  {
    path: '/lawAmerica',
    name: 'lawAmerica',
    component: lawAmerica
  },
  {
    path: '/lawHK',
    name: 'lawHK',
    component: lawHK
  },
  {
    path: '/news/company',
    name: 'newsCompany',
    component: newsCompany
  },
  {
    path: '/news/newsDetail',
    name: 'newsDetail',
    component: newsDetail
  },
  {
    path: '/fiscalTaxAmerica',
    name: 'fiscalTaxAmerica',
    component: fiscalTaxAmerica
  },
  {
    path: '/trademark/America',
    name: 'trademarkAmerica',
    component: trademarkAmerica
  },
  {
    path: '/trademark/EU',
    name: 'trademarkEU',
    component: trademarkEU
  },
  {
    path: '/trademark/Japan',
    name: 'trademarkJapan',
    component: trademarkJapan
  },
  {
    path: '/trademark/French',
    name: 'trademarkFrench',
    component: trademarkFrench
  },
  {
    path: '/trademark/domestic',
    name: 'trademarkdomestic',
    component: trademarkdomestic
  },
  {
    path: '/secretary/HongKong',
    name: 'secretaryHongKong',
    component: secretaryHongKong
  },
  {
    path: '/notarization/index',
    name: 'notarization',
    component: notarization
  },
  {
    path: '/notarization/trade',
    name: 'notarizationTrade',
    component: notarizationTrade
  },
  {
    path: '/notarization/hague',
    name: 'notarizationHague',
    component: notarizationHague
  },
  {
    path: '/notarization/china',
    name: 'notarizationChina',
    component: notarizationChina
  },
  {
    path: '/notarization/embassy',
    name: 'notarizationEmbassy',
    component: notarizationEmbassy
  },
  {
    path: '/register/Anquila',
    name: 'registerAnquila',
    component: registerAnquila
  },
  {
    path: '/register/Macau',
    name: 'registerMacau',
    component: registerMacau
  },
  {
    path: '/register/dubai',
    name: 'registerDubai',
    component: registerDubai
  },
  {
    path: '/register/French',
    name: 'registerFrench',
    component: registerFrench
  },
  {
    path: '/register/Korea',
    name: 'registerKorea',
    component: registerKorea
  },
  {
    path: '/register/Swiss',
    name: 'registerSwiss',
    component: registerSwiss
  },
  {
    path: '/register/Malta',
    name: 'registerMalta',
    component: registerMalta
  },
  {
    path: '/register/Netherlands',
    name: 'registerNetherlands',
    component: registerNetherlands
  },
  {
    path: '/register/Cyprus',
    name: 'registerCyprus',
    component: registerCyprus
  },
  {
    path: '/register/Japan',
    name: 'registerJapan',
    component: registerJapan
  },
  {
    path: '/register/Luxembourg',
    name: 'registerLuxembourg',
    component: registerLuxembourg
  },
  {
    path: '/register/Samoa',
    name: 'registerSamoa',
    component: registerSamoa
  },
  {
    path: '/register/Vietnam',
    name: 'registerVietnam',
    component: registerVietnam
  },
  {
    path: '/register/Canada',
    name: 'registerCanada',
    component: registerCanada
  },
  {
    path: '/register/Italy',
    name: 'registerItaly',
    component: registerItaly
  },
  {
    path: '/register/Indonesia',
    name: 'registerIndonesia',
    component: registerIndonesia
  },
  {
    path: '/register/Mauritius',
    name: 'registerMauritius',
    component: registerMauritius
  },
  {
    path: '/examination/kaiman',
    name: 'examinationKaiman',
    component: examinationKaiman
  },
  {
    path: '/examination/Singapore',
    name: 'examinationSingapore',
    component: examinationSingapore
  },
  {
    path: '/examination/America',
    name: 'examinationAmerica',
    component: examinationAmerica
  },
  {
    path: '/examination/bvi',
    name: 'examinationBvi',
    component: examinationBvi
  },
  {
    path: '/noCriminal/America',
    name: 'noCriminalAmerica',
    component: noCriminalAmerica
  },
  {
    path: '/noCriminal/Australia',
    name: 'noCriminalAustralia',
    component: noCriminalAustralia
  },
  {
    path: '/noCriminal/Britain',
    name: 'noCriminalBritain',
    component: noCriminalBritain
  },
  {
    path: '/noCriminal/Canada',
    name: 'noCriminalCanada',
    component: noCriminalCanada
  },
  {
    path: '/noCriminal/Singapore',
    name: 'noCriminalSingapore',
    component: noCriminalSingapore
  },
  {
    path: '/join',
    name: 'join',
    component: join
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/HKplan',
    name: 'HKplan',
    component: HKplan
  },
  {
    path: '/certification/Singapore',
    name: 'certificationSingapore',
    component: certificationSingapore
  },
  {
    path: '/certification/America',
    name: 'certificationAmerica',
    component: certificationAmerica
  },
  {
    path: '/certification/Australia',
    name: 'certificationAustralia',
    component: certificationAustralia
  },
  {
    path: '/certification/Britain',
    name: 'certificationBritain',
    component: certificationBritain
  },
  {
    path: '/certification/Bvi',
    name: 'certificationBvi',
    component: certificationBvi
  },
  {
    path: '/certification/Canada',
    name: 'certificationCanada',
    component: certificationCanada
  },
  {
    path: '/certification/cayman',
    name: 'certificationcayman',
    component: certificationcayman
  },
  {
    path: '/certification/French',
    name: 'certificationFrench',
    component: certificationFrench
  },
  {
    path: '/certification/Germany',
    name: 'certificationGermany',
    component: certificationGermany
  },
  {
    path: '/certification/Korea',
    name: 'certificationKorea',
    component: certificationKorea
  },
  {
    path: '/certification/Malaysia',
    name: 'certificationMalaysia',
    component: certificationMalaysia
  },
  {
    path: '/certification/Netherlands',
    name: 'certificationNetherlands',
    component: certificationNetherlands
  },
  {
    path: '/certification/Russia',
    name: 'certificationRussia',
    component: certificationRussia
  },
  {
    path: '/certification/Samoa',
    name: 'certificationSamoa',
    component: certificationSamoa
  },
  {
    path: '/certification/Seychelles',
    name: 'certificationSeychelles',
    component: certificationSeychelles
  },
  {
    path: '/certification/Vietnam',
    name: 'certificationVietnam',
    component: certificationVietnam
  },
  {
    path: '/certification/video',
    name: 'certificationVideo',
    component: certificationVideo
  }
  
  
  
]

const router = new VueRouter({
  // scrollBehavior(to, from, savedPosition) {
  //   return { top: 0 }
  // },
  routes
})

export default router
