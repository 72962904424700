<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式毛里求斯公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="indonesia-adv">
      <p class="re-title">注册毛里求斯公司的优势</p>
      <div class="indonesia-adv-box center-width flex-between">
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_1.png" alt="">
          <p class="indonesia-adv-p">可免交公司税、所得税、利润税、资产税等税项</p>
        </div>
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_2.png" alt="">
          <p class="indonesia-adv-p">可用公司名称买卖物业</p>
        </div>
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_3.png" alt="">
          <p class="indonesia-adv-p">无外汇管制，对资本投资非常开放，可以双向自由流进流出，允许外国人百分百控股</p>
        </div>
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_4.png" alt="">
          <p class="indonesia-adv-p">不必每年提交公司账目</p>
        </div>
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_5.png" alt="">
          <p class="indonesia-adv-p">政府为企业提供隐私保护，不会公开公司受益人身份</p>
        </div>
        <div class="indonesia-adv-inner">
          <img class="indonesia-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_1_6.png" alt="">
          <p class="indonesia-adv-p">每年不需办理税务申报及财务报表申请</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册毛里求斯公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/pic_1_2.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">海外公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">国际公司</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="indonesia-file">
      <p class="re-title">注册毛里求斯公司的条件/流程/资料</p>
      <div class="indonesia-file-box center-width flex-between">
        <div class="indonesia-file-left">
          <div class="indonesia-file-tab" :class="{'active': tab == 1}" @click="tab = 1">注册毛里求斯公司条件</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 2}" @click="tab = 2">注册毛里求斯公司流程</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</div>
        </div>
        <div class="indonesia-file-right">
          <div class="indonesia-file-right-box1" style="margin-left: 0" v-if="tab == 1">
            <!-- <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              2或3个拟定公司名称
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东护照复印件（可先发送扫描的复印件，后期需要提供公证的复印件原件）
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              银行推荐信
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东简历（需股东签字）
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              水电费账单复印件（近3个月内的），可先发送扫描的复印件，然后提供公证的账单复印件或账单原件
            </p> -->
            <div class="indonesia-file-tab3" style="margin-top: 50px">
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_1.png" alt="">
                <p class="indonesia-file-tab3-p" style="width: 113px">2或3个拟定公司名称</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_2.png" alt="">
                <p class="indonesia-file-tab3-p" style="width: 113px">股东护照复印件（可先发送扫描的复印件，后期需要提供公证的复印件原件）</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_3.png" alt="">
                <p class="indonesia-file-tab3-p" style="width: 113px">银行推荐信</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_4.png" alt="">
                <p class="indonesia-file-tab3-p" style="width: 113px">股东简历（需股东签字）</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_5.png" alt="">
                <p class="indonesia-file-tab3-p" style="width: 113px">水电费账单复印件（近3个月内的），可先发送扫描的复印件，然后提供公证的账单复印件或账单原件</p>
              </div>
            </div>
          </div>
          <div class="indonesia-file-right-box1" v-if="tab == 2">
            <img style="width: 745px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/pic.png" alt="">
          </div>
          <div class="indonesia-file-right-box3" v-if="tab == 3">
            <div class="indonesia-file-tab3" style="margin-top: 70px">
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_1.png" alt="">
                <p class="indonesia-file-tab3-p">注册证书<br />（简称IC）</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_2.png" alt="">
                <p class="indonesia-file-tab3-p">公司章程3本</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_3.png" alt="">
                <p class="indonesia-file-tab3-p">股票本</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_4.png" alt="">
                <p class="indonesia-file-tab3-p">钢印、签字原子印<br />各一枚</p>
              </div>
              <div class="indonesia-file-tab3-inner maur-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/else/icon_2_5.png" alt="">
                <p class="indonesia-file-tab3-p">申请注册毛里求斯<br />公司文件复印件一套</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册毛里求斯公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">毛里求斯公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="毛里求斯查名要多久？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">不含中文查名最快要3个工作日左右，一般1周。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="毛里求斯公司名称是不是能直接用中文，英文可以不加是吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">不可以单独用中文名称，毛里求斯公司12月份要年审的。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="毛里求斯公司类型有哪些？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">毛里求斯国外公司可分二类：国际公司International Company（IC）及海外公司Ordinary Status<br />
                Company（OS）。二者皆是境外所得免税，若是要享受毛里求斯与其它国家所签定的租税协议的优惠，则<br />
                需选择毛里求斯海外公司（OS），且每年需将公司经会计师签证之税务报表呈交给当地政府备查。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">十余年注册毛里求斯公司经验</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
