<template>
  <div class="mark">
    <div class="mark-header">
      <div class="mark-inner center-width">
        <p class="mark-p1">欧盟商标注册</p>
        <p class="mark-p2"> 迄今为止，欧盟共有27个成员国，分别是：英国、法国、德国、意大利、荷兰、比利时、卢森堡、丹麦、爱尔兰、希腊、葡萄牙、西班牙、奥地利、瑞典、芬兰、马耳他、塞浦路斯、波兰、匈牙利、捷克、斯洛伐克、斯洛文尼亚、爱沙尼亚、拉脱维亚、立陶宛、罗马尼亚、保加利亚</p>
      </div>
    </div>
    <div class="mark-trait">
      <p class="b-title">欧盟商标特点</p>
      <div class="mark-trait-box center-width">
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">费用低</p>
          <p class="mark-trait-p2">只须申请注册一次，即可在整个欧盟的十五个成员国使用该商标。较之于在各个成员国分别提出申请，费用大幅度减少</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">保护程序集中化</p>
          <p class="mark-trait-p2">一件商标注册可获得欧盟15个成员国的保护，有关商标案件的裁决将在欧盟所有的国家得到执行</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">已注册之商标可仅在<br />一个欧盟国家使用</p>
          <p class="mark-trait-p2">商标在欧盟任何一个国家的使用就足以对抗以未使用商标为由提出的撤销申请</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">享有巴黎公约优先权</p>
          <p class="mark-trait-p2">同一商标用于一种或多种指定商品或服务名称，在巴黎公约成员国申请后6个月申请共同体商标时，可享有优先权</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">拥有优先权</p>
          <p class="mark-trait-p2">已经在一个欧盟成员国公布的注册商标，在申请欧盟商标时，可请求优先权</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">注册种类多</p>
          <p class="mark-trait-p2">不但词语、标识等传统商标可获得注册，声音、气味、产品外观及构造等新型商标，也可申请注册</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">商标可涵盖3类</p>
          <p class="mark-trait-p2">一件商标申请最多可涵盖3类商品或服务，超过3类的，每类费用须另加</p>
        </div>
      </div>
    </div>
    <div class="mark-file">
      <p class="b-title">办理欧盟商标申请所需材料</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-left">
          <p class="mark-file-p">以法人申请，附《营业执照》或有效登记证明复印件1份；以自然人申请附个人身份证明文件1份；</p>
          <p class="mark-file-p">商标图样5份，要求清晰、纸张质量好。如是彩色商标，需递交1份黑白稿，5份彩稿；规格不得小于 5cm×5cm，最大不超过 10cm×20cm；</p>
          <p class="mark-file-p">填写申请表1份，申请表必须由申请人签署或由代理人签署；</p>
          <p class="mark-file-p">列出寻求注册的商品或者服务，指出商标类别，可从本网站，商标分类表，中查询；</p>
          <p class="mark-file-p">商标注册委托书，申请人必须在该委托书上签字盖章；</p>
        </div>
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="mark-file mark-file1">
      <p class="b-title">欧盟可注册的商标</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
        <div class="mark-file-left">
          <p class="mark-file-p">欧盟商标可包括任何可用图表代表的标记，特别是文字，包括人名、图案、字母、数字、商品形状</p>
          <p class="mark-file-p">其包装外观，只要这些标记能够把一种商品或服务用途同其它种类的用途区分开来。 因此，以下标记可以用来作为商标进行注册：</p>
          <p class="mark-file-p1">文字标记包括字母、数字、或字母、数字和文字的组合；</p>
          <p class="mark-file-p1">包含或不包含文字的图形标记</p>
          <p class="mark-file-p1">彩色图形标记</p>
          <p class="mark-file-p1">颜色或多种颜色的组合</p>
          <p class="mark-file-p1">三维立体标记</p>
          <p class="mark-file-p1">声音标记等</p>
        </div>
      </div>
    </div>
    <div class="mark-program">
      <p class="b-title">欧盟商标的注册程序</p>
      <div class="mark-program-box center-width flex-between">
        <div class="mark-program-inner">
          <div class="mark-program-title"></div>
          <p class="mark-program-p">申请人向欧盟商标局或欧盟成员国商标局提出申请</p>
        </div>
        <div class="mark-program-inner">
          <div class="mark-program-title title2"></div>
          <p class="mark-program-p">欧盟商标局将申请通知各成员国的商标主管机关，以进行各国的商标审查</p>
        </div>
        <div class="mark-program-inner">
          <div class="mark-program-title title3"></div>
          <p class="mark-program-p">审查通过后，进行三个月的公告。在此期间第三者可提出各种异议</p>
        </div>
        <div class="mark-program-inner">
          <div class="mark-program-title title4"></div>
          <p class="mark-program-p">如果没有第三者提出异议，申请的商标在一年内获准注册</p>
        </div>
        <div class="mark-program-inner">
          <div class="mark-program-title title5"></div>
          <p class="mark-program-p">需时12-15个月</p>
        </div>
      </div>
      <p class="mark-program-tips">
        <span class="span">商标检索：</span>
        由于欧盟商标注册涵盖的国家众多，导致潜在的商标异议人大量存在。因此，我司极力推荐申请人在提出欧洲商标注册申请前，进行商标检索，以确定是否有与拟注册商标相同或太相似的商标已被第三者注册，及避免不必要的损失
      </p>
    </div>
    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="欧盟成员国名单？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">德国、法国、意大利、比利时、卢森堡、丹麦、瑞典、西班牙、葡萄牙、芬兰、希腊、奥地利、荷兰、爱尔兰、<br />
                塞浦路斯、捷克、爱沙尼亚、匈牙利、拉脱维亚、立陶宛、马耳他、波兰、斯洛伐克、斯洛维尼亚、罗马尼亚、<br />
                保加利亚、克罗地亚。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册欧盟商标还是注册单一国？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">二者都是商标在其他国家保护的手段，尽量选择逐一国注册，当出现纠纷的时候逐一国注册的商标对申请人<br />
                更有利。欧盟在一次性申请国家比较多有价格优势。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="欧盟商标注册容易通过吗？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">任何国家或者组织联盟的商标注册之前都需要经过查询之后才能知道商标是否可以注册成功。如果需要注册请<br />
                联系顾问查询。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="商标只有10年有效吗？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">10年到期以后可以申请续展，理论上商标是持续有效的。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="mark-why">
      <p class="re-title">为什么选择我们</p>
      <div class="mark-why-box center-width flex-between">
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_1.png" alt="">
          <p class="mark-why-p1">快速</p>
          <p class="mark-why-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_2.png" alt="">
          <p class="mark-why-p1">专业</p>
          <p class="mark-why-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_3.png" alt="">
          <p class="mark-why-p1">严谨</p>
          <p class="mark-why-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_4.png" alt="">
          <p class="mark-why-p1">保障</p>
          <p class="mark-why-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .mark-header{
    width: 100%;
    height: 320px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program-title{
    width: 224px;
    height: 40px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_3.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .title2{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_4.png') no-repeat center center;
  }
  .title3{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_5.png') no-repeat center center;
  }
  .title4{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_6.png') no-repeat center center;
  }
  .title5{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_7.png') no-repeat center center;
  }
</style>
