<template>
  <div class="anquila swiss">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式瑞士公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="m-advantage swiss-adv">
      <p class="re-title">注册瑞士公司优势</p>
      <div class="m-adv-box swiss-adv-box">
        <div class="m-adv-right swiss-adv-left">
          <p class="m-ad-title">无外汇管制</p>
          <p class="m-ad-p">无外汇管制，募集资金容易</p>
          <p class="m-ad-title">适度的税收负担</p>
          <p class="m-ad-p">适度的税收负担，相对其他欧洲其他各国具有竞争力，关税、税费和其他费用很低，税务规划方案颇具吸引力</p>
          <p class="m-ad-title">高效率的资本市场</p>
          <p class="m-ad-p">高效率的资本市场及有力条件，范围广泛的银行和保险产品有利的利率期限，长期来看，物价稳定性高且通胀率低</p>
          <p class="m-ad-title">金融中心</p>
          <p class="m-ad-p">全球领先的产业集群，全球制药和生命科学类公司的独特聚集地，重要的金融中心，高端钟表业的市场领军者，欧盟的首选中立性商业驻地</p>
          <p class="m-ad-title">高度国际融合</p>
          <p class="m-ad-p">高度国际融合，瑞士与欧洲关系稳固，强有力的出口导向型商业，欧盟提供保障，除了四种国家语言外，用英语交流</p>
        </div>
        <img class="m-adv-left swiss-adv-right" style="width: 620px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/pic_1.png" alt="">
      </div>
    </div>
    <div class="korea-type">
      <p class="re-title">注册瑞士公司类型</p>
      <div class="korea-type-box center-width flex-between">
        <div class="korea-type-inner">
          <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/pic_2_1.png" alt="">
          <p class="korea-type-p">股份公司</p>
          <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <div class="korea-type-inner">
          <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/pic_2_2.png" alt="">
          <p class="korea-type-p">有限责任公司</p>
          <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="swiss-file">
      <p class="re-title">注册瑞士公司条件/流程/资料</p>
      <div class="swiss-file-box center-width" style="display:flex;align-items: center;">
        <div class="swiss-file-left">
          <p class="swiss-file-p" :class="{'active': tab == 1}" @click="tab = 1">注册瑞士公司条件</p>
          <p class="swiss-file-p" :class="{'active': tab == 2}" @click="tab = 2">注册瑞士公司流程</p>
          <p class="swiss-file-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="swiss-file-right" v-if="tab == 1">
          <img style="width: 700px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/else/pic_1.png" alt="">
        </div>
        <div class="swiss-file-right" v-if="tab == 2">
          <img style="width: 600px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/swis_pic_2.png" alt="">
        </div>
        <div class="swiss-file-right" v-if="tab == 3">
          <img style="width: 700px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/swisspic.png" alt="">
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册瑞士公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p3">在商业登记机关注册登记的实体将会获得 UID 号码；一般情况下，UID编号统一...</p>
          <p class="sub-p4">在商业登记机关注册登记的实体将会获得 UID 号码；一般情况下，UID 编号统一分配给作为法人实体的公司总部，其下设的分支机构不会获得 UID 编号</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">瑞士银行瑞士银行管理着世界国际投资私人财富的四分之一到三分之一；另外可...</p>
          <p class="sub-p4">瑞士银行瑞士银行管理着世界国际投资私人财富的四分之一到三分之一；另外可开在星展银行开户</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">瑞士的增值税仅为8%，瑞士税收系统采用联邦制形式，因此每个州对公司征收...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p2">公司年审：瑞士公司年审一年一次</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Swiss/icon_1_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">瑞士公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="瑞士公司信息是保密的吗?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">瑞士公司信息是保密的，注册的瑞士公司不需要披露实益拥有的数据，或披露银行账户信息，除非涉及正在<br />
                进行的刑事诉讼。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="瑞士公司名称要求?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">公司名称必须以SA 或 SARL ( 即"有限公司 Limited ");公司名称不能以"信托公司"或"银行"或其它被认为意思<br />
                相似的词结尾，除非这些公司在 Switzerland 瑞士公司获得相应的执照，公司注册证书不可以载述中文公司<br />
                名称</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="瑞士公司需要缴纳增值税吗?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">瑞士公司是否缴纳增值税以及缴纳多少，与很多因素有关。原则上，如果在瑞士的年营业额高于10万瑞士法<br />
                郎，就应该缴纳增值税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业瑞士公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/swissbanner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
