<template>
  <!-- <div class="law">
    <p class="law-p4">香港律师意见书，即由香港律师对香港公司相关文件进行审核，然后对其出具意见书，是由香港执业律师对香港公司的真实性，目前状态，财务状况等各种数据收集整理之后，从法律专业角度给出的综合评价。法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
    <p class="law-p2">一、法律意见书内容</p>
    <p class="law-p3">
(a) 一般而言，国内须要就香港公司以下各个范畴出具法律意见: 一般母公司上市, 其子公司需做以下7点调查<br />

1、该公司设立的合法性;<br />

2、该公司存续的合法性;<br />

3、该公司的股权情况及历次股份变动的合法性;<br />

4、该公司境外投资的情况;<br />

5、该公司现行的章程及其历次修订的合法性;<br />

6、该公司有权机构已经做出的决议的合法性;<br />

7、该公司及董事的诉讼、仲裁和行政处罚。<br />

(b)有一些当事人可能要求一些特别的法律查证，在法律许可下，敝所的法律意见书亦可包含一些特别的查证要求。<br />

(c)您所提供的法律意见书范本内容是可行的。<br />
</p>
    <p class="law-p2">二、法律意见书办理程序</p>
    <p class="law-p3">

(a)首先，须要客户确定委聘，然后敝所将会出具一委聘函。<br />

(b)客户须在敝所委聘函上签署确定指示，并缴付前期律师费按金。<br />

(c)当事人提供所须文件，包括: 香港公司的法定记录(Statutory Records)﹑公司2年内的核数报告﹑及其他所须资产证明(假若法律意见书须要就有关资产作出确定)等等。<br />

(d)在客户提供所有文件，并且做出所须审查后，敝所将草拟法律意见书。一般而言，敝所在得到客户书面确定及缴付前期律师费按金后5个工作天内出具一份意见书草稿。<br />

(e)最后，待客户确定意见书草稿内容后，敝所签发法律意见书。<br />
</p>
    <p class="law-p2">三、香港律师意见书时间：3-5个工作日</p>
   
  </div>
   -->
   <div class="law1">
    <div class="law-banner">
      <div class="banner-inner">
        <p class="banner-title">中国香港法律意见书</p>
        <p class="law-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="law-info">
      <p class="b-title">中国香港公司法律意见书</p>
      <div class="law-info-box">
        <div class="law-info-left">
          <p class="info-p1">中国香港律师意见书，即由中国香港律师对中国香港公司相关文件进行审核，然后对其出具意见书，是由中国香港执业律师对中国香港公司的真实性，目前状态，财务状况等各种数据收集整理之后，从法律专业角度给出的综合评价。法律意见书是律师执业中的重要文书。一份形式严谨、结构缜密、分析准确、论证精当的法律意见书不仅是律师为客户提供专业法律服务的工作指南，同时也是律师对纷繁复杂的各种法律关系宏观驾驭能力和微观操作技巧的充分展示。</p>
          <p class="info-ask" @click="Dialog = true">立即咨询 ></p>
        </div>
        <img class="law-info-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="law-when" style="height: 593px">
      <p class="b-title">什么情况下需要出具“中国香港公司法律意见书”</p>
      <p class="b-title1">越来越多的企业适用中国香港公司在国内进行投资经营，与此同时，中国香港公司法律意见书在各个场合的使用需求也越来越多，例如： </p>
      <div class="law-when-box">
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_2_1.jpg" alt="">
          <p class="when-p">新三板上市</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_2_2.jpg" alt="">
          <p class="when-p">企业并购</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_2_3.jpg" alt="">
          <p class="when-p">银行办理信贷业务</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_2_4.jpg" alt="">
          <p class="when-p">股权质押</p>
        </div>
        <div class="when-inner">
          <img class="when-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_2_5.jpg" alt="">
          <p class="when-p">其他</p>
        </div>
      </div>
      <p class="when-tips">（中国香港律师意见书办理时间：<span class="red-style">3-5 </span>个工作日左右！）</p>
    </div>
    <div class="law-range">
      <p class="b-title">法律意见书内容通常涉及以下范围</p>
      <p class="b-title1">一般而言，国内须要就中国香港公司以下各个范畴出具法律意见: 一般母公司上市, 其子公司需做以下7点调查： </p>
      <div class="law-range-box">
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_1.png" alt="">
          <p class="law-range-p">该公司设立的合法性</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_2.png" alt="">
          <p class="law-range-p">该公司续存的合法性</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_3.png" alt="">
          <p class="law-range-p">该公司的股权情况及历次股份变动的合法性</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_4.png" alt="">
          <p class="law-range-p">该公司境外投资的情况</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_5.png" alt="">
          <p class="law-range-p">该公司现行的章程及其历次修订的合法性</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_6.png" alt="">
          <p class="law-range-p">该公司有权机构已经做出的决议的合法性</p>
        </div>
        <div class="range-inner">
          <img class="law-range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/icon_1_7.png" alt="">
          <p class="law-range-p">该公司及董事的诉讼、仲裁和行政处罚</p>
        </div>
      </div>
    </div>
    <div class="law-flow" style="background: #fff">
      <p class="b-title">“中国香港公司法律意见书”办理程序</p>
      <div class="law-flow-box1" >
        <img class="law-flow-center" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_4.png" alt="">
        <!-- <div class="law-flow-bottom">
          <p class="law-flow-p">首先，需要客户确定委聘，然后敝所将会出具一委聘函。</p>
          <p class="law-flow-p">客户需在敝所委聘函上签署确定指示，并缴付前期律师费用金。</p>
          <p class="law-flow-p">当事人提供所需文件，包括: 香港公司的法定记录，公司2年内的核数报告及其他所需资产证明(假若法律意见书需要就有关资产作出确定)等。</p>
          <p class="law-flow-p">在客户提供所有文件，并且做出所需审查后，敝所将草拟法律意见书。一般而言，敝所在得到客户书面确定及缴付前期律师费用金后5个工作日内出具一份意见书草稿。</p>
          <p class="law-flow-p">最后，待客户确定意见书草稿内容后，敝所签发法律意见书。</p>
        </div> -->
      </div>
    </div>
    <div class="odi-add">
      <p class="b-title">增值服务</p>
      <p class="b-title1">我们还为您提供</p>
      <div class="odi-add-box center-width flex-between">
        <div class="odi-add-inner" >
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的<br />申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_7.png" alt="">
          <p class="odi-add-p">中国香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律<br />意见书等服务</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bank-page',
  components: {
    Footer,
    Link
  },
  data(){
    return{
      Dialog: false
    }
  },
  mounted(){
    // window.scrollTo(0,0);
  }
}
</script>
<style>
  .law-banner{
    width: 100%;
    height: 500px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .law-range{
    width: 100%;
    height: 555px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/HongKongLaw/pic_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
