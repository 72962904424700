<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式卢森堡公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="new-advantage">
      <p class="re-title">注册卢森堡公司的优势</p>
      <div class="new-advantage-box center-width flex-between">
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_1_1.png" alt="">
          <p class="new-advantage-p">近50个国家签订双重税收优惠</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_1_2.png" alt="">
          <p class="new-advantage-p">经济政治环境安全稳定</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_1_3.png" alt="">
          <p class="new-advantage-p">国际金融中心商业中心</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_1_4.png" alt="">
          <p class="new-advantage-p">创新金融产品高效专业</p>
        </div>
        <div class="new-advantage-inner">
          <img class="new-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_1_5.png" alt="">
          <p class="new-advantage-p">没被任何国家列入黑名单</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册卢森堡公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/pic_1_2.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">金融服务公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">金融控股公司</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="anquila-process" style="height: 829px">
      <p class="re-title">注册卢森堡公司的条件/流程/资料</p>
      <div class="anquila-process-box center-width">
        <div class="anquila-process-tab">
          <p class="anquila-process-tab-p" :class="{'active': tab == 1}" @click="tab = 1">注册卢森堡公司条件</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 2}" @click="tab = 2">注册卢森堡公司流程</p>
          <p class="anquila-process-tab-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 1">
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_1.png" alt="">
            <div>
              <p class="japan-process1-p1">公司名称</p>
              <p class="japan-process1-p2">3-4个使用名称</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_2.png" alt="">
            <div>
              <p class="japan-process1-p1">基本资料</p>
              <p class="japan-process1-p2">护照复印件、联系地址、签名样本</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_3.png" alt="">
            <div>
              <p class="japan-process1-p1">持股百分比</p>
              <p class="japan-process1-p2">各个股东的持股百分比</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_4.png" alt="">
            <div>
              <p class="japan-process1-p1">计划书</p>
              <p class="japan-process1-p2">有关从事行业的计划书</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_5.png" alt="">
            <div>
              <p class="japan-process1-p1">授权书</p>
              <p class="japan-process1-p2">当客户无法亲自到场时，公司所有者有关新建公司事宜的授权</p>
            </div>
          </div>
          <div class="anquila-process-inner">
            <img class="anquila-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_2_6.png" alt="">
            <div>
              <p class="japan-process1-p1">启动资金</p>
              <p class="japan-process1-p2">公司启动资金总数</p>
            </div>
          </div>
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 2">
          <img class="japan-process2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/pic.png" alt="">
        </div>
        <div class="anquila-process-content flex-between" v-if="tab == 3">
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_1.png" alt="">
            <p class="Luxembourg-process-p">公司成立证书（A、C）</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_2.png" alt="">
            <p class="Luxembourg-process-p">公司注册摘录(A、C）</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_3.png" alt="">
            <p class="Luxembourg-process-p">股权证书</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_4.png" alt="">
            <p class="Luxembourg-process-p">如果客户直接持股</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_5.png" alt="">
            <p class="Luxembourg-process-p">如果我方提供匿名股东</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_6.png" alt="">
            <p class="Luxembourg-process-p">股东名册（A）</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_7.png" alt="">
            <p class="Luxembourg-process-p">董事登记册</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_8.png" alt="">
            <p class="Luxembourg-process-p">股份登记册</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_9.png" alt="">
            <p class="Luxembourg-process-p">指定匿名股东或董事之协议（如有A和C）</p>
          </div>
          <div class="luxembourg-inner3">
            <img class="Luxembourg-process-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/icon_3_10.png" alt="">
            <p class="Luxembourg-process-p">公司长形签名章（C）</p>
          </div>
        </div>
      </div>

    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册卢森堡公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业卢森堡公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Luxembourg/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
