<template>
  <div class="HK-plan">
    <div class="HK-plan-header">
      <p class="HK-plan-banner-p2">中国香港优才计划</p>
      <p class="HK-plan-banner-p3">拿中国香港身份，保留内地身份，免签全球163国</p>
      <p class="HK-plan-banner-p4" @click="Dialog = true">立即咨询</p>
    </div>
    <div class="plan-box" style="height: 613px;">
      <p class="b-title">中国香港优才计划</p>
      <div class="center-box">
        <div class="plan-left1">
          <p class="plan-left1-p">中国香港优秀人才计划，简称“中国香港优才”，于2006年2月23日公布，于当年6月前开始实施。中国香港优才计划是中国香港政府推出的一项移民吸纳计划，旨在吸引高技术人才或优才来港定居，藉此提升中国香港的竞争力。所有申请人均必须首先符合基本资格的要求，才可根据计划所设两套计分制度的其中一套获取分数，提交申请资料。两套计分制度分别是「综合计分制」和「成就计分制」。</p>
          <div class="plan-btn">
            <p class="plan-btn1" @click="Dialog=true">立即咨询</p>
            <p class="plan-btn2" @click="toPoint()">查看计分制</p>
          </div>
        </div>
        <img class="plan-right1" src="../assets/img/HKplan/pic_1.jpg" alt="">
      </div>
    </div>
    <div class="plan-box" style="height: 643px;background: rgba(242, 245, 250, 1);">
      <p class="b-title">中国香港优才计划适用人群</p>
      <div class="center-box">
        <div class="plan-left2 lef2-bg1" >
          <div class="shaw-box">
            <p class="plan-left2-p1">想在中国香港发展、工作、创业</p>
            <p class="plan-left2-p2">为了今后在中国香港寻找更高的就业机会，发展自己的职业前景</p>
          </div>
        </div>
        <div class="plan-left2 lef2-bg2">
          <div class="shaw-box">
            <p class="plan-left2-p1">解决子女教育问题</p>
            <p class="plan-left2-p2">想要孩子到中国香港读书，享受优质丰富的教育资源，香港实行15年免费义务教育</p>
          </div>
        </div>
        <div class="plan-left2 lef2-bg3">
          <div class="shaw-box">
            <p class="plan-left2-p1">想获身份全家移居中国香港</p>
            <p class="plan-left2-p2">在港居住满7年可以申请中国香港永居和特区护照，护照免签164个国家和地区</p>
          </div>
          
        </div>
      </div>
    </div>
    <div class="plan-box" style="height: 798px;">
      <p class="b-title">中国香港优才计划申请条件</p>
      <p class="b-title1">中国香港优秀人才入境计划（简称“优才”）于2006年06月28日公布实施</p>
      <div class="center-box" style="padding-top: 40px;">
        <div class="plan-left3" >
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_1.png" alt="">
          <p class="plan-left3-p1">年龄</p>
          <p class="plan-left3-p2">申请人根据本计划提交申请时，年龄必须在18岁或以上</p>
        </div>
        <div class="plan-left3" style="background: rgba(240, 240, 240, 1);">
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_2.png" alt="">
          <p class="plan-left3-p1">财政要求</p>
          <p class="plan-left3-p2">申请人必须证明能独力负担其本人及受养人（如果有）居港期间的生活和住宿，不需依赖公共援助</p>
        </div>
        <div class="plan-left3" >
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_3.png" alt="">
          <p class="plan-left3-p1">良好品格</p>
          <p class="plan-left3-p2">申请人不得有任何在中国香港或其他地方的刑事罪行记录或不良入境记录</p>
        </div>
        <div class="plan-left3" style="background: rgba(240, 240, 240, 1);">
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_4.png" alt="">
          <p class="plan-left3-p1">语言能力</p>
          <p class="plan-left3-p2">申请人须具备良好中文或英文的书写及口语能力（中文口语指普通话或粤语）</p>
        </div>
        <div class="plan-left3" >
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_5.png" alt="">
          <p class="plan-left3-p1">基本学历</p>
          <p class="plan-left3-p2">申请人必须具备良好学历，一般要求为具备由认可大学或高等教育院校颁授的大学学位。在特殊情况下，能附以证明文件的良好技术资历、可证明的专业能力及／或经验及成就亦可获考虑</p>
        </div>
        <div class="plan-left3" style="background: rgba(240, 240, 240, 1);">
          <img class="plan-left3-icon" src="../assets/img/HKplan/icon_1_6.png" alt="">
          <p class="plan-left3-p1">计分制</p>
          <p class="plan-left3-p2">优才分为“综合计分制”或“成就计分制”两种申请方式</p>
          <p class="plan-left3-p3" @click="toPoint()">查看计分制 > </p>
        </div>
      </div>
    </div>
    <div class="plan-box" style="height: 767px;background: rgba(242, 245, 250, 1);">
      <p class="b-title">中国香港优才计划申请流程</p>
      <div class="center-box" style="padding-top: 60px">
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_1.png" alt="">
          <p class="plan-left4-p1">第一步</p>
          <p class="plan-left4-p2">咨询与评估工作（1周）</p>
          <p class="plan-left4-p3" @click="toPoint()">在线评估</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_2.png" alt="">
          <p class="plan-left4-p1">第二步</p>
          <p class="plan-left4-p2">签署协议，准备材料（3-6周）</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_3.png" alt="">
          <p class="plan-left4-p1">第三步</p>
          <p class="plan-left4-p2">提交申请，领取档案编号（1-2周）</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_4.png" alt="">
          <p class="plan-left4-p1">第四步</p>
          <p class="plan-left4-p2">甄选程序<br />（6-18个月）</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_5.png" alt="">
          <p class="plan-left4-p1">第五步</p>
          <p class="plan-left4-p2">获批通告<br />（即时通知）</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_6.png" alt="">
          <p class="plan-left4-p1">第六步</p>
          <p class="plan-left4-p2">签发签证</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_7.png" alt="">
          <p class="plan-left4-p1">第七步</p>
          <p class="plan-left4-p2">向公安机关申请签发多次往返签注</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_8.png" alt="">
          <p class="plan-left4-p1">第八步</p>
          <p class="plan-left4-p2">预约及领取中国香港<br />身份证</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_9.png" alt="">
          <p class="plan-left4-p1">第九步</p>
          <p class="plan-left4-p2">延长逗留签证</p>
        </div>
        <div class="plan-left4">
          <img class="plan-left4-icon" src="../assets/img/HKplan/icon_2_10.png" alt="">
          <p class="plan-left4-p1">第十步</p>
          <p class="plan-left4-p2">满七年申请永居<br />身份</p>
        </div>
      </div>
    </div>

    <div class="plan-box" style="height: 686px;">
      <p class="b-title">中国香港身份的好处</p>
      <div class="center-box" style="padding-top: 60px;">
        <div class="plan-left5">
          <p class="plan-left5-p1">高等教育资源</p>
          <p class="plan-left5-p3">在中国香港：中国香港身份的考生可以参加DSE考试（中国香港高考）升读中国香港/海外大学；或凭DSE成绩免试申请内地名牌大学。中国香港身份学生报考内地最多的十所院校有哪些？</p>
          <p class="plan-left5-p3">在内地：凭借中国香港身份，参与国内联招考试（港澳台华侨生联考），400分以上就可上清北名校，内地320所985/211名校任意报考。家长必看！2023年港澳台华侨生联考可报考的内地320所大学名单！</p>
        </div>
        <div class="plan-right5">
          <div class="plan-right5-inner">
            <p class="plan-left5-p1">保留两地身份</p>
            <p class="plan-left5-p2">目前，通过中国香港“优秀人才计划”申请中国香港身份，无需注销内地户口。</p>
          </div>
          <div class="plan-right5-inner">
            <p class="plan-left5-p1">国际教育资源</p>
            <p class="plan-left5-p2">子女可获取的教育资源更加丰富，拥有中国香港身份的孩子除了可以在中国香港入读各类学校（含国际学校）也可以在内地入读国际学校。</p>
          </div>
          <div class="plan-right5-inner">
            <p class="plan-left5-p1">社会保障体系</p>
            <p class="plan-left5-p2">中国香港医疗保障体系让所有中国香港居民以低价享受高质量医疗服务，即使非永居，家人亦可享受中国香港优质的医疗资源，还可免费接种疫苗。</p>
          </div>
          <div class="plan-right5-inner">
            <p class="plan-left5-p1">资产税务规划</p>
            <p class="plan-left5-p2">拥有中国香港身份，可解决全球化资产架构搭建、税务筹划的问题。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="plan-box plan-box6" style="height: 320px;">
      <p class="plan-box6-title">取消配额限制</p>
      <p class="plan-box6-p1">中国香港特首李家超宣布的2023年关于优秀人才计划的新政：取消中国香港优才计划配额限制，同时为了提高优才审批效率新政还取消了“原则性审批环节”即审批通过后需要带原件到入境处进行会面的环节；增加了线上提交和补件的方式；采用即时获批即时通知的方式，取消了季度统一发文公布方式等。通过历史获批的数据可以看出资讯科技与电讯、金融与会计服务、商业与贸易、建筑工程及制造、艺术和文化都是热门行业。但同时优才项目还是一个综合筛选机制，只有提供合适的材料和恰当的描述，才能机会获得入境处的青睐。</p>
     
    </div>
    <div class="odi-add" style="background: #fff;">
      <p class="b-title">增值服务</p>
      <p class="b-title1">我们还为您提供</p>
      <div class="odi-add-box center-width flex-between">
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的<br />申请和申报</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_7.png" alt="">
          <p class="odi-add-p">中国香港及境外公证</p>
        </div>
        <div class="odi-add-inner" style="background: rgba(242, 245, 250, 1);">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律<br />意见书等服务</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toPoint(){
      this.$router.push({name: 'point'})
    }
  }
}
</script>

<style scoped lang="scss">
  .HK-plan-header{
    width: 100%;
    height: 600px;
    background: url('../assets/img/HKplan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .lef2-bg1{
    background: url('../assets/img/HKplan/pic_2_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .lef2-bg2{
    background: url('../assets/img/HKplan/pic_2_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .lef2-bg3{
    background: url('../assets/img/HKplan/pic_2_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .plan-box6{
    background: url('../assets/img/HKplan/pic_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }

</style>
