<template>
  <div>
    <div class="point">
    <p class="point-p1">综合计分制<span class="point-span1">（最高245分） </span></p>
    <p class="point-p2">最新适用的最低及格分数是 <span class="point-span2">80分*</span></p>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(47, 114, 235, 1);"></span> 年龄（最高30分）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">1.1</p>
        <p class="table-2">18岁-39岁</p>
        <p class="table-3">30</p>
      </div>
      <div class="table-tr">
        <p class="table-1">1.2</p>
        <p class="table-2">40岁-44岁</p>
        <p class="table-3">20</p>
      </div>
      <div class="table-tr">
        <p class="table-1">1.3</p>
        <p class="table-2">45岁-50岁</p>
        <p class="table-3">15</p>
      </div>
      <div class="table-tr">
        <p class="table-1">1.4</p>
        <p class="table-2">51岁或以上</p>
        <p class="table-3">0</p>
      </div>
    </div>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(237, 41, 65, 1);"></span>学历／专业资格（最高70分）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">2.1</p>
        <p class="table-2">博士学位／2个或以上硕士学位</p>
        <p class="table-3">40</p>
      </div>
      <div class="table-tr">
        <p class="table-1">2.2</p>
        <p class="table-2">硕士学位／2个或以上学士学位</p>
        <p class="table-3">20</p>
      </div>
      <div class="table-tr">
        <p class="table-1">2.3</p>
        <p class="table-2">学士学位／由国家或国际认可或著名的专业团体颁授，以证实持有人具有极高水平的专门知识或专业技能的专业资格</p>
        <p class="table-3">10</p>
      </div>
      <div class="table-tr">
        <p class="table-1">2.4</p>
        <p class="table-2">如学士或以上程度的学位是由国际认可的著名院校颁授，可额外获得分数（注1）</p>
        <p class="table-3">30</p>
      </div>
    </div>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(235, 116, 47, 1);"></span>工作经验（最高75分）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.1</p>
        <p class="table-2">不少于10年相当于学位程度或专家水平的工作经验，当中最少5年担任高级职位</p>
        <p class="table-3">40</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.2</p>
        <p class="table-2">不少于5年相当于学位程度或专家水平的工作经验，当中最少2年担任高级职位</p>
        <p class="table-3">30</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.3</p>
        <p class="table-2">不少于5年相当于学位程度或专家水平的工作经验</p>
        <p class="table-3">15</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.4</p>
        <p class="table-2">不少于2年相当于学位程度或专家水平的工作经验</p>
        <p class="table-3">5</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.5</p>
        <p class="table-2">如拥有不少于2年相当于学位程度或专家水平的国际工作经验，可额外获取分数（注2）</p>
        <p class="table-3">15</p>
      </div>
      <div class="table-tr">
        <p class="table-1">3.6</p>
        <p class="table-2">如拥有不少于3年相当于学位程度或专家水平的跨国公司或知名企业的工作经验，如上市公司或位列《福布斯》全球企业2000强、《财富》世界500强和胡润中国500强企业，可额外获取分数</p>
        <p class="table-3">20</p>
      </div>
    </div>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(9, 186, 101, 1);"></span>人才清单（最高30分）（注3）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">4.1</p>
        <p class="table-2">如符合人才清单内相关专业的规格，可额外获取分数 <span @click="Dialog = true" style="color: rgba(47, 114, 235, 1);font-weight: 500;cursor: pointer;">查看人才清单 > </span></p>
        <p class="table-3">30</p>
      </div>
    </div>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(92, 9, 186, 1);"></span>语文能力（最高20分）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">5.1</p>
        <p class="table-2">良好中文及英文的书写及口语能力（中文口语指普通话或粤语）</p>
        <p class="table-3">20</p>
      </div>
      <div class="table-tr">
        <p class="table-1">5.2</p>
        <p class="table-2">除了具备良好中文或英文的书写及口语能力外（中文口语指普通话或粤语），也能流利应用不少于一种外国语言（包括书写及口语能力）</p>
        <p class="table-3">20</p>
      </div>
    </div>
    <p class="point-table-title"><span class="title-circle" style="background: rgba(9, 157, 186, 1);"></span>家庭背景（最高20分）</p>
    <div class="point-table">
      <div class="table-title">
        <p class="table-1">序号</p>
        <p class="table-2">得分范畴</p>
        <p class="table-3">得分</p>
      </div>
      <div class="table-tr">
        <p class="table-1">6.1</p>
        <p class="table-2">至少一名直系家庭成员（已婚配偶、父母、兄弟姊妹、子女）是现居于中国香港的香港永久性居民（注4）</p>
        <p class="table-3">5</p>
      </div>
      <div class="table-tr">
        <p class="table-1">6.2</p>
        <p class="table-2">随行已婚配偶的学历相当于大学学位或以上的水平（注4）</p>
        <p class="table-3">5</p>
      </div>
      <div class="table-tr">
        <p class="table-1">6.3</p>
        <p class="table-2">每名随行的18岁以下未婚及受养的子女得5分，最高可得10分</p>
        <p class="table-3">5/10</p>
      </div>
    </div>
    <p class="point-p5" style="padding-top: 20px;"><span style="font-weight: 500;color: rgba(237, 41, 65, 1);">注1：</span>持有由国际认可的著名院校颁授的学士或以上程度学位的申请人，如要获取该额外分数，有关院校须为由Q S、上海交通大学、泰晤士高等教育及美国新闻与世界报道发表的四个全球大学排名表之一的前1 0 0所大学／院校，或美国新闻与世界报道的全国文科大学排行榜的前3 0所学院。入境事务处亦可能就未有列入上述排名表的特定行业著名院校所颁授的资格，咨询相关专家或专业团体的意见。</p>
    <p class="point-p5"><span style="font-weight: 500;color: rgba(237, 41, 65, 1);">注2：</span>如要以拥有不少于两年的国际工作经验获取额外分数，有关国际工作经验须为申请人在原居国家／地区以外取得的相当于学位程度或专家水平的工作经验。</p>
    <p class="point-p5"><span style="font-weight: 500;color: rgba(237, 41, 65, 1);">注3：</span>如申请人拟循人才清单的得分范畴获取额外分数，必须提供证明文件令中国香港特区政府信纳其符合人才清单内相关专业的规格。入境处亦可能就个别申请是否符合人才清单内相关专业的规格，咨询相关政府机关/专业团体的意见。</p>
    <p class="point-p5"><span style="font-weight: 500;color: rgba(237, 41, 65, 1);">注4：</span>相关得分安排亦适用于根据缔结当地有效的法律缔结的同性民事伴侣关系、同性民事结合、“同性婚姻”、异性民事伴侣关系或异性民事结合的另一方，而该身份是缔结当地机关合法和官方承认的（见「计划宗旨」中的注）。</p>
    <p class="point-p6">（*「综合计分制」设有最低及格分数，有兴趣申请的人士，应先评估其个人资历是否已达到最低及格分数，才提交申请。最低及格分数可能会不时更改而不作事先通知。）</p>
  </div>
  <div class="point-box2">
      <div class="point-box2-inner">
        <p class="point-box2-p1">成就计分制</p>
        <p class="point-box2-p2">本计划亦为具备超凡才能或技术并拥有杰出成就的个别人士，提供另一套申请来港的计分制度。这类别的申请人可选择以「成就计分制」接受评核。此计分制的要求极高。申请人如被视作符合下段所述此计分制所列的其中一项要求，可获取2 4 5分，不符合者则不会获得分数，而不能取得分数的申请人，其申请会即时被拒絶。</p>
        <p class="point-box2-p3">如符合下述要求，可依此计分制获取分数：</p>
        <p class="point-box2-p4">申请人曾获得杰出成就奖（例如奥运奖牌、诺贝尔奖、国家/国际奖项）；</p>
        <p class="point-box2-p4">申请人可以证明其工作得到同业肯定，或对其界别的发展有重大贡献（例如获业内颁发终生成就奖）。</p>
      </div>
    </div>
  <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="人才清单的13项专业" width="1020px">
      <div style="width: 1020px; height: 600px;margin: 0 auto;">
          <div class="table1">
            <div class="table-inner" style="background: rgba(237, 237, 237, 1);">
              <p class="table-left">序号</p>
              <p class="table-right">专业名称</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">1</p>
              <p class="table-right">资产管理合规专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">2</p>
              <p class="table-right">环境、社会及管治（ESG）相关财经专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">3</p>
              <p class="table-right">废物处理专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">4</p>
              <p class="table-right">资产管理专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">5</p>
              <p class="table-right">海事保险专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">6</p>
              <p class="table-right">精算师</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">7</p>
              <p class="table-right">金融科技专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">8</p>
              <p class="table-right">数据科学家及网络安全专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">9</p>
              <p class="table-right">创新及科技专家</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">10</p>
              <p class="table-right">造船师</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">11</p>
              <p class="table-right">轮机工程师及船舶总管</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">12</p>
              <p class="table-right">创意产业和表演艺术专才</p>
            </div>
            <div class="table-inner1">
              <p class="table-left">13</p>
              <p class="table-right">争议解决专才及业务交易律师</p>
            </div>
          </div>
      </div>
    </el-dialog>
  </div>
  
</template>
<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    
  }
}
</script>

<style scoped lang="scss">
  

</style>
