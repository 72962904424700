import { render, staticRenderFns } from "./ODI.vue?vue&type=template&id=6eaab091&scoped=true&"
import script from "./ODI.vue?vue&type=script&lang=js&"
export * from "./ODI.vue?vue&type=script&lang=js&"
import style0 from "./ODI.vue?vue&type=style&index=0&id=6eaab091&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eaab091",
  null
  
)

export default component.exports