<template>
  <div class="noc-content">
    <div class="noc-content-header">
      <div class="noc-content-inner center-width">
        <div class="header-tag">
          <p class="tag-inner">无需回美国</p>
          <p class="tag-inner">平台式申请</p>
          <p class="tag-inner">合同保障</p>
        </div>
        <p class="noc-content-p1">美国无犯罪记录申请</p>
        <p class="noc-content-p2">留学 | 绿卡 | 工作 | 签证 | 留学</p>
        <p class="noc-content-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="exma-business-Am">
      <p class="b-title">美国无犯罪记录办理</p>
      <p class="b-title1">请填写以下表单留下您的信息，我们将第一时间联系您，谢谢！</p>
      <div class="exma-business-Am-box center-width">
        <div class="exma-business-Am-inner">
          <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
            <el-form-item label="" prop="name">
              <el-input class="exma-input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="exma-input-length" v-model="form.mobile" placeholder="请输入您的联系方式"></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select class="exma-select-length" v-model="form.type" disabled placeholder="请选择您要办理的业务类型">
                <el-option label="有指纹无犯罪" value="有指纹无犯罪"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <p class="exma-business-Am-btn" @click="toSubmit">提交</p>
            </el-form-item>
          </el-form>
        </div>
        <div class="exma-business-tips">
          <p class="exma-business-tips-p2">提交表单，免费咨询；或致电 <span class="middle-style">400-860-5600</span></p>
        </div>
      </div>
    </div>
    <div class="noc-type-amer">
      <p class="b-title">美国有指纹无犯罪办理</p>
      <div class="noc-type-amer-box">
        <div class="noc-type-amer-inner">
          <img class="noc-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/icon_1.png" alt="">
          <p class="noc-type-p1">有指纹无犯罪</p>
          <p class="noc-type-p2">电子版可申请加急</p>
          <p class="noc-type-p3">具体收费请咨询客户经理</p>
          <p class="noc-type-p4">可办理移民、绿卡、工作、签证</p>
          <p class="noc-type-p4">需2 ~ 3个工作日</p>
          <p class="noc-type-p4">需2张指纹卡、FD-258原件、783表格，护照复印件</p>
          <p class="noc-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <p class="noc-type-amer-tips">注：全国各城市公证处均可采集公证指纹</p>
      </div>
    </div>
    <div class="noc-dept amer-dept">
      <p class="b-title">哪个部门可出具美国无犯罪记录证明</p>
      <div class="noc-dept-box">
        <el-carousel :interval="5000" arrow="hover" height="430px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="noc-dept-inner">
              <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
              <div class="noc-dept-right">
                <p class="noc-dept-tag">有指纹无犯罪</p>
                <p class="noc-dept-p1">美国联邦调查局FBI</p>
                <p class="noc-dept-p2">Federal Bureau of investigation</p>
                <p class="noc-dept-p3">指纹采集（Fingerprint acquisition）鉴定方式</p>
                <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="noc-dept-inner">
              <img class="noc-dept-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_2.jpg" alt="">
              <div class="noc-dept-right">
                <p class="noc-dept-tag">无指纹无犯罪</p>
                <p class="noc-dept-p1">当地警察局</p>
                <p class="noc-dept-p2">Local police station</p>
                <p class="noc-dept-p3">名字（Name Based）检索的方式</p>
                <p class="noc-dept-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>  
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="noc-validity">
      <p class="b-title">美国无犯罪记录证明有效期</p>
      <p class="b-title1">一般为6个月内的无犯罪证明，超出需重新办理</p>
      <div class="noc-validity-box center-width flex-between">
        <img class="noc-validity-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_2.png" alt="">
        <div class="noc-validity-right">
          <p class="noc-validity-p1">6个月以内无犯罪证明</p>
          <p class="noc-validity-p2">美国无犯罪记录证明报告上并不会体现该报告的有效期，一般来讲，用于在国内申请工作签证或永久居留权时，政府部门要求该报告为六个月以内的无犯罪记录证明，超出6个月则需要重新办理</p>
          <p class="noc-validity-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="noc-advantage">
      <p class="re-title">服务优势</p>
      <div class="noc-advantage-box center-width flex-between">
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_1.png" alt="">
          <p class="noc-advantage-p">专业人员<br />全程跟进</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_2.png" alt="">
          <p class="noc-advantage-p">丰富经验<br />积累</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_3.png" alt="">
          <p class="noc-advantage-p">保护客户<br />隐私</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_4.png" alt="">
          <p class="noc-advantage-p">量身定制<br />办理方案</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_5.png" alt="">
          <p class="noc-advantage-p">合同保障<br />客户权益</p>
        </div>
        <div class="noc-advantage-inner">
          <img class="noc-advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Australia/icon_2_6.png" alt="">
          <p class="noc-advantage-p">实时反馈<br />办理进度</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">美国无犯罪热门问答</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国无犯罪证明怎么开？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">美国FBI无犯罪证明申请资料：<br />
              1、申请美国无犯罪证明的申请表，即I-783无犯罪记录申请<br />
              2、提供申请者的指纹卡，FD-258标准<br />
              3、申请人的有效护照扫描件/复印件</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="移民美国办理的无犯罪记录证明的有效期是多久?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">美国无犯罪证明是没有有效期的，美国无犯罪证明认证文件有效期是6个月 美国无犯罪证明，也就是证明在美国<br />
                居住生活期间是合法的公民，该份证明是没有一直有效的</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="美国的无犯罪记录证明该怎么做？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">如果您曾经在美国居留超过6个月，申请西班牙学习签证时会被要求提供美国无犯罪记录证明。美国无犯罪有两<br />
                种形式，一种是FBI出具的无犯罪记录证明，范围覆盖美国全境；另一种是各州局出具的无犯罪记录证明</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="noc-case">
      <p class="b-title">美国有指纹无犯罪办理</p>
      <div class="noc-case-box center-width flex-between">
        <div class="noc-case-inner">
          <img class="noc-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_3_1.png" alt="">
          <p class="noc-case-p1">美国无犯罪记录用于移民</p>
          <!-- <p class="noc-case-p2">张小姐需要美国无犯罪记录用于留学，找到我们快企务办理，3天就申请下来了！ </p>
          <p class="noc-case-p3" @click="Dialog = true">查看详情 > </p> -->
        </div>
        <div class="noc-case-inner">
          <img class="noc-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_3_2.png" alt="">
          <p class="noc-case-p1">美国无犯罪记录用于留学</p>
          <!-- <p class="noc-case-p2">张小姐需要美国无犯罪记录用于留学，找到我们快企务办理，3天就申请下来了！ </p>
          <p class="noc-case-p3" @click="Dialog = true">查看详情 > </p> -->
        </div>
        <div class="noc-case-inner">
          <img class="noc-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_3_3.png" alt="">
          <p class="noc-case-p1">美国无犯罪记录用于移民</p>
          <!-- <p class="noc-case-p2">陈小姐需要美国无犯罪记录用于工作签证，找到我们快企务办理，3天就申请下来了！ </p>
          <p class="noc-case-p3" @click="Dialog = true">查看详情 > </p> -->
        </div>
      </div>
    </div>
    <!-- <div class="noc-counselor">
      <p class="b-title">专业顾问</p>
      <div class="noc-counselor-box">
        <el-carousel :interval="5000" arrow="hover" height="550px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="noc-counselor-inner-box">
              <div class="noc-counselor-inner">
                <img class="noc-counselor-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                <p class="noc-counselor-p1">行业深耕10年有余，能快速全面的解答客户的疑惑，并给予合理化建议，从客户根本利益出发，给予一...</p>
                <div class="noc-counselor-bottom">
                  <img class="noc-counselor-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                  <p class="noc-counselor-p">Tainly</p>
                </div>
              </div>
              <div class="noc-counselor-inner">
                <img class="noc-counselor-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                <p class="noc-counselor-p1">熟悉香港、美国、新加坡、英国等地资本市场，精通各国财税实务在上市公司审计、财务风险管控、境...</p>
                <div class="noc-counselor-bottom">
                  <img class="noc-counselor-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                  <p class="noc-counselor-p">Irene</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="noc-counselor-inner-box">
              <div class="noc-counselor-inner">
                <img class="noc-counselor-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                <p class="noc-counselor-p1">行业深耕10年有余，能快速全面的解答客户的疑惑，并给予合理化建议，从客户根本利益出发，给予一...</p>
                <div class="noc-counselor-bottom">
                  <img class="noc-counselor-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                  <p class="noc-counselor-p">Tainly</p>
                </div>
              </div>
              <div class="noc-counselor-inner">
                <img class="noc-counselor-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                <p class="noc-counselor-p1">行业深耕10年有余，能快速全面的解答客户的疑惑，并给予合理化建议，从客户根本利益出发，给予一...</p>
                <div class="noc-counselor-bottom">
                  <img class="noc-counselor-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/pic_1_1.jpg" alt="">
                  <p class="noc-counselor-p">Tainly</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
    <div class="noc-handle">
      <div class="noc-handle-box center-width flex-between">
        <div class="noc-handle-left">
          <p class="noc-handle-p1">办理中国驻美国大使馆领事认证</p>
          <p class="noc-handle-p2">美国无犯罪记录证明用于国内工作签证或永久居留权申请，需要办理中国驻美国大使馆的领事认证</p>
          <p class="noc-handle-p3">一、将申请下来的美国无犯罪记录证明及当事人的护照扫描件提交至国际公证人处办理国际公证</p>
          <p class="noc-handle-p3">二、提交至美国外交部或者省政府指定部门进行办理认证</p>
          <p class="noc-handle-p3">三、交至中国驻美国大使馆办理认证</p>
          <p class="noc-handle-p4">正常办理：15-18个工作日</p>
          <p class="noc-handle-p5">加急服务：7-10个工作日（不包含国际邮寄时间）</p>
        </div>
        <img class="noc-handle-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/Canada/pic_4_1.png" alt="">
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import { submitForm } from '@/utils/api'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  mounted(){
    window.scrollTo(0,0);
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {
        type: '有指纹无犯罪'
      },
      activeName: '1',
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}]
      }
    }
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 2,
            name: that.form.name,
            mobile: that.form.mobile,
            crimeType: '有指纹无犯罪',
            country: '美国'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .noc-content-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/noCriminal/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
