<template>
  <div class="bank">
    <div class="bank-header">
      <p class="bank-p1">无需亲赴 全球银行轻松成功开户</p>
      <p class="bank-p2">全球银行任选 不成功全额退还费用</p>
      <p class="bank-p3">费用透明/专人协助</p>
      <p class="bank-btn" @click="Dialog = true">立即办理</p>
    </div>
    <div class="b-problem">
      <p class="b-title">银行开户，您是否遇到以下情况</p>
      <div class="b-problem-box">
        <div class="b-pro-inner">
          <img class="inner-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_1_1.png" alt="">
          <div class="inner-content">
            <p class="b-pro-p">
              <img class="pro-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_wailing_face.png" alt="">
              一定要买<span class="blue-style">理财</span>，不买理财不给通过</p>
            <p class="b-pro-p">
              <img class="pro-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_wailing_face.png" alt="">
              资料不合格，要求补<span class="blue-style">各种资料</span></p>
            <p class="b-pro-p">
              <img class="pro-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_wailing_face.png" alt="">
              没满<span class="blue-style">30岁</span>，不给开户</p>
            <p class="b-pro-p">
              <img class="pro-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_wailing_face.png" alt="">
              没有<span class="blue-style">国内公司</span>，不给开户</p>
            <p class="b-pro-p">
              <img class="pro-p-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_wailing_face.png" alt="">
              拒绝开户，理由只有四个字 —— <span class="blue-style">行政原因</span></p>
          </div>
        </div>
        <div class="b-pro-inner">
          <div class="b-pro-inner-left">
            <img class="inner-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_1_2.png" alt="">
            <div class="inner-content">
              <p class="b-pro-p1">立即咨询专业客户经理寻找解决方法</p>
              <p class="b-pro-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="b-why">
      <p class="b-title">为什么会发生以上情况?</p>
      <p class="b-title1">因为银行也被罚了！细数近年银行被罚的案例</p>
      <div class="b-why-box">
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_1.png" alt="">
          </div>
          <p class="b-why-p1">摩根大通罚款26亿美元</p>
          <p class="b-why-p2">2014年1月，摩根大通同意支付总计26亿美元的赔款，以和解对其涉嫌在麦道夫“旁氏骗局”案中不作为的刑事和民事诉讼</p>
        </div>
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_2.png" alt="">
          </div>
          <p class="b-why-p1">瑞士信贷罚款25亿美元</p>
          <p class="b-why-p2">2014年5月瑞士信贷银行承认美国司法部对其帮助美国客户逃税的罪行指挥，并支付超过25亿美元的罚款，以此作为和解协议的一部分</p>
        </div>
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_3.png" alt="">
          </div>
          <p class="b-why-p1">汇丰罚款19.2亿美元</p>
          <p class="b-why-p2">2012年12月，汇丰疑似洗钱，向美国联邦及地方主管机关缴交19.2亿美元和解金</p>
        </div>
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_4.png" alt="">
          </div>
          <p class="b-why-p1">德意志罚款6.3亿美元</p>
          <p class="b-why-p2">2017年2月，因对涉嫌洗钱的交易监管不力导致大约100亿美元流出俄罗斯，德意志银行受到美国和英国金融监管机构罚款，金额共计约6.3亿美元</p>
        </div>
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon icon4" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_5.png" alt="">
          </div>
          <p class="b-why-p1">渣打罚款6.27亿美元</p>
          <p class="b-why-p2">2012年底，渣打为伊朗与苏丹的客户，处理数千笔金融交易，缴交3.27亿美元和解金2014年中，渣打涉嫌反洗钱监控机制，罚款3亿美元</p>
        </div>
        <div class="b-why-inner">
          <div class="b-why-title">
            <img class="b-why-icon icon5" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_1_6.png" alt="">
          </div>
          <p class="b-why-p1">花旗罚款1.4亿美元</p>
          <p class="b-why-p2">2015年7月，花旗集团子公司Banamax USA因在反洗钱作为上有缺失，被监管机构要求缴交1.4亿美元罚款</p>
        </div>
      </div>
    </div>
    <div class="b-account">
      <p class="b-title">快企务如何帮您解决开户问题</p>
      <p class="b-title1">银行开户六步曲</p>
      <div class="b-account-box1">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_1.png" alt="">
          <p class="b-acc-p">1、合作洽谈</p>
        </div>
        <img class="b-acc-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_arrow.png" alt="">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_2.png" alt="">
          <p class="b-acc-p">2、专家预审</p>
        </div>
        <img class="b-acc-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_arrow.png" alt="">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_3.png" alt="">
          <p class="b-acc-p">3、资料补充</p>
        </div>
        <img class="b-acc-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_arrow.png" alt="">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_4.png" alt="">
          <p class="b-acc-p">4、银行复审</p>
        </div>
        <img class="b-acc-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_arrow.png" alt="">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_5.png" alt="">
          <p class="b-acc-p">5、预约开户</p>
        </div>
        <img class="b-acc-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_arrow.png" alt="">
        <div class="b-acc-inner1">
          <img class="b-acc-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_1_6.png" alt="">
          <p class="b-acc-p">6、开户成功</p>
        </div>
      </div>
      <div class="b-account-box2">
        <div class="b-acc-left">
          <img class="b-acc-img1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_2_1.png" alt="">
          <p class="b-acc-p1">公司户</p>
          <p class="b-acc-p2">提前审核材料，介绍适合的银行和方案</p>
          <p class="b-acc-p2">VIP通道快速预约开户，解决排期久的问题</p>
          <p class="b-acc-p2">提前审核资料是否齐全，确保面签一次通过</p>
          <p class="b-acc-p2">开户前后有保障，不成功全额退还费用</p>
          <p class="b-acc-btn" @click="Dialog = true">预约开户</p>
        </div>
        <div class="b-acc-left">
          <img class="b-acc-img1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_2_2.png" alt="">
          <p class="b-acc-p1">个人户</p>
          <p class="b-acc-p2">无需购买理财，无需购买保险</p>
          <p class="b-acc-p2">仅需身份证及港澳通行证，无需地址证明</p>
          <p class="b-acc-p2">最快当天可安排开户，当天拿账户</p>
          <p class="b-acc-p2">开户前后有保障，不成功全额退还费用</p>
          <p class="b-acc-btn" @click="Dialog = true">预约开户</p>
        </div>
      </div>
    </div>
    <div class="bank-advantage">
      <p class="b-title">快企务开户优势</p>
      <p class="b-title1">四大优势助您开户无忧</p>
      <div class="b-adv-box">
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_2_1.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">专家预审</p>
            <p class="bank-adv-p2">专人全程指导，银行面签前口述辅导，巧妙避开银行开户的痛点和难点</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_2_2.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">快速开户</p>
            <p class="bank-adv-p2">与各大银行亲密合作，通过VIP通道快速预约开户，破解开户排期难题</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_2_3.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">品牌保障</p>
            <p class="bank-adv-p2">拥有12年行业经验，在业界口碑好，品质和品牌均有保证</p>
          </div>
        </div>
        <div class="bank-adv-inner">
          <img class="bank-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_2_4.png" alt="">
          <div class="bank-adv-right">
            <p class="bank-adv-p1">不成功退款</p>
            <p class="bank-adv-p2">诚信保障，各大银行开户确保成功，不成功则退款</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bank-channel">
      <p class="b-title">快企务合作银行内部通道</p>
      <p class="b-title1">VIP快速通道让您开户快人一步</p>
      <div class="bank-cha-box">
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_1.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_2.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_3.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_4.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_5.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_6.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_7.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_8.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon low-height" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_9.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pufa.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_11.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_12.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_13.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_14.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_15.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_16.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_17.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_18.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_19.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
        <div class="bank-cha-inner">
          <img class="bank-cha-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/logo_2_20.png" alt="">
          <p class="bank-cha-p" @click="Dialog = true">立即预约 ></p>
        </div>
      </div>
    </div>
    <div class="bank-guide">
      <p class="b-title">境外账户维护指导</p>
      <p class="b-title1">我们的服务不止于开户</p>
      <div class="bank-guide-box">
        <div class="bank-guide-left">
          <p class="bank-guide-p1">保持账户活跃</p>
          <p class="bank-guide-p2">及时激活，并进行存款；每月登陆1-2次网银，近三个月内进行3-5次交易，保持账户资金满足各银行日均余额要求，避免不使用账户或长时间不发生业务往来</p>
          <p class="bank-guide-p1">及时回复银行信息</p>
          <p class="bank-guide-p2">确保预留给银行的通讯信息真实有效，若收到银行发生的文件信息务必及时回复</p>
          <p class="bank-guide-p1">确保交易安全性</p>
          <p class="bank-guide-p2">避免与敏感、高危地区进行交易，尽量采取对公汇款，减少个人交易，避免不合理的大笔资金进出账户</p>
          <p class="bank-guide-p1">合规运营依法维护</p>
          <p class="bank-guide-p2">境外公司合规运营，正常做账报税，妥善保管相关业务资料、账册，以备银行不定期抽查</p>
        </div>
        <div class="bank-guide-right">
          <img class="bank-guide-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_3.png" alt="">
          <div class="guide-content">
            <p class="guide-right-p1">合规运营依法维护</p>
            <p class="guide-right-p2">保持账户活跃</p>
            <p class="guide-right-p3">及时回复银行信息</p>
            <p class="guide-right-p4">确保交易安全性</p>
          </div>
        </div>
      </div>
    </div>
    <div class="bank-case">
      <p class="b-title">开户成功案例</p>
      <!-- <p class="bank-case-p">
        <img class="bank-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_notice.png" alt="">
        <span class="bank-case-span">成功办理中国香港银行开户</span>
        <span class="bank-case-time">2020-08-04 09:45 </span>
        <img class="bank-case-icon margin-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/icon_notice.png" alt="">
        <span class="bank-case-span">成功办理中国香港银行开户</span>
        <span class="bank-case-time">2020-08-04 09:45 </span>
      </p> -->
      <div class="bank-case-box">
        <img class="bank-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_4_1.jpg" alt="" />
        <img class="bank-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_4_2.jpg" alt="" />
        <img class="bank-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_4_3.jpg" alt="" />
        <img class="bank-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/pic_4_4.jpg" alt="" />
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bank-page',
  components: {
    Footer,
    Link
  },
  data(){
    return{
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
.bank-header{
  width: 100%;
  height: 600px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/bank/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
