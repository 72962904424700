<template>
  <div class="certification mark">
    <div class="certification-header">
      <div class="certification-inner center-width">
        <p class="certification-p2" style="padding-top: 124px">ODI企业境外投资备案一站式服务</p>
        <p class="certification-p3" >让您的资金进出境合法合规，为企业走出去保驾护航</p>
        <p class="certification-p4">新设海外子公司丨海外并购/参股丨资金合法出入境</p>
        <p class="certification-btn" style="background: rgba(47, 114, 235, 1);" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="odi-intro">
      <p class="b-title">ODI企业境外投资备案介绍</p>
      <p class="b-title1">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="odi-intro-box">
        <div class="odi-intro-left">
          <p class="odi-intro-p1">企业境外投资证书，简称“ODI境外投资备案"，根据《企业境外投资投资管理办法》规定，凡是内地企业想要到中国香港澳门或者国外地区进行新设子公司、并购/参股等投资行为，都必须办理ODI境外投资备案，否则就是违规操作，银行将限制其境内境外公司的资金进出入境，这就是为什么很多企业到银行转账出入境时，会被银行告知要先办理ODI境外投资备案的原因。<br />
            自2016年11月开始，ODI企业境外投资备案相关审批部门要求企业提供前期工作落实情况说明包括：尽职调查、可行性研究报告、投资资金来源情况说明、投资环境分析评价等，我司充分理解相关审批部门的着重关注点及不同阶段审批过程的不同政策倾向，同时具备编写境外投资项目备案材料的丰富经验，我们有能力向我们的客户提供一站式全流程办理服务，办理完成ODI境外投资备案后，客户即可直接把投资款打到境外，境外公司后期盈利了，也可以通过ODI直接结汇回来。</p>
          <p class="odi-intro-btn" @click="Dialog = true">立即咨询</p>  
        </div>
        <div class="odi-intro-right">
          <img class="odi-intro-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_1.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="mark-program" style="background: rgba(242, 245, 250, 1);height: 548px">
      <p class="b-title">哪些企业需要办理ODI企业境外投资备案</p>
      <p class="b-title1">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="mark-program-box center-width flex-between" style="margin-top: 30px">
        <div class="mark-program-inner inner1">
          <!-- <div class="mark-program-title"></div> -->
          <p class="mark-program-p" style="padding-top: 60px;">内地企业到港澳或国外地区设立子公司、并购/参股海外公司</p>
        </div>
        <div class="mark-program-inner inner2">
          <!-- <div class="mark-program-title title2"></div> -->
          <p class="mark-program-p" style="padding-top: 60px;">想要把投资款打出境外或者境外公司盈利了，想要将盈利资金结汇回流境内</p>
        </div>
        <div class="mark-program-inner inner3">
          <!-- <div class="mark-program-title title3"></div> -->
          <p class="mark-program-p" style="padding-top: 60px;">外商投资企业开设资本金账户，股权涉及境内自然人或境内企业</p>
        </div>
        <div class="mark-program-inner inner4">
          <!-- <div class="mark-program-title title4"></div> -->
          <p class="mark-program-p" style="padding-top: 60px;">企业开设NRA账户，股权穿透涉及境内自然人或境内企业</p>
        </div>
        <div class="mark-program-inner inner5">
          <!-- <div class="mark-program-title title5"></div> -->
          <p class="mark-program-p" style="padding-top: 60px;">企业境外上市合规需求以及境外成立股权激励平台</p>
        </div>
      </div>
    </div>
    <!-- <div class="introduction" style="height: 935px">
      <p class="b-title">商务部管理办法</p>
      <p class="b-title1">以下内容来源于中华人民共和国商务部</p>
      <p class="odi-title2">商务部和省级商务部主管部门按照企业境外投资的不同情形，分别实行备案和核准管理</p>
      <p class="odi-title3">企业境外投资涉及敏感国家和地区、敏感行业的，实行核准管理。核准管理向<span class="blue-style">国家商务部</span>
        提出申请；企业其他情形的境外投资，实行备案管理，备案向<span class="blue-style">地方商务部</span>提出申请。</p>
      <div class="introduction-box">
        <img class="introduction-img" style="height: auto"  src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/pic_1.png" alt="">
      </div>
    </div> -->
    
    <div class="odi-serve">
      <p class="b-title">境外投资备案全程服务</p>
      <p class="b-title1">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="odi-serve-box center-width flex-between">
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_1.png" alt="">
          <p class="odi-serve-p">新设类ODI境外投资备案</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_2.png" alt="">
          <p class="odi-serve-p">并购类ODI境外投资备案</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_3.png" alt="">
          <p class="odi-serve-p">境外投资再投资或增资备案</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_4.png" alt="">
          <p class="odi-serve-p">境外投资变更备案</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_5.png" alt="">
          <p class="odi-serve-p">ODI境外投资备案补办</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_6.png" alt="">
          <p class="odi-serve-p">37号文个人境外投资备案</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_7.png" alt="">
          <p class="odi-serve-p">海外公司注册</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="odi-serve-inner">
          <img class="odi-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_3_8.png" alt="">
          <p class="odi-serve-p">离岸账户开户</p>
          <div class="hover-box">
            <p class="hover-p" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="odi-serve1">
      <p class="b-title">ODI境外投资备案流程</p>
      <p class="b-title1">为企业提供专业的ODI企业境外投资备案服务，精准、高效地为您的企业提供帮助</p>
      <div class="odi-serve1-box center-width flex-between">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_2_1.png" alt="">
            <p class="odi-serve1-title-p">撰写说明书</p>
          </div>
          <div class="odi-serve1-bottom">
            <p class="odi-serve1-bottom-p">说明书包含内容：尽职调查报告、可行性研究报告、项目行业分析、投资地投资分析、项目方案、境内组织架构</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_2_2.png" alt="">
            <p class="odi-serve1-title-p">提交商务部</p>
          </div>
          <div class="odi-serve1-bottom">
            <p class="odi-serve1-bottom-p">整理商务部盖章资料,提交商务部网上信息,领取回执</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_2_3.png" alt="">
            <p class="odi-serve1-title-p">提交发改委</p>
          </div>
          <div class="odi-serve1-bottom">
            <p class="odi-serve1-bottom-p">整理发改委盖章资料,提交发改委网上信息,领取回执</p>
          </div>
        </div>
        <img class="odi-serve1-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_arrow.png" alt="">
        <div class="odi-serve1-inner">
          <div class="odi-serve1-title">
            <img class="odi-serve1-title-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_2_4.png" alt="">
            <p class="odi-serve1-title-p">银行外汇咨询</p>
          </div>
          <div class="odi-serve1-bottom">
            <p class="odi-serve1-bottom-p">银行外汇业务咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="odi-file" style="height: 750px">
      <p class="b-title">所需提供资料</p>
      <p class="b-title1">申请部门与所需资料</p>
      <div class="odi-file-box flex-between">
        <div class="odi-file-inner" style="height: 467px">
          <p class="odi-file-title">申请部门</p>
          <div class="odi-file-bottom">
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right.png" alt="">
              市商务部
            </p>
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right.png" alt="">
              发改委(深圳发展和改革委员会)
            </p>
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right.png" alt="">
              银行开户行
            </p>
          </div>
        </div>
        <div class="odi-file-inner" style="height: 467px">
          <p class="odi-file-title odi-file-title1">所需材料</p>
          <div class="odi-file-bottom" style="margin-top: 20px">
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right_1.png" alt="">
              境内企业审计报告
            </p>
            <p class="odi-file-bottom-p5">审计报告必须盈利，所有者权益接近投资额（深圳企业，报告必须备案）</p>
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right_1.png" alt="">
              境内企业资信证明
            </p>
            <p class="odi-file-bottom-p5">内容为存款证明，证明上余额必须大于投资额</p>
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right_1.png" alt="">
              境外企业名字与章程
            </p>
            <p class="odi-file-bottom-p5">未注册前必须核名，避免0DI办理完成，名称无法注册章程必须提供中外文版的</p>
            <p class="odi-file-bottom-p">
              <img class="odi-file-bottom-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_right_1.png" alt="">
              项目信息
            </p>
            <p class="odi-file-bottom-p5">需客户提供境外企业项目开展业务内容、费用预计支出，由我司完善撰写说明书</p>
          </div>
        </div>
      </div>
    </div>
    <div class="odi-type">
      <p class="b-title">涉及如下境外投资备案类型很难通过</p>
      <p class="b-title1">无法判断?咨询顾问为您优化分析</p>
      <div class="odi-type-box flex-between center-width">
        <div class="odi-type-inner">
          <img class="odi-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_1_1.png" alt="">
          <p class="odi-type-p">合伙企业<br />母小子大、快设快出</p>
        </div>
        <div class="odi-type-inner">
          <img class="odi-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_1_2.png" alt="">
          <p class="odi-type-p">资金来源<br />合伙人背景说不清</p>
        </div>
        <div class="odi-type-inner">
          <img class="odi-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_1_3.png" alt="">
          <p class="odi-type-p">境外投资项目<br />无法说明真实性</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">境外投资备案常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="国内主体公司不满1年可以对外投资吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">可以，但备案金额受限</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="国内主体公司股东有金融公司或外资公司也能做境外投资吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">国内主体公司股东有金融公司或外资公司也能做境外投资吗？</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="境外主体股东出现自然人可以吗？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">可以，但自然人不能是中国人或曾经是中国人</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="先注册国外子公司还是先办理境外投资证书？程序有要求吗？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">可同时进行，没具体要求</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="对外投资办下来后续还要做什么？" name="5">
            <div class="inner-pro">
              <div class="pro-p2">需要每年在商务部，发改委做年审，如有资金汇出，要做月报。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="odi-add">
      <p class="b-title">增值服务</p>
      <p class="b-title1">我们还为您提供</p>
      <div class="odi-add-box center-width flex-between">
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_1.png" alt="">
          <p class="odi-add-p">境外公司注册</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_2.png" alt="">
          <p class="odi-add-p">境外银行开户</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_3.png" alt="">
          <p class="odi-add-p">IPO业务咨询</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_4.png" alt="">
          <p class="odi-add-p">税务筹划</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_5.png" alt="">
          <p class="odi-add-p">VAT税号的<br />申请和申报</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_6.png" alt="">
          <p class="odi-add-p">全球商标申请</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_7.png" alt="">
          <p class="odi-add-p">中国香港及境外公证</p>
        </div>
        <div class="odi-add-inner">
          <img class="odi-add-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/icon_3_8.png" alt="">
          <p class="odi-add-p">代持和法律<br />意见书等服务</p>
        </div>
      </div>
    </div>
    <div class="odi-form">
      <p class="odi-form-title">十余年年代办经验</p>
      <p class="odi-form-title1">多一份参考总有益处</p>
      <p class="odi-form-tips">为您定制专属的《筹划方案》及报价</p>
      <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
        <div class="odi-form-line">
          <el-form-item label="" prop="name">
            <el-input class="line-input" v-model="form.name" placeholder="请输入您的称呼"></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <el-input class="line-input" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
          </el-form-item>
          <el-form-item label="" >
            <p class="odi-form-btn" @click="toSubmit">立即咨询</p>
          </el-form-item>
        </div>
      </el-form>
      <p class="odi-form-tips2">咨询相关问题或预约面谈，您还可以通过以下方式与我们联系 <span class="big-style">400 860 5600</span></p>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      Dialog: false,
      form: {},
      activeName: '1',
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}]
      }
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 8,
            name: that.form.name,
            mobile: that.form.mobile
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .certification-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program .mark-program-box .inner1{
    height: 260px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_2_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program .mark-program-box .inner2{
    height: 260px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_2_2.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program .mark-program-box .inner3{
    height: 260px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_2_3.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program .mark-program-box .inner4{
    height: 260px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_2_4.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program .mark-program-box .inner5{
    height: 260px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/odi/else/pic_2_5.png') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
