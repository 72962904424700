<template>
  <div class="anquila korea">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式韩国公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="korea-advantage">
      <p class="re-title">注册韩国公司优势</p>
      <div class="korea-adv-box center-width flex-between">
        <div class="korea-adv-inner">
          <p class="k-number">01</p>
          <p class="k-adv-p">国际IT强国，世界最高的宽带网入网率，高达68%的世界最高水平的移动电话普及率。</p>
        </div>
        <div class="korea-adv-inner">
          <p class="k-number">02</p>
          <p class="k-adv-p">韩国政府利好的外商投资政策：外商出资形式除了现金、机械设备，还可包括持有的知识产权、以及不动产、股份等；外商符合一定条件，可进行税收减免；对外商购买或租赁公有土地提供支持。</p>
        </div>
        <div class="korea-adv-inner">
          <p class="k-number">03</p>
          <p class="k-adv-p">亚洲第三大经济中心，绝佳的地理位置，韩国首都首尔占据东京、北京、中国香港等东南亚主要城市的行程均在3个小时以内，方便企业管理。</p>
        </div>
        <div class="korea-adv-inner">
          <p class="k-number">04</p>
          <p class="k-adv-p">亚太地区收益中心，外资企业的销售额的净利润远高于国内企业。</p>
        </div>
      </div>
    </div>
    <div class="korea-type">
      <p class="re-title">注册韩国公司类型</p>
      <div class="korea-type-box center-width flex-between">
        <div class="korea-type-inner">
          <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_1_1.png" alt="">
          <p class="korea-type-p">株式会社（股份公司）</p>
          <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <div class="korea-type-inner">
          <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_1_2.png" alt="">
          <p class="korea-type-p">有限会社</p>
          <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
    </div>
    <div class="korea-file">
      <p class="re-title">注册韩国公司条件/流程/资料</p>
      <div class="korea-file-box center-width">
        <div class="korea-file-tab">
          <p class="korea-file-tab-p" :class="{'active': tab == 1}" @click="tab = 1">注册韩国公司条件</p>
          <p class="korea-file-tab-p" :class="{'active': tab == 2}" @click="tab = 2">注册韩国公司流程</p>
          <p class="korea-file-tab-p" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="korea-file-content" v-if="tab == 1">
          <div class="korea-file1-box">
            <div class="korea-file1-left korea-file1-has-hover">
              <img class="korea-file1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_2_1.jpg" alt="">
              <p class="korea-file1-title">公司成员人数要求</p>
              <div class="hover-box">
                <p class="korea-file1-p1">公司成员人数要求</p>
                <p class="korea-file1-p2">成立株式会社（股份公司）</p>
                <p class="korea-file1-p2">1、如果股东和法人是同一人，则需要另设一名监事；</p>
                <p class="korea-file1-p2">2、股东和法人不是同一人，不需要另设监事；</p>
                <p class="korea-file1-p2">3、股东是公司，只需要一名法人，不需要监事；</p>
              </div>
            </div>
            <div class="korea-file1-right">
              <div class="korea-file1-top">
                <div class="korea-file1-top-inner korea-file1-has-hover">
                  <img class="korea-file1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_2_2.jpg" alt="">
                  <p class="korea-file1-title">注册资金</p>
                  <div class="hover-box">
                    <p class="korea-file1-p1">注册资金</p>
                    <p class="korea-file1-p2">一亿韩元，相当于60万人民币</p>
                  </div>
                </div>
                <div class="korea-file1-top-inner inner2 korea-file1-has-hover">
                  <img class="korea-file1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_2_3.jpg" alt="">
                  <p class="korea-file1-title">公司名称</p>
                  <div class="hover-box">
                    <p class="korea-file1-p1">公司名称</p>
                    <p class="korea-file1-p2">中文或英文名称，韩国营业执照上显示韩文名称</p>
                  </div>
                </div>
              </div>
              <div class="korea-file1-bottom korea-file1-has-hover">
                <img class="korea-file1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_2_4.jpg" alt="">
                <p class="korea-file1-title">注册地址</p>
                <div class="hover-box">
                    <p class="korea-file1-p1">注册地址</p>
                    <p class="korea-file1-p2">须有在韩国的实际地址</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="korea-file-content" v-if="tab == 2">
          <img class="korea-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic.png" alt="">
        </div>
        <div class="korea-file-content" v-if="tab == 3">
          <!-- <div class="korea-file3-box">
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_1.png" alt="">
              <p class="korea-file3-p">营业执照</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_2.png" alt="">
              <p class="korea-file3-p">法人登记簿</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_3.png" alt="">
              <p class="korea-file3-p">印鉴证明</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_4.png" alt="">
              <p class="korea-file3-p">公司卡</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_5.png" alt="">
              <p class="korea-file3-p">法人公章</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_6.png" alt="">
              <p class="korea-file3-p">公司章程</p>
            </div>
            <div class="korea-file3-inner">
              <img class="korea-file3-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_2_7.png" alt="">
              <p class="korea-file3-p">股东名册</p>
            </div>
          </div>-->
          <img class="korea-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/pic_2.png" alt="">
        </div> 
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册韩国公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p2">注册即有税号，但如果需要做电商，则需要申请VAT税号</p>
          <p class="sub-p4"></p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">一般可以在韩国本地开户或其他地区开设离岸帐户。开立韩国公司银行账户要求...</p>
          <p class="sub-p4">一般可以在韩国本地开户或其他地区开设离岸帐户。开立韩国公司银行账户要求公司董事亲临银行办理。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">每个季度提交增值税申报表，每月提交工资单和电子发票。根据各地不同要求，按...</p>
          <p class="sub-p4">每个季度提交增值税申报表，每月提交工资单和电子发票。根据各地不同要求，按单位主管税务局要求的期限进行申报，没发生税金的按时进行零申报。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p2">签署委托协议，递交政府审批。年审后可以拿到商业登记证和韩国公司年检证书</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/icon_3_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p3">韩国公司停止经营，需进行公司的解散申告，否则会对将来申请韩国签证，续...</p>
          <p class="sub-p4">韩国公司停止经营，需进行公司的解散申告，否则会对将来申请韩国签证，续签以及在韩国申请商业贷款等涉及个人信用的行为产生不利影响。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">韩国公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="能注册一人公司吗？股东可以是一人吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">在韩国是可以注册一人公司的，股东也可以是一个人。但建议大家，在注册时可以再设定一名非股东成员，<br />
                比如说理事或者监事。主要是设立法人程序中需调查报告人，只有非股东成员才能担任，因此设定这样一名<br />
                成员能大大降低设立法人的成本。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册资金方面有金额要求吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">目前，在韩国注册公司，注册资金方面是没有注册资金限制的，只要有100韩元即可设立法人。但需注意部分行<br />
                业依然存在注册资金的要求，所以在注册之前可先了解一下都有哪些行业有限制然后再注册。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="公司名称能用英文或符号吗？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">原则上在韩国注册公司是只能使用韩文的，另外，公司名称中必须包含“株式会社”。它可以任意放在公司名<br />
                称的前后。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业韩国公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Korea/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
