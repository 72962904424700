<template>
  <div class="mark">
    <div class="mark-header">
      <div class="mark-inner center-width">
        <p class="mark-p1" style="padding-top: 135px">美国商标注册</p>
      </div>
    </div>
    <div class="mark-trait" style="height: 716px">
      <p class="b-title">美国商标注册简介</p>
      <div class="mark-trait-box center-width">
        <div class="mark-trait-inner mark-trait-inner1">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 67px;">美国是一个实行判例法(即不成文法)的国家，因此法院判例在美国处理商标纠纷及商标法的发展中起到了较为重要的作用</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 30px;">美国是一个实行判例法(即不成文法)的国家，因此法院判例在美国处理商标纠纷及商标法的发展中起到了较为重要的作用</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 80px;">根据美国目前适用的商标注册规定，在美国可以注册商品商标、服务商标、集体商标和证明商标</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 68px;">美国采用商品和服务国际分类。商标注册分为主簿和副簿</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 46px;">美国参加的国际组织和国际公约有：世界知识产权组织、《保护工业产权巴黎公约》、《商标注册用商和服务国际分类尼斯协定》和《马德里协定议定书》</p>
        </div>
      </div>
    </div>
    <div class="mark-file">
      <p class="b-title">美国商标注册申请所需资料</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-left">
          <p class="mark-file-p">以法人申请，附《营业执照》或有效登记证明复印件加盖公章1份；以自然人申请附个人身份证明文件1份</p>
          <p class="mark-file-p">申请人的详细信息（中英文），包括姓名或名称，性质、国籍以及详细地址、邮编，联系方式</p>
          <p class="mark-file-p">电子版商标标样</p>
          <p class="mark-file-p">商品名称和类别</p>
          <p class="mark-file-p">申请人提供在商业中真实使用商标的声明（实际使用的）</p>
          <p class="mark-file-p">签署的委托书</p>
        </div>
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="mark-file mark-file1" style="height: 612px">
      <p class="b-title">美国商标的申请人资格</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-right" style="width: 472px;height: 362px">
          <img class="mark-file-img" style="width: 472px;height: 362px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/America/pic_1.png" alt="">
        </div>
        <div class="mark-file-left">
          <p class="mark-file-p">在美国商业中已使用商标的人可申请商标注册；</p>
          <p class="mark-file-p">根据1988年商标修改法令，真诚地打算在美国商业中使用商标的人也可以申请商标注册；</p>
          <p class="mark-file-p">巴黎公约成员国国民的商标如在其本国已注册，虽尚未在同美国的商务活动中使用，但已在他国使用或打算按1988年修改法令使用该商标的人可申请商标注册。外国申请人必须指定在美国的代理人代办商标事宜；</p>
        </div>
      </div>
    </div>
    <div class="mark-program" style="height: 614px">
      <p class="b-title">美国商标注册申请流程</p>
      <div class="mark-program-box center-width flex-between">
        <div class="mark-program-inner mark-program-inner1">
          <div class="mark-program-title"></div>
          <p class="mark-program-p">申请递交后对提交的申请文件、商标图样、委托书等文件进行的合法性审查；符合规定的，将授予申请日和申请号</p>
        </div>
        <div class="mark-program-inner mark-program-inner1">
          <div class="mark-program-title title2"></div>
          <p class="mark-program-p">根据法律审查商标是否具有可注册性、是否与在先注册的商标相同或近似、是否违背商标法的禁用条款。对于不通过实质审查的商标，审查官将书面通知申请人，并告知驳回理由。申请人在接到该驳回通知之日起限期内可提交复审，否则，该申请将被视为放弃，申请日和申请号均不予保留</p>
        </div>
        <div class="mark-program-inner mark-program-inner1">
          <div class="mark-program-title title3"></div>
          <p class="mark-program-p">经审查，审查官认为商标申请可以被接受后，便会在美国官方商标公告上刊登公告。自公告日起1个月为异议期</p>
        </div>
        <div class="mark-program-inner mark-program-inner1">
          <div class="mark-program-title title4"></div>
          <p class="mark-program-p">经过上述程序后，商标专利局颁发“允许通知”（anoticeofallowance），非注册证；颁发“允许通知”之后的6个月内，申请人必须提供在商业中真实使用商标的声明，或申请预留商标</p>
        </div>
        <div class="mark-program-inner mark-program-inner1">
          <div class="mark-program-title title5"></div>
          <p class="mark-program-p">商标专利局接到真实使用声明后，再次进行审查。如果经审查决定接受真实使用的声明，颁发注册证书。整个顺利的申请过程（如果没有驳回、异议等情况出现）大概需要20-24个月</p>
        </div>
      </div>
      <!-- <p class="mark-program-tips">
        <span class="span">商标检索：</span>
        由于欧盟商标注册涵盖的国家众多，导致潜在的商标异议人大量存在。因此，我司极力推荐申请人在提出欧洲商标注册申请前，进行商标检索，以确定是否有与拟注册商标相同或太相似的商标已被第三者注册，及避免不必要的损失
      </p> -->
    </div>
    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="美国商标申请人资格？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">在美国商业中已使用商标的人可申请商标注册。<br />
根据1988年商标修改法令，真诚地打算在美国商业中使用商标的人也可以申请商标注册。<br />
巴黎公约成员国国民的商标如在其本国已注册，虽尚没在同美国的商务活动中使用，但已在他国使用或打算按<br />
1988年修改法令使用该商标的人可申请商标注册。<br />
外国申请人必须指定在美国的代理人代办商标事宜。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="美国商标审查流程？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">意向使用：申请--受理--审查--公告--提交使用证据--注册--发证；<br />
实际使用：申请（提交使用证据）--受理--审查--公告--注册--发证。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="美国商标申请流程？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">1、递交申请文件。（受理通知时间约为1个月）<br />
2、形式审查与实质审查。（审查时间约为4-6个月）<br />
形式审查与实质审查同时进行，完成审查后美国专利商标局将下发官方审查意见（即驳回通知），该驳回通知<br />
的复审时间为6个月，申请人须在6个月内进行复审。<br />
3、公告。（公告时间为3个月）<br />
4、核准注册。公告期内若无任何第三人提出异议，或者异议裁定不成立的，该商标申请即被核准。<br />
5、提交使用证据。自注册之日起5-6年内，申请人必须提交相关使用证据以证明该商标仍在持续使用，否则该<br />
商标归于无效。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册后的使用要求？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">在美国,使用不是获得商标注册的惟一基础，但商标注册必须通过使用来维持。美国法律要求注册商标必须在商<br />
                业中使用，广告使用不被视为使用。商标注册后连续3年不使用，被视为初步放弃，商标注册可因未使用而被撤<br />
                销。<br />
另外,根据美国商标法规定，商标注册人在自商标注册之日起第5年至第6年间必须向美国专利商标局提交使用宜<br />
誓书，宜誓注册商标已在美国商业中使用于宣誓书中所述的商品或服务上，该商标注册才继续有效。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="mark-why">
      <p class="re-title">为什么选择我们</p>
      <div class="mark-why-box center-width flex-between">
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_1.png" alt="">
          <p class="mark-why-p1">快速</p>
          <p class="mark-why-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_2.png" alt="">
          <p class="mark-why-p1">专业</p>
          <p class="mark-why-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_3.png" alt="">
          <p class="mark-why-p1">严谨</p>
          <p class="mark-why-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_4.png" alt="">
          <p class="mark-why-p1">保障</p>
          <p class="mark-why-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .mark-header{
    width: 100%;
    height: 320px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/America/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program-title{
    width: 224px;
    height: 40px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_3.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .title2{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_4.png') no-repeat center center;
  }
  .title3{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_5.png') no-repeat center center;
  }
  .title4{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_6.png') no-repeat center center;
  }
  .title5{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_7.png') no-repeat center center;
  }
</style>
