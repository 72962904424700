<template>
    <div class="news">
      <div class="news-header"></div>
      <!-- <div class="news-top">
        <div class="news-top-left">
          <img class="news-top-left-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/news/pic_1.png" alt="">
        </div>
        <div class="news-top-right">
          <p class="news-top-p1">2022年快企务国庆节假日放假通知</p>
          <p class="news-top-p2">我司根据《国务院办公厅关于2022年部分节假日安排的通知》精神，并结合公司实际情况，现将2022年国庆节放假...</p>
          <p class="news-top-p3">09.30</p>
          <p class="news-top-p4">查看详情</p>
        </div>
      </div> -->
      <div>
        <div class="news-content">
          <div class="news-inner" v-for="(item,index) in list" :key="index">
            <img class="news-inner-img" :src="item.pic" alt="">
            <div class="news-inner-bottom">
              <p class="news-inner-p1">{{ item.title }}</p>
              <p class="news-inner-p2">{{ item.describes }}</p>
              <p class="news-inner-p3">{{item.createTime}}</p>
              <p class="news-inner-p4" @click="toDetail(item)">查看详情</p>
            </div>
          </div>
          
        </div>
        
        <div class="page">
          <!-- <p class="current">1</p>
          <p class="next"  @click="index = 2">2</p> -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
            >
          </el-pagination>
        </div>
      </div>
      
      
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer
    },
    props: {
      msg: String
    },
    
    data(){
      return{
        Dialog: false,
        index: 1,
        firstInfo:{},
        list: [],
        page: 1,
        total: 0
      }
    },
    mounted(){
      window.scrollTo(0,0);
      this.getList()
    },
    methods: {
      handleCurrentChange(val){
        console.log(`当前页: ${val}`);
        this.page = val
        this.getList()
      },
      getList(){
        let that = this
        that.$axios.get(`/news/front/list/page?page=${that.page}&limit=9`).then((res)=>{
          that.list = res.data.data
          that.total = Number(res.data.count)
          console.log(that.list)
        })
      },
      toDetail(item){
        this.$router.push({name:'newsDetail',query:{id: item.id}});
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .news-header{
      width: 100%;
      height: 400px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/news/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  