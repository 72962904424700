<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式马绍尔公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="m-advantage">
      <p class="re-title">注册马绍尔公司优势</p>
      <div class="m-adv-box">
        <img class="m-adv-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/pic_1.jpg" alt="">
        <div class="m-adv-right">
          <p class="m-ad-title">房地产投资</p>
          <p class="m-ad-p">资料绝对保密；资产转移极为容易；极佳的财产投资计划的工具</p>
          <p class="m-ad-title">合资企业</p>
          <p class="m-ad-p">马绍尔公司可由不同国籍人士共同经营，法律制度及公司法获国际认可</p>
          <p class="m-ad-title">上市公司</p>
          <p class="m-ad-p">马绍尔公司是集资的极佳工具，可用于主要证券交易所上市</p>
          <p class="m-ad-title">证券/银行账目</p>
          <p class="m-ad-p">马绍尔公司可以在美国证券市场上市，获主要金融市场认证</p>
          <p class="m-ad-title">马绍尔公司税收</p>
          <p class="m-ad-p">海外离岸马绍尔公司无需缴税，不受外汇规定管制</p>
        </div>
      </div>
    </div>
    <div class="m-type">
      <p class="re-title">注册马绍尔公司类型</p>
      <div class="m-type-box">
        <div class="m-type-inner">
          <img class="m-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/pic_1_1.png" alt="">
          <div class="m-type-bottom">
            <p class="m-type-p1">有限责任公司</p>
            <p class="m-type-p2">已设立法人，有公司名称但尚未经营业务的公司，可提供现成空壳公司名称确认进行查册。</p>
            <p class="m-type-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="m-type-inner">
          <img class="m-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/pic_1_2.png" alt="">
          <div class="m-type-bottom">
            <p class="m-type-p1">空壳公司</p>
            <p class="m-type-p2">只要一个人即可注册马绍尔公司，名称以"有限公司"结尾，如：LTD、CORP或INC.等。</p>
            <p class="m-type-p3" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
      </div>
    </div>
    <div class="m-conditions">
      <p class="re-title">注册马绍尔公司条件/流程/资料</p>
      <div class="m-con-box"> 
        <div class="m-con-tab">
          <p class="tab-title" :class="{'active': tab == 1}" @click="tab = 1"><span class="inner-span">注册马绍尔公司条件</span></p>
          <p class="tab-title" :class="{'active': tab == 2}" @click="tab = 2"><span class="inner-span">注册马绍尔公司流程</span></p>
          <p class="tab-title" :class="{'active': tab == 3}" @click="tab = 3"><span class="inner-span">注册完成后所得资料</span></p>
        </div>
        <div class="m-con-right">
          <div class="m-con-content" v-if="tab == 1">
            <p class="m-title">注册马绍尔公司条件</p>
            <div class="m-con-inner1">
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_1.png" alt="">
                <div class="content-right">
                  <p class="right-p1">公司名称</p>
                  <p class="right-p2">马绍尔公司名称必须以Limited、Corpora-tion、Incorporated、Societe Anonyme上述字眼之缩写作结束语</p>
                </div>
              </div>
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_2.png" alt="">
                <div class="content-right">
                  <p class="right-p1">董事</p>
                  <p class="right-p2">马绍尔群岛公司并无董事人数的规定，法人也可以出任董事</p>
                </div>
              </div>
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_3.png" alt="">
                <div class="content-right">
                  <p class="right-p1">股东</p>
                  <p class="right-p2">一位或以上的股东，无国籍要求，若多人股东，确定好股份比例</p>
                </div>
              </div>
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_4.png" alt="">
                <div class="content-right">
                  <p class="right-p1">注册资本</p>
                  <p class="right-p2">通常授权的股份资本为USD 50,000，不需验资，不需到位</p>
                </div>
              </div>
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_5.png" alt="">
                <div class="content-right">
                  <p class="right-p1">注册地址及秘书</p>
                  <p class="right-p2">注册地址及法定秘书可以是自然人或者法人担任，若需要可由快企务提供</p>
                </div>
              </div>
              <div class="inner-content1">
                <img class="icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_1_6.png" alt="">
                <div class="content-right">
                  <p class="right-p1">贸易限制</p>
                  <p class="right-p2">马绍尔国际商业公司不允许从事保险和银行业</p>
                </div>
              </div>
            </div>
          </div>
          <div class="m-con-content" v-if="tab == 2">
            <div class="con-inner1">
              <p class="m-title">注册完成后所得资料</p>
              <div class="m-con-inner2">
                <div class="inner-content2">
                  <img class="inner2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_2_1.png" alt="">
                  <p class="inner2-p">签署委托书</p>
                </div>
                <img class="inner2-line" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/line.png" alt="">
                <div class="inner-content2">
                  <img class="inner2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_2_2.png" alt="">
                  <p class="inner2-p">签署协议</p>
                </div>
                <img class="inner2-line" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/line.png" alt="">
                <div class="inner-content2">
                  <img class="inner2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_2_3.png" alt="">
                  <p class="inner2-p">交付金额</p>
                </div>
                <img class="inner2-line" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/line.png" alt="">
                <div class="inner-content2">
                  <img class="inner2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_2_4.png" alt="">
                  <p class="inner2-p">到政府部门办理相关手续</p>
                </div>
                <img class="inner2-line" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/line.png" alt="">
                <div class="inner-content2">
                  <img class="inner2-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_2_5.png" alt="">
                  <p class="inner2-p">注册完成领取资料</p>
                </div>
              </div>
            </div>
          </div>
          <div class="m-con-content" v-if="tab == 3">
            <p class="m-title">注册完成后所得资料</p>
            <div class="m-con-inner m-con-inner3">
              <div class="inner-content">
                <img class="inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_2_1.png" alt="">
                <div class="inner-content-left">
                  <p class="inner-p1">注册证书</p>
                  <p class="inner-p2">公司注册证书</p>
                </div>
              </div>
              <div class="inner-content">
                <img class="inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_2_2.png" alt="">
                <div class="inner-content-left">
                  <p class="inner-p1">公司章程</p>
                  <p class="inner-p2">3本，1正2核实副本</p>
                </div>
              </div>
              <div class="inner-content">
                <img class="inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_2_3.png" alt="">
                <div class="inner-content-left">
                  <p class="inner-p1">股份证明书</p>
                  <p class="inner-p2">股东/董事/秘书名册</p>
                </div>
              </div>
              <div class="inner-content">
                <img class="inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_2_4.png" alt="">
                <div class="inner-content-left">
                  <p class="inner-p1">公司章</p>
                  <p class="inner-p2">钢印/长方印/小圆印</p>
                </div>
              </div>
              <div class="inner-content">
                <img class="inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/else/icon_2_5.png" alt="">
                <div class="inner-content-left">
                  <p class="inner-p1">注册代理人证书</p>
                  <p class="inner-p2">INCUMBENCY</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b" style="height: 536px;">
      <p class="re-title">注册马绍尔公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_4_1renewal.png" alt="">
          <p class="sub-p1">续牌服务</p>
          <p class="sub-p2">无经营时无税金，可续牌，逾期不续牌或超时将被罚款或注销牌照，详情咨询顾问</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/icon_4_2.png" alt="">
          <p class="sub-p1">年度维护服务</p>
          <p class="sub-p2">提供公司注册地址使用及秘书服务</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">马绍尔公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="马绍尔公司名称有什么要求？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">公司名称不能与已注册公司的名称相同或太相似。公司必须向有关当局另行申请才可才用以下字样: bank,<br />
                chartered,establishment,foundation, insurance，partnership or trust 作为公司名称。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司是不需要缴税吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">1、公司不必就其环球收入或利润缴纳任何税项，目前马绍尔未与任何国家或地区签订任何双重税务条约<br />
                2、财政报表无须审核也不必提交予任何部门，但是公司仍然要编制足以反映其财务情况的财务报表；</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司优势？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">1、房地产投资、证券买卖、信托及财产投资;提供私隐保护，不需公开股东、董事身份;<br />
              2、以普通法为依据，紧贴市场脉搏，深受外商欢迎;商业运作方便，可以在美国证券市场上市:<br />
              3、无需申报受益者、年利润及财务状况;<br />
              4、无须呈递周年报表或经审计帐目，无须召开周年大会;<br />
              5、海外离岸马绍尔公司无需缴税和申报税务，不受外汇规定管制;最大限度地财产保护，资金转移便利;<br />
              6、马绍尔群岛也允许公司迁册进来或者迁册出去，若马绍尔群岛公司并非由原居民成立，则可合法免除一切税项;</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="马绍尔公司的股东是否有国籍限制？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">股东和董事最少一个，既可以是自然人，也可以是法人，没有国籍上的限制。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业马绍尔公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      activeName: '1',
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .m-con-tab{
    .active{
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Marshall/pic_2.png') no-repeat center center;
      background-size: 100% 100%;
      color: #fff;
      .inner-span{
        border-left: 4px solid #fff;
      }
    }
  }
  
</style>
