<template>
  <div class="fiscal">
    <div class="fiscal-header">
      <div class="header-box">
        <p class="fiscal-p1">十余年专业财税筹划</p>
        <p class="fiscal-p2">中国香港专业会计师团队<span class="blue-style"> 1对1 </span>为您服务</p>
        <p class="fiscal-p3">正规审计报告   解决税务难题</p>
        <p class="bank-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="f-impact">
      <p class="b-title">在中国香港成立公司不做账报税有什么影响</p>
      <p class="b-title1">情节严重最高监禁6个月</p>
      <div class="f-impact-box">
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_1.png" alt="">
          <p class="f-impact-p">一次超期罚款1200</p>
        </div>
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_2.png" alt="">
          <p class="f-impact-p">二次超期罚款3000起</p>
        </div>
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_3.png" alt="">
          <p class="f-impact-p">收到法院传票</p>
        </div>
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_4.png" alt="">
          <p class="f-impact-p">董事会列入黑名单最高监禁6个月</p>
        </div>
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_5.png" alt="">
          <p class="f-impact-p">公司被强制注销</p>
        </div>
        <div class="f-impact-inner">
          <img class="f-impact-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/logo_1_6.png" alt="">
          <p class="f-impact-p">银行账户强制扣税款/冻结</p>
        </div>
      </div>
    </div>
    <div class="f-which">
      <p class="b-title">哪些中国香港公司必须做账审计</p>
      <p class="b-title1">只要您以公司名义进行商业上的交易或活动， 您已经开始经营业务了。可以从以下几个方面来判断是否已经营业务，符合其中一方面的即为有经营业务,只要以公司名义进行商业上的交易或活动，贵司就需要做账审计</p>
      <div class="f-which-box">
        <div class="f-which-left">
          <p class="f-which-p f-which-p1">银行账户已经留下经营记录</p>
          <p class="f-which-p f-which-p2">政府海关、物流公司已经留下进出口记录</p>
          <p class="f-which-p f-which-p3">与中国香港客商发生购销头条</p>
          <p class="f-which-p f-which-p4">在中国香港已经聘请雇员</p>
          <p class="f-which-p f-which-p5">容许或授权在中国香港使用专利商标设计等资料</p>
          <p class="f-which-p f-which-p6">容许或授权在中国香港使用动产收取租金，租赁费等款项</p>
          <p class="f-which-p f-which-p7">委托在中国香港代销</p>
          <p class="f-which-p f-which-p8">其它得自或产生于中国香港的利润</p>
          <p class="f-which-btn" @click="Dialog = true">立即咨询</p>
        </div>
        <div class="f-which-right">
          <img class="f-which-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_1.png" alt="">
          <p class="f-right-p10">做账审计</p>
          <p class="f-right-p1">其它得自或产...</p>
          <p class="f-right-p2">银行账户已经...</p>
          <p class="f-right-p3">政府海关、物...</p>
          <p class="f-right-p4">与中国香港客商发...</p>
          <p class="f-right-p5">容许或授权在...</p>
          <p class="f-right-p6">委托在中国香港代销</p>
          <p class="f-right-p7">容许或授权在...</p>
          <p class="f-right-p8">在中国香港已经聘...</p>
          <p class="f-right-p9" @click="Dialog = true">咨询顾问<br />了解详情</p>
        </div>
      </div>
    </div>
    <div class="f-cost">
      <p class="b-title" style="color: #fff;">中国香港公司报税费用</p>
      <p class="b-title1" style="color: rgba(214, 231, 255, 1);">资深顾问为您估算价格</p>
      <div class="f-cost-box">
        <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
          <div class="f-cost-line">
            <el-form-item label="" prop="type">
              <el-select class="g-sub-select" v-model="form.type" placeholder="中国香港公司报税类型">
                <el-option label="零申报" value="零申报"></el-option>
                <el-option label="实报税" value="实报税"></el-option>
                <el-option label="出审计报告" value="出审计报告"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="received">
              <el-select class="g-sub-select" v-model="form.received" placeholder="中国香港公司是否收到税表">
                <el-option label="是" value="是"></el-option>
                <el-option label="否" value="否"></el-option>
                <el-option label="不清楚" value="不清楚"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="money">
              <el-select class="g-sub-select" v-model="form.money" placeholder="银行账户进账多少金额">
                <el-option label="50万以下" value="50万以下"></el-option>
                <el-option label="50-200万" value="50-200万"></el-option>
                <el-option label="200-1000万" value="200-1000万"></el-option>
                <el-option label="1000万以上" value="1000万以上"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="f-cost-line">
            <el-form-item label="" prop="name">
              <el-input class="line-input" style="width: 400px" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-input class="line-input" style="width: 400px" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
            </el-form-item>
          </div>
        </el-form>
        <div class="f-cost-line">
          <p class="f-cost-btn" @click="toSubmit">计算费用</p>
        </div>
      </div>
    </div>
    <div class="f-crs">
      <p class="b-title">如何规避中国香港银行调查和CRS税务信息影响</p>
      <div class="f-crs-box">
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_3_1.png" alt="">
          <p class="f-crs-p">选择在中国香港进行税务申报的优势</p>
        </div>
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_3_2.png" alt="">
          <p class="f-crs-p">选择正规的中国香港会计师行做账审计</p>
        </div>
        <div class="f-crs-inner">
          <img class="f-crs-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_3_3.png" alt="">
          <p class="f-crs-p">出具正规的审计报告让税务局无处挑剔</p>
        </div>
      </div>
    </div>
    <div class="f-guide">
      <p class="b-title">财税筹划专业指导</p>
      <p class="b-title1">财税筹划中常见的专业问题</p>
      <div class="f-guide-box">
        <div class="f-guide-inner">
          <p class="number2">1</p>
          <div class="f-guide-right">
            <p class="f-guide-p1">中国香港公司什么情况下无需纳税？</p>
            <p class="f-guide-p2">中国香港公司是根据利润来源地原则征收税收，如果利润不来源于中国香港，可以不用交税。中国香港税务局一般都要求纳税人提供足够之有关文件以证明上述问题之真实性，以确定纳税人所得利润之来源</p>
          </div>
        </div>
        <div class="f-guide-inner">
          <p class="number2">2</p>
          <div class="f-guide-right">
            <p class="f-guide-p1">中国香港公司成立后何时需要报税？</p>
            <p class="f-guide-p2">每一家中国香港公司约成立18个月后，会收到中国香港税务局发出的利得税报税表，中国香港税务局发放税表的时间是每年的四月份，此税表不是每家公司每年都有，是税务局以抽查的形式下发</p>
          </div>
        </div>
        <div class="f-guide-inner inner-bottom">
          <p class="number2">3</p>
          <div class="f-guide-right">
            <p class="f-guide-p1">中国香港公司成立后何时需要报税？</p>
            <p class="f-guide-p2">如您收到中国香港税表，必须交给中国香港会计师，根据公司银行资金往来情况评估如何报税， CRS新政策下，凡有中国香港银行账户企业，简单零申报将面临巨大的风险:<br />
              <span class="blue-style">★</span> 申报是法律规定的义务，收到税表就必须申报，否则将会收到法院传票和被冻结账户<br />
              <span class="blue-style">★</span>  缴税多少是由会计师根据公司情况决定，盈利额做多少，或者直接做亏损
            </p>
          </div>
        </div>
        <div class="f-guide-inner inner-bottom">
          <p class="number2">4</p>
          <div class="f-guide-right">
            <p class="f-guide-p1">中国香港公司成立后何时需要报税？</p>
            <p class="f-guide-p2">在中国香港设立的公司可依据自己的结账日期，申请延期提交报税表，一般可通过以下两种方式进行:<br />
              <span class="blue-style">★</span>  申请整批延期，如果中国香港公司业务每年结账日在11月30日后，而且税务代表在整批延期计划下，那么在办妥相关的延交手续后，提交报税表的期限可按结账日期的不同类别延至不同的时间<br />
              <span class="blue-style">★</span>   自行书面申请，除整批延期计划以外，也可以自行用书面方式申请延交。在申请时需说明未能及时提交的原因，并附上所有的证明文件
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="f-process">
      <p class="b-title">记帐/核数/报税流程</p>
      <p class="b-title1">全程跟进</p>
      <div class="f-process-box">
        <img class="f-process-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_4.png" alt="">
        <p class="f-process-p process-p1">评估、报价、商洽后做报账协议</p>
        <p class="f-process-p process-p2">预交款项</p>
        <p class="f-process-p process-p3">整理单据税务处理</p>
        <p class="f-process-p process-p4">做账完毕</p>
        <p class="f-process-p process-p5">付尾款</p>
        <p class="f-process-p process-p6">核数师审计</p>
        <p class="f-process-p process-p7">审计完成</p>
        <p class="f-process-p process-p8">股东签署审计报告</p>
        <p class="f-process-p process-p9">会计师持审计报告证报税</p>
        <p class="f-process-p process-p10">相关文件返还客户</p>
      </div>
    </div>
    <div class="f-audit">
      <p class="b-title">一站式专业审计服务</p>
      <p class="b-title1">我们为您提供</p>
      <div class="f-audit-box">
        <el-carousel :interval="5000" arrow="always" height="270px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="f-audit-box1">
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_1.png" alt="">
                <p class="f-audit-p1">做账报税</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_2.png" alt="">
                <p class="f-audit-p1">税务筹划</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="f-audit-box1">
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_3.png" alt="">
                <p class="f-audit-p1">审计报告</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
              <div class="f-audit-inner">
                <img class="f-audit-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/pic_5_4.png" alt="">
                <p class="f-audit-p1">税务指南</p>
                <p class="f-audit-p2" @click="Dialog = true">立即咨询 ></p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'fiscalTax-page',
  components: {
    Footer,
    Link
  },
  data(){
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号码格式不正确'))
          }
        }
      }, 100)
    }
    return{
      form: {},
      rules: {
        name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
        type: [{ required: true, message: '中国香港公司报税类型', trigger: 'change' }],
        received: [{ required: true, message: '中国香港公司是否收到税表', trigger: 'change' }],
        money: [{ required: true, message: '银行账户进账多少金额', trigger: 'change' }]
      },
      options: [],
      value: '',
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    toSubmit(){
      let that = this
      that.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            type: 3,
            name: that.form.name,
            mobile: that.form.mobile,
            taxType: that.form.type,
            received: that.form.received,
            bankAccountReceipt: that.form.money,
            country: '中国香港'
          }
          that.$axios.post('/cms/message/save',params).then((result)=>{
            this.$notify({
              title: '提交成功',
              message: '我们会尽快联系您！',
              type: 'success',
              offset: 100,
              duration: 2000
            });
            that.form.name = ''
            that.form.mobile = ''
            that.form.type = null
            that.form.received = null
            that.form.money = null
          })
        } else {
          return false;
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.fiscal-header{
  width: 100%;
  height: 600px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/fiscalTax/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
