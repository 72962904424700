<template>
  <div class="mark">
    <div class="mark-header">
      <div class="mark-inner center-width">
        <p class="mark-p1" style="padding-top: 135px">日本商标注册</p>
      </div>
    </div>
    <div class="mark-trait" style="height: 840px">
      <p class="b-title">日本商标注册概述</p>
      <p class="b-title1">以自然人或者法人直接向日本特许厅提出申请。允许多类申请，商品分类实行尼斯协定。<br />日本《商标法》规定， 下述商标因设计没有特点，而不予注册</p>
      <div class="mark-trait-box center-width">
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 69px;font-size: 16px;">用商品本身名称表示的商标</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 30px;font-size: 16px;">凡用普通方式表示商品的产地、销售地、质量、原料、用途、数量、形状、价格或生产、加工和使用方法以及时期的商标</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 47px;font-size: 16px;">凡用普通方式表示常见姓名或名称的商标，如"田中"、"池田"等</p>
        </div>
        <div class="mark-trait-inner">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 47px;font-size: 16px;">极其简单而常见的标记。如一个日文字母或一个英文字母</p>
        </div>
        <div class="mark-trait-inner" style="height: 268px">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 102px;font-size: 16px;">其它使顾客不能辨认经销商的商标</p>
        </div>
        <div class="mark-trait-inner" style="height: 268px">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 30px;width: 250px;font-size: 16px;">与"惯用商标"相同或类似的商标。比如，日本清酒的"正宗"，最初是某个公司使用的商标，但久而久之制造同类商品的其它公司也用它来表示自己的商品，或作为商标的一部分使用，一般顾客总是把这样的文字或图形同这种商品结合在一起，使人不能辨别这是哪家公司的商品，这种“惯用商标”也不能注册</p>
        </div>
        <div class="mark-trait-inner" style="height: 268px">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p3" style="padding-top: 68px;width: 194px;font-size: 16px;">与菊花徽章（日本皇室标志）、国旗、勋章、联合国或其它国际组织的标志相同或相似的商标及有害风俗的商标。</p>
        </div>
      </div>
      <p class="mark-trait-tips">商标注册后连续三年不使用将有可能被他人提出撤销。商标申请或注册商标均可转让、许可。转让合同要求登记，不登记转让无效。许可合同不要求必须登记，但排他使用许可必须登记后方有效</p>
    </div>
    <div class="japan-serve">
      <div class="japan-serve-box flex-between">
        <div class="japan-serve-inner">
          <p class="japan-serve-title">日本商标注册手续（查册阶段）</p>
          <div class="japan-serve-bottom">
            <p class="japan-serve-p1">商标查册：每个商标及每个类别</p>
            <p class="japan-serve-p2"> 于拟注册类别查搜相同之商标（不包括图案商标）</p>
            <p class="japan-serve-p2"> 7个工作日内以电邮或传真通知查搜结果</p>
            <p class="japan-serve-p2"> 日本本地的商标分类与国际分类不同，因此，可能需要查搜多于一个类别，查搜费用也因此可能高于1,800港元</p>
          </div>
        </div>
        <div class="japan-serve-inner">
          <p class="japan-serve-title">金百利服务</p>
          <div class="japan-serve-bottom">
            <p class="japan-serve-p2"> 于两个星期内提交申请表格</p>
            <p class="japan-serve-p2"> 免费解答一般文书问题</p>
            <p class="japan-serve-p2"> 定期(每个月)跟进注册进度</p>
            <p class="japan-serve-p2"> 委任当地律师为代表</p>
            <p class="japan-serve-p2"> 不包括提供法律意见之费用</p>
            <p class="japan-serve-p2"> 政府登记收费</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mark-file">
      <p class="b-title">日本商标所需文件和资料</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-left">
          <p class="mark-file-p">申请人的姓名、地址或注册国家</p>
          <p class="mark-file-p">如拟注册商标是彩色图案，请提供6份商标图案； 如拟注册商标是黑白图案，则只需提供一份商标图案</p>
          <p class="mark-file-p">采用拟注册商标的产品或服务之详细清单，及欲注册的类别</p>
          <p class="mark-file-p">优先权文件，如申请优先注册</p>
        </div>
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="mark-program" style="height: 688px;background: rgba(242, 245, 250, 1);">
      <p class="b-title">日本商标注册程序、时间及费用</p>
      <div class="mark-program-box center-width flex-between">
        <div class="mark-program-inner mark-program-inner1" style="background: #fff;">
          <div class="mark-program-title"></div>
          <p class="mark-program-p" style="text-align: left">商标注册申请递交到日本特许厅</p>
        </div>
        <div class="mark-program-inner mark-program-inner1" style="background: #fff;">
          <div class="mark-program-title title2"></div>
          <p class="mark-program-p" style="text-align: left">形式审查阶段。审查申请文件是否齐备，必填项目是否填写完整。如不符合要求，通知申请人更正</p>
        </div>
        <div class="mark-program-inner mark-program-inner1" style="background: #fff;">
          <div class="mark-program-title title3"></div>
          <p class="mark-program-p" style="text-align: left">实质审查阶段。审查商标是否满足实质审查要求；通过实质审查，做出允许商标注册的决定；
未能满足要求者发出驳回理由通知书，申请人对此出具抗辩书或理由书</p>
        </div>
        <div class="mark-program-inner mark-program-inner1" style="background: #fff;">
          <div class="mark-program-title title4"></div>
          <p class="mark-program-p" style="text-align: left">交付注册费用后，商标注册权确立，商标权开始存在，予以公告，异议期两个月；<br />
如果抗辩书或理由书不能消除驳回理由，驳回裁定成立；<br />
对裁定不服可以起诉，如果对起诉结果不满，可向东京高级法院提起上诉。如果仍不服，可继续向高级法院提起上诉</p>
        </div>
        <div class="mark-program-inner mark-program-inner1" style="background: #fff;">
          <div class="mark-program-title title5"></div>
          <p class="mark-program-p" style="text-align: left">整个顺利的申请注册过程，约需 10 到 15 个月</p>
        </div>
      </div>
      <p class="mark-program-tips3">
        <span class="span">商标检索：</span>
        每个注册的有效期为10年，如需延长，可以继续注册，这时需交纳6万日元的注册费<br />
        <span class="span">费用：</span>
        USD255
      </p>
    </div>
    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="日本商标申请条件？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">可以以自然人或法人申请，自然人必须附上护照或身份证复印件，法人必须附上营业执照复印件。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="日本商标注册申请所需材料？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">提交申请人的姓名、地址、国籍；<br />
提供商标的中文或外文名称；<br />
提供20份清晰商标图样（2*2/8*8）；<br />
如果商标为彩色，必须指定哪种颜色；如果提交的商标为黑白，以后使用什么颜色都可以；<br />
所申请的商品或服务类别，假如知道的话；<br />
填写申请表1份，申请表必须由申请人签署或由代理人签署。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="日本商标查询程序？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">由于商标的申请是一个复杂而漫长的过程，为了避免在申请的过程中出现徒劳无功并浪费金钱的现象，查询是<br />
                一个必不可少的环节。查询通常可以得到两个结果，一是得知所申请的商标是否与他人的重复或类似；二是得<br />
                知我们的商标是否符合政府关于商标法律的标准。法律并没有规定一定要通过查询的环节，但申请注册之前，<br />
                最好先做好查册工作。<br />
日本特许厅在受理申请时不提供免费查询，因此在注册之前建议进行独立的查询。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="mark-why" style="background: rgba(242, 245, 250, 1);">
      <p class="re-title">为什么选择我们</p>
      <div class="mark-why-box center-width flex-between">
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_1.png" alt="">
          <p class="mark-why-p1">快速</p>
          <p class="mark-why-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_2.png" alt="">
          <p class="mark-why-p1">专业</p>
          <p class="mark-why-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_3.png" alt="">
          <p class="mark-why-p1">严谨</p>
          <p class="mark-why-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_4.png" alt="">
          <p class="mark-why-p1">保障</p>
          <p class="mark-why-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .mark-header{
    width: 100%;
    height: 320px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/Japan/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program-title{
    width: 224px;
    height: 40px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_3.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .title2{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_4.png') no-repeat center center;
  }
  .title3{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_5.png') no-repeat center center;
  }
  .title4{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_6.png') no-repeat center center;
  }
  .title5{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_7.png') no-repeat center center;
  }
</style>
