<template> 
  <div class="bvi anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">快企务专注于涉内外企业服务十余年</p>
        <p class="banner-p2">一站式BVI公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="youshi">
      <p class="re-title">注册BVI公司优势</p>
      <div class="youshoi-box">
        <div class="you-inner">
          <img class="you-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_1_1shareholder.png" alt="">
          <p class="you-text">公司不受股东人数限制</p>
        </div>
        <div class="you-inner">
          <img class="you-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_1_2stable.png" alt="">
          <p class="you-text">政治、经济和贸易环境稳定</p>
        </div>
        <div class="you-inner">
          <img class="you-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_1_3no_tax.png" alt="">
          <p class="you-text">不在岸经营可不交当任何税</p>
        </div>
        <div class="you-inner">
          <img class="you-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_1_4around_the_world.png" alt="">
          <p class="you-text">世界各地均可开立银行账户</p>
        </div>
        <div class="you-inner">
          <img class="you-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_1_5secrecy.png" alt="">
          <p class="you-text">董事资料保密金融法律良好</p>
        </div>
      </div>
    </div>
    <!-- <div class="type">
      <p class="re-title">注册BVI公司类型</p>
      <div class="type-box">
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_1.png" alt="">
          <div class="type-shade">
            <p class="type-p1">1</p>
            <p class="type-p2">本土公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_2.png" alt="">
          <div class="type-shade">
            <p class="type-p1">2</p>
            <p class="type-p2">非本土公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_3.png" alt="">
          <div class="type-shade">
            <p class="type-p1">3</p>
            <p class="type-p2">豁免公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_4.png" alt="">
          <div class="type-shade">
            <p class="type-p1">4</p>
            <p class="type-p2">有期限公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_5.png" alt="">
          <div class="type-shade">
            <p class="type-p1">5</p>
            <p class="type-p2">海外公司</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="anquila-type">
      <p class="re-title">注册BVI公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_2_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/pic_2_2.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">有限合伙</p>
          <p class="anquila-type-p3" v-if="changeTabIndex == 0">（BVI Limited Partnership）</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">普通公司</p>
          <p class="anquila-type-p3" v-if="changeTabIndex == 1">（BVI Business Company）</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="conditions">
      <p class="re-title">注册BVI公司条件/流程/资料</p>
      <div class="con-box">
        <div class="con-tab">
          <p class="tab-title" :class="{'active': tab == 1}" @click="tab = 1">注册BVI公司条件</p>
          <p class="tab-title" :class="{'active': tab == 2}" @click="tab = 2">注册BVI公司流程</p>
          <p class="tab-title" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="con-content1" v-if="tab == 1">
          <div class="else-file-box2">
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_1.png" alt="">
              <p class="else-file2-p1">名称</p>
              <p class="else-file2-p2">公司名称必须以“有限公司”结尾，如：LIMITED、CORPORATION、INCORPORATED或缩写。公司名称不能以“信托公司”、“银行”或其他被认定为意思相似的词结尾，除非这些公司在英属京群岛（bvi）获得相应的执照。</p>
            </div>
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_2.png" alt="">
              <p class="else-file2-p1">董事</p>
              <p class="else-file2-p2">一个或多个董事，可以是法人实体或者任何国籍的自然人。</p>
            </div>
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_3.png" alt="">
              <p class="else-file2-p1">注册资金</p>
              <p class="else-file2-p2">BVI公司标准注册资金为50000美元（资金无需验资到位），分为50,000股，每股为1美元；如资金超过50000美元，政府需收取1350美元印花税。</p>
            </div>
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_4.png" alt="">
              <p class="else-file2-p1">地址</p>
              <p class="else-file2-p2">公司注册地址必须在英属维京群岛，快企务可提供。</p>
            </div>
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_5.png" alt="">
              <p class="else-file2-p1">董事会议</p>
              <p class="else-file2-p2">董事可在任何一个国家举行会议，或通过代理参加这样的会议。</p>
            </div>
            <div class="else-file2-inner">
              <img class="else-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_1_6.png" alt="">
              <p class="else-file2-p1">公司印鉴</p>
              <p class="else-file2-p2">英属维京群岛公司必须有自己的公司印鉴，并且公司印鉴的式样需上报公司注册署。</p>
            </div>
          </div>
          <!-- <div class="con-inner inner1">
            <p class="con-p1">名称</p>
            <p class="con-p2">公司名称必须以“有限公司”结尾，如：LIMITED、CORPORATION、INCORPORATED或缩写。公司名称不能以“信托公司”、“银行”或其他被认定为意思相似的词结尾，除非这些公司在英属京群岛（bvi）获得相应的执照。</p>
          </div>
          <div class="con-inner inner2">
            <p class="con-p1">董事</p>
            <p class="con-p2">一个或多个董事，可以是法人实体或者任何国籍的自然人。</p>
          </div>
          <div class="con-inner inner3">
            <p class="con-p1">地址</p>
            <p class="con-p2">公司注册地址必须在英属维京群岛，快企务可提供。</p>
          </div>
          <div class="con-inner inner4">
            <p class="con-p1">注册资金</p>
            <p class="con-p2">BVI公司标准注册资金为50000美元（资金无需验资到位），分为50,000股，每股为1美元；如资金超过50000美元，政府需收取1350美元印花税。</p>
          </div>
          <div class="con-inner inner5">
            <p class="con-p1">董事会议</p>
            <p class="con-p2">董事可在任何一个国家举行会议，或通过代理参加这样的会议。</p>
          </div>
          <div class="con-inner inner6">
            <p class="con-p1">公司印鉴</p>
            <p class="con-p2">英属维京群岛公司必须有自己的公司印鉴，并且公司印鉴的式样需上报公司注册署。</p>
          </div> -->
        </div>
        <div class="con-content con-tab2" v-if="tab == 2">
          <div class="tab-inner2">
            <p class="con-p">要注册的公司名称查询是否可以注册，一般1-2个工作日</p>
          </div>
          <div class="tab-inner2">
            <p class="con-p">签订授权委托书和合同</p>
          </div>
          <div class="tab-inner2">
            <p class="con-p">客户提供一系列注册公司所需要的文件及资料</p>
          </div>
          <div class="tab-inner2">
            <p class="con-p">将注册完成后的公司文件快递给客户</p>
          </div>
          <div class="tab-inner2">
            <p class="con-p">注册完成客户收到注册成功后文件的扫描件</p>
          </div>
          <div class="tab-inner2">
            <p class="con-p">开始注册约8个月工作日完成注册</p>
          </div>
        </div>
        <div class="con-content1" v-if="tab == 3">
          <!-- <img class="con-img2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_2.png" alt=""> -->
          <div class="else-file-box1">
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_1.png" alt="">
              <p class="else-file-p">注册证书</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_2.png" alt="">
              <p class="else-file-p">公司章程</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_3.png" alt="">
              <p class="else-file-p">代理人证书</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_4.png" alt="">
              <p class="else-file-p">董事会议记录</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_5.png" alt="">
              <p class="else-file-p">董事委托书</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_6.png" alt="">
              <p class="else-file-p">股东名册</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_7.png" alt="">
              <p class="else-file-p">同意出任董事、股东书</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_8.png" alt="">
              <p class="else-file-p">董事名册</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_9.png" alt="">
              <p class="else-file-p">股票本</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_10.png" alt="">
              <p class="else-file-p">公司印章</p>
            </div>
            <div class="else-file-inner">
              <img class="else-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/bvi/icon_2_11.png" alt="">
              <p class="else-file-p">文件盒子</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent">
      <p class="re-title">公司注册后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_1.png" alt="">
          <p class="sub-p1">年审/续牌</p>
          <p class="sub-p2">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_2.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_3.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_5.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_6.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">为公司提供完善的支持服务，包括但不限于代收及处理机构往来函件、提供年审报...</p>
          <p class="sub-p4">为公司提供完善的支持服务，包括但不限于代收及处理机构往来函件、提供年审报税等服务</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_3_4.png" alt="">
          <p class="sub-p1">公司注销</p>
          <p class="sub-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="libi">
      <p class="re-title">注册BVI公司的利弊</p>
      <div class="libi-box center-width">
        <!-- <div class="libi-left">
          <p class="libi-title">注册BVI公司的利</p>
          <p class="libi-p">BVI（英属维京群岛）是政治、经济和贸易环境非常稳定的地方</p>
          <p class="libi-p">有良好的金融法律设施，方便建立各种金融机构或基金会的成立与发展</p>
          <p class="libi-p">政府保护股东利益，不需要公布受益人身份</p>
          <p class="libi-p">当地政府为了鼓励经济发展，吸引外资，一个人可以完全拥有一家有限公司</p>
          <p class="libi-p">政府为各企业提供私隐保护，董事资料绝对保密</p>
          <p class="libi-p">低税率，岛屿国际有限公司所受的税务管制非常少</p>
          <p class="libi-p">不需术数师报告，只需保留资料反映经济状况即可</p>
          <p class="libi-p">在外地经营所得利润无须交利得税，达到合理避税的目的</p>
          <p class="libi-p">岛上企业在世界各地均可开立银行账户</p>
          <p class="libi-p">后续服务简单，保密性极强，免税，政治经济环境稳定，全球融资上市</p>
        </div>
        <div class="libi-right">
          <p class="libi-title1">注册BVI公司的弊</p>
          <p class="libi-p1"><span class="libi-span">数据不公开：</span>>注册的公司股东身份、董事名册、股权比例、收益状况高度保密并受法律保护，公众人士不能查询，法律禁止任意泄露公司的有关材料</p>
          <p class="libi-p1"><span class="libi-span">成立时间：</span>公司成立时间会比香港公司多3个工作日</p>
          <p class="libi-p1"><span class="libi-span">费用：</span>BVI费用会比香港公司高，年审费用也高</p>
          <p class="libi-p1"><span class="libi-span">公司结束：</span>连续两年没有交年费，公司自动注销，所有资产归政府所有</p>
          <p class="libi-p1"><span class="libi-span">坏国名单：</span>BVI列入坏国名单，所以成立BVI公司在国际的声誉受到影响</p>
        </div> -->
      </div>
      <p class="libi-tips">是否适合注册BVI公司，要根据公司的综合业务情况，也欢迎来电咨询:<span class="libi-span">400 860 5600</span></p>
    </div>
    <div class="problem">
      <p class="re-title">BVI公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册BVI公司是否一定要有中文公司名称?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">不一定。法例只规定必须有英文公司名称，可自行决定是否采用中文BVI公司名称。而且,并不是每个BVI公司注册地<br />都允许采用中文名称的。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册bvi公司经营范围注意事项？" name="2">
            <div class="inner-pro">
              <!-- <div class="pro-p1">注册BVI公司的优势有哪些？</div> -->
              <div class="pro-p2">1.不可从事信托、银行、保险、军事或其他类似中介业务<br />
                2.不得在当地作为其它公司之代理人及提供BVI之注册地址<br />
                3.不得从事违法犯罪活动</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="英属维尔京群岛是一个免税的法区吗?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">英属维尔京群岛（BVI）有一个开放的经济，主要由金融业驱动，而税法亦有一个强大的监管框架，非常有利于在该地<br />
                区做生意。<br />
                因此，英属维尔京群岛的离岸公司不需要支付所得税。也没有应税资本利得税，也没有赠与税、继承税、销售税或增值<br />
                税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="是否必须亲自到英属维尔京群岛去注册公司?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">不需要。你可以在专业服务机构的帮助下，从国外成立你的英属维尔京群岛公司。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <!-- <div class="advantage">
      <p class="re-title">快企务的优势</p>
      <p class="re-title1">十余年注册BVI公司经验</p>
      <div class="adv-box">
        <div class="adv-inner adv-inner1">
          <img class="adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_1secrecy_2.png" alt="">
          <div class="hover-box">
            <img class="adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_1secrecy_1.png" alt="">
            <p class="hover-p1">公正</p>
            <p class="hover-p2">费用透明，无隐形消费</p>
          </div>
        </div>
        <div class="adv-inner adv-inner2">
          <img class="adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_2whole_course_2.png" alt="">
          <div class="hover-box">
            <img class="adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_2whole_course_1.png" alt="">
            <p class="hover-p1">公正</p>
            <p class="hover-p2">费用透明，无隐形消费</p>
          </div>
        </div>
        <div class="adv-inner adv-inner2">
          <img class="adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_3major_2.png" alt="">
          <div class="hover-box">
            <img class="adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_3major_1.png" alt="">
            <p class="hover-p1">公正</p>
            <p class="hover-p2">费用透明，无隐形消费</p>
          </div>
        </div>
        <div class="adv-inner adv-inner3">
          <img class="adv-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_4transparent_2.png" alt="">
          <div class="hover-box">
            <img class="adv-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_4_4transparent_1.png" alt="">
            <p class="hover-p1">公正</p>
            <p class="hover-p2">费用透明，无隐形消费</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">快企务的优势</p>
      <p class="re-title1">十余年注册BVI公司经验</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      // console.log(e)
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
.libi{
  height: 909px;
  .libi-box{
    height: 572px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_1_else.png') no-repeat center center;
    background-size: 100% 100%;
  }
}
.inner1{
  width: 550px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_1name.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 484px;
  }
}
.inner2{
  width: 310px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_2director.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 237px;
  }
}
.inner3{
  width: 300px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_3address.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 213px;
  }
}
.inner4{
  width: 390px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_4capital.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 328px;
  }
}
.inner5{
  width: 360px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_5board_meetings.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 284px;
  }
}
.inner6{
  width: 410px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_6company_seal.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 331px;
  }
}
.con-tab2{
  width: 100%;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_3.png') no-repeat center center;
  background-size: 1200px 410px;
}

</style>
