<template>
    <div class="notar-china mark hague">
      <div class="notarization-header">
        <div class="center-width">
          <p class="notarization-p1" style="padding-top: 104px">【快企务专注于涉内外企业服务十余年】</p>
          <p class="notarization-p2">中国委托公证人公证</p>
          <p class="certification-p4">中国香港公证，中国香港律师公证</p>
          <p class="notarization-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="china-range center-width">
        <p class="b-title">中国委托公证人制度</p>
        <p class="b-title1" style="font-size: 16px">中国委托公证人制度，即中国香港居民回内地处理法律事务所需公证书须由司法部任命的委托公证人出具，并经中国法律服务（中国香港）有限公司加章转递，才能发往内地使用。实行这一制度，是基于中国香港和内地之间法律制度不同，办理公证证明所依据的法律、办证程式和效力不同而设置的一项特殊法律制度，核心是为了确<br />保中国香港发往内地使用的公证文书的真实性、合法性。<br />『最高人民法院』与『司法部』
        <span style="color:rgba(47, 114, 235, 1)">《关于涉港公证文书效力问题的通知》</span>：未经审核加章转递程式的证明文书，不具有公证文书的证明效力和执行效力。
        </p>
        
      </div>
      <div class="china-range center-width">
        <!-- <p class="b-title">中国委托公证人服务范围</p>
        <p class="b-title1">选择您的公证类别（可多选）</p> -->
        <p class="hague-title" style="margin-bottom: 30px">选择您的公证类别（可多选）</p>
        <!-- <p class="hague-title1">用途-国家-省份(州)-城市</p> -->
        <div class="else-tab">
          <p class="else-tab-p" :class="{'active': tab == 1}" @click="tab = 1">商业文件</p>
          <p class="else-tab-p" :class="{'active': tab == 2}" @click="tab = 2">民事文件</p>
        </div>
        <div class="china-range-box flex-between" v-if="tab == 1">
            <div class="china-range-right">
                <p class="china-range-p" @click="changeActive(index)" v-for="(item,index) in rangeList" :key="item.label" :class="{'active': item.active == true}">{{item.label}}</p>
            </div>
            <div class="china-range-left">
              <p class="china-range-title">请输入信息，我们将第一时间联系您</p>
              <div class="form-box">
                <el-form :model="form" ref="ruleForm" :rules="rules" label-width="0">
                  <el-form-item label="" prop="name">
                    <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="mobile">
                    <el-input v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <p class="china-range-btn" @click="toSubmit">咨询认证</p>
            </div>
        </div>
        <div class="china-range-box flex-between" v-if="tab == 2">
            <div class="china-range-right">
                <div class="china-range-p" @click="changeActive1(index)" v-for="(item,index) in rangeList1" :key="item.label" :class="{'active': item.active == true}">
                  <p >{{item.label}}</p>
                </div>
               
            </div>
            <div class="china-range-left">
              <p class="china-range-title">请输入信息，我们将第一时间联系您</p>
              <div class="form-box">
                <el-form :model="form" ref="ruleForm" :rules="rules" label-width="0">
                  <el-form-item label="" prop="name">
                    <el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="mobile">
                    <el-input v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <p class="china-range-btn" @click="toSubmit">咨询认证</p>
            </div>
        </div>
      </div>
      <div class="mark-trait center-width" style="height: 500px;background: #fff;">
        <p class="hague-title" style="margin-bottom: 30px">中国委托公证使用场景</p>
        <div class="mark-trait-box center-width">
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">不享有商标专用权</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">很多中国香港同胞到内地探亲、定居、领养子女、结婚</p>
          </div>
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">存在被抢注的风险</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">中国香港企业到内地人事投资、经商，亦或者在中国香港注册中国香港公司后，在国内用中国香港公司投资经营等相关事宜，由于内地有关部门及人士无法了解香港当事人的真实情况</p>
          </div>
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">容易造成侵权行为</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">中国香港居民到内地登记结婚，相关机构有必要了解其婚姻状况</p>
          </div>
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">品牌形象受损</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">中国香港企业在内地进行商业活动或投资，内地的合作伙伴或银行、工商登记机关亦有需要对中国香港公司有若干的了解，如公司的商业登记记录、董事或股东资料、财务状况等等</p>
          </div>
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">造成经济损失</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">中国香港企业需要开设代表处或港资公司时的法定提交文件，也可用于中国香港公司在内地购置不动产</p>
          </div>
          <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;background: rgba(242, 245, 250, 1);">
            <p class="mark-trait-title"></p>
            <!-- <p class="mark-trait-p1">造成经济损失</p> -->
            <p class="mark-trait-p2" style="margin-top: 58px;color: rgba(20, 19, 19, 1);width: 300px;font-size: 16px;">海外公司或外国人士如要在中国大陆境内注册公司、购买物业、或需要在中国注册登记的商品时，则需要出具其所在国家或地区的法律公证文件</p>
          </div>
        </div>
      </div>
      <div class="china-banner center-width">
        <p class="hague-title">中国委托公证需要提供的信息</p>
        <p class="hague-title1">用途-国家-省份(州)-城市</p>
        <div class="china-banner-box">
          <img class="china-banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_2.png" alt="">
          <div class="china-banner-inner">
            <div class="china-banner-left">
              <p class="china-banner-p1">公证用途</p>
              <p class="china-banner-p2">注册外商独资公司、中外合资公司外商驻代表处或其他用途</p>
            </div>
            <div class="china-banner-right">
              <p class="china-banner-p1">公证使用地</p>
              <p class="china-banner-p2">需注明国家、省份、城市</p>
            </div>
          </div>
        </div>
      </div>
      <div class="china-program center-width">
        <p class="hague-title">中国委托公证人公证程序</p>
        <p class="hague-title1">四步公证</p>
        <div class="china-program-box flex-between">
          <div class="china-program-inner">
            <img class="china-program-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_1.png" alt="">
            <p class="china-program-p">申请人递交公证资料或文件</p>
          </div>
          <img class="china-program-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_5.png" alt="">
          <div class="china-program-inner">
            <img class="china-program-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_2.png" alt="">
            <p class="china-program-p">司法部任命中国香港中国委托公证人出具委托公证文书证明文件并制作公证文件</p>
          </div>
          <img class="china-program-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_5.png" alt="">
          <div class="china-program-inner">
            <img class="china-program-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_3.png" alt="">
            <p class="china-program-p">中国法律服务（中国香港）有限公司电脑登记公证文件加章转递</p>
          </div>
          <img class="china-program-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_5.png" alt="">
          <div class="china-program-inner">
            <img class="china-program-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_3_4.png" alt="">
            <p class="china-program-p">确定文件之后4-5个工作日，如加急可2-3个工作日</p>
          </div>
        </div>
      </div>
      <!-- <div class="china-cost center-width">
        <p class="hague-title">中国委托公证人费用</p>
        <p class="hague-title1">十余年行业代办经验</p>
        <div class="china-cost-box flex-between">
          <div class="china-cost-inner">
            <div class="title-box">
              <img class="title-img-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_4_1.png" alt="">
              <p class="china-cost-title">快企务办理 （快企务公司）</p>
            </div>
            <p class="china-cost-p"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_1smiling_face.png" alt="">专业办理香港公证，香港公司公证，香港文件公证</p>
            <p class="china-cost-p"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_1smiling_face.png" alt="">在香港的公证人专业办理香港公证手续</p>
            <p class="china-cost-p"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_1smiling_face.png" alt="">没有任何中间环节</p>
            <p class="china-cost-p"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_1smiling_face.png" alt="">大大降低了香港公证成本</p>
            <p class="china-cost-btn" @click="Dialog = true">咨询办理 ></p>
          </div>
          <div class="china-cost-inner">
            <div class="title-box">
              <img class="title-img-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_4_2.png" alt="">
              <p class="china-cost-title">自己办理</p>
            </div>
            <p class="china-cost-p china-cost-p1"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_2wailing_face.png" alt="">香港公证费用包含政府收费，公证人费用，国际快递费用</p>
            <p class="china-cost-p china-cost-p1"><img class="china-cost-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/icon_1_2wailing_face.png" alt="">香港公司公证要经过两步程序：中国委托公证人公证和中国服务（香港）有限公司加章转递，这样每一步都会产生相应的费用，公证人、香港政府都要收取费用，同时再加上来回的国际快递费用，代理公司的办理手续费用等。</p>
          </div>
        </div>
      </div> -->
      <div class="china-case center-width">
        <p class="hague-title">中国香港公司公证案例</p>
        <div class="china-case-tab">
          <p class="case-tab-p" :class="{'active': caseTab == 1}" @click="caseTab = 1">中国香港公司用于福建诉讼使用</p>
          <p class="case-tab-p" :class="{'active': caseTab == 2}" @click="caseTab = 2">中国香港公司用于上海设立外资</p>
          <p class="case-tab-p" :class="{'active': caseTab == 3}" @click="caseTab = 3">中国香港公司用于南通设立公司</p>
        </div>
        <div class="china-case-box" v-if="caseTab == 1">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_5_1.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_5_2.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_5_3.png" alt="">
        </div>
        <div class="china-case-box" v-if="caseTab == 2">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_6_1.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_6_2.png" alt="">
        </div>
        <div class="china-case-box" v-if="caseTab == 3">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_7_1.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_7_2.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_7_3.png" alt="">
          <img class="china-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/pic_7_4.png" alt="">
        </div>
      </div>
      <div class="hague-advantage center-width">
        <p class="hague-title">我司办理的优势</p>
        <p class="hague-title1">十余年行业代办经验</p>
        <div class="hague-adv-box flex-between">
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon1.png" alt="">
                <div>
                  <p class="hague-adv-p1">严格保密</p>
                  <p class="hague-adv-p2" style="width: 166px">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon2.png" alt="">
                <div>
                  <p class="hague-adv-p1">透明公开</p>
                  <p class="hague-adv-p2" style="width: 166px">价格公开透明，公证认证办理费用公开透明可查</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon3.png" alt="">
                <div>
                  <p class="hague-adv-p1">全天跟进</p>
                  <p class="hague-adv-p2" style="width: 166px">24小时跟进，每份公证材料专员全天候跟进反馈</p>
                </div>
            </div>
            <div class="hague-adv-inner">
                <img class="hague-adv-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/member/icon4.png" alt="">
                <div>
                  <p class="hague-adv-p1">退费承诺</p>
                  <p class="hague-adv-p2" style="width: 166px">文件无法使用，全额退还费用</p>
                </div>
            </div>
        </div>
      </div>
      <div class="hague-consulting center-width">
        <p class="hague-title">咨询服务</p>
        <p class="hague-title1">我们还能为您做</p>
        <div class="hague-consulting-box">
            <div class="hague-consulting-left">
                <div class="consulting-inner">
                    <p class="consulting-p1">海牙认证</p>
                    <p class="consulting-p2">海牙公约组织成员国，目前成员国有102个。如德国、法国、韩国、美国、日本、澳大利亚、中国香港 及中国澳门都是公约成员国</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">国际公证认证</p>
                    <p class="consulting-p2">特聘具有国际公证员资格国际公证人，为中国香港文件、事实及法律文书等用于海外提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">使馆认证</p>
                    <p class="consulting-p2">使馆认证是指一国外交机构及其授权机构在涉外文书上确认所在国公证机关或某些特殊机关的印章或该机关主管人员的签字属实</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
            </div>
            <div class="hague-consulting-right">
                <img class="hague-consulting-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_8.png" alt="">
            </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        form: {},
        caseTab: 1,
        tab: 1,
        rangeList: [
          { label: '注册证书', active: true },
          { label: '商业登记证', active: false },
          { label: '董事会决议', active: false },
          { label: '授权委托书', active: false },
          { label: '章程', active: false },
          { label: '法团成立表格', active: false },
          { label: '会计报告', active: false },
          { label: '银行资信', active: false },
          { label: '更多', active: false }
        ],
        rangeList1: [
          { label: '申请同内地人士结婚声明书', active: true },
          { label: '申请内地亲属来港声明书', active: false },
          { label: '继承 (放弃继承) 遗产声明书', active: false },
          { label: '夫妻关系 (结婚) 声明书', active: false },
          { label: '申请回内地收养子女声明书', active: false },
          { label: '内地人士出国读书经济担保声明书', active: false },
          { label: '个人委托书', active: false },
          { label: '学历证明书', active: false },
          { label: '更多', active: false }
        ],
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}]
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      changeActive(index){
        if(index == 8){
          this.Dialog = true
        }else{
          if(this.rangeList[index].active == false){
            this.rangeList[index].active = true
          }else{
            this.rangeList[index].active = false
          }
        }
      },
      changeActive1(index){
        if(index == 8){
          this.Dialog = true
        }else{
          if(this.rangeList1[index].active == false){
            this.rangeList1[index].active = true
          }else{
            this.rangeList1[index].active = false
          }
        }
      },
      toSubmit(){
        let that = this
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              type: 4,
              name: that.form.name,
              mobile: that.form.mobile
            }
            that.$axios.post('/cms/message/save',params).then((result)=>{
              this.$notify({
                title: '提交成功',
                message: '我们会尽快联系您！',
                type: 'success',
                offset: 100,
                duration: 2000
              });
              that.form.name = ''
              that.form.mobile = ''
            })
          } else {
            return false;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .notarization-header{
      width: 100%;
      height: 520px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/china/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
  }
  </style>
  