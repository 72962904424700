<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">快企务专注于涉内外企业服务十余年</p>
        <p class="banner-p2">一站式开曼公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="youshi youshi1">
      <p class="re-title">注册开曼公司优势</p>
      <div class="youshoi1-box">
        <div class="youshi-left">
          <div class="left-inner">
            <img class="youshi-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_1_1.png" alt="">
            <p class="youshi-p"> 一位股东，一位董事，可同一人</p>
          </div>
          <div class="left-inner">
            <img class="youshi-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_1_2.png" alt="">
            <p class="youshi-p"> 注册资金＄50,000，且不需要验资</p>
          </div>
          <div class="left-inner">
            <img class="youshi-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_1_3.png" alt="">
            <p class="youshi-p"> 非当地运营可不交当地税，可投资国内</p>
          </div>
          <div class="left-inner">
            <img class="youshi-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_1_4.png" alt="">
            <p class="youshi-p"> 记名/不记名股票，可互换</p>
          </div>
          <div class="left-inner">
            <img class="youshi-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_1_5.png" alt="">
            <p class="youshi-p"> 资料保密，取名自由，架构搭建</p>
          </div>
        </div>
        <img class="youshi-right" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/pic_1.png" alt="">
      </div>
    </div>
    <div class="type">
      <p class="re-title">注册开曼公司类型</p>
      <div class="type-box">
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_1.png" alt="">
          <div class="type-shade">
            <p class="type-p1">1</p>
            <p class="type-p2">本土公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_2.png" alt="">
          <div class="type-shade">
            <p class="type-p1">2</p>
            <p class="type-p2">非本土公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_3.png" alt="">
          <div class="type-shade">
            <p class="type-p1">3</p>
            <p class="type-p2">豁免公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_4.png" alt="">
          <div class="type-shade">
            <p class="type-p1">4</p>
            <p class="type-p2">有期限公司</p>
          </div>
        </div>
        <div class="type-inner">
          <img class="type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/pic_1_5.png" alt="">
          <div class="type-shade">
            <p class="type-p1">5</p>
            <p class="type-p2">海外公司</p>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions">
      <p class="re-title">注册开曼公司条件/流程/资料</p>
      <div class="con-box">
        <div class="con-tab">
          <p class="tab-title" :class="{'active': tab == 1}" @click="tab = 1">注册开曼公司条件</p>
          <p class="tab-title" :class="{'active': tab == 2}" @click="tab = 2">注册开曼公司流程</p>
          <p class="tab-title" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="con-content" v-if="tab == 1">
          <div class="con-inner1">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_1.png" alt="">
            <div class="content-right">
              <p class="con-p1">名称</p>
              <p class="con-p2">可中文、英文，一般以“有限公司”LIMITED结尾，如出现银行、信托、保险、基金等字样需要政府特许</p>
            </div>
          </div>
          <div class="con-inner1">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_2.png" alt="">
            <div class="content-right">
              <p class="con-p1">股东</p>
              <p class="con-p2">至少一名，自然人或法人，国籍不限，提供身份证、护照等身份证明文件</p>
            </div>
          </div>
          <div class="con-inner1">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_3.png" alt="">
            <div class="content-right">
              <p class="con-p1">董事</p>
              <p class="con-p2">至少一名，自然人或法人，国籍不限；提供身份证、护照等身份证明文件；股东和董事可以为同一人，没有限制</p>
            </div>
          </div>
          <div class="con-inner1 inner2">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_4.png" alt="">
            <div class="content-right">
              <p class="con-p1">秘书</p>
              <p class="con-p2">必须委任开曼公司或个人当注册代理人（我司提供）</p>
            </div>
          </div>
          <div class="con-inner1 inner2">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_5.png" alt="">
            <div class="content-right">
              <p class="con-p1">资本</p>
              <p class="con-p2">50,000美金，无需验资，可划分为50,000股</p>
            </div>
          </div>
          <div class="con-inner1 inner2">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_6.png" alt="">
            <div class="content-right">
              <p class="con-p1">地址</p>
              <p class="con-p2">必须是当地地址（我司可提供）</p>
            </div>
          </div>
          <div class="con-inner1 inner3">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_7.png" alt="">
            <div class="content-right">
              <p class="con-p1">财务报表</p>
              <p class="con-p2">不需要</p>
            </div>
          </div>
          <div class="con-inner1 inner3">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_8.png" alt="">
            <div class="content-right">
              <p class="con-p1">税务</p>
              <p class="con-p2">没有</p>
            </div>
          </div>
          <div class="con-inner1 inner3">
            <img class="con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else1/icon_1_9.png" alt="">
            <div class="content-right">
              <p class="con-p1">经营范围</p>
              <p class="con-p2">没有限制</p>
            </div>
          </div>
        </div>
        <div class="con-content1 con-tab2" v-if="tab == 2">
          <div class="tab-innerk">
            <p class="con-p">提供三个公司名称给快企务进行查名</p>
          </div>
          <div class="tab-innerk">
            <p class="con-p">进行免费查名一般1~2工作日</p>
          </div>
          <div class="tab-innerk">
            <p class="con-p">客户签订合同</p>
          </div>
          <div class="tab-innerk">
            <p class="con-p">约8个工作日完成注册</p>
          </div>
          <div class="tab-innerk">
            <p class="con-p">客户领取注册文件的扫描件</p>
          </div>
          <div class="tab-innerk">
            <p class="con-p">文件快递给客户或客户自行提取</p>
          </div>
        </div>
        <div class="con-content1 con-content-else" v-if="tab == 3">
          <!-- <div class="tab-innerw">
            <p class="tab3-p1">发起人决议书</p>
            <p class="tab3-p2">首任董事决议书</p>
            <p class="tab3-p3">公司唯一董事决议书</p>
            <p class="tab3-p4">企业注册登记证原件</p>
            <p class="tab3-p5">公司章程复印件</p>
            <p class="tab3-p6">股权证明</p>
          </div>
          <div class="tab-innerw tab-innerw2">
            <p class="tab3-p7">股权转让书</p>
            <p class="tab3-p8">公司钢印和图章</p>
            <p class="tab3-p9">开曼公司文件登记时间表</p>
            <p class="tab3-p10">公司章程原件</p>
            <p class="tab3-p11">公司成员/董事/职员登记册和股权转让</p>
          </div>
          <p class="tab-innerw3">注册完成后所得资料</p>  -->
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_1.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">发起人决议书</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_2.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">首任董事决议书</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_3.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">公司唯一董事决议书</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_4.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">企业注册登记证原件</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_5.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">公司章程复印件</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_6.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">股权证明</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_7.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">股权转让书</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_8.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">公司钢印和图章</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_9.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">开曼公司文件登记时间表</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_10.png" alt="">
            <!-- <div class="content-right">
              
            </div> -->
            <p class="con-p3">公司章程原件</p>
          </div>
          <div class="con-inner1 inner4">
            <img class="con-icon3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/else/icon_1_11.png" alt="">
            <!-- <div class="content-right">
             
            </div> -->
            <p class="con-p3">公司成员/董事/职员登记册和股权转让</p>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent1">
      <p class="re-title">开曼公司注册后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_1.png" alt="" />
          <p class="sub-p1">续牌</p>
          <p class="sub-p2">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_2.png" alt="" />
          <p class="sub-p1">股东董事变更</p>
          <p class="sub-p2">公司经营过程中的股东董事变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_3.png" alt="" />
          <p class="sub-p1">公司名称变更</p>
          <p class="sub-p2">公司经营过程中的公司名称变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_5.png" alt="" />
          <p class="sub-p1">章程修订</p>
          <p class="sub-p3">根据公司经营实际情况和相关法律法规、监管部门的要求，协助公司董事会拟对公司...</p>
          <p class="sub-p4">根据公司经营实际情况和相关法律法规、监管部门的要求，协助公司董事会拟对公司章程进行修订</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_6.png" alt="" />
          <p class="sub-p1">良好证明申请</p>
          <p class="sub-p2">一般应银行需求，协助提供相关证明</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_7.png" alt="" />
          <p class="sub-p1">代理变更</p>
          <p class="sub-p2">公司经营过程中的代理变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_8.png" alt="" />
          <p class="sub-p1">董事在职证明申请</p>
          <p class="sub-p2">一般应银行需求，协助提供相关证明</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/icon_3_4.png" alt="" />
          <p class="sub-p1">公司注销</p>
          <p class="sub-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="libi">
      <p class="re-title">注册开曼公司的利弊</p>
      <div class="libi-box center-width">
        <!-- <div class="libi-left">
          <p class="libi-title">注册BVI公司的利</p>
          <p class="libi-p">BVI（英属维京群岛）是政治、经济和贸易环境非常稳定的地方</p>
          <p class="libi-p">有良好的金融法律设施，方便建立各种金融机构或基金会的成立与发展</p>
          <p class="libi-p">政府保护股东利益，不需要公布受益人身份</p>
          <p class="libi-p">当地政府为了鼓励经济发展，吸引外资，一个人可以完全拥有一家有限公司</p>
          <p class="libi-p">政府为各企业提供私隐保护，董事资料绝对保密</p>
          <p class="libi-p">低税率，岛屿国际有限公司所受的税务管制非常少</p>
          <p class="libi-p">不需术数师报告，只需保留资料反映经济状况即可</p>
          <p class="libi-p">在外地经营所得利润无须交利得税，达到合理避税的目的</p>
          <p class="libi-p">岛上企业在世界各地均可开立银行账户</p>
          <p class="libi-p">后续服务简单，保密性极强，免税，政治经济环境稳定，全球融资上市</p>
        </div>
        <div class="libi-right">
          <p class="libi-title1">注册BVI公司的弊</p>
          <p class="libi-p1"><span class="libi-span">数据不公开：</span>>注册的公司股东身份、董事名册、股权比例、收益状况高度保密并受法律保护，公众人士不能查询，法律禁止任意泄露公司的有关材料</p>
          <p class="libi-p1"><span class="libi-span">成立时间：</span>公司成立时间会比香港公司多3个工作日</p>
          <p class="libi-p1"><span class="libi-span">费用：</span>BVI费用会比香港公司高，年审费用也高</p>
          <p class="libi-p1"><span class="libi-span">公司结束：</span>连续两年没有交年费，公司自动注销，所有资产归政府所有</p>
          <p class="libi-p1"><span class="libi-span">坏国名单：</span>BVI列入坏国名单，所以成立BVI公司在国际的声誉受到影响</p>
        </div> -->
      </div>
      <p class="libi-tips">是否适合注册开曼公司，要根据公司的综合业务情况，也欢迎来电咨询:<span class="libi-span">400 860 5600</span></p>
    </div>
    <div class="problem">
      <p class="re-title">开曼公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="开曼群岛介绍" name="1">
            <div class="inner-pro">
              <div class="pro-p2">开曼群岛由加勒比海中三个岛屿组成，主岛为大开曼岛，经济发达，达到全民就业，政府收入大部份来自金融服务业<br />
                及旅游业，官方语言以英语为主，通用货币是美元。政府对于持续把开曼群岛发展成为一个离岸金融中心给予很大支<br />
                持。开曼群岛的名声和受欢迎程度日趋增强，特别在亚洲地区，因其是中国香港证券交易所仅有的两个获准上市的离岸法<br />
                区之一。在1997年，开曼群岛建立自己的证券交易所,开曼的法律体系是参照英国普通法体系设立的。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="投资区块链为什么选择开曼？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">1、没有附加条例限制贸易发展；<br />
              2、政治稳定良好，没有对国外利润征税； <br />
              3、没有外汇管制，资金可灵活调动；4、公司类型为免除公司的名称无须用"LIMITED"（即"有限"）字样结尾；<br />
              5、保密性良好。开曼的税务免除公司与一个自然人享有完全相同的权利；<br />
              6、开曼群岛完全没有税收，无论是对个人、公司还是信托行业都不征任何税；
              </div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册开曼公司有什么固定要求？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">1、资本要求：开曼群岛政府对其离岸公司的注册资本并没有限制，但一般采用50,000美元作为最少注册资本，可划分<br />
                为50,000股，每股1美元。<br />
                2、股东要求：注册开曼公司至少一名股东，可以是自然人(年满18周岁)或法人，无国籍限制;董事资料需向公司注册处<br />
                披露，但这些资料不会被公开作公众查册。<br />
                3、董事要求：注册开曼公司至少一名董事，可以是自然人(年满18周岁)或法人，无国籍限制;<br />
                4、名称要求：注册开曼公司名称没有特殊要求，一般以“LIMITED”结尾，如出现“银行”、“信托”、“保险”、<br />
                “基金”等字样需要经过相关政府部门特许才行。<br />
                5、税务要求：无需申报或缴纳任何税项，除了年度牌照费。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册开曼群岛公司，为什么能减少缴税?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">开曼群岛完全没有税收，无论是对个人、公司还是信托行业都不征任何税。所以开曼群岛被称为一个真正的税务避风<br />
                港。开曼群岛在1978年获得一个皇家法令，法律规定永远豁免开曼群岛的缴税义务，目前这个法令持续有效。还可申<br />
                请一个20年有效的证书更进一步保证其无须缴税的地位。没有利润税、财产税，唯一之税项为印花税，任何转让或按<br />
                揭房地产均需缴付印花税。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="开曼公司名称可以随意取吗？" name="5">
            <div class="inner-pro">
              <div class="pro-p2">对公司名称没有特殊要求，除非经过特许，否则公司名称不能出现BANK(银行) 、TRUST(信托)、MUTUAL FUND(基<br />
                金)、INSURANCE(保险)，或是REINSURANCE(再保险)等字眼。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="开曼公司的信息保密性高吗？" name="6">
            <div class="inner-pro">
              <div class="pro-p2">开曼公司的保密性良好。开曼的税务免除公司与一个自然人享有完全相同的权利。免除公司必须每年提交年检报告，但<br />
                是年检报告中无须被露公司董事和成员信息。2001年4月，开曼政府颁布了新规定要求所有公司向注册代理技露公司主<br />
                要负责人、成员、受益人和授权人的信息。这个规定适用所有新公司，已注册的公司必须在一定期限内提供有关信息。<br />
                有关信息被视为商业机密，受《保密关系维护法》管辖。因此任何人如果披露该信息或试图获取该信息或通过非法途径<br />
                获取了该信息，将融犯刑律。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title">我们的优势</p>
      <p class="re-title1">十余年注册开曼公司经验</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      tab: 1,
      activeName: '1',
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/banner.jpeg') no-repeat center center;
    background-size: 100% 100%;
}
.inner1{
  width: 550px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_1name.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 484px;
  }
}
.inner2{
  width: 310px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_2director.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 237px;
  }
}
.inner3{
  width: 300px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_3address.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 213px;
  }
}
.inner4{
  width: 390px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_4capital.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 328px;
  }
}
.inner5{
  width: 360px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_5board_meetings.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 284px;
  }
}
.inner6{
  width: 410px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/icon_2_6company_seal.png') no-repeat center center;
  background-size: 100% 100%;
  .con-p2{
    width: 331px;
  }
}
.libi{
  height: 909px;
  .libi-box{
    height: 572px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/pic_2_else.png') no-repeat center center;
    background-size: 100% 100%;
  }
}
.con-content1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.con-content-else{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.con-tab2{
  width: 100%;
  // height: 300px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/pic_2.png') no-repeat center center;
  background-size: 1200px 170px; 
  .tab-innerk{
    width: 182px;
    height: 171px;
    .con-p{
      width: 126px;
      margin: 0 auto;
      font-size: 16px;
      color: rgba(20, 19, 19, 1);
      margin-top: 30px;
      text-align: center;
    }
  }
}
.con-tab3{
  width: 100%;
  height: 300px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/kaiman/pic_3.png') no-repeat center center;
  background-size: 1200px 300px; 
  
}

.con-inner1{
  width: 380px;
  padding-bottom: 20px;
  height: 142px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: rgba(242, 245, 250, 1);
  display: flex;
  align-items: flex-start;
  .con-icon{
    width: 60px;
    height: 60px;
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .con-p1{
    font-size: 20px;
    color: rgba(20, 19, 19, 1);
    margin-top: 20px;
  }
  .con-p2{
    width: 252px;
    font-size: 16px;
    color: rgba(112, 112, 112, 1);
    margin-top: 9px;
  }
}

.inner2{
  height: 120px;
}
.inner3{
  height: 98px;
}
.inner4{
  width: 285px;
  height: 100px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  .con-p3{
    width: 142px;
    font-size: 16px;
  }
  .con-icon3{
    margin-left: 30px;
    margin-right: 20px;
  }
}
.inner4:nth-child(4n){
  margin-right: 0;
}

</style>
