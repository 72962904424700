<template>
    <div class="news-detail">
      <div class="detail-left">
        <p class="detail-title">{{info.title}}</p>
        <p class="detail-time">{{info.createTime}}</p>
        <div class="detail-content" v-html="info.content"></div>
      </div>
      <div class="detail-right">
        <p class="right-title">相关阅读</p>
        <div class="correlation-box">
          <div class="correlation-inner" @click="getInfo(item.id)" v-for="(item,index) in list1" :key="index">
            <p class="correlation-p1">{{item.title}}</p>
            <p class="correlation-p2">{{item.createTime}}</p>
          </div>
        </div>
        <p class="right-title">热门业务</p>
        <div class="works-box">
          <p class="works-p" @click="toUrl('global')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span" >全球公司注册</span>
          </p>
          <p class="works-p" @click="toUrl('notarization')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span">国际公证认证</span>
          </p>
          <p class="works-p" @click="toUrl('secretaryHongKong')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span">秘书服务</span>
          </p>
          <p class="works-p" @click="toUrl('fiscalTax')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span">财税筹划</span>
          </p>
          <p class="works-p" @click="toUrl('bank')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span">银行开户</span>
          </p>
          <p class="works-p" @click="toUrl('ODI')">
            <img class="works-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/hot.png" alt="">
            <span class="works-span">境外投资备案</span>
          </p>
        </div>
      </div>
    </div>
  </template>
  <script>
  // import Footer from '@/components/Footer.vue'
  export default {
    name: 'bvi-page',
    // components: {
    //   Footer
    // },
    props: {
      msg: String
    },
    data(){
      return{
        info: {},
        id: '',
        list1: []
      }
    },
    mounted(){
      window.scrollTo(0,0);
      this.id = this.$route.query.id
      // this.id = 7
      this.getList()
      this.getList1()
    },
    methods: {
      getList(){
        let that = this
        that.$axios.get(`/news/find/${that.id}`,{}).then((res)=>{
          that.info = res.data.data
        })
      },
      getList1(){
        let that = this
        that.$axios.get(`/news/front/recommend/list`,{}).then((res)=>{
          that.list1 = res.data.data
        })
      },
      toUrl(url){
        this.$router.push({name: url})
      },
      getInfo(id){
        let that = this
        that.$axios.get(`/news/find/${id}`,{}).then((res)=>{
          that.info = res.data.data
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .news-header{
      width: 100%;
      height: 400px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/news/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
    }
  </style>
  