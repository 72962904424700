<template>
  <div id="app">
    <Header></Header>
    <div style="width: 100%;height: 100px"></div>
    <div class="to-top" @click="toTop">
      <img src="../src/assets/img/else/icon_return.png" alt="">
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import './assets/css/style.scss';
@import './assets/css/register.scss';
@import './assets/css/register1.scss';
@import './assets/css/register2.scss';
@import './assets/css/bank.scss';
@import './assets/css/secretary.scss';
@import './assets/css/notarization.scss';
@import './assets/css/examination.scss';
@import './assets/css/about.scss';
@import './assets/css/certification.scss';

#app {
  // font-family: '阿里巴巴惠普体标准';
}
</style>
<script>
import Header from '@/components/Header.vue'
export default {
  name: "App",
  components: {
    Header
  },
  methods: {
    toTop(){
      window.scrollTo(0,0);
    }
  }
}
</script>
<style>
  .to-top{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10vh;
    right: 20px;
    cursor: pointer;
  }
</style>

