<template>
  <div class="certification">
    <div class="certification-header">
      <div class="certification-inner center-width">
        <p class="certification-p1">公司文件认证/个人文件认证</p>
        <p class="certification-p2">中国驻英国使馆认证</p>
        <p class="certification-p3">七天取件丨一对一服务丨合同保障</p>
        <p class="certification-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="introduction">
      <p class="b-title">中国驻英国使馆认证简介</p>
      <p class="b-title1">英国使馆认证是英国文件拿到中国使用的前提，如办理英国外商投资企业变更、英国公司在国内法院办理诉讼、英国公司名义在国内设立公司、用英国持有国内商标专利及其变更等，都需要办理中国驻英国使馆认证，其真实性才会被国内相关政府部门认可。</p>
      <div class="introduction-box">
        <img class="introduction-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/pic_1_8.png" alt="">
      </div>
    </div>
    <div class="range">
      <p class="b-title">中国驻英国使馆认证范围</p>
      <div class="range-box flex-between">
        <div class="range-inner">
          <div class="range-header">
            <img class="range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_1_1.png" alt="">
            <p class="range-header-p">公司文件</p>
          </div>
          <div class="range-content">
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">注册证书</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">董事会决议会议纪要</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">章程</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">...</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">资信证明</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">续存证明</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">股东董事名册</span>
            </p>
          </div>
        </div>
        <div class="range-inner">
          <div class="range-header">
            <img class="range-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_1_2.png" alt="">
            <p class="range-header-p">个人文件</p>
          </div>
          <div class="range-content">
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">无犯罪记录证明</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">学历证明</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">结婚证</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">房屋委托书</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">出生证</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">离婚证</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">护照</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">资格证书</span>
            </p>
            <p class="range-content-p">
              <img class="range-content-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">同一人声明书</span>
              <img class="range-content-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_2right.png" alt="">
              <span class="range-content-span">...</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="file">
      <p class="b-title">中国驻英国使馆认证所需资料</p>
      <div class="file-box center-width flex-between">
        <div class="file-inner">
          <img class="file-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/pic_2_1.jpg" alt="">
          <p class="file-inner-p">公证认证申请表</p>
        </div>
        <div class="file-inner">
          <img class="file-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/pic_2_2.jpg" alt="">
          <p class="file-inner-p">申请人护照复印件</p>
        </div>
        <div class="file-inner">
          <img class="file-inner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/pic_2_3.jpg" alt="">
          <p class="file-inner-p">待认证文书复印件</p>
        </div>
      </div>
    </div>
    <div class="process">
      <p class="b-title">中国驻英国使馆认证流程</p>
      <p class="b-title1">中国驻英国使馆认证的时间一般为  <span class="blue-style">7~15 </span>个工作日</p>
      <div class="process-box flex-between">
        <div class="process-inner">
          <img class="process-inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_3_1.png" alt="">
          <p class="process-inner-p">1. 律师/公证员公证</p>
        </div>
        <img class="process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_4arrow.png" alt="">
        <div class="process-inner">
          <img class="process-inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_3_2.png" alt="">
          <p class="process-inner-p">2. 英国外交部认证</p>
        </div>
        <img class="process-arrow" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/icon_4arrow.png" alt="">
        <div class="process-inner">
          <img class="process-inner-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/new/icon_1.png" alt="">
          <p class="process-inner-p">3. 中国驻英国使馆认证</p>
        </div>
      </div>
    </div>
    <div class="advantage">
      <p class="b-title">快企务优势</p>
      <p class="b-title1">十余年中国驻英国使馆认证经验</p>
      <div class="advantage-box center-width flex-between">
        <div class="advantage-inner">
          <img class="advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/new/icon_2_1.png" alt="">
          <p class="advantage-p1">严格保密</p>
          <p class="advantage-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
        </div>
        <div class="advantage-inner">
          <img class="advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/new/icon_2_2.png" alt="">
          <p class="advantage-p1">透明公开</p>
          <p class="advantage-p2">价格公开透明，公证认证办理费用公开透明可查</p>
        </div>
        <div class="advantage-inner">
          <img class="advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/new/icon_2_3.png" alt="">
          <p class="advantage-p1">全天跟进</p>
          <p class="advantage-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
        </div>
        <div class="advantage-inner">
          <img class="advantage-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/new/icon_2_4.png" alt="">
          <p class="advantage-p1">退费承诺</p>
          <p class="advantage-p2">文件无法使用，全额退还费用</p>
        </div>
      </div>
    </div>
    <div class="cer-case">
      <p class="b-title">客户案例</p>
      <div class="cer-case-box center-width flex-between">
        <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/Britain/pic_1.jpg" alt="">
        <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/Britain/pic_2.jpg" alt="">
        <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/Britain/pic_3.jpg" alt="">
        <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/Britain/pic_4.jpg" alt="">
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {}
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .certification-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/certification/banner_8.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
