<template>
    <div class="notarization">
      <div class="notarization-header">
        <div class="center-width">
          <p class="notarization-p1">【快企务专注于涉内外企业服务十余年】</p>
          <p class="notarization-p2">国际公证认证服务</p>
          <p class="notarization-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="notarization-box1">
        <p class="b-title">与全球公证机构展开合作，是最早进入国际公证认证领域</p>
        <p class="b-title1">办理了
            <span class="blue-big-style"> 10000+ </span>
            客户文件，承诺文件无法使用，
            <span class="blue-big-style">100% </span>
            全额退还费用
        </p>
        <div class="no-box1 center-width flex-between">
            <div class="no-box1-inner">
                <img class="no-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/icon_1_1efficient.png" alt="">
                <p class="no-box1-p1">专业高效</p>
                <p class="no-box1-p2">与全球公证机构开展合作</p>
            </div>
            <div class="no-box1-inner">
                <img class="no-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/icon_1_2security.png" alt="">
                <p class="no-box1-p1">安全保密</p>
                <p class="no-box1-p2">资料严格保密，保护隐私</p>
            </div>
            <div class="no-box1-inner">
                <img class="no-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/icon_1_3reliable.png" alt="">
                <p class="no-box1-p1">真实可靠</p>
                <p class="no-box1-p2">专业国际公证人签字，确保文件真实</p>
            </div>
            <div class="no-box1-inner">
                <img class="no-box1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/icon_1_4refund.png" alt="">
                <p class="no-box1-p1">承诺退款</p>
                <p class="no-box1-p2">文件无法使用，100%全额退还费用</p>
            </div>
        </div>
      </div>
      <div class="no-range">
        <p class="b-title">快企务国际公证认证服务范围</p>
        <p class="b-title1">海牙认证/中国委托公证人公证/使馆认证</p>
        <div class="no-range-box center-width">
            <div class="no-range-tab">
                <p class="tab-p" :class="{'active': tab == 1}" @click="tab = 1">海牙认证</p>
                <p class="tab-p" :class="{'active': tab == 2}" @click="tab = 2">中国委托公证人公证</p>
                <p class="tab-p" :class="{'active': tab == 3}" @click="tab = 3">使馆认证</p>
            </div>
            <div class="no-range-content" v-if="tab == 1">
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">个人类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">出生证</p>
                        <p class="center-p">授权委托书</p>
                        <p class="center-p">护照</p>
                        <p class="center-p">学历证书</p>
                        <p class="center-p">成绩单</p>
                        <p class="center-p">结婚证</p>
                        <p class="center-p">领养</p>
                        <p class="center-p">寄养</p>
                        <p class="center-p">亲属关系</p>
                        <p class="center-p">死亡证明</p>
                        <p class="center-p">工作经历</p>
                        <p class="center-p">声明书</p>
                        <p class="center-p">绿卡认证</p>
                        <p class="center-p">邀请函</p>
                        <p class="center-p">驾照</p>
                        <p class="center-p">离婚书</p>
                        <p class="center-p">遗嘱</p>
                        <p class="center-p">房产证</p>
                        <p class="center-p">职业资格证书</p>
                        <p class="center-p">......</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">公司类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">注册证书</p>
                        <p class="center-p">法人资格</p>
                        <p class="center-p">协议书</p>
                        <p class="center-p">合同书</p>
                        <p class="center-p">授权书</p>
                        <p class="center-p">声明书</p>
                        <p class="center-p">证明书</p>
                        <p class="center-p">公司更名文件</p>
                        <p class="center-p">商标证书</p>
                        <p class="center-p">资产证明</p>
                        <p class="center-p">资信证明</p>
                        <p class="center-p">董事信息</p>
                        <p class="center-p">公司章程</p>
                        <p class="center-p">会议记录</p>
                        <p class="center-p">发票</p>
                        <p class="center-p">原产地证</p>
                        <p class="center-p">报关单</p>
                        <p class="center-p">产品证书</p>
                        <p class="center-p">产品介绍</p>
                        <p class="center-p">公司文件</p>
                        <p class="center-p">......</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">第三方机构类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">政府网站宣传内容</p>
                        <p class="center-p">警署信件</p>
                        <p class="center-p">海关报告</p>
                        <p class="center-p">政府报告等</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
            </div>
            <div class="no-range-content" v-if="tab == 2">
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">商业文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">中国香港公司公证</p>
                        <p class="center-p">中国香港律师公证</p>
                        <p class="center-p">中国香港公司诉讼公证</p>
                        <p class="center-p">外商主体资格公证</p>
                        <p class="center-p">中国香港公司意见书公证</p>
                        <p class="center-p">董事会决议</p>
                        <p class="center-p">授权委托书</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">民事文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">中国香港出生证公证</p>
                        <p class="center-p">中国香港身份证公证</p>
                        <p class="center-p">中国香港学历证公证</p>
                        <p class="center-p">中国香港结婚证公证</p>
                        <p class="center-p">中国香港个人委托书公证</p>
                        <p class="center-p">中国香港继承遗产公证</p>
                        <p class="center-p">中国香港收养子女公证</p>
                        <p class="center-p">中国香港经济担保公证</p>
                        <p class="center-p">中国香港亲属来港公证</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
            </div>
            <div class="no-range-content" v-if="tab == 3">
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">个人类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">结婚证</p>
                        <p class="center-p">出生证</p>
                        <p class="center-p">离婚书</p>
                        <p class="center-p">领养</p>
                        <p class="center-p">寄养</p>
                        <p class="center-p">亲属关系</p>
                        <p class="center-p">死亡证明</p>
                        <p class="center-p">工作经历</p>
                        <p class="center-p">邀请函</p>
                        <p class="center-p">单身证明</p>
                        <p class="center-p">房产证</p>
                        <p class="center-p">学历证书</p>
                        <p class="center-p">职业资格证书</p>
                        <p class="center-p">医学证明</p>
                        <p class="center-p">个人专利</p>
                        <p class="center-p">毕业证</p>
                        <p class="center-p">无犯罪证明</p>
                        <p class="center-p">成绩单</p>
                        <p class="center-p">委托书</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">公司类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">注册证书</p>
                        <p class="center-p">营业执照</p>
                        <p class="center-p">公司章程</p>
                        <p class="center-p">董事信息</p>
                        <p class="center-p">股东信息</p>
                        <p class="center-p">法人资格</p>
                        <p class="center-p">公司更名文件</p>
                        <p class="center-p">自由贸易证书</p>
                        <p class="center-p">协议书</p>
                        <p class="center-p">授权书认证</p>
                        <p class="center-p">声明书</p>
                        <p class="center-p">证明书</p>
                        <p class="center-p">委派书</p>
                        <p class="center-p">商标证书</p>
                        <p class="center-p">资产证明</p>
                        <p class="center-p">资信证明</p>
                        <p class="center-p">会议记录</p>
                        <p class="center-p">发票</p>
                        <p class="center-p">原产地证</p>
                        <p class="center-p">报送单</p>
                        <p class="center-p">......</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">第三方机构类文件</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">政府网站宣传内容</p>
                        <p class="center-p">警署信件</p>
                        <p class="center-p">海关报告</p>
                        <p class="center-p">政府报告等</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
                <div class="no-range-inner flex-between">
                    <div class="range-inner-left">
                        <img class="range-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_1.png" alt="">
                        <p class="range-p1">热门大使馆认证</p>
                        <p class="line"></p>
                    </div>
                    <div class="range-inner-center">
                        <p class="center-p">美国大使馆认证</p>
                        <p class="center-p">英国大使馆认证</p>
                        <p class="center-p">萨摩亚大使馆认证</p>
                        <p class="center-p">塞舌尔大使馆认证</p>
                        <p class="center-p">新加坡大使馆认证</p>
                        <p class="center-p">加拿大大使馆认证</p>
                        <p class="center-p">德国大使馆认证</p>
                        <p class="center-p">意大利大使馆认证</p>
                        <p class="center-p">安圭拉大使馆认证</p>
                        <p class="center-p">日本大使馆认证</p>
                        <p class="center-p">澳大利亚大使馆认证</p>
                        <p class="center-p">新西兰大使馆认证</p>
                        <p class="center-p">荷兰大使馆认证</p>
                        <p class="center-p">法国大使馆认证</p>
                        <p class="center-p">......</p>
                    </div>
                    <div class="range-inner-right">
                        <p class="blue-bg-btn" style="margin-right: 20px;" @click="Dialog = true">咨询认证</p>
                        <p class="white-bg-btn" @click="Dialog = true">查看详情</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="no-case">
        <div class="no-case-box">
            <div class="no-case-left">
                <p class="no-case-p1">快企务公证认证案例</p>
                <p class="no-case-p2">快企务已累计办理认证业务 <span class="big-blue">10000+</span></p>
                <p class="blue-bg-btn" @click="Dialog = true">立即咨询</p>
            </div>
            <div class="no-case-right">
                <img class="no-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_2_1.png" alt="">
                <img class="no-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_2_2.png" alt="">
                <img class="no-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_2_3.png" alt="">
                <img class="no-case-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/pic_2_4.png" alt="">
            </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
                src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
                width="850px"
                height="100%"
                frameborder="0"
                scrolling="no">
            </iframe>
        </div>
        </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      return{
        Dialog: false,
        tab: 1
      }
    },
    mounted(){
        window.scrollTo(0,0);
    }
  }
  </script>
  
  <style scoped lang="scss">
    .notarization-header{
      width: 100%;
      height: 520px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
  }
  </style>
  