import axios from 'axios'
import router from '@/router'
import { Message, Loading } from 'element-ui'
// const ConfigBaseURL = process.env.VUE_APP_BASE_API
const ConfigBaseURL = 'http://47.115.17.200:5291/'
let loadingInstance = null 

const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
export const Service = axios.create({
  timeout: 30000, 
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    token: userInfo.token
  }
})

// 添加请求拦截器
Service.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('userInfo') != null && localStorage.getItem('userInfo') != '' ? JSON.parse(localStorage.getItem('userInfo')).token : ""
  loadingInstance = Loading.service({
    lock: true,
    text: 'loading...'
  })
  return config
})

// 添加响应拦截器
Service.interceptors.response.use(response => {
  loadingInstance.close()
  if(response.data.status == 401){
    Message({
      message: '登陆过期，请重新登陆！',
      type: 'error'
    })
    router.push('/login') 
  }else{
    return response.data
  }
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 3 * 1000
  })
  loadingInstance.close()
  return Promise.reject(error)
})