<template>
  <div class="join">
    <div class="join-header">
      <div class="join-header-box">
        <p class="join-header-p1">欢迎加入我们，成就自我，助力千万企业乘风出海，快企无忧！</p>
        <p class="join-header-btn">加入我们</p>
      </div>
    </div>
    <div class="join-office">
      <p class="b-title">快乐实干的团队</p>
      <div class="join-office-box">
        <el-carousel :interval="5000" arrow="always" height="548px" indicator-position="outside" :autoplay="true">
          <!-- <el-carousel-item>
            <div class="join-office-inner">
              <div class="join-office-left">
                <img class="join-office-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_1_1.png" alt="">
              </div>
              <div class="join-office-right">
                <div class="join-office-right-top">
                  <img class="join-office-right-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_1_2.png" alt="">
                </div>
                <div class="join-office-right-bottom">
                  <div class="join-office-right-bottom-inner inner1">
                    <img class="join-office-right-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_1_3.png" alt="">
                  </div>
                  <div class="join-office-right-bottom-inner">
                    <img class="join-office-right-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_1_4.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="join-office-inner">
              <div class="join-office-left" style="margin-right: 30px;width: 630px">
                <img class="join-office-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_1.png" alt="">
              </div>
              <div class="join-office-left" style="margin-right: 30px;width: 630px">
                <img class="join-office-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_2.png" alt="">
              </div>
              <div class="join-office-right">
                <img class="join-office-img" style="width: 375px;margin-bottom: 20px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_3.png" alt="">
                <img class="join-office-img" style="width: 375px" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_4.png" alt="">
              </div>
            </div>
          </el-carousel-item> -->
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_1.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_2.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_3.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_4.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_5.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_6.jpg" alt="">
          </el-carousel-item>
          <!-- <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_7.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/join/pic_8.jpg" alt="">
          </el-carousel-item> -->
          <!-- <el-carousel-item>
            <div class="join-office-inner">
              <img class="join-office-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_1.png" alt="">
              <div class="join-office-right">
                <img class="join-office-right-img1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_2.png" alt="">
                <img class="join-office-right-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_3.png" alt="">
                <img class="join-office-right-img2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_2_4.png" alt="">
              </div>
            </div>
          </el-carousel-item> -->
        </el-carousel>
      </div>
    </div>
    <div class="join-position">
      <p class="b-title">请选择职位</p>
      <div class="join-position-box">
        <div class="join-position-left">
          <p class="join-position-left-p" :class="{'active': tab == 1}" @click="(tab = 1)">销售顾问</p>
          <p class="join-position-left-p" :class="{'active': tab == 2}" @click="(tab = 2)">签证专员</p>
        </div>
        <div class="join-position-right" v-if="tab == 1">
          <p class="join-position-title">销售顾问</p>
          <p class="join-position-p">公司产品：涉外企业注册（含后续开户年审报税）、国际公证认证、知识产权等</p>
          <p class="join-position-title1">岗位职责：</p>
          <p class="join-position-p">1. 负责客户来电、来访、网络和邮件的商务联系与咨询；</p>
          <p class="join-position-p">2. 负责老客户的维护及新业务的及时跟进；</p>  
          <p class="join-position-p">3. 负责做好电话记录，并积极主动回访，随时了解客户需求； </p>  
          <p class="join-position-p">4. 负责收集信息来源及客户所属行业等有效的客户资料； </p> 
          <p class="join-position-p">5. 负责做好客户的接待工作，出色完成商务谈判与签约工作； </p> 
          <p class="join-position-p">6. 负责做好签约客户记录，详细记载办理进程以及重点事项，完善后期维护。</p>
          <p class="join-position-title1">任职要求： </p>   
          <p class="join-position-p"> 1. 市场营销、国际贸易、电子商务、财会类相关专业，大专以上学历；</p>
          <p class="join-position-p">2. 具有较好的谈判技巧，熟悉商务流程，能够独立接待客户。</p>  
          <p class="join-position-p">3. 能自觉主动的工作、学习；能熟练操作相关现代化办公设备。 </p>  
          <p class="join-position-p">4. 良好的团队精神、职业道德修养和职业形象。 </p> 
          <p class="join-position-p">5. 原则性、自律性、责任心强、有一定的抗压能力；诚实守信，做事稳重，敬业爱岗，具备良好的保密意识。 </p> 
          <p class="join-position-p1">招聘地区：北京、上海、广州、深圳，期待您的加入！</p>
          <p class="join-position-email">简历请投递至： hr@kuaiqiwu.com</p>
        </div>
        <div class="join-position-right" v-if="(tab == 2)">
          <p class="join-position-title">签证专员</p>
          <p class="join-position-title1">岗位职责：</p>
          <p class="join-position-p">1、英语四级4级以上，英语可作为阅读语言；</p>
          <p class="join-position-p">2、对热门国家的签证流程和规则非常熟悉；</p>  
          <p class="join-position-p">3、负责签证资料的整理、翻译、审查、填写、校对及送签的准备工作；</p>  
          <p class="join-position-p">4、及时了解各使馆签证动态，获取签证最新的准确信息； </p> 
          <p class="join-position-p"> 5、可外勤前往领馆送取签证。 </p> 
          <p class="join-position-title1">任职要求： </p>  
          <p class="join-position-p">1、大专及以上学历，英语4级以上；</p>
          <p class="join-position-p">2、有1年以上各国送签经验及相关旅行社工作经验者，熟悉签证工作流程；</p>  
          <p class="join-position-p">3、有良好的英文读写能力，具备可独立完成各种签证资料和信函的中英文翻译工作； </p>  
          <p class="join-position-p">4、对签证知识有足够的了解和对签证有专业的独立操作的能力； </p> 
          <p class="join-position-p">5、为人正直诚信，责任心强，对工作有高度的责任心，具备良好的团队精神。</p> 
          <p class="join-position-p1">招聘地区：深圳 </p>
          <p class="join-position-email">简历请投递至： hr@kuaiqiwu.com</p>
        </div>
      </div>
    </div>
    <div class="join-cooperation">
      <div class="join-cooperation-box">
        <p class="join-cooperation-p1">商务合作</p>
        <p class="join-cooperation-p2">公司旗下拥有“离岸岛”、“快企务”品牌，离岸岛品牌创建于2014年8月，定位为全球公司设立服务商。快企务品牌创建于2018年10月，定位为涉内外企业服务综合平台。十余年来，金百利一直致力于为客户提供一站式的涉内外企业咨询服务，拥有一支超100人的离岸、会计、税务、金融、法律、知产等高级顾问团队；欢迎更多合作伙伴加入我们！</p>
        <p class="join-cooperation-p3">咨询电话：400 860 5600</p>
      </div>
    </div>
    <Footer></Footer>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'bank-page',
  components: {
    Footer
  },
  data(){
    return{
      tab: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
.join-header{
  width: 100%;
  height: 600px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
.join-cooperation{
  width: 100%;
  height: 294px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/join/pic_3.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
