import { render, staticRenderFns } from "./fiscalTax.vue?vue&type=template&id=ac4eadd8&scoped=true&"
import script from "./fiscalTax.vue?vue&type=script&lang=js&"
export * from "./fiscalTax.vue?vue&type=script&lang=js&"
import style0 from "./fiscalTax.vue?vue&type=style&index=0&id=ac4eadd8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac4eadd8",
  null
  
)

export default component.exports