<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式意大利公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="Italy-adv">
      <p class="re-title">注册意大利公司的优势</p>
      <div class="Italy-adv-box center-width flex-between">
        <div class="Italy-adv-inner">
          <img class="Italy-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_1_3.png" alt="">
          <p class="Italy-adv-p">以使用意大利的公司与品牌返销国内与贴牌销售，占据高中档消费市场</p>
        </div>
        <div class="Italy-adv-inner">
          <img class="Italy-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_1_2.png" alt="">
          <p class="Italy-adv-p">成立公司后，以股东身份申请意大利商业居留、移民，并可置业、投资、上市</p>
        </div>
        <div class="Italy-adv-inner">
          <img class="Italy-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_1_3.png" alt="">
          <p class="Italy-adv-p">用意大利的公司与品牌将产品行销世界，进入中高档消费市场</p>
        </div>
        <div class="Italy-adv-inner">
          <img class="Italy-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_1_4.png" alt="">
          <p class="Italy-adv-p">意大利公司的国际诚信度很高，原产地文化各品牌影响力居世界前列，公司审批相对严格，需要有居留权人担任公司法定代表，需要验资或担保等</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册意大利公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/pic_1_3.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/pic_1_4.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">代表处 </p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">分公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">有限责任公司<br />（S.R.L）</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 3">股份有限公司<br />（S.P.A）</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>  
    </div>
    <div class="indonesia-file">
      <p class="re-title">注册意大利公司的条件/流程/资料</p>
      <div class="indonesia-file-box center-width flex-between">
        <div class="indonesia-file-left">
          <div class="indonesia-file-tab" :class="{'active': tab == 1}" @click="tab = 1">注册意大利公司条件</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 2}" @click="tab = 2">注册意大利公司流程</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</div>
        </div>
        <div class="indonesia-file-right">
          <div class="indonesia-file-right-box1" v-if="tab == 1">
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东成员：意大利允许外国自然人或企业法人担任公司股东/董事，要求资信良好，年龄须满18周岁，且至少有2名或2名以上股东；意大利公司法规定，必须有一名或一名以上股东为意大利籍人士或在意大利拥有居留权人士
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              外国企业法人担任意大利公司股东：外国企业法人担任意大利公司股东的，必须递交所在国有效的企业法人营业执照
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              法定董事：意大利公司法规定，法定董事必须是意大利籍人士或在意大利拥有十年居留权人士担任，无犯罪记录，商业信用良好
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              注册资本：意大利普通有限责任公司最低注册资本10,000欧元
            </p>
          </div>
          <div class="indonesia-file-right-box1" v-if="tab == 2">
            <img style="width: 745px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/pic.png" alt="">
          </div>
          <div class="indonesia-file-right-box3" v-if="tab == 3">
            <div class="indonesia-file-tab3">
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_4_1.png" alt="">
                <p class="indonesia-file-tab3-p">公司注册证书</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_4_2.png" alt="">
                <p class="indonesia-file-tab3-p">公司章程</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_4_3.png" alt="">
                <p class="indonesia-file-tab3-p">公司股东会议<br />记录簿</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_4_4.png" alt="">
                <p class="indonesia-file-tab3-p">法人公章、钢印</p>
              </div>
              <div class="indonesia-file-tab3-inner">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/icon_4_5.png" alt="">
                <p class="indonesia-file-tab3-p">意大利华侨华人<br />贸易总会出具的<br />《公证书》</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册意大利公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">意大利公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="意大利公司对于注册资本有什么要求？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">意大利普通有限责任公司标准注册资本10000欧元，公司名称以SRL结尾。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="意大利公司名称可以随便起吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">意大利公司名称选择所选名称不能与已注册名称相同或太相似。公司名称也不能含有与法国政府相关或保险等<br />
                字样，除非另外行申请相关的牌照。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="名义经理和注册地址要求？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">注册意大利公司须安排一位或以上名义经理，名义经理为意大利公司合法代表人，必须由意大利当地公民担任；<br />
                注册地址须安排在意大利本地。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">十余年注册意大利公司经验</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  mounted(){
    window.scrollTo(0,0);
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Italy/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
