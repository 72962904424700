<template>
  <div class="anquila netherlands">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式荷兰公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="french-advantage nether-adv">
      <p class="re-title">注册荷兰公司优势</p>
      <div class="french-adv-box center-width flex-between">
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_1_1.png" alt="">
            <p class="french-adv-con1-p">荷兰实行低税收制度</p>
          </div>
          <div class="french-adv-hover">
            <p class="french-adv-hover-p">荷兰实行低税收制度</p>
            <p class="french-adv-con1-p1">企业所得税低至25.5%，低于英国、法国、德国、比利时等</p>
            <p class="ask-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_1_2.png" alt="">
            <p class="french-adv-con1-p">荷兰拥有欧洲优质的经商环境</p>
          </div>
          <div class="french-adv-hover">
            <p class="french-adv-hover-p">荷兰拥有欧洲优质的经商环境</p>
            <p class="french-adv-con1-p1">荷兰位于欧洲西部，是欧盟成员国，东与德国为邻，南邻比利时</p>
            <p class="ask-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_1_3.png" alt="">
            <p class="french-adv-con1-p">荷兰公司关税递延</p>
          </div>
          <div class="french-adv-hover">
            <p class="french-adv-hover-p">荷兰公司关税递延</p>
            <p class="french-adv-con1-p1">关税和增值税实行递延政策，缓解企业资金压力</p>
            <p class="ask-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_1_4.png" alt="">
            <p class="french-adv-con1-p">荷兰无外汇管制</p>
          </div>
          <div class="french-adv-hover">
            <p class="french-adv-hover-p">荷兰无外汇管制</p>
            <p class="french-adv-con1-p1">荷兰公司注册后方便从事国际贸易的客户</p>
            <p class="ask-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_1_5.png" alt="">
            <p class="french-adv-con1-p">荷兰公司参股所得免税</p>
          </div>
          <div class="french-adv-hover">
            <p class="french-adv-hover-p">荷兰公司参股所得免税</p>
            <p class="french-adv-con1-p1">即对从合格持股所得红利和转让合同股权的资本收益不征企业所得税</p>
            <p class="ask-btn" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="nether-type">
      <p class="re-title">注册荷兰公司类型</p>
      <div class="nether-type-box center-width">
        <el-carousel :interval="5000" arrow="hover" height="366px" indicator-position="outside" :autoplay="true">
          <el-carousel-item>
            <div class="nether-type-box1">
              <div class="korea-type-inner">
                <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/pic_2_1.png" alt="">
                <p class="korea-type-p">私营有限责任公司</p>
                <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
              </div>
              <div class="korea-type-inner">
                <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/pic_2_2.png" alt="">
                <p class="korea-type-p">公共有限责任公司</p>
                <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="nether-type-box1">
              <div class="korea-type-inner">
                <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/pic_2_3.png" alt="">
                <p class="korea-type-p">合作社</p>
                <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
              </div>
              <div class="korea-type-inner">
                <img class="korea-type-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/pic_2_4.png" alt="">
                <p class="korea-type-p">分公司和代表处</p>
                <p class="korea-type-btn" @click="Dialog = true">立即咨询</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="nether-file">
      <p class="re-title">注册荷兰公司条件/流程/资料</p>
      <div class="nether-file-box center-width">
        <img class="nether-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/pic_3.png" alt="">
      </div>
    </div>
    <div class="subsequent subsequent-b nether-serve">
      <p class="re-title">注册荷兰公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p3">依照荷兰的税务管理法律规定，个人并不能申请荷兰的VAT税号，申请荷兰公司的...</p>
          <p class="sub-p4">依照荷兰的税务管理法律规定，个人并不能申请荷兰的VAT税号，申请荷兰公司的VAT税号也需要提供较多资料</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">在荷兰注册公司，必须开立当地银行帐户。公司注册成立后，公司股东董事无须...</p>
          <p class="sub-p4">在荷兰注册公司，必须开立当地银行帐户。公司注册成立后，公司股东董事无须亲临银行办理，我们会备妥开户文件并全程协助协调</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">主要氛围直接税和间接税。直接税包括企业所得税、预提税；间接税包括增值税...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">公司年审：1.提前一个月通知公司年审，准备相关年审文件...</p>
          <p class="sub-p4">公司年审：1.提前一个月通知公司年审，准备相关年审文件<br />
          2.递交年审文件，政府审核，约5-7个工作日3.年审完成，邮寄领取年检证书等文件。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/icon_2_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">荷兰公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册荷兰公司，公司名称有何限制？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">公司名需要是英文名，注册前需要先核名，查询是否近似或重复</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="公司董事股东必须是荷兰人吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">不是必须条件。董事股东的所有资料文件需要翻译成英文，做公证后才能用于公司注册，需要做最终受益人(UBO）<br />
                登记备案</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="母子公司的业务范围要完全相同么？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">子公司的经营范围不一定要和母公司相同，可以适当扩宽经营范围。申请人登陆到荷兰拿到5年居留卡后，还可<br />
                以对荷兰公司经营范围进行增项。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业荷兰公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Netherlands/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
