<template>
  <div class="anquila">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式加拿大公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="vietnam-adv" style="height: 738px">
      <p class="re-title">注册加拿大公司的优势</p>
      <div class="vietnam-adv-box">
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_1.png" alt="">
          <p class="vietnam-adv-p1">18岁以后在加拿大居住10年（拿到绿卡），可以享受加拿大退休养老保障，养老金根据国家经济发展情况逐年递增。无论您那是在全球的任何地方度假或居住都能得到政府的支票</p>
        </div>
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_2.png" alt="">
          <p class="vietnam-adv-p1">投资移民仅限于做出投资，有权选择在加拿大做生意或不做生意</p>
        </div>
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_3.png" alt="">
          <p class="vietnam-adv-p1">投资移民在所有移民申请中享受VIP服务，最优先审理</p>
        </div>
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_4.png" alt="">
          <p class="vietnam-adv-p1">无风险，面试通过后将资金注入</p>
        </div>
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_5.png" alt="">
          <p class="vietnam-adv-p1">注册温哥华公司移民温哥华无年龄、语言、学历要求</p>
        </div>
        <div class="vietnam-adv-inner" style="width: 252px">
          <img class="vietnam-adv-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_1_6.png" alt="">
          <p class="vietnam-adv-p1">享受加拿大全部公费医疗保障及社会福利</p>
        </div>
      </div>
    </div>
    <div class="anquila-type">
      <p class="re-title">注册加拿大公司类型</p>
      <div class="anquila-type-box center-width flex-between">
        <div class="anquila-type-left">
          <el-carousel :interval="5000" arrow="always" height="380px" @change="changeTab" indicator-position="inside" :autoplay="true">
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/pic_1_1.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/pic_1_2.jpg" alt="">
            </el-carousel-item>
            <el-carousel-item>
              <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/pic_1_3.jpg" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="anquila-type-right">
          <p class="anquila-type-p1" v-if="changeTabIndex == 0">加拿大独资公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 1">加拿大合资公司</p>
          <p class="anquila-type-p1" v-if="changeTabIndex == 2">加拿大有限公司</p>
          <p class="anquila-type-p2" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="indonesia-file">
      <p class="re-title">注册加拿大公司的条件/流程/资料</p>
      <div class="indonesia-file-box center-width flex-between">
        <div class="indonesia-file-left">
          <div class="indonesia-file-tab" :class="{'active': tab == 1}" @click="tab = 1">注册加拿大公司条件</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 2}" @click="tab = 2">注册加拿大公司流程</div>
          <div class="indonesia-file-tab" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</div>
        </div>
        <div class="indonesia-file-right">
          <div class="indonesia-file-right-box1" v-if="tab == 1">
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              没有资金资本要求，不需要验资
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              公司名称可以用数字字母，但不可以与其他公司名称相同或相似，以“有限公司”、“责任有限公司”字样或缩写结尾，外国公司在加拿大的分公司可在名称加上“加拿大”以与其母公司相区别
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              董事最少一名，年满18周岁，无犯罪记录，信誉良好，无国籍限制。可以是自然人但不能是法人，有些省份要求有当地或永久居留权的董事占比 25% 或 30% 以上，温哥华注册公司无需提供当地董事
            </p>
            <p class="indonesia-file-tab1-p">
              <img class="indonesia-file-tab1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Mauritius/icon_2_right.png" alt="">
              股东最少一名，年满18周岁，可以是自然人或法人，无犯罪记录，信誉良好
            </p>
          </div>
          <div class="indonesia-file-right-box1" v-if="tab == 2">
            <img style="width: 745px;" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Vietnam/pic.png" alt="">
          </div>
          <div class="indonesia-file-right-box3" v-if="tab == 3">
            <div class="indonesia-file-tab3" style="margin-top: 80px">
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_1.png" alt="">
                <p class="indonesia-file-tab3-p">经营证</p>
              </div>
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_2.png" alt="">
                <p class="indonesia-file-tab3-p">证明文件<br />（NUANS）</p>
              </div>
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_3.png" alt="">
                <p class="indonesia-file-tab3-p">股份证明书2本</p>
              </div>
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_4.png" alt="">
                <p class="indonesia-file-tab3-p">会议记录1本</p>
              </div>
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_5.png" alt="">
                <p class="indonesia-file-tab3-p">公司原子签名<br />章及金属钢印</p>
              </div>
              <div class="indonesia-file-tab3-inner canada-tab3">
                <img class="indonesia-file-tab3-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/icon_4_6.png" alt="">
                <p class="indonesia-file-tab3-p">公司股东、董<br />事及秘书资料<br />文件1份</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册加拿大公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_5renewal.png" alt="">
          <p class="sub-p1">公司续牌/公司年审</p>
          <p class="sub-p3">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影...</p>
          <p class="sub-p4">所有公司须按年度定时递交包含股东、董事、公司秘书和其他任何当年可能影响公司行政管理结构详情信息的公司年度报表</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证...</p>
          <p class="sub-p4">办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Japan/icon_5_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p2">因破产、股东决议解散、存续期届满等原因需要注销的，应及时主动注销公司</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">加拿大公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="加拿大注册公司的注册类型?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">联邦公司注册和注册省级公司，加拿大省级企业主要有三种形式，即独资/合资企业/有限公司。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="加拿大公司每年是否必需要做年检?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">是的。加拿大公司如果连续两年不年检，BC Registry部门会关闭相应的公司。如果想要维持公司的存续，必<br />
                须做年检，而且需要另外支付地址服务费用。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="加拿大公司注册时间需要多久？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">注册加拿大公司一般在递交完资料后两-三个月完成。由于文件的寄送和具体问题的往返可能会有所延误，但<br />
                一般都不会发生这种问题，具体时间可以点击咨询在线顾问。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业加拿大公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1',
      changeTabIndex: 0
    }
  },
  methods: {
    changeTab(e){
      this.changeTabIndex = e
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Canada/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
