<template>
  <div class="certification">
    <div class="certification-header">
      <div class="certification-inner center-width">
        <p class="video-banner-p">公证认证一站式服务</p>
        <p class="certification-p2">视频委托公证认证</p>
        <p class="certification-p3"> 加急一天出件丨全国受理  不限户籍丨10+年办理经验</p>
        <p class="certification-btn" @click="Dialog = true">立即办理</p>
      </div>
    </div>
    <div class="box">
      <p class="b-title">什么情况下需要办理视频委托公证？</p>
      <p class="b-title1">由于其他原因，人在国外/异地，无法亲自办理，可以视频委托进行相关事项的代办</p>
      <div class="video-box1">
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_1.png" alt="">
          <p class="video-box1-p1">线上办理</p>
          <p class="video-box1-p2">无需本人亲自到场，高效便捷</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_2.png" alt="">
          <p class="video-box1-p1">无需预约</p>
          <p class="video-box1-p2">无需原件、无需排队</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_3.png" alt="">
          <p class="video-box1-p1">当场审核</p>
          <p class="video-box1-p2">效率高，出证快</p>
        </div>
        <div class="video-box1-inner">
          <img class="video-box1-icon" src="../../assets/img/video/pic_1_4.png" alt="">
          <p class="video-box1-p1">全国办理</p>
          <p class="video-box1-p2">不限户籍，1对1服务</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="b-title">电子签名公证适用的场景</p>
      <p class="b-title1">（涉及继承、人身关系类的事项不能适用电子签名）</p>
      <div class="video-box2">
        <div class="video-box2-inner inner1">
          <p class="video-box2-p1">声明书/同意书</p>
          <p class="video-box2-p2">未成年人出国留学、父母资助</p>
        </div>
        <div class="video-box2-inner inner2">
          <p class="video-box2-p1" style="padding-top: 114px;">委托书</p>
          <p class="video-box2-p2">处理各类财产类/非财产类事务</p>
        </div>
        <div class="video-box2-inner inner2">
          <p class="video-box2-p1" style="padding-top: 49px;">债权文书赋予强制执行效力</p>
          <p class="video-box2-p2">经公证的借款、抵押等债权文书，当债务人不履行还款义务时，债权人不用到法院起诉，可直接向公证处申请出具执行证书，直接向人民法院申请强制执行。</p>
        </div>
        <div class="video-box2-inner inner2">
          <p class="video-box2-p1" style="padding-top: 90px;">离婚财产分割补充/变更协议</p>
          <p class="video-box2-p2">对离婚时未分割的财产进行补充约定或重新约定财产归属</p>
        </div>
        <div class="video-box2-inner inner2">
          <p class="video-box2-p1" style="padding-top: 114px;">夫妻财产协议</p>
          <p class="video-box2-p2">对婚前或婚后取得的财产归属进行约定</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="b-title">电子签名公证事项简介</p>
      <div class="video-box3">
        <div class="video-box3-left">
          <p class="video-box3-p1">当您身处外地或国外，不便亲自办理相关事务（设立或注销公司、买卖房产、领取公积金、未成年人出国签证、签订合同、夫妻分割财产等），电子签名公证可以帮您解决。您不用前往公证处，也不用前往中国驻国外的使领馆，只需一部手机就能轻松搞定。</p>
          <p class="video-box3-p2">立即咨询</p>
        </div>
        <img class="video-box3-right" src="../../assets/img/video/pic_3.png" alt="">
      </div>
    </div>

    <div class="box" style="background-color: rgba(242, 245, 250, 1);margin-top: 60px;padding-bottom: 60px;">
      <p class="b-title">公证分类</p>
      <p class="b-title1">以客户需求和产品发展理念为驱动力</p>
      <div class="video-box4">
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_1.jpg" alt="">
          <p class="video-box4-p">委托书公证 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_2.jpg" alt="">
          <p class="video-box4-p">营业执照公证 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_3.jpg" alt="">
          <p class="video-box4-p">护照公证 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_4.jpg" alt="">
          <p class="video-box4-p">放弃继承声明公证书 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_5.jpg" alt="">
          <p class="video-box4-p">房产证公证 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_6.jpg" alt="">
          <p class="video-box4-p">签名公证书 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_7.jpg" alt="">
          <p class="video-box4-p">公司章程公证书 </p>
        </div>
        <div class="video-box4-inner">
          <img class="video-box4-img" src="../../assets/img/video/pic_4_8.jpg" alt="">
          <p class="video-box4-p">在职证明公证 </p>
        </div>
      </div>
      <p class="video-box4-btn">查看更多公证</p>
    </div>

    <div class="box">
      <p class="b-title">办理流程</p>
      <p class="b-title1">以客户需求和产品发展理念为驱动力</p>
      <div class="video-box5">
        <div class="video-box5-inner">
          <img class="video-box5-icon" src="../../assets/img/video/pic_5_1.png" alt="">
          <p class="video-box5-p">客户咨询下单</p>
        </div>
        <img class="video-box5-arrow" src="../../assets/img/video/pic_5_5.png" alt="">
        <div class="video-box5-inner">
          <img class="video-box5-icon" src="../../assets/img/video/pic_5_2.png" alt="">
          <p class="video-box5-p">审核材料</p>
        </div>
        <img class="video-box5-arrow" src="../../assets/img/video/pic_5_5.png" alt="">
        <div class="video-box5-inner">
          <img class="video-box5-icon" src="../../assets/img/video/pic_5_3.png" alt="">
          <p class="video-box5-p">线上视频审核</p>
        </div>
        <img class="video-box5-arrow" src="../../assets/img/video/pic_5_5.png" alt="">
        <div class="video-box5-inner">
          <img class="video-box5-icon" src="../../assets/img/video/pic_5_4.png" alt="">
          <p class="video-box5-p">最快当天出公证书</p>
        </div>
      </div>
    </div>

    <div class="box">
      <p class="b-title">办理流程</p>
      <p class="b-title1">以客户需求和产品发展理念为驱动力</p>
      <div class="video-box6">
        <div class="video-box6-inner">
          <img class="video-box6-icon" src="../../assets/img/video/pic_6_1.png" alt="">
          <div class="video-box6-right">
            <p class="video-box6-p1">严格保密</p>
            <p class="video-box6-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
          </div>
        </div>
        <div class="video-box6-inner">
          <img class="video-box6-icon" src="../../assets/img/video/pic_6_2.png" alt="">
          <div class="video-box6-right">
            <p class="video-box6-p1">透明公开</p>
            <p class="video-box6-p2">价格公开透明，公证认证办理费用公开透明可查</p>
          </div>
        </div>
        <div class="video-box6-inner">
          <img class="video-box6-icon" src="../../assets/img/video/pic_6_3.png" alt="">
          <div class="video-box6-right">
            <p class="video-box6-p1">全天跟进</p>
            <p class="video-box6-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
          </div>
        </div>
        <div class="video-box6-inner">
          <img class="video-box6-icon" src="../../assets/img/video/pic_6_4.png" alt="">
          <div class="video-box6-right">
            <p class="video-box6-p1">退费承诺</p>
            <p class="video-box6-p2">公证拒办退款，客户公证材料被拒全额退还费用</p>
          </div>
        </div>
      </div>
    </div>

    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="什么是在线远程公证？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">在线公证是通过视频连线，人脸识别等技术手段，发起、受理、缴费及出具公证书等全部流程都无需到与公证员碰面，也无需亲赴公证处现场，仅一部手机即可完成办理。事后通过快递寄送公证书。在线公证大幅提高了群众办事效率和便捷性。是近几年技术成熟后，国内少数公证处新推出的公证便民服务。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="网上公证和线下一样吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">线上办理公证是一个新生事物，很多人并没有接触过。担心网上公证和公证 处现场公证的是不一样，但实际上，不论是网上公证还是现场公证都是一样的， 区别在于办理渠道不同，网上公证材料需要在线提交，而现场申请需要客户带着 材料到公正机构的柜台办理。两种没有本质区别。 目前国内只有少数公证处开放线上业务，并且每家受理范围和受理条件不一样， 我们作为第三方协办，会根据你的需求，选择不同的接口，无需客户本人到场，节约时间。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="可申请办理海外远程视频公证的事项包括哪些？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">声明、委托（包括涉房产、股权、继承等财产类事务）、婚姻状况、国籍、姓名、 出生、死亡、亲属关系、无犯罪记录、经历、学历、证书（执照）、文书上的签名、印 鉴、文本相符公证等。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>

    <div class="cer-case">
      <p class="b-title">电子签名公证案例</p>
      <div class="cer-case-box center-width flex-between">
        <img src="../../assets/img/video/pic_7_1.jpg" alt="">
        <img src="../../assets/img/video/pic_7_2.jpg" alt="">
        <img src="../../assets/img/video/pic_7_3.jpg" alt="">
        <img src="../../assets/img/video/pic_7_4.jpg" alt="">
      </div>
    </div>

    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .certification-header{
    width: 100%;
    height: 590px;
    background: url('../../assets/img/video/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
    padding-top: 109px;
  }
  .video-banner-p{
    width: 353px;
    height: 95px;
    background: url('../../assets/img/video/banner_1.png') no-repeat center center;
    background-size: 100% 100%;
    font-size: 30px;
    color: #fff;
    line-height: 85px;
    text-align: center;
  }
  .inner1{
    background: url('../../assets/img/video/pic_2_1.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner2{
    background: url('../../assets/img/video/pic_2_2.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner3{
    background: url('../../assets/img/video/pic_2_3.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner4{
    background: url('../../assets/img/video/pic_2_4.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .inner5{
    background: url('../../assets/img/video/pic_2_5.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
</style>
