<template>
    <div class="embassy">
      <div class="notarization-header">
        <div class="center-width">
          <p class="notarization-p1 embassy-header-p2">【快企务专注于涉内外企业服务十余年】</p>
          <p class="notarization-p2">使馆认证快速办理</p>
          <p class="notarization-p1 embassy-header-p1">留学 | 移民 | 探亲 | 旅游 | 工作 | 商务</p>
          <p class="notarization-btn" @click="Dialog = true">立即咨询</p>
        </div>
      </div>
      <div class="emba-use center-width">
        <p class="b-title">首先要确定您的文件在国外使用，还是国外的文件在国内使用？</p>
        <p class="b-title1">选择您的使馆认证办理信息</p>
        <div class="emba-use-box">
            <div class="emba-use-left">
              <p class="emba-use-left-p">文件类型</p>
              <p class="emba-use-left-p emba-use-left-p1">使用方式</p>
            </div>
            <div class="emba-use-right">
              <el-form :inline="true" :model="form" ref="ruleForm" :rules="rules" class="demo-form-inline" label-width="0" >
                    <el-form-item label="" prop="file">
                      <el-select class="select1" v-model="form.file" placeholder="请选择文件类别">
                        <el-option label="商用文件" value="商用文件"></el-option>
                        <el-option label="民事文件" value="民事文件"></el-option>
                      </el-select>
                    </el-form-item>
                      <el-form-item label="" prop="type">
                        <el-select v-if="form.file == '商用文件'" class="input-length two-margin" v-model="form.type" placeholder="请选择文件类别">
                          <el-option label="营业执照" value="营业执照"></el-option>
                          <el-option label="注册证书" value="注册证书"></el-option>
                          <el-option label="公司章程" value="公司章程"></el-option>
                          <el-option label="董事信息" value="董事信息"></el-option>
                          <el-option label="股东信息" value="股东信息"></el-option>
                          <el-option label="法人资格" value="法人资格"></el-option>
                          <el-option label="公司更名文件" value="公司更名文件"></el-option>
                          <el-option label="自由贸易证书" value="自由贸易证书"></el-option>
                          <el-option label="协议书" value="协议书"></el-option>
                          <el-option label="授权书认证" value="授权书认证"></el-option>
                          <el-option label="声明书" value="声明书"></el-option>
                          <el-option label="证明书" value="证明书"></el-option>
                          <el-option label="委派书" value="委派书"></el-option>
                          <el-option label="商标证书" value="商标证书"></el-option>
                          <el-option label="资产证明" value="资产证明"></el-option>
                          <el-option label="资信证明" value="资信证明"></el-option>
                          <el-option label="会议记录" value="会议记录"></el-option>
                          <el-option label="发票" value="发票"></el-option>
                          <el-option label="原产地证" value="原产地证"></el-option>
                          <el-option label="报关单" value="报关单"></el-option>
                          <el-option label="产品证书" value="产品证书"></el-option>
                          <el-option label="产品介绍及其他公司文件等" value="产品介绍及其他公司文件等"></el-option>
                        </el-select>
                        <el-select v-if="form.file == '民事文件'" class="input-length two-margin" v-model="form.type" placeholder="请选择文件类别">
                          <el-option label="毕业证" value="毕业证"></el-option>
                          <el-option label="结婚证" value="结婚证"></el-option>
                          <el-option label="学历证明" value="学历证明"></el-option>
                          <el-option label="出生证" value="出生证"></el-option>
                          <el-option label="委托书" value="委托书"></el-option>
                          <el-option label="离婚证明" value="离婚证明"></el-option>
                          <el-option label="无犯罪证明" value="无犯罪证明"></el-option>
                          <el-option label="死亡证明" value="死亡证明"></el-option>
                          <el-option label="成绩单" value="成绩单"></el-option>
                          <el-option label="亲属关系" value="亲属关系"></el-option>
                          <el-option label="个人专利" value="个人专利"></el-option>
                          <el-option label="其他个人证明" value="其他个人证明"></el-option>
                        </el-select>
                        <el-select v-if="form.file == '' " class="input-length two-margin" v-model="form.type" placeholder="请选择文件类别">
                        </el-select>
                      </el-form-item>
                      <el-form-item label="" prop="name">
                        <el-input class="input-length" v-model="form.name" placeholder="请输入您的姓名"></el-input>
                      </el-form-item>
                      <el-form-item label="" prop="mobile">
                        <el-input class="input-length" v-model="form.mobile" placeholder="请输入您的手机号码"></el-input>
                      </el-form-item>
                    <el-form-item prop="range">
                      <el-select  class="select1" v-model="form.range" placeholder="请选择文件类别">
                        <el-option label="国内使用" value="国内使用"></el-option>
                        <el-option label="国外使用" value="国外使用"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="country">
                      <el-select class="input-length two-margin" v-if="form.range == '国内使用'" v-model="form.country" placeholder="请选择文件类别">
                        <el-option label="韩国" value="韩国"></el-option>
                        <el-option label="阿联酋" value="阿联酋"></el-option>
                        <el-option label="日本" value="日本"></el-option>
                        <el-option label="新加坡" value="新加坡"></el-option>
                        <el-option label="印度" value="印度"></el-option>
                        <el-option label="印度尼西亚" value="印度尼西亚"></el-option>
                        <el-option label="马来西亚" value="马来西亚"></el-option>
                        <el-option label="德国" value="德国"></el-option>
                        <el-option label="法国" value="法国"></el-option>
                        <el-option label="英国" value="英国"></el-option>
                        <el-option label="意大利" value="意大利"></el-option>
                        <el-option label="荷兰" value="荷兰"></el-option>
                        <el-option label="希腊" value="希腊"></el-option>
                        <el-option label="比利时" value="比利时"></el-option>
                        <el-option label="美国" value="美国"></el-option>
                        <el-option label="加拿大" value="加拿大"></el-option>
                        <el-option label="巴西" value="巴西"></el-option>
                        <el-option label="墨西哥" value="墨西哥"></el-option>
                        <el-option label="阿根廷" value="阿根廷"></el-option>
                        <el-option label="肯尼亚" value="肯尼亚"></el-option>
                        <el-option label="南非" value="南非"></el-option>
                        <el-option label="利比亚" value="利比亚"></el-option>
                        <el-option label="阿尔及利亚" value="阿尔及利亚"></el-option>
                        <el-option label="赞比亚" value="赞比亚"></el-option>
                        <el-option label="乌干达" value="乌干达"></el-option>
                        <el-option label="埃及" value="埃及"></el-option>
                        <el-option label="澳大利亚" value="澳大利亚"></el-option>
                        <el-option label="新西兰" value="新西兰"></el-option>
                        <el-option label="其他国家" value="其他国家"></el-option>
                      </el-select>
                      <el-select class="input-length two-margin" v-if="form.range == '国外使用'" v-model="form.country" placeholder="请选择文件类别">
                        <el-option label="韩国" value="韩国"></el-option>
                        <el-option label="印度" value="印度"></el-option>
                        <el-option label="新加坡" value="新加坡"></el-option>
                        <el-option label="马来西亚" value="马来西亚"></el-option>
                        <el-option label="德国" value="德国"></el-option>
                        <el-option label="法国" value="法国"></el-option>
                        <el-option label="英国" value="英国"></el-option>
                        <el-option label="俄罗斯" value="俄罗斯"></el-option>
                        <el-option label="美国三级认证" value="美国三级认证"></el-option>
                        <el-option label="加拿大" value="加拿大"></el-option>
                        <el-option label="哥伦比亚" value="哥伦比亚"></el-option>
                        <el-option label="厄瓜多尔" value="厄瓜多尔"></el-option>
                        <el-option label="南非" value="南非"></el-option>
                        <el-option label="摩洛哥" value="摩洛哥"></el-option>
                        <el-option label="赞比亚" value="赞比亚"></el-option>
                        <el-option label="纳米比亚" value="纳米比亚"></el-option>
                        <el-option label="澳大利亚" value="澳大利亚"></el-option>
                        <el-option label="新西兰" value="新西兰"></el-option>
                        <el-option label="斐济" value="斐济"></el-option>
                        <el-option label="瓦努阿图" value="瓦努阿图"></el-option>
                      </el-select>
                      <el-select class="input-length two-margin" v-if="form.range == ''" v-model="form.country" placeholder="请选择文件类别">
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <p class="emba-use-btn" @click="toSubmit">计算价格</p>
                    </el-form-item>
              </el-form>
            </div>
        </div>
      </div>
      <div class="emba-banner center-width">
        <img class="emba-banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_1.png" alt="">
        <p class="emba-banner-p1">商业文件 & 民事文件</p>
        <p class="emba-banner-p2">以下为常规办理文件</p>
      </div>

      <div class="emba-file center-width">
        <div class="emba-file-box flex-between">
            <div class="emba-file-left">
              <div class="emba-file-title">
                <p class="file-title-p">商业文件</p>
              </div>
              <div class="emba-file-content">
                <p class="emba-file-p">营业执照</p>
                <p class="emba-file-p">注册证书</p>
                <p class="emba-file-p">公司章程</p>
                <p class="emba-file-p">董事信息</p>
                <p class="emba-file-p">股东信息</p>
                <p class="emba-file-p">法人资格</p>
                <p class="emba-file-p">公司更名文件</p>
                <p class="emba-file-p">自由贸易证书</p>
                <p class="emba-file-p">协议书</p>
                <p class="emba-file-p">授权书认证</p>
                <p class="emba-file-p">声明书</p>
                <p class="emba-file-p">证明书</p>
                <p class="emba-file-p">委派书</p>
                <p class="emba-file-p">商标证书</p>
                <p class="emba-file-p">资产证明</p>
                <p class="emba-file-p">资信证明</p>
                <p class="emba-file-p">会议记录</p>
                <p class="emba-file-p">发票</p>
                <p class="emba-file-p">原产地证</p>
                <p class="emba-file-p">报送单</p>
                <p class="emba-file-p">产品证书</p>
                <p class="emba-file-p">产品介绍</p>
                <p class="emba-file-p">其他公司文件</p>
              </div>
              <p class="emba-file-btn">咨询办理</p>
            </div>
            <div class="emba-file-left">
              <div class="emba-file-title">
                <p class="file-title-p">民事文件</p>
              </div>
              <div class="emba-file-content">
                <p class="emba-file-p">毕业证</p>
                <p class="emba-file-p">结婚证</p>
                <p class="emba-file-p">学历证明</p>
                <p class="emba-file-p">出生证</p>
                <p class="emba-file-p">委托书</p>
                <p class="emba-file-p">离婚证明</p>
                <p class="emba-file-p">无犯罪证明</p>
                <p class="emba-file-p">死亡证明</p>
                <p class="emba-file-p">成绩单</p>
                <p class="emba-file-p">亲属关系</p>
                <p class="emba-file-p">个人专利</p>
              </div>
              <p class="emba-file-btn">咨询办理</p>
            </div>
        </div>
      </div>
      <div class="emba-banner center-width">
        <img class="emba-banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_2.png" alt="">
        <p class="emba-banner-p1">中国驻外国使馆认证 & 外国驻中国使馆认证</p>
        <p class="emba-banner-p2">中国驻外使馆业务涵盖、外国驻中国使馆业务涵盖</p>
      </div>
      <div class="emba-approve center-width flex-between">
        <div class="emba-approve-left">
          <div class="emba-approve-title">
            <p class="emba-approve-p">中国驻外国使馆认证</p>
          </div>
          <div class="emba-approve-content">
            <p class="emba-approve-tips"><span class="circle"></span>亚洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">韩国</p>
              <p class="emba-file-p">新加坡</p>
              <p class="emba-file-p">印度</p>
              <p class="emba-file-p">马来西亚</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>欧洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">英国</p>
              <p class="emba-file-p">德国</p>
              <p class="emba-file-p">法国</p>
              <p class="emba-file-p">俄罗斯</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>美洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">美国</p>
              <p class="emba-file-p">加拿大</p>
              <p class="emba-file-p">哥伦比亚</p>
              <p class="emba-file-p">厄瓜多尔</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>非洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">南非</p>
              <p class="emba-file-p">摩洛哥</p>
              <p class="emba-file-p">纳米比亚</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>大洋洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">澳大利亚</p>
              <p class="emba-file-p">新西兰</p>
              <p class="emba-file-p">斐济</p>
              <p class="emba-file-p">瓦努阿图</p>
            </div>
          </div>
          <p class="emba-file-btn" @click="Dialog = true">咨询办理</p>
        </div>
        <div class="emba-approve-left">
          <div class="emba-approve-title">
            <p class="emba-approve-p">外国驻中国使馆认证</p>
          </div>
          <div class="emba-approve-content">
            <p class="emba-approve-tips"><span class="circle"></span>亚洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">韩国</p>
              <p class="emba-file-p">阿联酋</p>
              <p class="emba-file-p">日本</p>
              <p class="emba-file-p">新加坡</p>
              <p class="emba-file-p">印度</p>
              <p class="emba-file-p">印尼</p>
              <p class="emba-file-p">马来西亚</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>欧洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">德国</p>
              <p class="emba-file-p">法国</p>
              <p class="emba-file-p">英国</p>
              <p class="emba-file-p">意大利</p>
              <p class="emba-file-p">荷兰</p>
              <p class="emba-file-p">希腊</p>
              <p class="emba-file-p">比利时</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>美洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">美国</p>
              <p class="emba-file-p">加拿大</p>
              <p class="emba-file-p">巴西</p>
              <p class="emba-file-p">墨西哥</p>
              <p class="emba-file-p">阿根廷</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>非洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">肯尼亚</p>
              <p class="emba-file-p">南非</p>
              <p class="emba-file-p">利比亚</p>
              <p class="emba-file-p">阿尔及利亚</p>
              <p class="emba-file-p">乌干达</p>
              <p class="emba-file-p">埃及</p>
            </div>
            <p class="emba-approve-tips"><span class="circle"></span>大洋洲</p>
            <div class="emba-approve-line">
              <p class="emba-file-p">澳大利亚</p>
              <p class="emba-file-p">新西兰</p>
            </div>
          </div>
          <p class="emba-file-btn" @click="Dialog = true">咨询办理</p>
        </div>
      </div>

      <div class="emba-data center-width">
        <p class="hague-title">使馆认证办理所需资料</p>
        <p class="hague-title1">三类必要资料</p>
        <div class="emba-data-box flex-between">
          <div class="emba-data-inner">
            <img class="emba-data-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_3_1.png" alt="">
            <div class="emba-data-inner">
              <img class="emba-data-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_1_1.png" alt="">
              <p class="emba-data-p">申请人护照和身份信息</p>
            </div>
          </div>
          <div class="emba-data-inner">
            <img class="emba-data-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_3_2.png" alt="">
            <div class="emba-data-inner">
              <img class="emba-data-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_1_2.png" alt="">
              <p class="emba-data-p">文件认证用途、目的</p>
            </div>
          </div>
          <div class="emba-data-inner">
            <img class="emba-data-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_3_3.png" alt="">
            <div class="emba-data-inner">
              <img class="emba-data-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_1_3.png" alt="">
              <p class="emba-data-p">需要认证的文件</p>
            </div>
          </div>
        </div>
      </div>
      <div class="emba-data-time">
        <div class="emba-data-time-box center-width flex-between">
          <div class="emba-data-left">
            <p class="emba-data-p1">使馆认证流程/时间</p>
            <p class="emba-data-p2">全程7~12个工作日</p>
            <p class="emba-data-p3"><span class="time-number">1</span>国际公证人公证</p>
            <p class="emba-data-p3"><span class="time-number">2</span>外交部认证</p>
            <p class="emba-data-p3"><span class="time-number">3</span>驻内/驻外使馆认证</p>
            <p class="blue-bg-btn emba-data-btn" @click="Dialog = true">立即咨询</p>
          </div>
          <div class="emba-data-right">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_4.png" class="emba-data-img" alt="">
          </div>
        </div>
      </div>

      <div class="emba-advantage center-width">
        <p class="hague-title">我们的优势</p>
        <p class="hague-title1">公证认证价格透明、材料翻译认证盖章，提供一站式服务邮寄到家办理</p>
        <div class="emba-adv-box flex-between">
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_1.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">1对1代办</p>
              <p class="emba-adv-p2">公证代办，全程指定专员负责办理</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_2.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">透明公开</p>
              <p class="emba-adv-p2">价格公开透明，公证认证办理费用公开透明可查</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_3.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">专业翻译</p>
              <p class="emba-adv-p2">翻译内容制作，提供专业的涉外材料公证翻译工作</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_4.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">免费盖章</p>
              <p class="emba-adv-p2">免费排版盖章，免费提供公证材料排版认证盖章</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_5.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">全天跟进</p>
              <p class="emba-adv-p2">24小时跟进，每份公证材料专员全天候跟进反馈</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_6.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">退费承诺</p>
              <p class="emba-adv-p2">文件无法使用，全额退还费用</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_7.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">严格保密</p>
              <p class="emba-adv-p2">严密保存材料，公证书办理完成即刻销毁所有材料文件</p>
            </div>
          </div>
          <div class="emba-adv-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/icon_2_8.png" class="emba-adv-img" alt="">
            <div class="emba-adv-inner-box">
              <p class="emba-adv-p1">免费邮寄</p>
              <p class="emba-adv-p2">免排队邮寄到家，办公证不用愁，代排队代办理送到家</p>
            </div>
          </div>
        </div>
      </div>
      <div class="emba-case center-width">
        <p class="hague-title">行业案例</p>
        <p class="hague-title1">公证认证价格透明、材料翻译认证盖章，提供一站式服务邮寄到家办理</p>
        <div class="emba-case-box flex-between">
          <div class="emba-case-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_5_1.png" class="emba-case-img" alt="">
            <p class="emba-case-p1">加拿大使馆认证</p>
            <div class="emba-tag">
              <p class="tag-inner blue-tag">个人</p>
              <p class="tag-inner red-tag">学历证明</p>
              <p class="tag-inner green-tag">加拿大</p>
            </div>
          </div>
          <div class="emba-case-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_5_2.png" class="emba-case-img" alt="">
            <p class="emba-case-p1">美国使馆认证</p>
            <div class="emba-tag">
              <p class="tag-inner blue-tag">公司</p>
              <p class="tag-inner red-tag">商业书</p>
              <p class="tag-inner green-tag">美国</p>
            </div>
          </div>
          <div class="emba-case-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_5_3.png" class="emba-case-img" alt="">
            <p class="emba-case-p1">美国使馆认证</p>
            <div class="emba-tag">
              <p class="tag-inner blue-tag">公司</p>
              <p class="tag-inner red-tag">商业</p>
              <p class="tag-inner green-tag">美国</p>
            </div>
          </div>
          <div class="emba-case-inner">
            <img src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/pic_5_4.png" class="emba-case-img" alt="">
            <p class="emba-case-p1">新加坡使馆认证</p>
            <div class="emba-tag">
              <p class="tag-inner blue-tag">个人</p>
              <p class="tag-inner red-tag">结婚证</p>
              <p class="tag-inner green-tag">新加坡</p>
            </div>
          </div>
        </div>
      </div>
      <div class="hague-consulting center-width">
        <p class="hague-title">咨询服务</p>
        <p class="hague-title1">我们还能为您做</p>
        <div class="hague-consulting-box">
            <div class="hague-consulting-left">
                <div class="consulting-inner">
                    <p class="consulting-p1">海牙认证</p>
                    <p class="consulting-p2">海牙公约组织成员国，目前成员国有102个。如德国、法国、韩国、美国、日本、澳大利亚、中国香港 及中国澳门都是公约成员国</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">国际公证认证</p>
                    <p class="consulting-p2">特聘具有国际公证员资格国际公证人，为中国香港文件、事实及法律文书等用于海外提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
                <div class="consulting-inner">
                    <p class="consulting-p1">中国委托公证人公证</p>
                    <p class="consulting-p2">特聘具有中国委托公证人资格香港公证人，为香港文件、事实及法律文书等用于内地提供公证认证服务</p>
                    <p class="consulting-p3" @click="Dialog = true">咨询办理 ></p>
                </div>
            </div>
            <div class="hague-consulting-right">
                <img class="hague-consulting-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/hague/pic_8.png" alt="">
            </div>
        </div>
      </div>
      <Link></Link>
      <Footer></Footer>
      <el-dialog :visible.sync="Dialog" title="" width="900px">
        <div style="width: 1000px; height: 600px;">
            <iframe
              src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
              width="850px"
              height="100%"
              frameborder="0"
              scrolling="no">
            </iframe>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  import Footer from '@/components/Footer.vue'
  import Link from '@/components/link.vue'
  export default {
    name: 'bvi-page',
    components: {
      Footer,
      Link
    },
    props: {
      msg: String
    },
    data(){
      var checkPhone = (rule, value, callback) => {
        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
        if (!value) {
          return callback(new Error('手机号码不能为空'))
        }
        setTimeout(() => {
          if (!Number.isInteger(+value)) {
            callback(new Error('请输入数字值'))
          } else {
            if (phoneReg.test(value)) {
              callback()
            } else {
              callback(new Error('手机号码格式不正确'))
            }
          }
        }, 100)
      }
      return{
        Dialog: false,
        form: {
          range: '',
          file: ''
        },
        rules: {
          name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
          mobile: [{ required: true, trigger: 'blur', validator: checkPhone}],
          file: [{ required: true, message: '请选择文件类别', trigger: 'change'}],
          type: [{ required: true, message: '请选择文件类别', trigger: 'change'}],
          range: [{ required: true, message: '请选择文件类别', trigger: 'change'}],
          country: [{ required: true, message: '请选择文件类别', trigger: 'change'}]
        }
      }
    },
    mounted(){
      window.scrollTo(0,0);
    },
    methods: {
      toSubmit(){
        let that = this
        that.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              type: 5,
              name: that.form.name,
              mobile: that.form.mobile,
              oneLevelFile: that.form.file,
              twoLevelFile: that.form.type,
              fileCertificType: that.form.range,
              location: that.form.country
            }
            that.$axios.post('/cms/message/save',params).then((result)=>{
              this.$notify({
                title: '提交成功',
                message: '我们会尽快联系您！',
                type: 'success',
                offset: 100,
                duration: 2000
              });
              that.form.name = ''
              that.form.mobile = ''
              that.form.file = null
              that.form.type = null
              that.form.range = null
              that.form.country = null
            })
          } else {
            return false;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
    .notarization-header{
      width: 100%;
      height: 520px;
      background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/notarization/embassy/banner.jpg') no-repeat center center;
      background-size: 100% 100%;
  }
  </style>
  