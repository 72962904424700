<template>
  <div class="about">
    <div class="about-header">
      <div class="about-header-box">
        <div class="about-header-left">
          <p class="about-header-p1">快企务</p>
          <p class="about-header-p2">专业的涉内外企业服务平台</p>
        </div>
        <div class="about-header-right">
          <!-- <img class="about-header-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/large_1_1.png" alt=""> -->
          <video controls class="about-header-img" src="https://www.kuaiqiwu.com/video.mp4"></video>
        </div>
      </div>
    </div>
    <div class="about-us">
      <p class="b-title">关于快企务</p>
      <div class="about-us-box center-width">
        <p class="about-us-p1">“快企务”是深圳金百利国际商务顾问有限公司旗下的涉内外企业服务平台，是业内领先的企业商服服务商，主要服务于全球企业客户，为客户提供全球公司（办事处）设立、公司税筹、公证认证、公司秘书、知识产权、法律合规、资金出入境（合规）等业务。目前已成功服务客户多达万家。<br /><br />
深圳金百利国际商务顾问有限公司成立于<span style="color: rgba(47, 114, 235, 1);font-weight: bold;">2010年5月</span>，已在<span style="color: rgba(47, 114, 235, 1);font-weight: bold;">上海、北京、广州、深圳、中国香港、伦敦</span>设立分公司，拥有一支上百人的专业团队。</p>
        <div class="about-us-bottom">
          <div class="about-us-inner">
            <p class="about-us-number" style="display: flex;align-items: center;justify-content: center;">
              <!-- 100,000 -->
              <NumberGrow :value="100000" :dot="0"></NumberGrow>
            +</p>
            <p class="about-us-p3">服务客户</p>
          </div>
          <div class="about-us-inner">
            <p class="about-us-number" style="display: flex;align-items: center;justify-content: center;">
              <!-- 200,000 -->
              <NumberGrow :value="200000" :dot="0"></NumberGrow>
            +</p>
            <p class="about-us-p3">累计成交</p>
          </div>
          <div class="about-us-inner">
            <p class="about-us-number" style="display: flex;align-items: center;justify-content: center;">
              <!-- 10,000 -->
              <NumberGrow :value="10000" :dot="0"></NumberGrow>
            +</p>
            <p class="about-us-p3">合作企业</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about-serve">
      <p class="b-title">公司服务理念</p>
      <div class="about-serve-box">
        <div class="about-serve-inner">
          <img class="about-serve-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/serve/icon_1.png" alt="">
          <p class="about-serve-p">客户有问题，我们有方案</p>
        </div>
        <div class="about-serve-inner">
          <img class="about-serve-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/serve/icon_2.png" alt="">
          <p class="about-serve-p">注册公司，仅仅是我们服务客户的开始</p>
        </div>
        <div class="about-serve-inner">
          <img class="about-serve-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/serve/icon_3.png" alt="">
          <p class="about-serve-p">非公司注册，希望我们跟客户有一个好的开始</p>
        </div>
        <div class="about-serve-inner">
          <img class="about-serve-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/serve/icon_4.png" alt="">
          <p class="about-serve-p">让客户满意，给客户体验100分</p>
        </div>
        <div class="about-serve-inner">
          <img class="about-serve-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/serve/icon_5.png" alt="">
          <p class="about-serve-p">不让客户受损失，过错我们买单</p>
        </div>
      </div>
    </div>
    <div class="about-history">
      <p class="b-title">发展历程</p>
      <div class="about-history-box center-width">
        <div class="about-history-left">
          <p class="about-history-year" :class="{'active': tab == 1}" @click="(tab = 1)">2010年</p>
          <p class="about-history-year" :class="{'active': tab == 2}" @click="(tab = 2)">2014年</p>
          <p class="about-history-year" :class="{'active': tab == 3}" @click="(tab = 3)">2017年</p>
          <p class="about-history-year" :class="{'active': tab == 4}" @click="(tab = 4)">2018年</p>
          <p class="about-history-year" :class="{'active': tab == 5}" @click="(tab = 5)">2020年</p>
          <p class="about-history-year" :class="{'active': tab == 6}" @click="(tab = 6)">2021年</p>
          <p class="about-history-year" :class="{'active': tab == 7}" @click="(tab = 7)">2023年</p>
        </div>
        <div class="about-history-right" v-if="(tab == 1)">
          <p class="about-history-p1" style="padding-top: 216px">2010年5月，公司于深圳福田区成立。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 2)">
          <p class="about-history-p1" style="padding-top: 202px">2014年，公司注册了“离岸岛”品牌，开启了服务企业用户的专业化品牌之路。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 3)">
          <p class="about-history-p1" style="padding-top: 202px">2017年12月，公司在上海，设立第一家分公司；上海作为国际金融中心，是优质外资企业的集中点，公司在持续性发展战中占据重要位置。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 4)">
          <p class="about-history-p1">2018年5月，公司成立8周年，已成功转型成为一家专业的涉外综合服务商。</p>
          <p class="about-history-p1 margin-40">2018年6月，公司引进双重加密系统，切实为保障客户信息下功夫。</p>
          <p class="about-history-p1 margin-40">2018年9月，公司在北京设立分公司，北京作为国家政治中心，大部分使馆也集中在北京，将有利于第一时间完成客户所需，为服务提速提质。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 5)">
          <p class="about-history-p1" style="padding-top: 202px">2020年1月，全新品牌“快企务”正式上线，实现双品牌战略发展。同时公司持续开拓新业务，进军知识产权及国内工商业务，以更全面服务客户。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 6)">
          <p class="about-history-p1" style="padding-top: 86px">2021年6月获得2020年度“广东省守合同重信用企业”称号。</p>
          <p class="see-box" @click="seeBtn(1)">查看证书 ></p>
          <p class="about-history-p1 margin-40">2021年获得2020年度“广东省A级企业纳税单位”称号。</p>
          <p class="see-box" @click="seeBtn(2)">查看证书 ></p>
          <p class="about-history-p1 margin-40">2021年11月，公司在大湾区广州成立子公司。目前，公司已有中国香港、深圳、上海、北京、广州五地公司。</p>
        </div>
        <div class="about-history-right" v-if="(tab == 7)">
          <p class="about-history-p1" style="padding-top: 40px">2023年获得2022年度“广东省A级企业纳税单位”称号</p>
          <p class="see-box" @click="seeBtn(3)">查看证书 ></p>
          <p class="about-history-p1" style="padding-top: 30px">2023年6月与富港银行建立战略合作伙伴</p>
          <p class="see-box" @click="seeBtn(4)">查看证书 ></p>
          <p class="about-history-p1" style="padding-top: 30px">2023年9月获得“计算机软件著作权登记证书”</p>
          <p class="see-box" @click="seeBtn(5)">查看证书 ></p>
          <p class="about-history-p1" style="padding-top: 30px">2023年10月获得深圳市投资商会“理事单位”</p>
          <p class="see-box" @click="seeBtn(6)">查看证书 ></p>
        </div>
      </div>
    </div>
    <div class="about-case">
      <p class="b-title">客户案例</p>
      <p class="b-title3">快企务年服务企业高达<span class="blue-style">10,000+</span>且还在不断上升，长期合作知名上市企业<span class="blue-style">200+</span></p>
      <div class="about-case-box">
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_1.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_2.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_3.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_4.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_5.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_6.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_7.png" alt="">
        </div>
        <div class="about-case-inner">
          <img class="about-case-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/logo_1_8.png" alt="">
        </div>
      </div>
    </div>
    <!-- <div class="about-values">
      <p class="b-title">价值观</p>
      <div class="about-values-box">
        <div class="about-values-inner">
          <div class="about-values-box1">
            <img class="about-values-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/icon_1_1.png" alt="">
            <p class="about-values-p">企业愿景</p>
          </div>
          <div class="about-values-box2">
            <p class="about-values-p1">企业愿景</p>
            <p class="about-values-p2">把“快企务”、“离岸岛”做成企服行业知名的品牌</p>
          </div>
        </div>
        <div class="about-values-inner">
          <div class="about-values-box1">
            <img class="about-values-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/icon_1_2.png" alt="">
            <p class="about-values-p">价值观</p>
          </div>
          <div class="about-values-box2">
            <p class="about-values-p1">价值观</p>
            <p class="about-values-p2">把“快企务”、“离岸岛”做成企服行业知名的品牌</p>
          </div>
        </div>
        <div class="about-values-inner">
          <div class="about-values-box1">
            <img class="about-values-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/icon_1_3.png" alt="">
            <p class="about-values-p">使命</p>
          </div>
          <div class="about-values-box2">
            <p class="about-values-p1">使命</p>
            <p class="about-values-p2">把“快企务”、“离岸岛”做成企服行业知名的品牌</p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="environment">
      <p class="b-title">快乐实干的团队</p>
      <div class="environment-box">
        <el-carousel :interval="4000" type="card" arrow="always" height="297px" indicator-position="inside" :autoplay="true">
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_2_1.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_2_2.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_2_3.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_2_4.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_4.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_5.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_6.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_7.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_8.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_9.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_10.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/pic_11.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="../assets/img/about/pic_1.jpg" alt="">
          </el-carousel-item>
          <el-carousel-item>
            <img class="environment-box-img" src="../assets/img/about/pic_2.jpg" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="about-advantage">
      <p class="b-title">快企务优势</p>
      <p class="b-title1">“洞察客户需求，让客户满意”是公司成立13年以来的执着，因此我们收获了上万家客户的支持，<br />也帮助了上万家客户解决了相关问题。未来我们将继续走品牌发展之路，成为行业领先的企业服务品牌。</p>
      <div class="about-advantage-box center-width">
        <div class="about-advantage-inner">
          <img class="about-advantage-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/pic_1.jpg" alt="">
          <p class="about-advantage-p1">快</p>
          <div class="about-advantage-box2">
            <!-- <p class="about-advantage-p2">时间要求最快<br />效率保证最高</p> -->
            <p class="about-advantage-p3">“快“是快企务的灵魂，也是快企务的挑战， 我们穷尽一切办法，利用一切资源，力求给客户提供最快的解决方案，如我们设立一家开曼公司仅需要20小时，完成一份文件认证仅需要30分钟。而回答客户的问题仅需0秒。</p>
          </div>
        </div>
        <div class="about-advantage-inner">
          <div class="about-advantage-box1">
            <img class="about-advantage-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/pic_2.jpg" alt="">
            <p class="about-advantage-p1"> 全</p>
          </div>
          <div class="about-advantage-box2">
            <!-- <p class="about-advantage-p2">紧盯客户需求<br />创新产品方案</p> -->
            <p class="about-advantage-p3">当客户找到我们时，已为客户准备了所需要的全套产品和服务内容，信心来自于100多个国家的渠道已打通，13年的企服经验和100多人的团队协同。如客户成立公司到上市所需要的相关配套业务我们介可提供。</p>
          </div>
        </div>
        <div class="about-advantage-inner">
          <div class="about-advantage-box1">
            <img class="about-advantage-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/pic_3.jpg" alt="">
            <p class="about-advantage-p1">进</p>
          </div>
          <div class="about-advantage-box2">
            <!-- <p class="about-advantage-p2">整合全球资源<br />提供综合服务</p> -->
            <p class="about-advantage-p3">追求“进”步从未停止，13年征程我们如履薄冰也开放从容，客户的服务进步了，产品优化进步了，合规尽调进步了，员工的工作能力进步了，客户的满意度进一步提升。如我们客户的留存率一直稳定在92.6%以上。</p>
          </div>
        </div>
        <div class="about-advantage-inner">
          <div class="about-advantage-box1">
            <img class="about-advantage-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/pic_4.jpg" alt="">
            <p class="about-advantage-p1">退</p>
          </div>
          <div class="about-advantage-box2">
            <!-- <p class="about-advantage-p2">拥抱合规合法<br />尊重忠诚于客户</p> -->
            <p class="about-advantage-p3">可“退”款承诺在13年以前成立公司之时就写进了服务条款，13年的坚持传承从未改变。满足不了客户的需求，解决不了客户的问题，我们认为是自己的过错，给客户退款不让客户遭受任何损失，同时也是对我们专业的信心。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="about-link">
      <p class="b-title">联系我们</p>
      <p class="b-title1">专业、高效、敬业的团队为您服务</p>
      <div class="about-link-box">
        <div class="about-link-tab">
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 1}" @click="addressTab = 1">深圳总部</p>
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 2}" @click="addressTab = 2">北京分部</p>
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 3}" @click="addressTab = 3">上海分部</p>
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 4}" @click="addressTab = 4">广州分部</p>
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 5}" @click="addressTab = 5">中国香港分部</p>
          <p class="about-link-tab-p" :class="{'addressActive': addressTab == 6}" @click="addressTab = 6">伦敦办公室</p>
        </div>
        <div class="about-map">
          <div v-if="addressTab == 1">
            <img class="map" src="../assets/img/else/shenzhen.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"> 
            <!-- <div id="bdmap" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <div v-if="addressTab == 2">
            <img class="map" src="../assets/img/else/beijing.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;">
            <!-- <div id="bdmap1" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <div v-if="addressTab == 3">
            <img class="map" src="../assets/img/else/shanghai.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;">
            <!-- <div id="bdmap2" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <div v-if="addressTab == 4">
            <img class="map" src="../assets/img/else/guangzhou.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;">
            <!-- <div id="bdmap3" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <div v-if="addressTab == 5">
            <img class="map" src="../assets/img/else/hk.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"> 
            <!-- <div id="bdmap4" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <div v-if="addressTab == 6">
            <img class="map" src="../assets/img/else/London.png" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"> 
            <!-- <div id="bdmap4" class="map" style ="width:1200px;height: 700px;margin: 0 auto;margin-top: 40px;border-radius: 20px;"></div> -->
          </div>
          <!-- <img class="map-img" v-if="addressTab == 1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/shenzhen.jpg" alt=""> -->
          <!-- <img class="map-img" v-if="addressTab == 2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/beijing.jpg" alt="">
          <img class="map-img" v-if="addressTab == 3" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/shanghai.jpg" alt="">
          <img class="map-img" v-if="addressTab == 4" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/guangzhou.jpg" alt="">
          <img class="map-img" v-if="addressTab == 5" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/Hongkong.jpg" alt=""> -->
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="seeDialog" width="800px" title="">
      <img v-if="type == 1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/certificate_1.jpg" alt="">
      <img v-if="type == 2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/about/certificate_2.jpg" alt="">
      <img v-if="type == 3" src="../assets/img/certificate.jpg" alt="">
      <img v-if="type == 4" src="../assets/img/65465414521.jpg" alt="">
      <img v-if="type == 5" src="../assets/img/8757465785.jpg" alt="">
      <img v-if="type == 6" src="../assets/img/45658475675220.jpg" alt="">
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import NumberGrow from '@/components/numberGrow.vue'
import MapInit from "@/components/Map.js"
export default {
  name: 'bank-page',
  components: {
    Footer,
    NumberGrow
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      addressTab: 1,
      map: null,
      seeDialog: false,
      type: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
    // this.init()
    // let url = 'https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/shenzhen.png'
    // let id = 'bdmap'
    // let lang = 114.070193
    // let lat = 22.548803
    // this.init(id,lang,lat)
  },
  methods:{
    seeBtn(type){
      this.type = type
      this.seeDialog = true
    },
    // changeMap(tab){
    //   this.addressTab = tab
    //   let id = ''
    //   let lang = ''
    //   let lat = ''
    //   if(tab == 1){
    //     id = 'bdmap'
    //     lang = 114.070193
    //     lat = 22.548803
    //   }else if(tab == 2){
    //     id = 'bdmap1'
    //     lang = 116.447827
    //     lat = 39.914866
    //   }else if(tab == 3){
    //     id = 'bdmap2'
    //     lang = 121.455102
    //     lat = 31.23317
    //   }else if(tab == 4){
    //     id = 'bdmap3'
    //     lang = 113.328876
    //     lat = 23.126088
    //   }else if(tab == 5){
    //     id = 'bdmap4'
    //     lang = 114.183393
    //     lat = 22.281373
    //   }
    //   this.init(id,lang,lat)
    // },
    // init(id,lang,lat){
    //     let that = this
    //     MapInit.init().then(
    //     T => {
    //         that.T = T;
    //         const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=396a532e3cc05a260931d1b308636316";
    //         const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
    //         const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
    //         that.map = new T.Map(id, config);
    //         const ctrl = new T.Control.MapType();
    //         that.map.addControl(ctrl);
    //         that.map.centerAndZoom(new T.LngLat(lang, lat), 16)
    //         let maker = null
    //         if(that.addressTab == 1){
    //           maker = new T.Marker(new T.LngLat(114.070193, 22.548803),{
    //             icon: new T.Icon({
    //               iconUrl: require('../assets/img/else/shenzhen.png'),
    //               iconSize: new T.Point(450,366)
    //             })
    //           })
    //         }else if(that.addressTab == 2){
    //           maker = new T.Marker(new T.LngLat(116.447827, 39.914866),{
    //             icon: new T.Icon({
    //               iconUrl: require('../assets/img/else/beijing.png'),
    //               iconSize: new T.Point(450,366)
    //             })
    //           })
    //         }else if(that.addressTab == 3){
    //           maker = new T.Marker(new T.LngLat(121.455102, 31.23317),{
    //             icon: new T.Icon({
    //               iconUrl: require('../assets/img/else/shanghai.png'),
    //               iconSize: new T.Point(450,366)
    //             })
    //           })
    //         }else if(that.addressTab == 4){
    //           maker = new T.Marker(new T.LngLat(113.328876, 23.126088),{
    //             icon: new T.Icon({
    //               iconUrl: require('../assets/img/else/guangzhou.png'),
    //               iconSize: new T.Point(450,366)
    //             })
    //           })
    //         }else if(that.addressTab == 5){
    //           maker = new T.Marker(new T.LngLat(114.183393, 22.281373),{
    //             icon: new T.Icon({
    //               iconUrl: require('../assets/img/else/HongKong.png'),
    //               iconSize: new T.Point(400,150)
    //             })
    //           })
    //         }
    //         that.map.addOverLay(maker)
    //         that.map.addEventListener("zoomend", () => {
    //         console.log(lay.Pe)
    //       });
    //     }).catch()
    // },
    // init1(){
    //     MapInit.init().then(
    //     T => {
    //         this.T = T;
    //         const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=396a532e3cc05a260931d1b308636316";
    //         const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
    //         const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
    //         this.map = new T.Map('bdmap1', config);
    //         const ctrl = new T.Control.MapType();
    //         this.map.addControl(ctrl);
    //         this.map.centerAndZoom(new T.LngLat(116.447827, 39.914866), 16)
    //         let maker = new T.Marker(new T.LngLat(116.447827, 39.914866),{
    //           icon: new T.Icon({
    //             iconUrl: require('../assets/img/else/beijing.png'),
    //             iconSize: new T.Point(450,366)
    //           })
    //         })
    //         this.map.addOverLay(maker)
    //         this.map.addEventListener("zoomend", () => {
    //         console.log(lay.Pe)
    //     });
    //     }).catch()
    // },
    // init2(){
    //     MapInit.init().then(
    //     T => {
    //         this.T = T;
    //         const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=396a532e3cc05a260931d1b308636316";
    //         const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
    //         const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
    //         this.map = new T.Map('bdmap2', config);
    //         const ctrl = new T.Control.MapType();
    //         this.map.addControl(ctrl);
    //         this.map.centerAndZoom(new T.LngLat(121.455102, 31.23317), 16)
    //         let maker = new T.Marker(new T.LngLat(121.455102, 31.23317),{
    //           icon: new T.Icon({
    //             iconUrl: require('../assets/img/else/shanghai.png'),
    //             iconSize: new T.Point(450,366)
    //           })
    //         })
    //         this.map.addOverLay(maker)
    //         this.map.addEventListener("zoomend", () => {
    //         console.log(lay.Pe)
    //     });
    //     }).catch()
    // },
    // init3(){
    //     MapInit.init().then(
    //     T => {
    //         this.T = T;
    //         const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=396a532e3cc05a260931d1b308636316";
    //         const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
    //         const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
    //         this.map = new T.Map('bdmap3', config);
    //         const ctrl = new T.Control.MapType();
    //         this.map.addControl(ctrl);
    //         this.map.centerAndZoom(new T.LngLat(113.328876, 23.126088), 16)
    //         let maker = new T.Marker(new T.LngLat(113.328876, 23.126088),{
    //           icon: new T.Icon({
    //             iconUrl: require('../assets/img//else/guangzhou.png'),
    //             iconSize: new T.Point(450,366)
    //           })
    //         })
    //         this.map.addOverLay(maker)
    //         this.map.addEventListener("zoomend", () => {
    //         console.log(lay.Pe)
    //     });
    //     }).catch()
    // },
    // init4(){
    //     MapInit.init().then(
    //     T => {
    //         this.T = T;
    //         const imageURL = "http://t0.tianditu.gov.cn/img_c/wmts?tk=396a532e3cc05a260931d1b308636316";
    //         const lay = new T.TileLayer(imageURL, { minZoom: 1, maxZoom: 18 });
    //         const config = { layers: [lay], name: 'TMAP_SATELLITE_MAP' };
    //         this.map = new T.Map('bdmap4', config);
    //         const ctrl = new T.Control.MapType();
    //         this.map.addControl(ctrl);
    //         this.map.centerAndZoom(new T.LngLat(114.183393, 22.281373), 16)
    //         let maker = new T.Marker(new T.LngLat(114.183393, 22.281373),{
    //           icon: new T.Icon({
    //             iconUrl: require('../assets/img/else/HongKong.png'),
    //             iconSize: new T.Point(400,150)
    //           })
    //         })
    //         this.map.addOverLay(maker)
    //         this.map.addEventListener("zoomend", () => {
    //         console.log(lay.Pe)
    //     });
    //     }).catch()
    // },
  }
}
</script>

<style scoped lang="scss">
.about-header{
  width: 100%;
  height: 620px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/about/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
.map{
  display: block;
}
</style>
