<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式英国公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>

    <div class="b-advantage">
      <p class="re-title">注册英国公司优势</p>
      <div class="b-ad-box">
        <img class="b-ad-left" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/pic_1.png" alt="">
        <div class="b-ad-right">
          <p class="b-ad-p">注册时间短，所需材料少，注册灵活性大</p>
          <p class="b-ad-p">股东可一位，国籍不限</p>
          <p class="b-ad-p">不在当地经营，合理避税，公司管理便捷</p>
          <p class="b-ad-p">外汇进出自由，可在英国欧洲投资</p>
          <p class="b-ad-p">设立国际品牌企业国际形象，国际法律地位高</p>
        </div>
      </div>
    </div>
    
    <div class="b-type">
      <p class="re-title">注册英国公司类型</p>
      <div class="b-type-box">
        <div class="b-type-inner">
          <img class="b-type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/pic_2_1.jpg" alt="">
          <div class="hover-bg"></div>
          <div class="b-type-shade">
            <img class="b-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_1_1.png" alt="">
            <p class="b-type-p2">私营无限责任公司</p>
          </div>
          <div class="b-type-shade1">
            <p class="b-type-p3">私营无限责任公司</p>
            <p class="b-type-p4" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="b-type-inner">
          <img class="b-type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/pic_2_2.jpg" alt="">
          <div class="hover-bg"></div>
          <div class="b-type-shade">
            <img class="b-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_1_2.png" alt="">
            <p class="b-type-p2">上市公司</p>
          </div>
          <div class="b-type-shade1">
            <p class="b-type-p3">上市公司</p>
            <p class="b-type-p4" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
        <div class="b-type-inner">
          <img class="b-type-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/pic_2_3.jpg" alt="">
          <div class="hover-bg"></div>
          <div class="b-type-shade">
            <img class="b-type-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_1_3.png" alt="">
            <p class="b-type-p2">英国私人有限公司</p>
          </div>
          <div class="b-type-shade1">
            <p class="b-type-p3">英国私人有限公司</p>
            <p class="b-type-p4" @click="Dialog = true">立即咨询 ></p>
          </div>
        </div>
      </div>
    </div>

    <div class="b-conditions">
      <p class="re-title">注册英国公司条件/流程/资料</p>
      <div class="b-con-box">
        <div class="b-con-tab">
          <p class="tab-title" :class="{'active': tab == 1}" @click="tab = 1">注册英国公司条件</p>
          <p class="tab-title" :class="{'active': tab == 2}" @click="tab = 2">注册英国公司流程</p>
          <p class="tab-title" :class="{'active': tab == 3}" @click="tab = 3">注册完成后所得资料</p>
        </div>
        <div class="b-con-right">
          <div class="b-con-content" v-if="tab == 1">
            <div class="b-con1-box">
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_1.png" alt="">
                <p class="b-con1-p1">名称</p>
                <p class="b-con1-p2">确定拟注册的三个英文公司名称</p>
              </div>
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_2.png" alt="">
                <p class="b-con1-p1">股东</p>
                <p class="b-con1-p2">提供董事/股东人数及身份信息</p>
              </div>
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_3.png" alt="">
                <p class="b-con1-p1">注资</p>
                <p class="b-con1-p2">标准注册资金（最少1英镑）</p>
              </div>
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_4.png" alt="">
                <p class="b-con1-p1">地址</p>
                <p class="b-con1-p2">确认公司的注册地址（我司可提供）</p>
              </div>
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_5.png" alt="">
                <p class="b-con1-p1">经营范围</p>
                <p class="b-con1-p2">确认公司的经营范围（我司协助选择）</p>
              </div>
              <div class="b-con1-inner">
                <img class="b-con1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_2_6.png" alt="">
                <p class="b-con1-p1">法定秘书</p>
                <p class="b-con1-p2">确认公司的法定秘书（我司可提供）</p>
              </div>
            </div>
            
          </div>
          <div class="b-con-content2" v-if="tab == 2">
            <img class="b-con2-bg" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/pic_3.png" alt="">
            <p class="b-con2-p p1">提供注册公司名称（英文，最好备选三个）</p>
            <p class="b-con2-p p2">公司名称查册（24小时查册结果）</p>
            <p class="b-con2-p p3">签署注册委托协议</p>
            <p class="b-con2-p p4">提供董事和股东的身份证或护照复印件</p>
            <p class="b-con2-p p5">注册英国公司完成领取全套资料</p>
            <p class="b-con2-p p6">开始注册</p>
            <p class="b-con2-p p7">提供各股东投资股份分配比例</p>
          </div>
          <div class="b-con-content" v-if="tab == 3">
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_1.png" alt="">
              <p class="b-con-p">英国公司注册证书</p>
            </div>
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_2.png" alt="">
              <p class="b-con-p">英国公司现状报表</p>
            </div>
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_3.png" alt="">
              <p class="b-con-p">英国公司代理人证明</p>
            </div>
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_4.png" alt="">
              <p class="b-con-p">英国公司查册报告</p>
            </div>
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_5.png" alt="">
              <p class="b-con-p">英国公司章程</p>
            </div>
            <div class="b-con-inner1">
              <img class="b-con-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_3_6.png" alt="">
              <p class="b-con-p">英国公司第一次会议记录</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-b">
      <p class="re-title">注册英国公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_1.png" alt="">
          <p class="sub-p1">年审/续牌</p>
          <p class="sub-p3">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款年审或续牌根据...</p>
          <p class="sub-p4">年审或续牌根据国家地区的要求，如期进行合规申报，否则产生罚款年审或续牌根据国家地区的要求，如期进行</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p3">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或...</p>
          <p class="sub-p4">开设公司注册所在地的银行账户，有利于公司随时接受贸易汇款，享受当地政策或规避外汇风险</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_3tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p2">公司注册之后需按照当地政府单位要求进行做账报税</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_5change.png" alt="">
          <p class="sub-p1">公司变更</p>
          <p class="sub-p2">公司经营过程中的公司信息（如地址、股东、经营范围等）变更</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_6secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">为公司提供完善的支持服务，包括但不限于代收及处理机构往来函件、提供年审报...</p>
          <p class="sub-p4">为公司提供完善的支持服务，包括但不限于代收及处理机构往来函件、提供年审报税等服务</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/icon_4_4cancellation.png" alt="">
          <p class="sub-p1">公司注销</p>
          <p class="sub-p2">公司确认不继续经营之后，需及时提交相关资料进行注销，避免产生后续罚款</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">英国公司注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册英国公司需要验资吗？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">注册英国公司，注册资本不需到位，无需验资，税务安排便利，品牌信誉度高，成立费用不高，每年维护成本低。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="英国公司税收高吗?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">英国公司与中国香港公司的税务几乎一致，不是在英国本地获得的收入，是可以不用在英国进行税务缴纳。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="英国公司注册成功后可以查证吗？需要多久？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">一般在注册完成后的24小时内就可以在英国工商局网站上查到注册信息(英国工商局在英国时间早上7点到晚上12点提供网上免费查询服务.</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="英国公司的的经济效应?" name="4">
            <div class="inner-pro">
              <div class="pro-p2">从全球经济和资本运作看来，英国是继美国之后的第二大金融中心，再加上其在欧洲的重要地位和与北美及整个<br />
                英联邦国家的密切经济关系，英国的公司往往在世界上有着较好的声誉。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业英国公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/Britain/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
