<template>
  <div class="bvi">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p2-sing" style="padding-top: 130px;">注册中国香港公司</p>
        <p class="banner-p1-sing" style="padding-top: 20px;">正规持牌机构 价格透明 协助开户</p>
        <p class="banner-p3" style="font-size: 32px;">【企业全球拓展第一步】</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="hong-package">
      <p class="re-title">中国香港公司注册套餐</p>
      <p class="re-title2">选择您需要的服务</p>
      <div class="hong-packag-table">
        <div class="hong-pa-th">
          <p class="th-p">服务内容</p>
          <p class="th-p th-p1">特惠套餐</p>
          <p class="th-p th-p2">商务套餐</p>
          <p class="th-p th-p3">无忧套餐</p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">中国香港有限公司</p>
          <p class="tr-p tr-p1">3-8个工作日完成</p>
          <p class="tr-p tr-p1">3-8个工作日完成</p>
          <p class="tr-p tr-p1">3-8个工作日完成</p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">公司名称查册</p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">申请公司注册证书（CI）<br />申请商业登记证（BR）</p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">编印公司章程7本、会议记录本、股票本<br />制作公司签字章、小圆章、钢印</p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">申报首任董事、股东、秘书及注册地址<br />中国香港SCR司重要控制人备存建册</p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">中国香港公司挂水牌服务</p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">VIP银行开户包过服务<br />（多元化银行渠道可选）</p>
          <p class="tr-p tr-p1"><i class="el-icon-success gray-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p">超值商标注册 <br />（提供一对一服务 一站式注册全球商标）</p>
          <p class="tr-p tr-p1"><i class="el-icon-success gray-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success gray-style"></i></p>
          <p class="tr-p tr-p1"><i class="el-icon-success blue-style"></i></p>
        </div>
        <div class="hong-pa-tr">
          <p class="tr-p"></p>
          <p class="tr-p tr-p1" @click="Dialog = true"><span class="hong-btn2">立即购买</span></p>
          <p class="tr-p tr-p1" @click="Dialog = true"><span class="hong-btn2">立即购买</span></p>
          <p class="tr-p tr-p1" @click="Dialog = true"><span class="hong-btn2">立即购买</span></p>
        </div>
      </div>
    </div>
    <div class="hong-time">
      <p class="re-title">注册中国香港公司的条件、流程、时间</p>
      <p class="re-title2">专业、真实的信息</p>
      <div class="h-time-tab">
        <div class="h-tab-inner" :class="{'active': timeTab == 1}" @click="timeTab = 1">注册流程</div>
        <div class="h-tab-inner" :class="{'active': timeTab == 2}" @click="timeTab = 2">注册条件/时间</div>
      </div>
      <div class="h-time-box" v-if="timeTab == 1">
        <img class="tab1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/tab1.png" alt="">
        <div class="tab1-inner">
          <p class="inner-text">中国香港公司<br />名称注册</p>
          <p class="inner-text">签署委托<br />代理协议</p>
          <p class="inner-text">填报公司<br />注册文件</p>
          <p class="inner-text">客户签名</p>
          <p class="inner-text">交付款项</p>
          <p class="inner-text">向政府申报<br />成立文件</p>
          <p class="inner-text">公司注册<br />核准</p>
          <p class="inner-text">公司盒交接</p>
        </div>
      </div>
      <div class="h-time-box1" v-if="timeTab == 2">
        <div class="h-time-box-left">
          <p class="h-inner-title">中国香港公司注册条件</p>
          <div class="left-inner">
            <div class="left-content">
              <p class="inner-title">公司股东</p>
              <p class="inner-text">一位或一位股东以上，股东必须超过18岁</p>
            </div>
            <div class="left-content">
              <p class="inner-title">股东身份证明</p>
              <p class="inner-text">所以股东必须出示有效身份或护照影印件</p>
            </div>
            <div class="left-content">
              <p class="inner-title">企业身份证明</p>
              <p class="inner-text">以企业法人作为股东的，企业法人必须提交营业执照和法人代表的身份证复印件</p>
            </div>
            <div class="left-content">
              <p class="inner-title">公司地址</p>
              <p class="inner-text">提供中国香港注册地址（我司提供）</p>
            </div>
            <div class="left-content">
              <p class="inner-title">法定秘书</p>
              <p class="inner-text">委任中国香港人或中国香港公司担任公司法定秘书（一般由我们提供）</p>
            </div>
            <div class="left-content">
              <p class="inner-title">注册资本</p>
              <p class="inner-text">中国香港公司法规定：标准注册资本为10000港币</p>
            </div>
            <div class="left-content">
              <p class="inner-title">公司注册名称</p>
              <p class="inner-text">需提供三个以供查册</p>
            </div>
          </div>
        </div>
        <div class="h-time-box-right">
          <p class="h-inner-title">中国香港公司注册时间</p>
          <div class="right-inner">
            <p class="h-p2">常规</p>
            <p class="h-p3">8-10个工作日</p>
          </div>
          <div class="right-inner">
            <p class="h-p2 red-style">加急</p>
            <p class="h-p3 red-style">电子注册，最快3个工作日内完成</p>
          </div>
        </div>
      </div>
    </div>
    <div class="h-bank">
      <p class="re-title">中国香港公司银行开户</p>
      <p class="re-title2">我们只做更好的</p>
      <div class="h-bank-box1">
        <div class="h-bank-box1-inner">
          <img class="box1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_2_1.png" alt="">
          <p class="box1-p">多元化渠道</p>
        </div>
        <div class="h-bank-box1-inner">
          <img class="box1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_2_2.png" alt="">
          <p class="box1-p">VIP绿色通道</p>
        </div>
        <div class="h-bank-box1-inner">
          <img class="box1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_2_3.png" alt="">
          <p class="box1-p">个性化匹配</p>
        </div>
        <div class="h-bank-box1-inner">
          <img class="box1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_2_4.png" alt="">
          <p class="box1-p">保证成功率</p>
        </div>
      </div>
      <div class="h-bank-box2">
        <img class="h-bank-box2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/left.png" alt="">
        <div class="bank-right">
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_1.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">中国银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_2.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">渣打银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_3.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">富帝银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_4.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">永隆银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_5.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">东亚银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_6.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">华侨永亨银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_7.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">星展银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner">
            <img class="bank-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/logo_1_8.png" alt="">
            <div class="bank-icon-right">
              <p class="bank-icon-p1">汇丰银行</p>
              <p class="bank-icon-p2" @click="Dialog = true">立即咨询 ></p>
            </div>
          </div>
          <div class="bank-right-inner right-inner2">
            <p class="bank-more" @click="Dialog = true">更多银行开户 ></p>
          </div>
        </div>
      </div>
    </div>
    <div class="subsequent subsequent-h">
      <p class="re-title">一站式中国香港企业服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_1.png" alt="" />
          <p class="sub-p1">中国香港公司年审</p>
          <p class="sub-p2">中国香港公司每年均需要针对其商业登记证进行一次全新的审核</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_2.png" alt="" />
          <p class="sub-p1">中国香港公司变更</p>
          <p class="sub-p2">股东、董事、公司名称、地址等发生变化</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_3.png" alt="" />
          <p class="sub-p1">中国香港公司公证</p>
          <p class="sub-p2">公司文件应用于内地，即中国委托公证人公证</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_5.png" alt="" />
          <p class="sub-p1">中国香港商标注册</p>
          <p class="sub-p3">可以是各种各样的图案，甚至可以是声音，按照货品和服务分为45个类，一般6-10...</p>
          <p class="sub-p4">可以是各种各样的图案，甚至可以是声音，按照货品和服务分为45个类，一般6-10个月有结果</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_6.png" alt="" />
          <p class="sub-p1">中国香港公司SCR备案</p>
          <p class="sub-p3">所有在中国香港注册的公司注册为重要控制人，该备案政策专门针对中国香港公司，旨在打击...</p>
          <p class="sub-p4">所有在中国香港注册的公司注册为重要控制人，该备案政策专门针对中国香港公司，旨在打击税务违规行为等。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_7.png" alt="" />
          <p class="sub-p1">会计师咨询服务</p>
          <p class="sub-p2">做账报税审计等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_8.png" alt="" />
          <p class="sub-p1">中国香港公司税务筹划</p>
          <p class="sub-p2">项目投资、运用中国香港税款双边协定及相应税收优惠</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_9.png" alt="" />
          <p class="sub-p1">协助中国香港银行开户</p>
          <p class="sub-p2">渣打银行、恒生银行、汇丰银行等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_3_4.png" alt="">
          <p class="sub-p1">中国香港公司注销</p>
          <p class="sub-p2">分为主动注销或自动注销</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="" />
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="h-vs">
      <p class="re-title">快企务<span class="red-style">VS</span>其他公司</p>
      <p class="re-title2">更直观了解我们的优势</p>
      <div class="vs-box">
        <div class="vs-box-left">
          <img class="logo" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/home/logo_3_kuaiqiwu.png" alt="">
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_1.png" alt="">
            <div class="right-box">
              <p class="vs-p1">快速</p>
              <p class="vs-p2">专业级系统，5分钟快速核名，确保查明准确无误</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_2.png" alt="">
            <div class="right-box">
              <p class="vs-p1">真实</p>
              <p class="vs-p2">中国香港核心商业区甲级地址</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_3.png" alt="">
            <div class="right-box">
              <p class="vs-p1">透明</p>
              <p class="vs-p2">费用一次性收取，无其他任何费用，签订正规合同</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_4.png" alt="">
            <div class="right-box">
              <p class="vs-p1">多元</p>
              <p class="vs-p2">与中国香港会计师工会、中国委托公证人律师、中国香港20多家政府银行机构深度合作</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_5.png" alt="">
            <div class="right-box">
              <p class="vs-p1">完善</p>
              <p class="vs-p2">后续服务包括（年审、开户、税务处理、中国香港工作、中国香港商标注册）</p>
            </div>
          </div>
        </div>
        <div class="vs-box-right">
          <p class="vs-title">其他公司</p>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_1.png" alt="">
            <div class="right-box">
              <p class="vs-p1">快速</p>
              <p class="vs-p2">核名半天以上</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_2.png" alt="">
            <div class="right-box">
              <p class="vs-p1">真实</p>
              <p class="vs-p2">中国香港旧区大厦，甚至是盗用他人的地址</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_3.png" alt="">
            <div class="right-box">
              <p class="vs-p1">透明</p>
              <p class="vs-p2">收费不透明、含多谢隐形收费</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_4.png" alt="">
            <div class="right-box">
              <p class="vs-p1">多元</p>
              <p class="vs-p2">与政府、银行无多元化合作</p>
            </div>
          </div>
          <div class="vs-left-icon">
            <img class="vs-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/icon_4_5.png" alt="">
            <div class="right-box">
              <p class="vs-p1">完善</p>
              <p class="vs-p2">后续服务（年审、开户、税务处理）</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="problem gray-bg">
      <p class="re-title">注册中国香港公司常见问题</p>
      <p class="re-title3">解决您的一切疑问</p>
      <div class="pro-box margin-top-80">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册中国香港公司取名有什么限制?" name="1">
            <div class="inner-pro">
              <div class="pro-p2">中国香港公司取名相对自在，公司名称可以含有国际、集团、控股、实业、出版社、物流、修建、旅行、财政、等字眼，为了避<br />
                免重名，公司名称有必要查册。需要注意的是中国香港公司的名称必须有英文组成，可搭配中文起名；</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册中国香港公司注册资本是多少?" name="2">
            <div class="inner-pro">
              <div class="pro-p2">有限公司注册资本惯例为1万港币，公司注册资本的巨细决议着股东承担职责的巨细。需要提前准备好公司的股份及股份分<br />
                配，并准备好股东出资方的证明文件</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="商业登记证是否必须填写业务性质?" name="3">
            <div class="inner-pro">
              <div class="pro-p2">不一定，如公司不想特别注明，可不填写。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="股东和董事有什么要求？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">中国香港公司需一位以上的股东和董事，股东能够由法人和自然人担任，自然人为年满18周岁。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="大陆居民是否可以注册中国香港公司？" name="5">
            <div class="inner-pro">
              <div class="pro-p2">可以。中国香港的公司法对股东/董事的国籍没有任何限制。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="注册中国香港公司需要多久？" name="6">
            <div class="inner-pro">
              <div class="pro-p2">一般来说，注册一家中国香港公司需要3-6个工作日，客户选择加急注册，并且提交资料完整时，快企务注册最快6小时出执照。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title">我们的优势</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      activeName: '1',
      timeTab: 1,
      Dialog: false
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/HongKong/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
}
</style>
