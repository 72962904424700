<template>
  <div class="mark">
    <div class="mark-header">
      <div class="mark-inner center-width">
        <p class="mark-p1" style="padding-top: 135px">国内商标注册</p>
      </div>
    </div>
    <div class="mark-trait" style="height: 652px">
      <p class="b-title">商标裸奔的危害</p>
      <div class="mark-trait-box center-width">
        <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">不享有商标专用权</p>
          <p class="mark-trait-p2">《商标法》只保护注册商标的专有权，未注册不保护，谁都可以用</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">存在被抢注的风险</p>
          <p class="mark-trait-p2">商标注册采样申请在先原则，一但他人抢注，商标专属权就规他</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">容易造成侵权行为</p>
          <p class="mark-trait-p2">一旦该商标被抢注，原企业在不知情的状况下如果继续使用该商标进行推广，便会构成侵权行为</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">品牌形象受损</p>
          <p class="mark-trait-p2">其他公司使用同样的商标，如果出现质量问题，您的品牌也会遭到影响</p>
        </div>
        <div class="mark-trait-inner mark-trait-inner1" style="height: 191px;">
          <p class="mark-trait-title"></p>
          <p class="mark-trait-p1">造成经济损失</p>
          <p class="mark-trait-p2">无论是商标被抢注还是大量低品质产品的涌入，企业能做的只有两点：要么将商标“赎”回来，要么放弃此商标的使用权。而无论选择哪一种，势必都将造成巨额经济损失</p>
        </div>
      </div>
    </div>
    <div class="mark-file">
      <p class="b-title">商标注册所需资料</p>
      <div class="mark-file-box center-width">
        <div class="mark-file-left" >
          <p class="mark-file-p">申请人资格证明资料</p>
          <p class="mark-file-p1">以公司名义申请，附企业营业执照复印件；</p>
          <p class="mark-file-p1">以个人名义申请，附身份证或者护照复印件。 （需有个体工商户营业执照且执照经营范围要与所注册的商标类别相符）</p>
          <p class="mark-file-p">列出寻求注册的商品或者服务，指出商标类别 5cm×5cm，最大不超过 10cm×20cm；</p>
          <p class="mark-file-p">《商标注册委托书》，我司提供，用于委托商标事务所代理商标注册业务</p>
        </div>
        <div class="mark-file-right">
          <img class="mark-file-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/EU/pic_2.png" alt="">
        </div>
      </div>
    </div>
    <div class="mark-program" style="background: rgba(242, 245, 250, 1);height: 392px;">
      <p class="b-title">商标注册流程</p>
      <div class="mark-program-box center-width flex-between ">
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title"></div>
          <p class="mark-program-p2">查询商标<br />能否注册</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title2"></div>
          <p class="mark-program-p2">提交注册<br />申请</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title3"></div>
          <p class="mark-program-p2">核实并递<br />交商标局</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title4"></div>
          <p class="mark-program-p2">商标局形式<br />复查</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title5"></div>
          <p class="mark-program-p2">下发商标<br />申请受理书</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title6"></div>
          <p class="mark-program-p2">实质审查</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title7"></div>
          <p class="mark-program-p2">公示期</p>
        </div>
        <div class="mark-program-inner" style="background:#fff;height: 142px;width: 133px;">
          <div class="mark-program-title title8"></div>
          <p class="mark-program-p2">下发商标<br />注册证书</p>
        </div>
      </div>
    </div>
    <div class="mark-value">
      <p class="re-title">商标价值</p>
      <div class="mark-value-box center-width flex-between">
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_1.png" alt="">
          <p class="mark-value-p">申请微信、微博<br />官方认证</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_2.png" alt="">
          <p class="mark-value-p">入驻天猫、京东<br />等电商网站</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_3.png" alt="">
          <p class="mark-value-p">提高公司或<br />个人信誉</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_4.png" alt="">
          <p class="mark-value-p">无形资产<br />(转让,许可,参股,抵押)</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_5.png" alt="">
          <p class="mark-value-p">办理质检、<br />卫检、条码等</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_6.png" alt="">
          <p class="mark-value-p">主动防御<br />(防止被人抢注)</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_7.png" alt="">
          <p class="mark-value-p">享有商标专用<br />权，受法律保护</p>
        </div>
        <div class="mark-value-inner">
          <img class="mark-value-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_1_8.png" alt="">
          <p class="mark-value-p">增加购买信心，有利<br />于消费者认牌购物</p>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="申请注册商标都需要提交哪些材料？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">一、商标注册委托书、申请书（用于代理机构商标注册业务，商标注册委托书，申请书可在网站自动生成）。<br />
二、申请人资格证明资料：<br />
① 以公司名义申请，附企业营业执照复印件;<br />
② 以个体名义申请，附身份证或者护照复印件及个体营业执照。<br />
三、商标图样：商标图样（JPG格式，400*400-1000*1000大小）。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="商标分哪些类型？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">（1）根据商标的构图形式来分类，可分为文字商标、图形商标、图形与文字组合商标。<br />
（2）根据商标的用途和作用来分类，可划分为商品商标和服务商标。<br />
（3）根据商标拥有者、使用者的不同来分类，可划分为制造商标、销售商标、集体商标。<br />
（4）根据商标使用人对商标的使用动机来分类，可划分为联合商标、防御商标、证明商标。<br />
（5）根据商标的载体分类，可分为平面商标、立体商标、音啊商标、气味商标等。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="哪些东西可注册商标？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">文字、图形、字母、数字、三维标志和颜色组合，以及上述要素的组合，均可以作为商标申请注册。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="商标保护期限是多长？" name="4">
            <div class="inner-pro">
              <div class="pro-p2">注册商标的有效期为10年。注册商标有效期满后需要继续使用的，应当在期满前的一年内申请续展注册。在此<br />
                期间未能提出申请的，还有6个月的宽展期。宽展期内仍未提出申请的，期满后商标局将予以注销。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <div class="mark-why">
      <p class="re-title">为什么选择我们</p>
      <div class="mark-why-box center-width flex-between">
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_1.png" alt="">
          <p class="mark-why-p1">快速</p>
          <p class="mark-why-p2">一对一顾问服务，快速响应客户需求</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_2.png" alt="">
          <p class="mark-why-p1">专业</p>
          <p class="mark-why-p2">十余年专业代理国内外商标注册相关业务</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_3.png" alt="">
          <p class="mark-why-p1">严谨</p>
          <p class="mark-why-p2">商标顾问、质检部经理、客户三重质检、多重把关</p>
        </div>
        <div class="mark-why-inner">
          <img class="mark-why-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/icon_2_4.png" alt="">
          <p class="mark-why-p1">保障</p>
          <p class="mark-why-p2">知识产权的商标注册成功率高达98.3%</p>
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      form: {},
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .mark-header{
    width: 100%;
    height: 320px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  .mark-program-title{
    width: 133px;
    height: 38px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_1.png') no-repeat center center;
    background-size: 100% 100%;
  }
  .title2{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_2.png') no-repeat center center;
  }
  .title3{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_3.png') no-repeat center center;
  }
  .title4{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_4.png') no-repeat center center;
  }
  .title5{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_5.png') no-repeat center center;
  }
  .title6{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_6.png') no-repeat center center;
  }
  .title7{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_7.png') no-repeat center center;
  }
  .title8{
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/trademark/domestic/pic_1_8.png') no-repeat center center;
  }
</style>
