<template>
  <div class="knowledge">
    <div class="konw-header">
      <p class="search-title">商标查询</p>
      <div class="konw-header-box">
        <div class="banner-line1">
          <div class="banner-line1-inner">
            <p class="know-p1">
              <!-- 66575734 -->
              <NumberGrow :value="66575734" :dot="0"></NumberGrow>
              <span class="small-style">次</span>
            </p>
            <p class="know-p2">累计查询</p>
          </div>
          <div class="banner-line1-inner">
            <p class="know-p1">
              <!-- 308 -->
              <NumberGrow :value="308" :dot="0"></NumberGrow>
              <span class="small-style">件</span>
            </p>
            <p class="know-p2">昨日成交</p>
          </div>
          <div class="banner-line1-inner">
            <p class="know-p1">
              <!-- 98.3 -->
              <NumberGrow :value="98.3" :dot="1"></NumberGrow>
              %</p>
            <p class="know-p2">商标注册成功率</p>
          </div>
        </div>
        <div class="banner-line2">
          <el-input clear="banner-input" placeholder="请输入您想要了解的商标名称"></el-input>
          <p class="banner-btn" @click="Dialog = true">查询免费能否注册</p>
        </div>
      </div>
    </div>
    <div class="k-trademark">
      <p class="b-title">商标服务</p>
      <p class="b-title1">企业没商标，等于帮别人做品牌，拿什么保护创业中的您</p>
      <div class="k-trademark-box">
        <div class="k-trademark-left">
          <div class="trade-left-div" :class="{'active': serveTab == 1}" @click="serveTab = 1"><p class="trade-left-p">国际商标注册</p></div>
          <div class="trade-left-div" :class="{'active': serveTab == 2}" @click="serveTab = 2"><p class="trade-left-p">国内商标注册</p></div>
          <div class="trade-left-div" :class="{'active': serveTab == 3}" @click="serveTab = 3"><p class="trade-left-p">商标服务</p></div>
          
        </div>
        <div class="k-trademark-right">
          <div class="right-box1" v-if="serveTab == 2">
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_1.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">普通商标注册</p>
                <p class="trade-p2">价格实惠，快企务专业注册，行业低价</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_2.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">商标极速注册</p>
                <p class="trade-p2">59分钟递送商标局</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_3.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">极速双保注册</p>
                <p class="trade-p2">59分钟报件，如驳回免费<br />再注册一个</p>
              </div>
            </div>
          </div>
          <div class="right-box1" v-if="serveTab == 3">
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_4.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">商标驳回复审</p>
                <p class="trade-p2">商标申请被驳回，申请复<br />审加急处理</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_5.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">商标续展</p>
                <p class="trade-p2">面临被注销风险，及时续<br />展保护权利</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_6.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">商标异议答辩</p>
                <p class="trade-p2">商标被他人异议，答辩应<br />助成功</p>
              </div>
            </div>
          </div>
          <div class="right-box1" v-if="serveTab == 1">
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_USA.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">美国商标注册</p>
                <p class="trade-p2">注册时间：12-18个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_singapore.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">新加坡商标注册</p>
                <p class="trade-p2">注册时间：9-12个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_european_union.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">欧盟商标注册</p>
                <p class="trade-p2">注册时间：12-15个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_1_2.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">中国香港商标注册</p>
                <p class="trade-p2">注册时间：6-8个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_England.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">英国商标注册</p>
                <p class="trade-p2">注册时间：4-6个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_Germany.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">德国商标注册</p>
                <p class="trade-p2">注册时间：12-18个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_Japan.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">日本商标注册</p>
                <p class="trade-p2">注册时间：9-11个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/flag_France.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">法国商标注册</p>
                <p class="trade-p2">注册时间：6-9个月<br />有效期：10年</p>
              </div>
            </div>
            <div class="right1-inner">
              <img class="right1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/icon_2.png" alt="">
              <div class="right1-right">
                <p class="trade-p1">更多国际商标</p>
                <p class="trade-p2">人工客服在线</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-advantage know-advantage">
      <p class="b-title">快企务助您职业路上后顾无忧！</p>
      <p class="b-title1">一站式知识产权整合服务</p>
      <div class="m-adv-box">
        <div class="m-adv-right">
          <p class="m-ad-title">免费商标寄售</p>
          <p class="m-ad-p">免费上传通道，支持批量导入，极速审核</p>
          <p class="m-ad-title">商标价值评估</p>
          <p class="m-ad-p">大数据测算，保障评估更有意义</p>
          <p class="m-ad-title">商标续展</p>
          <p class="m-ad-p">商标有效期到期前一年，可对商标申请续展</p>
        </div>
        <div class="m-adv-left">
          <img class="m-adv-left-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/pic_1.png" alt="">
          <p class="m-adv-left-p1">商标价值评估</p>
          <p class="m-adv-left-p2">免费商标寄售</p>
          <p class="m-adv-left-p3">商标续展</p>
          <p class="m-adv-left-p4">客户</p>
        </div>
      </div>
    </div>
    <div class="one-server">
      <p class="b-title">一站式企业服务</p>
      <p class="b-title1">十余年专业涉内外企业服务综合平台</p>
      <div class="one-serve-box">
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/pic_2_1.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">全球公司注册</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/pic_2_2.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">国际公证认证</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/pic_2_3.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">全球银行开户</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
        <div class="one-serve-inner">
          <img class="one-serve-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/pic_2_4.png" alt="">
          <div class="hover-box"></div>
          <p class="one-serve-p">秘书服务</p>
          <p class="one-serve-p1" @click="Dialog = true">立即咨询 ></p>
        </div>
      </div>
    </div>
    <div class="k-choose">
      <p class="b-title">他们都选择快企务</p>
      <div class="k-choose-box">
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_1.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_2.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_3.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_4.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_5.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_6.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_7.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_8.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_9.png" alt="">
        </div>
        <div class="k-choose-inner">
          <img class="k-choose-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/logo_1_10.png" alt="">
        </div>
      </div>
    </div>
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template> 

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import NumberGrow from '@/components/numberGrow.vue'
export default {
  name: 'bank-page',
  components: {
    Footer,
    Link,
    NumberGrow
  },
  data(){
    return{
      Dialog: false,
      serveTab: 1
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
.konw-header{
  width: 100%;
  height:540px;
  background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/knowledge/banner.jpg') no-repeat center center;
  background-size: 100% 100%;
}
</style>
