<template>
  <div class="anquila french">
    <div class="bvi-header">
      <div class="banner-inner">
        <p class="banner-p1">【快企务专注于涉内外企业服务十余年】</p>
        <p class="banner-p2">一站式法国公司注册服务</p>
        <p class="banner-btn" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="french-advantage">
      <p class="re-title">注册法国公司优势</p>
      <div class="french-adv-box center-width flex-between">
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_1.png" alt="">
            <p class="french-adv-con1-p">法国公司运营成本更低</p>
          </div>
          <div class="french-adv-hover">
            <img class="french-adv-hover-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_6.png" alt="">
            <p class="french-adv-hover-p">法国公司运营成本更低</p>
            <p class="french-adv-con1-p1">仅包括会计及税务师费用、公司地址费用，银行账户费用等</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_2.png" alt="">
            <p class="french-adv-con1-p">入驻亚马逊或其他电商平台</p>
          </div>
          <div class="french-adv-hover">
            <img class="french-adv-hover-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_7.png" alt="">
            <p class="french-adv-hover-p">入驻亚马逊或其他电商平台</p>
            <p class="french-adv-con1-p1"></p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_3.png" alt="">
            <p class="french-adv-con1-p">打造国际品牌</p>
            <p class="french-adv-con1-p1"></p>
          </div>
          <div class="french-adv-hover">
            <img class="french-adv-hover-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_8.png" alt="">
            <p class="french-adv-hover-p">打造国际品牌</p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_4.png" alt="">
            <p class="french-adv-con1-p">法国增值税VAT获得更快</p>
          </div>
          <div class="french-adv-hover">
            <img class="french-adv-hover-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_9.png" alt="">
            <p class="french-adv-hover-p">法国增值税VAT获得更快</p>
            <p class="french-adv-con1-p1"></p>
          </div>
        </div>
        <div class="french-adv-inner">
          <div class="french-adv-con1">
            <img class="french-adv-con1-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_5.png" alt="">
            <p class="french-adv-con1-p">法国公司税收政策更优惠</p>
          </div>
          <div class="french-adv-hover">
            <img class="french-adv-hover-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_1_10.png" alt="">
            <p class="french-adv-hover-p">法国公司税收政策更优惠</p>
            <p class="french-adv-con1-p1"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="french-type">
      <p class="re-title">注册法国公司类型</p>
      <div class="french-type-top flex-between">
        <div class="top-inner-box" :class="{'active': tab == 1}" @click="tab = 1">
          <img class="top-inner-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_1.png" alt="">
          <img class="top-inner-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_2.png" alt="">
          <p class="top-inner-p">法国股份公司</p>
        </div>
        <div class="top-inner-box" :class="{'active': tab == 2}" @click="tab = 2">
          <img class="top-inner-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_3.png" alt="">
          <img class="top-inner-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_4.png" alt="">
          <p class="top-inner-p">法国独资公司</p>
        </div>
        <div class="top-inner-box" :class="{'active': tab == 3}" @click="tab = 3">
          <img class="top-inner-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_5.png" alt="">
          <img class="top-inner-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_6.png" alt="">
          <p class="top-inner-p">有限责任公司</p>
        </div>
        <div class="top-inner-box" :class="{'active': tab == 4}" @click="tab = 4">
          <img class="top-inner-icon1" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_7.png" alt="">
          <img class="top-inner-icon2" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_2_8.png" alt="">
          <p class="top-inner-p">法国合伙公司</p>
        </div>
      </div>
      <div class="french-type-banner" v-if="tab == 1">
        <img class="banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_1_1.jpg" alt="">
        <p class="type-banner-p1">法国股份公司</p>
        <p class="type-banner-p2" @click="Dialog = true">立即咨询</p>
      </div>
      <div class="french-type-banner" v-if="tab == 2">
        <img class="banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_1_2.jpg" alt="">
        <p class="type-banner-p1">法国独资公司</p>
        <p class="type-banner-p2" @click="Dialog = true">立即咨询</p>
      </div>
      <div class="french-type-banner" v-if="tab == 3">
        <img class="banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_1_3.jpg" alt="">
        <p class="type-banner-p1">有限责任公司</p>
        <p class="type-banner-p2" @click="Dialog = true">立即咨询</p>
      </div>
      <div class="french-type-banner" v-if="tab == 4">
        <img class="banner-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_1_4.jpg" alt="">
        <p class="type-banner-p1">法国合伙公司</p>
        <p class="type-banner-p2" @click="Dialog = true">立即咨询</p>
      </div>
    </div>
    <div class="french-file" style="height: 800px;">
      <p class="re-title">注册法国公司条件/流程/资料</p>
      <div class="french-file-box">
        <div class="french-file-tab">
          <p class="french-file-tab-p" :class="{'fileActive': fileTab == 1}" @click="fileTab = 1">注册法国公司条件</p>
          <p class="french-file-tab-p" :class="{'fileActive': fileTab == 2}" @click="fileTab = 2">注册法国公司流程</p>
          <p class="french-file-tab-p" :class="{'fileActive': fileTab == 3}" @click="fileTab = 3">注册完成后所得资料</p>
        </div>
        <div class="french-file-content" >
          <div class="french-file-inner" v-if="fileTab == 1">
            <div class="french-file1-box">
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">名称</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_1.png" alt="">
                </div>
                <p class="french-file1-p2">公司名称的后尾必须加上Soci-été à respon-sabilité limitée（有限责任公司）字样或其开头字母“S.A.R.L”</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">董事股东要求</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_2.png" alt="">
                </div>
                <p class="french-file1-p2">1人或以上年满18周岁的人士担任，无国籍限制</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">注册地址</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_3.png" alt="">
                </div>
                <p class="french-file1-p2">公司注册地址必须是法国，我司可提供</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">注册资本</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_4.png" alt="">
                </div>
                <p class="french-file1-p2">没有实际要求，不需要实际到位；（如需开户，资金需要实际到位）</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">持股率</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_5.png" alt="">
                </div>
                <p class="french-file1-p2">如果是多个股东，每个股东的持股率</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">经营范围</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_3_6.png" alt="">
                </div>
                <p class="french-file1-p2">公司的营业范围（请详细介绍）</p>
              </div>
            </div>
          </div>
          <div class="french-file-inner" v-if="fileTab == 2">
            <img class="Germany-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_1.png" alt="">
          </div>
          <div class="french-file-inner" v-if="fileTab == 3">
            <!-- <img class="Germany-file2-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/pic_3.png" alt=""> -->
            <div class="french-file1-box french-file3-box">
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">营业执照</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/else/icon_1_1.png" alt="">
                </div>
                <p class="french-file1-p2">公司营业执照一份</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">印章</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/else/icon_1_2.png" alt="">
                </div>
                <p class="french-file1-p2">公司印章一套</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">印章卡</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/else/icon_1_3.png" alt="">
                </div>
                <p class="french-file1-p2">公司印章卡一枚</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">章程</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/else/icon_1_4.png" alt="">
                </div>
                <p class="french-file1-p2">公司章程一份</p>
              </div>
              <div class="french-file1-inner">
                <div class="french-file1-title">
                  <p class="french-file1-p1">印章证明书</p>
                  <img class="french-file1-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/else/icon_1_5.png" alt="">
                </div>
                <p class="french-file1-p2">公司印章证明书一份</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subsequent subsequent-b french-serve">
      <p class="re-title">注册法国公司后为您提供的后续服务</p>
      <div class="sub-box">
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_1federal_tax_number.png" alt="">
          <p class="sub-p1">税号申请</p>
          <p class="sub-p3">法国VAT注册需要1-2个月左右，一年12次月申报，不需要做年度申报，法国申报...</p>
          <p class="sub-p4">法国VAT注册需要1-2个月左右，一年12次月申报，不需要做年度申报，法国申报税率是20%，适用于大部分商品和服务</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_2bank_account.png" alt="">
          <p class="sub-p1">银行开户</p>
          <p class="sub-p2">法国巴黎银行、法国兴业银行、里昂信贷银行、法国工商信贷银行等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_3secretarial_services.png" alt="">
          <p class="sub-p1">秘书服务</p>
          <p class="sub-p3">注册代理人或本地人代理人服务，办公地址，公司周年报表年检，公司董事股...</p>
          <p class="sub-p4">注册代理人或本地人代理人服务，办公地址，公司周年报表年检，公司董事股东成员名册更新，在职证明与良好证明，代缴政府规费，修改公司章程等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_4tax_declaration.png" alt="">
          <p class="sub-p1">做账报税</p>
          <p class="sub-p3">首年年底前向税务局提交1003P号表格，之后每年5月1日前提交1003号表格申...</p>
          <p class="sub-p4">首年年底前向税务局提交1003P号表格，之后每年5月1日前提交1003号表格申明公司财产等</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_5.png" alt="">
          <p class="sub-p1">商标注册</p>
          <p class="sub-p3">到国家工业产权局登记或到公司所在地区的商业法院登记。法国商标保护期为10...</p>
          <p class="sub-p4">到国家工业产权局登记或到公司所在地区的商业法院登记。法国商标保护期为10年。期满后半年内可以续展。</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
        <div class="sub-inner">
          <img class="sub-img" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/icon_6_6cancellation.png" alt="">
          <p class="sub-p1">注销服务</p>
          <p class="sub-p3">公司不再运营，或宣告破产、营业期限届满，公司内部分离解散等可以申请注...</p>
          <p class="sub-p4">公司不再运营，或宣告破产、营业期限届满，公司内部分离解散等可以申请注销，注销3个月内无人提出异议即可陈宫注销</p>
          <div class="sub-bottom">
            <img class="sub-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/ask.png" alt="">
            <p class="sub-ask" @click="Dialog = true">立即咨询</p>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <p class="re-title">法国注册常见问题</p>
      <div class="pro-box">
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="注册法国公司需要多久？是否需要亲自过去？" name="1">
            <div class="inner-pro">
              <div class="pro-p2">法国公司从初期准备材料到后面拿到公司文件，一共需要大约3周半，法人代表及股东不需要实际到场。</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="法国公司名称有什么限制吗？" name="2">
            <div class="inner-pro">
              <div class="pro-p2">(1) 公司名称避免带有完全外来名词，也不能在公司名称加上“法国”字眼。<br />
                (2) 公司应使用法语一般用词。<br />
                (3) 公司名的后尾须加上“Société à responsabilité limitée”字样或其开头字母“S、A、R、L</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="法国公司董事有什么要求？" name="3">
            <div class="inner-pro">
              <div class="pro-p2">至少有一个董事，该董事须是自然人。董事可以是任何国籍的人，并且不必居住在法国。董事会只涉及S.A.(股<br />
                份有限公司)，S.A.R.L(责任有限公司)只设定一个经营或持牌人(GERANT)管理公司。章程中应规定董事会的构<br />
                成形式及其权利，在法律上，董事会享有代表公司进行活动的充分权利。董事会应设董事长一人，董事若干人</div>
              <p class="pro-p3" @click="Dialog = true">立即咨询</p>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="problem-link" @click="Dialog = true">
        <img class="problem-link-icon" src="https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/else/icon_customer_service.png" alt="">
        <p class="problem-link-p">更多问题？立即咨询人工客服</p> 
      </div>
    </div>
    <div class="advantage k-advantage">
      <p class="re-title" style="margin-bottom: 20px">我们的优势</p>
      <p class="re-title1">专业法国公司注册十余年</p>
      <Advantage></Advantage>
    </div>
    <!-- <div class="case">
      <p class="re-title">案例客户</p>
    </div> -->
    <Link></Link>
    <Footer></Footer>
    <el-dialog :visible.sync="Dialog" title="" width="900px">
      <div style="width: 1000px; height: 600px;">
          <iframe
            src="https://dht.zoosnet.net/LR/Chatpre.aspx?id=DHT42713507&lng=cn"
            width="850px"
            height="100%"
            frameborder="0"
            scrolling="no">
          </iframe>
      </div>
    </el-dialog>
  </div>
  
</template>

<script>
import Footer from '@/components/Footer.vue'
import Link from '@/components/link.vue'
import Advantage from '@/components/Advantage.vue'
export default {
  name: 'bvi-page',
  components: {
    Footer,
    Link,
    Advantage
  },
  props: {
    msg: String
  },
  data(){
    return{
      Dialog: false,
      tab: 1,
      fileTab: 1,
      activeName: '1'
    }
  },
  mounted(){
    window.scrollTo(0,0);
  }
}
</script>

<style scoped lang="scss">
  .bvi-header{
    width: 100%;
    height: 600px;
    background: url('https://kuaiqiwu.oss-cn-hangzhou.aliyuncs.com/img/register/French/banner.jpg') no-repeat center center;
    background-size: 100% 100%;
  }
  
</style>
